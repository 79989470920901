import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AxiosResponse } from "axios";

import type { ServiceMessage } from "store/serviceMessages/types";
import {
  getServiceMessagesList,
  getServiceMessagesUnreadCount,
} from "store/serviceMessages/actions";
import { selectServiceMessages } from "store/serviceMessages/selectors";
import Api from "api";

interface Output {
  isFetchingList: boolean;
  pageNumber: number;
  totalElements: number;
  serviceMessagesList: ServiceMessage[];
  handleChangePageNumber: (nextPage: number) => void;
}

const useServiceMessagesScreen = (): Output => {
  const dispatch = useDispatch();

  const {
    list: serviceMessagesList,
    totalElements,
    isFetchingList,
  } = useSelector(selectServiceMessages);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const handleChangePageNumber = (checkedPage: number) => {
    setPageNumber(checkedPage - 1);
  };

  useEffect(() => {
    dispatch(getServiceMessagesList({ pageNumber }));
  }, [dispatch, pageNumber]);

  useEffect(() => {
    const fetch = async () => {
      const ids = serviceMessagesList.map((message) => message.id);

      await serviceMessagesMarkRead(ids);
      dispatch(getServiceMessagesUnreadCount());
    };

    fetch();
  }, [dispatch, serviceMessagesList]);

  return {
    isFetchingList,
    pageNumber,
    totalElements,
    serviceMessagesList,
    handleChangePageNumber,
  };
};

export const serviceMessagesMarkRead = async (
  ids: number[]
): Promise<AxiosResponse<any>> => {
  return Api.post(`/core-service/integration-notifications/mark-read`, { ids });
};

export default useServiceMessagesScreen;
