import { Button, Space, Table, Tooltip, ConfigProvider  } from 'antd';
import ScreenHeaderList from 'components/ScreenHeaderList/ScreenHeaderList';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getUsersList,
  getUsersViewType,
  archiveUser,
  unArchiveUser,
  saveUsersScreenPage
} from 'store/users/actions';
import { ReactComponent as EditSvg } from 'assets/images/basic-edit.svg';
import { ReactComponent as UnblockSvg } from 'assets/images/ic-block-unblock.svg';
import { ReactComponent as BlockSvg } from 'assets/images/ic-block.svg';
import {
  ISaveUsersScreenPage, ISaveUsersScreenPageAction,
  ISetUsersFailureAction,
  ISetUsersListAction,
  ISetUsersPendingAction,
  IsetUsersViewAction,
  IUserArchiveFailureAction,
  IUserArchiveSuccessAction,
  IUserUnArchiveFailureAction,
  IUserUnArchiveSuccessAction,
  IUserView
} from 'store/users/types';
import ListSearch from 'components/ListSearch/ListSearch';
import ArchiveModal from 'components/ArchiveModal/ArchiveModal';
import {users, smallNotification} from '../../globalConstants/textinputs.json';
import EmptyContent from "../../components/EmptyContent/EmptyContent";
import {
  setAdvertFilter,
  setIsFromAdvertPush
} from 'store/adverts/actions';
import { IFromAdvert, IFromAdvertAction, IGetAdvertAction } from 'store/adverts/types';
import { clientTagsMapper } from 'services/mapper';
import moment from 'moment';
import UserFilter from 'components/UserFilter/UserFilter';
import { ISaveStaffScreenPage, ISaveStaffScreenPageAction } from 'store/staff/types';
import { saveStaffScreenPage } from 'store/staff/actions';
import { ISaveContractScreenInfoAction, ISaveContractScreenPage } from 'store/contracts/types';
import { saveContractScreenPage } from 'store/contracts/actions';

interface IProps {
  getUsersList: (sortBy: string, sortType: string, page: number, size: number, unansweredProposals: string, inactivityPeriod: boolean, rsql?: string) => Promise<ISetUsersListAction | ISetUsersPendingAction | ISetUsersFailureAction>;
  users: any;
  user: any;
  history: any;
  setAdvertFilter: (data: any) => IGetAdvertAction;
  getUsersViewType: (data: IUserView) => IsetUsersViewAction;
  archiveUser: (id: number) => Promise<IUserArchiveSuccessAction | IUserArchiveFailureAction>;
  unArchiveUser: (id: number) => Promise<IUserUnArchiveSuccessAction | IUserUnArchiveFailureAction>;
  saveUsersScreenPage: (usersScreenPage :ISaveUsersScreenPage) => ISaveUsersScreenPageAction;
  setIsFromAdvertPush: (isFromAdvert: IFromAdvert) => IFromAdvertAction;
  saveStaffScreenPage: (staffScreenPage: ISaveStaffScreenPage) => ISaveStaffScreenPageAction;
  saveContractScreenPage: (staffScreenPage: ISaveContractScreenPage) => ISaveContractScreenInfoAction;
}

const mapStateToProps = state => ({
  users: state.users,
  user: state.user,
});

const mapDispatchToProps = {
  getUsersList,
  getUsersViewType,
  archiveUser,
  unArchiveUser,
  saveUsersScreenPage,
  setIsFromAdvertPush,
  setAdvertFilter,
  saveStaffScreenPage,
  saveContractScreenPage
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UsersScreen: FC<IProps> = (props) => {

  const [currentPage, setCurrentPage] = React.useState<number>(props.users?.usersScreenPage?.currentPage)

  const [isArchiveModalVisible, setIsArchiveModalVisible] = React.useState(false);

  const [ArchiveModalContent, setrchiveModalContent] = React.useState({ id: "", status: "" });

  const [rsql, setRsql] = React.useState(props.users?.usersScreenInfo?.string);

  useEffect(() => {
    props.getUsersList('id', 'desc', currentPage, 10, props.users?.usersScreenInfo?.inactivityPeriod || "", props.users?.usersScreenInfo?.unansweredProposals || false, `&search=${rsql}`)
    props.setIsFromAdvertPush({isFromAdvert:props.history.location.pathname})
    props.setAdvertFilter({})
    props.saveStaffScreenPage({
      currentPage: 0
    })
    props.saveContractScreenPage({
      currentPage: 0
    })
  }, [])

  const viewTypeHandler = (value) => (e) => {
    props.getUsersViewType(value)
    props.saveUsersScreenPage({
      currentPage: currentPage
    })
  }

  const actions = props.user?.userRole === "ROLE_ADMIN" ?
    {
      title: users.list.tableColActions,
      render: (record) => {
        return (
          <Space size="middle">
            <>
              <Tooltip title={record.status !== "DELETED" ?`${users.list.tableColActionsEditTooltip}` : null}><Button className={record.status === "DELETED" ? "deleted-user" : ""} type="link" disabled={record.status === "DELETED"}><Link to={`/dashboard/users/${record.id}`} onClick={viewTypeHandler(true)}><EditSvg /></Link></Button></Tooltip>
              <Tooltip title={record.status !== "DELETED" ? (record.status === "ACTIVE" || record.status === "NOT_VERIFIED" ? users.list.tableColActionsBlockTooltip : users.list.tableColActionsUnBlockTooltip) : null}>
                <Button type="link" onClick={modalArchiveHandler(record.id, record.status)} className={record.status === "DELETED" ? "deleted-user" : ""} disabled={record.status === "DELETED"}>
                  {record.status === "ACTIVE" || record.status === "NOT_VERIFIED" ? <UnblockSvg /> : <BlockSvg />}
                </Button>
              </Tooltip>
            </>
          </Space>
        )
      }
    } : {}

  const columns: Array<any> = [
    {
      title: users.list.tableColNameLastName,
      dataIndex: 'fio',
      sorter: true,
      showSorterTooltip: false,
      render: (fio, record) => {
        return <Link className={record.status === "DELETED" ? "deleted-user-string" : ""} to={`/dashboard/users/${record.id}`} onClick={viewTypeHandler(false)}>
          {fio?.lastName} {fio?.firstName}
        </Link>
      }
    },
    {
      title: users.list.tableColPhone,
      dataIndex: 'phone',
      render: (phone, record) => {
        return <span className={record.status === "DELETED" ? "deleted-user-string" : ""}>{phone}</span>
      }
    },
    {
      title: users.list.tableColCount,
      dataIndex: 'offersCount',
      sorter: true,
      showSorterTooltip: false,
      render: (offersCount, record) => {
        return <span className={record.status === "DELETED" ? "deleted-user-string" : ""}>{offersCount}</span>
      }
    },
    {
      title: users.list.tableColCreatedDate,
      dataIndex: 'createdDate',
      sorter: true,
      showSorterTooltip: false,
      render: (createdDate, record) => {
        return <span className={record.status === "DELETED" ? "deleted-user-string" : ""}>{moment(createdDate).format('DD.MM.YYYY')}</span>
      }
    },
    {
      title: users.list.tableColAgentNumber,
      dataIndex: 'agentPhoneNumber',
      showSorterTooltip: false,
      render: (agentPhoneNumber, record) => {
        return <span className={record.status === "DELETED" ? "deleted-user-string" : ""}>{agentPhoneNumber}</span>
      }
    },
    {
      title: users.list.tableColTag,
      dataIndex: 'tag',
      sorter: true,
      showSorterTooltip: false,
      render: (tag, record) => {
        return <span className={record.status === "DELETED" ? "deleted-user-string" : ""}>{clientTagsMapper(tag)}</span>
      }
    },
    {
      title: users.list.tableColLastSeen,
      dataIndex: 'lastSeenDate',
      showSorterTooltip: false,
      render: (lastSeenDate, record) => {
        return <span className={record.status === "DELETED" ? "deleted-user-string" : ""}>{lastSeenDate && moment(lastSeenDate).format('DD.MM.YYYY')}</span>
      }
    },
    actions
  ];

  const modalArchiveHandler = (id, status) => (e) => {
    setrchiveModalContent({ id: id, status: status })
    setIsArchiveModalVisible(true)
  }

  const tableOnchangeHandler = (pagination: any, filter: any, sorter: any) => {
    let orderBy = sorter.order === "ascend" ? "asc" : "desc"
    if (sorter.field === "fio") {
      sorter.field = "fio.lastName"
    }
    if (sorter.field === "offersCount") {
      sorter.field = "publicationStatistic.totalPublished"
    }
    setCurrentPage(pagination.current - 1);
    props.getUsersList(sorter.field || "id", orderBy || "desc", pagination.current - 1, pagination.pageSize, props.users?.usersScreenInfo?.inactivityPeriod || "" , props.users?.usersScreenInfo?.unansweredProposals || false,`&search=${rsql}`)
  }

  const filterSearch = (rsqlString, unansweredProposals, inactivityPeriod) => {
    setRsql(rsqlString)
    props.getUsersList('id', 'desc', 0, 10, inactivityPeriod, unansweredProposals,`&search=${rsqlString}`)
  }

  const handleModalClose = () => {
    setIsArchiveModalVisible(false)
  }

  const archiveHandlerCallback = (id, status) => {
    if (status === "BLOCKED") {
      props.unArchiveUser(id).then(resp => {
        if (resp.type === "UNARCHIVE_USER_SUCCESS") {
          props.getUsersList('id', 'desc', props.users.results.number, 10,props.users?.usersScreenInfo?.inactivityPeriod || "" ,props.users?.usersScreenInfo?.unansweredProposals || false, rsql)
        } else {
          // if (resp.payload.error === "ERR_STAFF_008") {
          //   openNotification('error', smallNotification.errorTitle, "Спiвробiтник не може заблокувати сам себе!")
          // }
        }
      })
    } else {
      props.archiveUser(id).then(resp => {
        if (resp.type === "ARCHIVE_USER_SUCCESS") {
          props.getUsersList('id', 'desc', props.users.results.number, 10, props.users?.usersScreenInfo?.inactivityPeriod || "" ,props.users?.usersScreenInfo?.unansweredProposals || false, rsql)
        } else {
          // if (resp.payload.error === "ERR_STAFF_008") {
          //   openNotification('error', smallNotification.errorTitle, "Спiвробiтник не може заблокувати сам себе!")
          // }
        }
      })
    }
  }

  return (
    <div className="users-screen">
      <ArchiveModal
        isModalVisible={isArchiveModalVisible}
        handleModalClose={handleModalClose}
        id={ArchiveModalContent.id}
        status={ArchiveModalContent.status}
        archivePartnerHandler={archiveHandlerCallback}
        archivedText={<span>{users.blockModal.unBlockMessagePartOne}<br />{users.blockModal.unBlockMessagePartTwo}</span>}
        unArchivedText={<span>{users.blockModal.blockMessagePartOne}<br /> {users.blockModal.blockMessagePartTwo}</span>}
      />
      <ScreenHeaderList
        title={users.list.mainHeading}
        isCreate={false}
      />
      <UserFilter filterSearch={filterSearch}/>
      <ConfigProvider renderEmpty={() => <EmptyContent messageOne={users.errors.noSearchedUsersFoundMessageOne} messageTwo={users.errors.noSearchedUsersFoundMessageTwo}/>}>
        <Table
            columns={columns}
            className={props.user?.userRole === "ROLE_ADMIN" ? "" : "custom-paddings"}
            dataSource={props.users.results?.content || []}
            onChange={tableOnchangeHandler}
            pagination={{
              defaultPageSize: 10,
              total: props.users.results?.totalElements,
              showSizeChanger: false,
              pageSizeOptions: ['10', '20', '30'],
              position: ["bottomCenter"],
              hideOnSinglePage: true,
              current: currentPage + 1
            }}
            loading={props.users.isFetching}
        />
      </ConfigProvider>

    </div>
  );
};

export default connector(UsersScreen);