import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import React, { FC, useEffect } from 'react';
import "./ChatUserInfo.scss";
import { ReactComponent as DeclineSvg } from 'assets/images/ic-choise-decline.svg'
import { ReactComponent as UsersSvg } from 'assets/images/ic-users.svg'
import { ReactComponent as TagsSvg } from 'assets/images/ic-tags.svg'
import { ReactComponent as TagsBigSvg } from 'assets/images/ic-tags-big.svg'
import { ReactComponent as TagsMediumSvg } from 'assets/images/ic-tags-medium.svg'
import { ReactComponent as DeleteSvg } from 'assets/images/ic-delete-medium.svg'
import { ReactComponent as AlarmSvg } from 'assets/images/ic-alarm.svg'
import { ReactComponent as PhoneSvg } from 'assets/images/ic-phone.svg'
import { ReactComponent as MailSvg } from 'assets/images/ic-mail.svg'
import { ReactComponent as ProfileSvg } from 'assets/images/ic-profile-outline.svg'
import { ReactComponent as InfoSvg } from 'assets/images/ic-info.svg'

import DropdownComponent from 'components/DropdownComponent/DropdownComponent';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IsetAddListAction } from 'store/adverts/types';
import { getAdvertList } from 'store/adverts/actions';
import { adStatusMapper, contractPrepaymentMapper, contractStatusMapper } from 'services/mapper';
import { getContractsList } from 'store/contracts/actions';
import { IsetContractsFailureAction, IsetContractsListAction, IsetContractsPendingAction } from 'store/contracts/types';
import SimpleModal from 'components/SimpleModal/SimpleModal';
import { useForm } from 'antd/lib/form/Form';
import { createTag, getTagsList, addNewTag, updateTagsFlag, closeChat, getChatList, addRemember, getReminderList, removeReminde } from 'store/chats/actions';
import { values } from 'lodash';
import moment from 'moment';
import InfoModal from 'components/InfoModal/InfoModal';
import RemoveModal from 'components/RemoveModal/RemoveModal';


const mapStateToProps = state => ({
  chats: state.chats,
  user: state.user
});

const mapDispatchToProps = {
  getAdvertList,
  getContractsList,
  createTag,
  getTagsList,
  addNewTag,
  updateTagsFlag,
  closeChat,
  getChatList,
  addRemember,
  getReminderList,
  removeReminde
};

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps {
  chats: any;
  closeChat: any;
  createTag: any;
  getTagsList: any;
  addNewTag: any;
  updateTagsFlag: any;
  user: any;
  getChatList: any;
  addRemember: any;
  getReminderList: any;
  removeReminde: any;
  getAdvertList: (sortBy: string, sortType: string, page?: number, size?: number, rsql?: string) => Promise<IsetAddListAction>;
  getContractsList: (sortBy: string, sortType: string, page: number, size: number, rsql?: string) => Promise<IsetContractsListAction | IsetContractsPendingAction | IsetContractsFailureAction>;
}

const ChatUserInfo: FC<IProps> = (props) => {

  const { single, actualChat } = props.chats

  const { userInfo } = props.user

  const [form] = useForm();

  const [tagForm] = useForm();

  const [ReminderForm] = useForm();

  const [adverts, setAdverts] = React.useState([]);

  const [deals, setDeals] = React.useState([]);

  const [isTags, setIsTags] = React.useState(false);

  const [isSimpleModalVisible, setIsSimpleModalVisible] = React.useState(false);

  const [isSimpleTagsModalVisible, setIsSimpleTagsModalVisible] = React.useState(false);

  const [isReminderModalVisible, setIsReminderModalVisible] = React.useState(false);

  const [isCloseChatModalVisible, setIsCloseChatModalVisible] = React.useState(false);

  const [isEpmtyTagsChatModalVisible, setIsEpmtyTagsModalVisible] = React.useState(false);

  const [setTags, isSetTags] = React.useState([])

  const [setReminders, isSetReminders] = React.useState([])

  const [tagstListForBack, setTagsListForBack] = React.useState(null)

  useEffect(() => {
    setIsTags(false)
    if (single.length) {
      props.getAdvertList('id', 'desc', 0, 2000, `&search=author.id==${single[0].customer?.customerId}`).then(resp => {
        setAdverts(resp.preview.content)
      })
      props.getContractsList('id', 'desc', 0, 2000, `&search=seller.id==${single[0].customer?.customerId},buyer.id==${single[0].customer?.customerId}`).then(resp => {
        setDeals(resp.preview.content)
      })
      getTags()
      //getReminders(single[0].roomId)
    }
  }, [props.chats.single])

  useEffect(() => {
    if (props.chats.singleChat.content?.length) {
      setIsTags(true)
    }
  }, [props.chats.singleChat])

  const renderAdvertsList = (value) => {
    return value.map(item => {
      return (
        <div className="advert-info-item" key={item.id}>
          <h3>{`№ ДЗК: ${item.cadastralNumber}`}</h3>
          <h4>{`ID ${item.id}`}</h4>
          {item.address && <p>{`${item.address?.district} р., ${item.address?.region} обл., ${item.address?.settlement || ""}`}</p>}
          <span>{`${item.price} грн`}</span>
          <p className="info">
            {item.adStatus && adStatusMapper(item.adStatus)}
            {item.prepaymentStatus && contractStatusMapper(item.dealStatus)}
          </p>
        </div>
      )
    })
  }

  const tagsHandler = () => {
    setIsSimpleModalVisible(true)
  }

  const addTagsHandler = () => {
    setIsSimpleTagsModalVisible(true)
  }

  const addReminderHandler = () => {
    setIsReminderModalVisible(true)
    ReminderForm.resetFields()
  }

  const formSubmitHandler = () => {
    form.submit()
  }

  const getTags = () => {
    props.getTagsList('id', 'desc', 0, 2000).then(resp => {
      isSetTags(resp.preview.content)
    })
  }

  // const getReminders = (roomId) => {
  //   props.getReminderList(roomId).then(resp => {
  //     isSetReminders(resp.preview)
  //   })
  // }

  const onFinish = (values: any) => {
    props.createTag({ tagName: values.newTag }).then(resp => {
      if (resp.status === "OK") {
        getTags()
      }
    })
    setIsSimpleModalVisible(false)
    form.resetFields()
  }

  const tagsSelectHandler = value => {
    console.log(value)
    setTagsListForBack(value)
  }
  const addTagHandler = () => {
    props.addNewTag(
      {
        "tagIds": [
          tagstListForBack
        ]
      }, actualChat[0].id).then(resp => {
        props.updateTagsFlag(true)
      })
    setIsSimpleTagsModalVisible(false)

  }

  const tagsListRender = (values) => {
    return values.map((item, index) => {
      return (
        <div className="tag-item" key={index}>
          <TagsMediumSvg />
          <div className="tags-naming">
            <p>{item.name}</p>
            <span>{moment.utc(item.createdAt).local().format('YYYY.MM.DD, HH:mm')}</span>
          </div>
          {/* <DeleteSvg className="remove-svg" /> */}
        </div>
      )
    })
  }

  // const removeRemidner = (id) => e => {
  //   props.removeReminde(id).then(resp => {
  //     console.log(resp)
  //     if(resp.type === "GET_REMOVE_REMINDE_SUCCESS") {
  //       getReminders(single[0].roomId)
  //     }
  //   })
  // }

  // const reminderListRender = (values) => {
  //   console.log('asdasdsad', values)
  //   return values.map((item, index) => {
  //     return (
  //       <div className="tag-item" key={item.id}>
  //         <AlarmSvg />
  //         <div className="tags-naming">
  //           <div className="top-wrapper">
  //             <span className="date">{moment.utc(item.activationTime).local().format('YYYY.MM.DD, HH:mm')}</span>
  //             <span className="name">{item.manager?.fio?.lastName} {item.manager?.fio?.firstName}</span>
  //           </div>
  //           <div className="bot-wrapper">
  //             <p>{item.text}</p>
  //           </div>
  //         </div>
  //         <DeleteSvg className="remove-svg" onClick={removeRemidner(item.id)}/>
  //       </div>
  //     )
  //   })
  // }

  const infoBackHandler = () => {
    // setIsEdited(false)
    setIsEpmtyTagsModalVisible(false)
    // form.resetFields();
  }

  const closeChatHandler = () => {
    if (!actualChat[0].tags.length) {
      setIsEpmtyTagsModalVisible(true)
    } else {
      setIsCloseChatModalVisible(true)
    }

  }

  const closeChatHandlerCallback = (id) => {
    props.closeChat(id).then(resp => {
      props.getChatList(userInfo.id)
    })
  }

  const formSubmitHandlerZ = () => {
    ReminderForm.submit()
  }

  const onFinishReminder = (values) => {
    let activationTime = `${values.reminderDate.toISOString().split('T')[0]}T${values.reminderTime.toISOString().split('T')[1]}`
    let data = {
      "activationTime": activationTime,
      "text": values.reminderText,
      "chatId": single[0].chatId,
    }
    props.addRemember(data).then(resp => {
      console.log(resp)
      // if (resp.type === "ADD_REMEMBER_SUCCESS") {
      //   setIsReminderModalVisible(false)
      //   getReminders(single[0].roomId)
      // }
    })
  }

  const openTagsHandler = () => {
    setIsEpmtyTagsModalVisible(false)
    setIsSimpleTagsModalVisible(true)
  }
  return (
    <div className="right-side">
      {/* setIsCloseChatModalVisible */}
      <RemoveModal
        isSaved={true}
        title={"Завершити бесіду"}
        isModalVisible={isCloseChatModalVisible}
        closeModal={setIsCloseChatModalVisible}
        img={<InfoSvg />}
        text={<span>Ви дійсно бажаєте завершити бесіду? <br />Історія бесіди буде доступна після завершення.</span>}
        cancelBtnText={"Скасувати"}
        successBtnText={"Завершити"}
        id={actualChat[0]?.id}
        removeChatHandler={closeChatHandlerCallback}
      />
      <InfoModal
        isSaved={true}
        title={"Увага!"}
        isModalVisible={isEpmtyTagsChatModalVisible}
        closeModal={setIsEpmtyTagsModalVisible}
        onSubmit={infoBackHandler}
        img={<InfoSvg />}
        text={<span>Для завершення бесіди вам потрібно додати хоча б один тег. <br />Історія бесіди буде доступна після завершення.</span>}
        cancelBtnText={"Скасувати"}
        successBtnText={"Перейти до тегів бесіди"}
        openTagsHandler={openTagsHandler}
      //handleCancel={}
      />
      <SimpleModal
        isSaved={false}
        closable={true}
        title={"Нагадування"}
        isModalVisible={isReminderModalVisible}
        closeModal={setIsReminderModalVisible}
        cancelBtnText={"Скасувати"}
        successBtnText={"Додати"}
        onSubmit={formSubmitHandlerZ}
        modalName={"lefted tags"}
        fileList={'1'}
      >
        <>

          {/* {isTags && actualChat[0]?.tags?.length ?
              <div className="tags-list-wrapper">
                {tagsListRender(actualChat[0].tags)}
              </div>
              :
              <div className="no-tags">
                <TagsBigSvg />
                <p>У циєї бесіди ще немає тегів</p>
              </div>} */}
          {/* {setReminders.length ?
            <div className="reminders-list-wrapper">
              {reminderListRender(setReminders)}
            </div>
            :
            <div className="no-tags">
              <AlarmSvg />
              <p>У вас немає запланованих нагадувань у цьому чаті</p>
            </div>
          } */}
          {/*  */}
          <h3>Додати нагадування</h3>

          <Form
            name="tags-modal-form"
            form={ReminderForm}
            onFinish={onFinishReminder}
          >
            <div className="col-wrapper">
              <Form.Item
                name="reminderDate"
                rules={[
                  { required: true, message: 'Заповнiть це поле' }
                ]}
              >
                <DatePicker placeholder="Дата" />
              </Form.Item>
              <Form.Item
                name="reminderTime"
                rules={[
                  { required: true, message: 'Заповнiть це поле' }
                ]}
              >
                <TimePicker placeholder="Час" defaultOpenValue={moment('00:00', 'HH:mm')} format={'HH:mm'} />
              </Form.Item>
            </div>
            <Form.Item
              name="reminderText"
              rules={[
                { required: true, message: 'Заповнiть це поле' }
              ]}
            >
              <Input placeholder="Нагадування" />
            </Form.Item>
          </Form>
        </>
      </SimpleModal>
      <SimpleModal
        isSaved={false}
        closable={true}
        title={"Створити тег бесіди"}
        isModalVisible={isSimpleModalVisible}
        closeModal={setIsSimpleModalVisible}
        cancelBtnText={"Скасувати"}
        successBtnText={"Додати"}
        onSubmit={formSubmitHandler}
        modalName={"lefted tags"}
        fileList={'1'}
      >
        <>
          {/* <div className="tags-list-wrapper">
            {isTags && actualChat[0]?.tags?.length ?
              <div className="tags-list-wrapper">
                {tagsListRender(actualChat[0].tags)}
              </div>
              :
              <div className="no-tags">
                <TagsBigSvg />
                <p>У циєї бесіди ще немає тегів</p>
              </div>}
          </div> */}
          <p className="tags-label">Створіть новий тег:</p>
          <Form
            name="tags-modal-form"
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name="newTag"
              rules={[
                { required: true, message: 'Заповнiть це поле' }
              ]}
            >
              <Input placeholder="Створити новий тег" />
            </Form.Item>
          </Form>
        </>
      </SimpleModal>

      <SimpleModal
        isSaved={false}
        closable={true}
        title={"Додати тег бесіди"}
        isModalVisible={isSimpleTagsModalVisible}
        closeModal={setIsSimpleTagsModalVisible}
        cancelBtnText={"Скасувати"}
        successBtnText={"Додати"}
        onSubmit={addTagHandler}
        modalName={"lefted tags"}
        fileList={'1'}
      >
        <>
          {actualChat && actualChat[0]?.tags?.length ?
            <div className="tags-list-wrapper">
              {console.log(actualChat[0].tags)}
              {tagsListRender(actualChat[0].tags)}
            </div>
            :
            <div className="no-tags">
              <TagsBigSvg />
              <p>У циєї бесіди ще немає тегів</p>
            </div>}
          <p className="tags-label">Виберіть тег зі списку:</p>
          <Form
            name="tags-modal-form"
            form={tagForm}
            onFinish={onFinish}
          >
            <Form.Item
              name="tags"
              rules={[
                { required: true, message: 'Заповнiть це поле' }
              ]}
            >
              <Select
                placeholder="Виберіть тег…"
                onChange={tagsSelectHandler}
              >
                {setTags.filter(item => !actualChat[0]?.tags.map(item => item.name).includes(item.name)).map((item, index) => ( 
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </>
      </SimpleModal>
      <div className="top-part">
        {single.length ?
          <div className="profile-wrapper">
            <div className="profile-head">
              <h3>{`${single[0].customer?.fio.firstName} ${single[0].customer?.fio.lastName}`}</h3>
              {single[0].customer?.phone && <p><PhoneSvg />{single[0].customer?.phone}</p>}
              {single[0].customer?.email && <p><MailSvg />{single[0].customer?.email}</p>}
            </div>
            <Link to={`/dashboard/users/${single[0].customer?.customerId}`}><Button size="small" type="default" id="chat-alarms"><ProfileSvg />Профіль користувача</Button></Link>
            <DropdownComponent title="Оголошення користувача" id="chat-user-adverts" items={adverts.length}>
              <div className="drodown-list">
                {renderAdvertsList(adverts)}
              </div>
            </DropdownComponent>
            <DropdownComponent title="Угоди користувача" id="chat-user-contracts" items={deals.length}>
              <div className="drodown-list">
                {renderAdvertsList(deals)}
                {/* <div className="advert-info-item">
                  <h3>№ ДЗК: 1234567890:89:123:1234</h3>
                  <h4>ID 000123456 </h4>
                  <p>Звенигородський р., Чернівецька обл., м. Кропивницький </p>
                  <span>1 250 000 грн</span>
                  <p className="info">Повні дані, очікується модерація</p>
                </div> */}
              </div>
            </DropdownComponent>
          </div>
          :
          <div className="empty-wrapper">
            <ProfileSvg />
            <p>Тут буде відображатися<br /> інформація про користувача</p>
          </div>
        }
      </div>
      <div className="bot-part">
        {/* <Button size="small" type="default" id="chat-alarms" disabled={!isTags} onClick={addReminderHandler}><AlarmSvg />Нагадування</Button> */}
        <Button size="small" type="default" id="chat-tags" disabled={!isTags} onClick={addTagsHandler}><TagsSvg />Додати тег бесіди</Button>
        {props.user?.userRole === "ROLE_ADMIN" && <Button size="small" type="default" id="chat-tags" onClick={tagsHandler}><TagsSvg />Створити тег бесіди</Button>}
        {/* <Button size="small" type="default" id="send-chat"><UsersSvg />Передати бесіду</Button> */}
        <Button size="small" type="default" id="finish-dialog" disabled={!isTags || actualChat[0]?.chatStatus === "CLOSED"} onClick={closeChatHandler}><DeclineSvg />Завершити бесіду</Button>
      </div>
    </div>
  );
};

export default connector(ChatUserInfo);