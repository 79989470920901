import React, {FC} from 'react';
import './OwnerInfoBlock.scss';
import {connect} from "react-redux";
import {Button, Col, Form, Input, Row} from "antd";
import {ReactComponent as EditSvg} from 'assets/images/ic-edit-black.svg'
import {adverts as advertText, contracts} from "../../globalConstants/textinputs.json";
import {editAdvertOwner} from "../../store/adverts/actions";
import {
    IEditAdvertOwnerFailureAction,
    IEditAdvertOwnerSuccessAction
} from "../../store/adverts/types";

interface IProps {
    ownerLastName: string;
    ownerFirstName: string;
    ownerPatronymic: string;
    ownerPhone: string;
    updateAdvert: any;
    adID: number;
    editAdvertOwner: (adID: number, body: any) =>  Promise<IEditAdvertOwnerSuccessAction | IEditAdvertOwnerFailureAction>;
}


const mapDispatchToProps = {
    editAdvertOwner
};

const connector = connect(null, mapDispatchToProps)

export const OwnerInfoBlock: FC<IProps> = (props) => {

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const {editAdvertOwner} = props;

    const editInfo = () => {
        setIsEdit(true)
    }

    const onFinish = (values) => {
        Object.keys(values).forEach(agentItem => {
            if (values[agentItem] == undefined || values[agentItem] == "") {
                values[agentItem] = null
            }
            return values[agentItem]
        })

        let data = {
            "fio": {
                "firstName": values.firstName,
                "lastName": values.lastName,
                "patronymic": values.patronymic
            },
            "phone": `+${values.phone}`
        }

        editAdvertOwner(props.adID, data).then(resp => {
            if (resp.status === "OK") {
                setIsEdit(false);
                props.updateAdvert();
            }
        })


    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }


    return (
        <>
            {

                isEdit ? <Form name="owner-info-form" onFinish={onFinish} onFinishFailed={onFinishFailed}
                               initialValues={{
                                   firstName: props.ownerFirstName,
                                   lastName: props.ownerLastName,
                                   patronymic: props.ownerPatronymic,
                                   phone: props.ownerPhone.replace('+', '')
                               }}>
                        <Row gutter={24} className="owner-row owner-row-edit">
                            <Col span={3}>
                                <p className="owner-edit-label">{advertText.single.documents.ownerEditLabel}</p>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="lastName" rules={[{
                                    required: true,
                                    message: advertText.single.documents.requiredFieldErrorMessage
                                }]}>
                                    <Input className="owner-row-edit-lastname-input"/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="firstName" rules={[{
                                    required: true,
                                    message: advertText.single.documents.requiredFieldErrorMessage
                                }]}>
                                    <Input className="owner-row-edit-firstname-input"/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="patronymic" rules={[{
                                    required: true,
                                    message: advertText.single.documents.requiredFieldErrorMessage
                                }]}>
                                    <Input className="owner-row-edit-patronymic-input"/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="phone" rules={[{
                                    required: true,
                                    message: advertText.single.documents.requiredFieldErrorMessage
                                }]}>
                                    <Input prefix={"+"} className="owner-row-edit-phone-input"/>
                                </Form.Item>
                            </Col>
                            <Col span={5} className='confirm-edit-btn-col'>
                                <Button type="primary" htmlType='submit' className="confirm-edit-btn owner-row-edit-confirm-btn" size="small">
                                    {advertText.single.documents.submitOwnerInfoBtn}
                                </Button>
                            </Col>
                        </Row>
                    </Form> :
                    <Row gutter={24} className="owner-row owner-row-view">

                        <Col span={8}>
                            <span className="title">{advertText.single.documents.ownerNameLabel}</span>
                            <p className="name owner-row-view-name">{props.ownerLastName || advertText.single.notStated} {props.ownerFirstName || advertText.single.notStated} {props.ownerPatronymic || null}</p>
                        </Col>
                        <Col span={5}>
                            <span className="title">{advertText.single.documents.ownerPhone}</span>
                            <p className="name owner-row-view-phone">{props.ownerPhone || advertText.single.notStated}</p>
                        </Col>
                        <Col span={1}>
                            <button type="button" className="edit-owner-btn owner-row-view-edit-btn" onClick={editInfo}>
                                <EditSvg/>
                            </button>
                        </Col>
                        <Col>
                            <p className="owner-name-tip owner-row-view-tip">{advertText.single.documents.ownerNameTip}</p>
                        </Col>
                    </Row>

            }


        </>

    );
};

export default connector(OwnerInfoBlock);