import { Button, Space, Table, Tooltip } from 'antd';
import React, { FC, useEffect } from 'react';
import "./PricesScreen.scss";
import { ReactComponent as AttachSvg } from 'assets/images/ic-attach.svg'
import { ReactComponent as DownloadSvg } from 'assets/images/ic-download.svg'

import moment from 'moment';
import { getPricesList, downloadPricesFile } from 'store/prices/actions';
import { connect } from 'react-redux';
import { IDownloadPricesAction, IDownloadPricesFailureAction, IDownloadPricesPendingAction, ISetPricesFailureAction, ISetPricesListAction, ISetPricesPendingAction } from 'store/prices/types';
import UploadPricesModal from 'components/UploadPricesModal/UploadPricesModal';
import CalculatorModal from "../../components/CalculatorModal/CalculatorModal";
import {marketPrices} from "../../globalConstants/textinputs.json";

const mapStateToProps = state => ({
  prices: state.prices,
});

const mapDispatchToProps = {
  getPricesList,
  downloadPricesFile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IProps {
  prices: any;
  getPricesList: (sortBy: string, sortType: string, page: number, size: number) => Promise<ISetPricesListAction | ISetPricesPendingAction | ISetPricesFailureAction>;
  downloadPricesFile: (id: string) => Promise<IDownloadPricesAction | IDownloadPricesPendingAction | IDownloadPricesFailureAction>
}

export const PricesScreen: FC<IProps> = (props) => {
  useEffect(() => {
    renderPricesList()
  }, [])

  const [isUploadModalVisible, setIsUploadModalVisible] = React.useState(false);

  const [isCalculatorModalVisible, setIsCalculatorModalVisible] = React.useState(false);

  const columns: Array<any> = [
    {
      title: marketPrices.tableColName,
      dataIndex: 'fileName',
      className: 'fileName'
    },
    {
      title: marketPrices.tableColDownloadedBy,
      dataIndex: 'actor',
      className: 'actor',
      render: (actor, record) => {
        return `${actor?.lastName} ${actor?.firstName}`
      }
    },
    {
      title: marketPrices.tableColDownloadDate,
      dataIndex: 'createdDate',
      className: 'createdDate',
      render: (createdDate, record) => {
        return `${moment(createdDate).format('DD.MM.YYYY, hh:mm')}`
      }
    },
    {
      title: marketPrices.tableColActions,
      className: 'actions',
      render: (record) => {
        return (
          <Space size="middle">
            <Tooltip title={marketPrices.tableColActionsDownloadTooltip}><Button type="link" ><DownloadSvg onClick={downloadFileHandler(record.id, record.fileName)} /></Button></Tooltip>
          </Space>
        )
      }
    }
  ];

  const downloadFileHandler = (data, name) => e => {
    props.downloadPricesFile(data).then(res => {
    //@ts-ignore
    var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    });
  }

  const tableOnchangeHandler = (pagination: any, filter: any, sorter: any) => {
    props.getPricesList("id", "desc", pagination.current - 1, pagination.pageSize)
  }

  const uploadPricesHandler = () => {
    setIsUploadModalVisible(true)
  }

  const openCalculatorModalHandler = () => {
      setIsCalculatorModalVisible(true);
  }

  const renderPricesList = () => {
    props.getPricesList('id', 'desc', 0, 10)
  }

  return (
    <div className="prices-wrapper">
      <UploadPricesModal
        closeModal={setIsUploadModalVisible}
        isModalVisible={isUploadModalVisible}
        renderPricesList={renderPricesList}
      />
      <CalculatorModal isModalVisible={isCalculatorModalVisible}
      closeModal={setIsCalculatorModalVisible}/>

      <div className="title-wrapper">
        <h2 className="title">{marketPrices.mainHeading}</h2>
        <div className="buttons-container">
          <Button type="primary" onClick={openCalculatorModalHandler}>{marketPrices.calculatorBtn}</Button>
        </div>
      </div>
      <div className="prices-list-wrapper">
        <div className="prices-header">
          <h3>{marketPrices.tableHeading}</h3>
          <Button size="small" type="primary" onClick={uploadPricesHandler}><AttachSvg />{marketPrices.tableImportBtn}</Button>
        </div>
        <Table
          columns={columns}
          dataSource={props.prices.results?.content || []}
          onChange={tableOnchangeHandler}
          pagination={{
            defaultPageSize: 10,
            total: props.prices.results?.totalElements,
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '30'],
            position: ["bottomCenter"],
          }}
          loading={props.prices.isFetching}
        />
      </div>
    </div>
  );
};

export default connector(PricesScreen);