import { Form, Input, Row, Select } from 'antd';
import ScreenHeaderSingle from 'components/ScreenHeaderSingle/ScreenHeaderSingle';
import React, { FC } from 'react';
import "./AddStaffScreen.scss";
import { connect } from 'react-redux';
import { createStaff } from 'store/staff/actions'
import { ICreateStaffData, IStaffRegisterFailureAction, IStaffRegisterSuccessAction } from 'store/staff/types';
import InfoModal from 'components/InfoModal/InfoModal';
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { openNotification } from 'services';
import {staff, roles, smallNotification} from '../../globalConstants/textinputs.json';

const { Option } = Select;

interface IProps {
  createStaff: (param: ICreateStaffData) => Promise<IStaffRegisterSuccessAction | IStaffRegisterFailureAction>;
  history: any;
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  createStaff
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AddStaffScreen: FC<IProps> = (props) => {

  const formRef = React.useRef(null);

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [isResetModalVisible, setIsResetModalVisible] = React.useState(false);

  const [isUserRegModalVisible, setUserRegModalVisible] = React.useState(true);


  const onFinish = (values: any) => {
    console.log('Success:', values);
    props.createStaff(prepareAddStaff(values)).then(resp => {
      if (resp.type === "STAFF_REGISTER_SUCCESS") {
        props.history.push("/dashboard/staff");
      } else if (resp.payload.error === "ERR_PARAM_INVALID") {
        openNotification('error', smallNotification.errorTitle, staff.errors.incorrectPhoneFormat)
      } else {
        setErrorCode(resp.payload.error)
      }
    })
  };

  const resetErrorHandler = () => {
    setErrorCode("")
    setUserRegModalVisible(true)
  }

  const switchModals = (name) => {
    switch (name) {
      case 'ERR_STAFF_001':
        return (<InfoModal
          isSaved={false}
          title={staff.errorModal.heading}
          isModalVisible={isUserRegModalVisible}
          closeModal={setUserRegModalVisible}
          resetErrorHandler={resetErrorHandler}
          backPath={"/dashboard/staff"}
          img={<WarningSvg />}
          text={staff.create.alreadyExistsError}
          successBtnText={staff.errorModal.backBtn}
        />)
      default: return ("")
    }
  }

  const prepareAddStaff = (values) => {
    Object.keys(values).forEach(staffItem => {
      if (values[staffItem] == undefined || values[staffItem] == "") {
        values[staffItem] = null
      }
      return values[staffItem]
    })
    return (
      {
        "email": values.email,
        "fio": {
          "firstName": values.firstName,
          "lastName": values.lastName,
          "patronymic": values.patronymic
        },
        "phone": `+${values.phone}`,
        "position": values.position,
        "role": values.role
      }
    )
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const submitHandler = () => {
    formRef.current.submit();
  }

  const resetModalHandler = () => {
    setIsResetModalVisible(true)
  }

  return (
    <div>
      {switchModals(errorCode)}
      <InfoModal
        isSaved={true}
        title={staff.create.cancelEditModalHeading}
        isModalVisible={isResetModalVisible}
        closeModal={setIsResetModalVisible}
        backPath={"/dashboard/staff"}
        img={<WarningSvg />}
        text={<span>{staff.create.cancelEditModalMessagePartOne}<br />{staff.create.cancelEditModalMessagePartTwo}</span>}
        cancelBtnText={staff.create.cancelEditModalCancelBtn}
        successBtnText={staff.create.cancelEditModalStayBtn}
      />
      <ScreenHeaderSingle
        title={staff.create.heading}
        backPath={"/dashboard/staff"}
        submitHandler={submitHandler}
        resetModalHandler={resetModalHandler}
      />
      <Form
        name="add-user"
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h3>{staff.create.subheadingOne}</h3>
        <Row gutter={24} className="form-row">
          <Form.Item
            name="lastName"
            label={staff.fields.lastName}
            rules={[
              { required: true, message: staff.fields.requiredFieldErrorMessage }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="firstName"
            label={staff.fields.firstName}
            rules={[
              { required: true, message: staff.fields.requiredFieldErrorMessage }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patronymic"
            label={staff.fields.patronymic}
            rules={[
              { required: true, message: staff.fields.requiredFieldErrorMessage }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label={staff.fields.phone}
            rules={[
              { required: true, message: staff.fields.requiredFieldErrorMessage },
            ]}
          >
            <Input className="tel-input" prefix={"+"}/>
          </Form.Item>
          <Form.Item
            label={staff.fields.email}
            name={['email']}
            rules={[{ required: true, message: staff.fields.requiredFieldErrorMessage }, { type: 'email', message: staff.fields.emailErrorMessage }]
            }>
            <Input />
          </Form.Item>
        </Row>
        <h3>{staff.create.subheadingTwo}</h3>
        <Row gutter={24} className="form-row">
          <Form.Item
            name="position"
            label={staff.fields.position}
            rules={[
              { required: true, message: staff.fields.requiredFieldErrorMessage }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label={staff.fields.accessLevel}
            rules={[
              { required: true, message: staff.fields.requiredFieldErrorMessage }
            ]}
          >
            <Select
            >
              <Option value="ROLE_ADMIN">{roles.admin}</Option>
              <Option value="ROLE_MANAGER">{roles.manager}</Option>
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default connector(AddStaffScreen);