import { Button, Input, Upload } from 'antd';
import React, { FC, useEffect } from 'react';
import "./ChatField.scss";
import { ReactComponent as SendSvg } from 'assets/images/ic-chat-send.svg'
import { ReactComponent as AttachSvg } from 'assets/images/ic-attach-yellow.svg'
import { ReactComponent as EmptyChatSvg } from 'assets/images/ic-empty-chat-message.svg'
import { ReactComponent as DownloadSvg } from 'assets/images/ic-download.svg'
import { connect, useDispatch } from 'react-redux';
import { getSingleChat, getSingleRoom, updateSingleChat, pushNewMessage, uploadChatFile, setActiveUserChatInfo, updateTagsFlag, downloadChatFile } from 'store/chats/actions';
import {
  IDownloadChatFileAction,
  IDownloadChatFileFailureAction,
  IDownloadChatFilePendingAction,
  ISetSingleChatAction,
  ISetSingleChatFailureAction,
  ISetSingleChatPendingAction,
  ISetSingleRoomAction,
  ISetSingleRoomFailureAction,
  ISetSingleRoomPendingAction,
  IUpdateSingleChatAction,
  IUpdateSingleChatFailureAction,
  IUpdateSingleChatPendingAction
} from 'store/chats/types';
import moment from 'moment';
import { ReactHeight } from 'react-height';
import { ReactComponent as FolderSvg } from 'assets/images/ic-folder.svg'
import { sha512 } from 'js-sha512';
import { useCallback } from 'react';
import SimpleModal from 'components/SimpleModal/SimpleModal';

const { TextArea } = Input;

const mapStateToProps = state => ({
  chats: state.chats,
  user: state.user
});

const mapDispatchToProps = {
  getSingleChat,
  getSingleRoom,
  updateSingleChat,
  pushNewMessage,
  uploadChatFile,
  setActiveUserChatInfo,
  updateTagsFlag,
  downloadChatFile
};

const connector = connect(mapStateToProps, mapDispatchToProps)

interface IProps {
  chats: any;
  user: any;
  socketClient: any;
  setActiveUserChatInfo: any;
  getSingleRoom: (id: string) => Promise<ISetSingleRoomPendingAction | ISetSingleRoomAction | ISetSingleRoomFailureAction>;
  getSingleChat: (id: string, params: string) => Promise<ISetSingleChatPendingAction | ISetSingleChatAction | ISetSingleChatFailureAction>;
  updateSingleChat: (id: string, params: string) => Promise<IUpdateSingleChatPendingAction | IUpdateSingleChatAction | IUpdateSingleChatFailureAction>
  uploadChatFile: any;
  pushNewMessage: any;
  updateTagsFlag: any;
  downloadChatFile: (id: string, chatId: string) => Promise<IDownloadChatFileAction | IDownloadChatFilePendingAction | IDownloadChatFileFailureAction>
}

const ChatField: FC<IProps> = (props) => {

  const { single } = props.chats

  const { userInfo } = props.user

  const [сhats, setChats] = React.useState([]);

  const [listHeight, setListHeight] = React.useState(0);

  const [actualChat, setActualChat] = React.useState(null)

  const [textInfoField, setTextInfoField] = React.useState(null)

  const [currentSubscription, setCurrentSubscription] = React.useState(null)

  const [isUnsubscribe, setisUnsubscribe] = React.useState(false)

  const [scrollPosition, setScrollPosition] = React.useState(0)

  const [isSimpleModalVisible, setIsSimpleModalVisible] = React.useState(false);

  const [fileList, setfileList] = React.useState([]);

  const [isOverSize, setisOverSize] = React.useState(false);

  const fileProps = {
    onRemove: file => {
      setfileList([])
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        file.flag = true;
        setisOverSize(true)
        return false;
      }
      setisOverSize(false)
      setfileList([file])
      return false;
    }
  };

  const { socketClient } = props

  const dispatch = useDispatch();

  const socketRef = React.useRef(null);

  useEffect(() => {
    return () => {
      socketClient.onDisconnect = (dis) => {
        socketRef.current && socketRef.current.deactivate()
      }
    }
  }, [])
  

  useEffect(() => {
    dispatch({
      type: 'GET_SINGLE_CHAT_SUCCESS',
    })
    dispatch({
      type: 'SET_ACTIVE_SINGLE_USER_CHAT_INFO_SUCCESS',
    })
    if (single.length) {
      props.getSingleRoom(single[0].roomId).then(resp => {
        setChats(resp.preview)
      })
    }

  }, [props.chats.single])

  // useEffect(() => {
  //   console.log('total PAge', props.chats.singleChat.totalPages)
  //   if(!props.chats.singleChat.totalPages) {
  //     console.log('nema')
  //   }
  //   if (!props.chats.singleChat.totalPages && isUnsubscribe) {
  //     currentSubscription.unsubscribe()
  //     setisUnsubscribe(false)
  //   }
  // }, [props.chats.singleChat.totalPages])

  const scrollDownMessage = useCallback((notSmooth, top = 99999999) => {
    const scrollContainer = document.querySelector('.center-chat-body-wrapper')
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: top,
        behavior: notSmooth ? 'auto' : 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    console.log(props.chats.updateFlag)
    if (props.chats.updateFlag) {
      props.getSingleRoom(single[0].roomId).then(resp => {
        setChats(resp.preview)
        props.updateTagsFlag(false)
      })
    }
  }, [props.chats.updateFlag])

  const chatActivate = (id) => {
    let actual = сhats.filter(chats => chats.id === id)
    setActualChat(actual)
    props.setActiveUserChatInfo(actual)
    props.getSingleChat(id, `?page=0&size=10`)
    socketRef.current = socketClient
    socketRef.current.onConnect = () => {
      setCurrentSubscription(
        socketRef.current.subscribe(`/topic/staff.chat.${id}`, messageSubscribe),
      )
    }
    socketRef.current.activate();
  }

  const getSingleChatHandler = (id) => e => {
    if (!actualChat) {
      chatActivate(id)
    }
    if (id !== actualChat && actualChat) {
      currentSubscription.unsubscribe()
      chatActivate(id)
    }
  }

  useEffect(() => {
    if (props.chats.singleChat.content?.length <= 10 && props.chats.singleChat.content?.length !== 0) {
      scrollDownMessage(false)
      const scrollContainerHeight = document.querySelector('.center-chat-body-wrapper').scrollHeight
      setScrollPosition(scrollContainerHeight)
    }
  }, [props.chats.singleChat])

  const messageSubscribe = (e) => {
    const message = JSON.parse(e.body)
    // console.log(e.body, 'e.body')
    // console.log(message.chatId);
    // console.log(actualChat);
    //if (message.chatId === actualChat[0].id) {
    props.pushNewMessage(message)
    //}
    scrollDownMessage(false)
  }
  // useEffect(() => {
  //   if (Object.keys(socketRef).length) {
  //     setCurrentSubscription(
  //       socketRef.current.subscribe('/user/queue/chats/messages', messageSubscribe),
  //     )
  //   }
  // }, [])

  const messagesScrollHandler = (e) => {
    if (e.target.scrollTop === 0 && props.chats.singleChat.number !== props.chats.singleChat.totalPages - 1) {
      props.updateSingleChat(actualChat[0].id, `?page=${props.chats.singleChat.number + 1}&size=10`).then(resp => {
        let scrollContainerHeight = document.querySelector('.center-chat-body-wrapper').scrollHeight
        setScrollPosition(scrollContainerHeight)
        scrollDownMessage(true, scrollContainerHeight - scrollPosition)
      })

    }
  }


  const singleChatRender = (values) => {
    return values.map(item => {
      return (
        <div className="chat-item" key={item.id} onClick={getSingleChatHandler(item.id)}>
          <div className="center-left-side">
            {item.tags.length ? item.tags.map((item, index) => {
              return (<span className="blue" key={item.id}>
                {item.name}
              </span>)
            }) : ""}
            {item.chatStatus === "OPENED" &&
              <span className="active">
                Активный
              </span>
            }
          </div>
          <div className="center-right-side">
            <span className="name">{item.managerName}</span>
            <span className="date">{moment.utc(item.createdAt).local().format('YYYY.MM.DD')}</span>
          </div>
        </div>
      )
    })
  }

  const downloadFileHandler = (chatId, id, name) => e => {
    console.log(chatId, id)
    props.downloadChatFile(chatId, id).then(res => {
      //@ts-ignore
      var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      });
  }

  const chatBodyRender = (values) => {
    return values.map((item, index) => {
      return (
        <li key={index}>
          <div className={item.sender.userId === single[0]?.customer?.customerId ? "chat-message align-left" : "chat-message align-right"}>
            <div className="message">
              <div className={item.sender.userId === single[0]?.customer?.customerId ? "talk-bubble-left round left-in" : "talk-bubble-right round right-in"}>
                <p>{item.content || <div className="files-wrapper"><DownloadSvg onClick={downloadFileHandler(single[0]?.chatId, item.fileKeys[0].documentId ,item.fileKeys[0].fileName)}/> {item.fileKeys[0].fileName}</div>}</p>
              </div>
              <div className="date-status">
                {item.sender?.fio?.firstName} {item.sender?.fio?.lastName} • {moment.utc(item.updatedAt).local().format('HH:mm')}
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  const getListHeight = (e) => {
    setListHeight(Number(e + 147))
  }

  const sendMessage = useCallback(() => {
    let messageBody = {
      "id": null,
      "roomId": single[0].roomId,
      "chatId": actualChat[0].id,
      "senderId": userInfo.id,
      "recipientId": actualChat[0].customer.customerId,
      "content": textInfoField,
      "fileKey": null,
      "eventType": "MESSAGE",
      "eventStatus": "NEW",
      "autoMessageReply": null
    }
    //let shaId = sha512(actualChat[0].id)

    if (socketRef.current.connected) {
      socketRef.current.publish({
        destination: `/farmland/chat.${actualChat[0].id}`,
        headers: {},
        body: JSON.stringify(messageBody)
      });
    }
    setTextInfoField("")
  }, [socketRef.current, textInfoField])

  const textHandler = (e) => {
    setTextInfoField(e.target.value)
  }

  const formSubmitHandler = () => {
    console.log(actualChat);
    props.uploadChatFile(actualChat[0].id, fileList).then(resp => {
      console.log(resp.data.documentId)
      if (resp.status === "OK") {
        let messageBody = {
          "id": null,
          "roomId": single[0].roomId,
          "chatId": actualChat[0].id,
          "senderId": actualChat[0].manager.managerId,
          "recipientId": actualChat[0].customer.customerId,
          "content": null,
          "fileKeys": [
            {
              "documentId": resp.data.documentId,
              "fileName": resp.data.fileName
            }
          ],
          "eventType": "MESSAGE",
          "eventStatus": "NEW",
          "autoMessageReply": null
        }
        //let shaId = sha512(actualChat[0].id)
        socketRef.current.publish({
          destination: `/farmland/chat.${actualChat[0].id}`,
          headers: {},
          body: JSON.stringify(messageBody)
        });
        setIsSimpleModalVisible(false)
      }
    })
  }

  const SimpleModalHandler = () => {
    setIsSimpleModalVisible(true)
  }

  return (
    <div className="center-side">
      <SimpleModal
        isSaved={true}
        title={"Завантажити документ"}
        isModalVisible={isSimpleModalVisible}
        closeModal={setIsSimpleModalVisible}
        cancelBtnText={"Закрити"}
        successBtnText={"Зберегти"}
        onSubmit={formSubmitHandler}
        modalName={"lefted chat-file-upload"}
        fileList={fileList}
        setFileList={setfileList}
      >
        <Upload
          maxCount={1}
          beforeUpload={fileProps.beforeUpload}
          onRemove={fileProps.onRemove}
          fileList={fileList}
          multiple={false}
        >
          <Button icon={<FolderSvg />}>Відкрити файл…</Button>
          {isOverSize && <span className="small-size-normal">Занадто великий розмір файла. Ви можете завантажити файли розміром до 25 мб.</span>}
        </Upload>
      </SimpleModal>
      <div className="center-part" style={{ height: `calc(100vh - ${listHeight}px)` }}>
        <ReactHeight onHeightReady={getListHeight}>
          <div className="center-chats-list-wrapper">
            {singleChatRender(сhats)}
          </div>
        </ReactHeight>
        {props.chats.singleChat.content?.length ?
          <div className="center-chat-body-wrapper" style={{ height: `calc(100vh - ${listHeight}px)` }} onScroll={messagesScrollHandler}>
            <ul className="chat-body">
              {chatBodyRender(props.chats.singleChat.content)}
            </ul>
          </div>
          :
          <div className="empty-wrapper">
            <EmptyChatSvg />
            <p>Тут буде відображатися ваш чат з користувачем</p>
          </div>
        }
      </div>
      <div className="bot-part">
        {(props.chats.singleChat.size && props.chats.actualChat[0]?.chatStatus !== "CLOSED") ? <AttachSvg className="attach-icon" onClick={SimpleModalHandler} /> : null}
        <TextArea rows={3} onChange={textHandler} value={textInfoField} disabled={!props.chats.singleChat.size || props.chats.actualChat[0]?.chatStatus === "CLOSED"} />
        <Button id="sendMsg" type="primary" disabled={!textInfoField} onClick={sendMessage}><SendSvg /></Button>
      </div>
    </div>
  );
};

export default connector(ChatField);