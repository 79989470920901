import { Button } from 'antd';
import React, { FC } from 'react';
import "./ScreenHeaderSingle.scss";
import { ReactComponent as DeclineSvg } from 'assets/images/ic-choise-decline.svg'
import { ReactComponent as ApproveSvg } from 'assets/images/ic-choise-approve.svg'
import { ReactComponent as BackSvg } from 'assets/images/ic-back.svg'
import { Link } from 'react-router-dom';
import {components} from '../../globalConstants/textinputs.json';

interface IProps {
  title: string;
  backPath: string;
  submitHandler: () => void;
  resetModalHandler: () => void;
}

const ScreenHeaderSingle: FC<IProps> = (props) => {
  return (
    <div className="header-wrapper">
      <h2><Link to={props.backPath} className="goBack"><BackSvg /></Link>{props.title}</h2>
      <div className="btn-wrapper">
        <Button type="default" danger id="reset" onClick={props.resetModalHandler}><DeclineSvg />{components.createScreenHeader.cancelBtn}</Button>
        {/* <Link to={props.backPath}><Button type="default" danger id="reset"><DeclineSvg/>Скасувати</Button></Link> */}
        <Button type="primary" id="save" onClick={props.submitHandler}><ApproveSvg />{components.createScreenHeader.saveBtn}</Button>
      </div>
    </div>
  );
};

export default ScreenHeaderSingle;