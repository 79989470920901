import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { FC } from 'react';
import "./EditStaffScreen.scss";
import { connect } from 'react-redux';
import { getSingleStaff, updateStaff, archiveStaff } from 'store/staff/actions'
import { ISetSingleStaffFailureAction, ISetSingleStaffListAction, ISetSingleStaffPendingAction, IStaffArchiveFailureAction, IStaffArchiveSuccessAction, IStaffUpdateFailureAction, IStaffUpdateSuccessAction, IUpdateStaffData } from 'store/staff/types';
import { ReactComponent as EditSvg } from 'assets/images/ic-edit.svg';
import { ReactComponent as BlockSvg } from 'assets/images/ic-block-w.svg'
import { ReactComponent as DeclineSvg } from 'assets/images/ic-choise-decline.svg'
import { ReactComponent as ApproveSvg } from 'assets/images/ic-choise-approve.svg'
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { ReactComponent as UnarchiveSvg } from 'assets/images/ic-unarchive.svg'
import { ReactComponent as ChatBlueSvg } from 'assets/images/ic-chat-message-blue.svg'
import { ReactComponent as BackSvg } from 'assets/images/ic-back.svg'
import { roleMapper, setActualStatus } from 'services/mapper';
import InfoModal from 'components/InfoModal/InfoModal';
import ArchiveModal from 'components/ArchiveModal/ArchiveModal';
import { openNotification } from 'services';
import { useForm } from 'antd/lib/form/Form';
import SpinComponent from 'components/SpinComponent/SpinComponent';
import { Link } from 'react-router-dom';
import {staff, roles, smallNotification} from '../../globalConstants/textinputs.json';

const { Option } = Select;

interface IProps {
  history: any;
  match: any;
  getSingleStaff: (id: number) => Promise<ISetSingleStaffListAction | ISetSingleStaffPendingAction | ISetSingleStaffFailureAction>;
  staff: any;
  updateStaff: (id: number, param: IUpdateStaffData) => Promise<IStaffUpdateSuccessAction | IStaffUpdateFailureAction>;
  archiveStaff: (id: number, data: any) => Promise<IStaffArchiveSuccessAction | IStaffArchiveFailureAction>;
}

const mapStateToProps = state => ({
  staff: state.staff,
});

const mapDispatchToProps = {
  getSingleStaff,
  updateStaff,
  archiveStaff
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const EditStaffScreen: FC<IProps> = (props) => {

  React.useEffect(() => {
    form.resetFields();
    props.getSingleStaff(props.match.params.id)
  }, [])

  const { single } = props.staff

  const [form] = useForm();

  const [isEdited, setIsEdited] = React.useState<boolean>(Boolean(props.staff.view));

  const [isResetModalVisible, setIsResetModalVisible] = React.useState(false);

  const [isArchiveModalVisible, setIsArchiveModalVisible] = React.useState(false);

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [isUserRegModalVisible, setUserRegModalVisible] = React.useState(true);

  React.useEffect(() => {
    form.setFieldsValue({ ...single, lastName: single.fio?.lastName, firstName: single.fio?.firstName, patronymic: single.fio?.patronymic, phone: single?.phone?.replace('+', '') })
  }, [single])

  const editHandler = () => {
    setIsEdited(!isEdited)
  }

  const resetModalHandler = () => {
    setIsResetModalVisible(true)
  }

  const saveChangesHandler = () => {
    form.submit();
  }

  const onFinish = (values: any) => {
    props.updateStaff(props.match.params.id, prepareAddStaff(values)).then(resp => {
      if (resp.type === "STAFF_UPDATE_SUCCESS") {
        props.getSingleStaff(props.match.params.id)
        setIsEdited(false)
      } else if (resp.payload.error === "ERR_PARAM_INVALID") {
        openNotification('error', smallNotification.errorTitle, staff.errors.incorrectPhoneFormat)
      } else {
        setErrorCode(resp.payload.error)
      }
    })
  };

  const prepareAddStaff = (values) => {
    Object.keys(values).forEach(staffItem => {
      if (values[staffItem] == undefined || values[staffItem] == "") {
        values[staffItem] = null
      }
      return values[staffItem]
    })
    return (
      {
        "email": values.email,
        "fio": {
          "firstName": values.firstName,
          "lastName": values.lastName,
          "patronymic": values.patronymic
        },
        "phone": `+${values.phone}`,
        "position": values.position,
        "role": values.role
      }
    )
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const infoBackHandler = () => {
    setIsEdited(false)
    setIsResetModalVisible(false)
    form.resetFields();
  }

  const modalArchiveHandler = (e) => {
    setIsArchiveModalVisible(true)
  }

  const handleModalClose = () => {
    setIsArchiveModalVisible(false)
  }

  const archiveHandlerCallback = (id, status) => {
    props.archiveStaff(id, { "status": setActualStatus(status) }).then(resp => {
      if (resp.type === "ARCHIVE_STAFF_SUCCESS") {
        props.getSingleStaff(props.match.params.id)
      }
    })
  }

  const switchModals = (name) => {
    switch (name) {
      case 'ERR_STAFF_001':
        return (<InfoModal
          isSaved={false}
          title={staff.errorModal.heading}
          isModalVisible={isUserRegModalVisible}
          closeModal={setUserRegModalVisible}
          resetErrorHandler={resetErrorHandler}
          backPath={"/dashboard/staff"}
          img={<WarningSvg />}
          text={staff.edit.alreadyExistsError}
          successBtnText={staff.errorModal.backBtn}
        />)
        case 'ERR_STAFF_009':
          return (<InfoModal
            isSaved={false}
            title={staff.errorModal.heading}
            isModalVisible={isUserRegModalVisible}
            closeModal={setUserRegModalVisible}
            resetErrorHandler={resetErrorHandler}
            backPath={"/dashboard/staff"}
            img={<WarningSvg />}
            text={staff.edit.emailAlreadyExistsError}
            successBtnText={staff.errorModal.backBtn}
          />)
      default: return ("")
    }
  }

  const resetErrorHandler = () => {
    setErrorCode("")
    setUserRegModalVisible(true)
  }


  return (
    <>
      <div className="edit-staff-wrapper">
        {props.staff.isFetching && <SpinComponent fullHeight={true} />}
        {switchModals(errorCode)}
        <ArchiveModal
          isModalVisible={isArchiveModalVisible}
          handleModalClose={handleModalClose}
          id={single.id}
          status={single.status}
          archivePartnerHandler={archiveHandlerCallback}
          archivedText={<span>{staff.blockModal.unBlockMessagePartOne}<br />{staff.blockModal.unBlockMessagePartTwo}</span>}
          unArchivedText={<span>{staff.blockModal.blockMessagePartOne}<br />{staff.blockModal.blockMessagePartTwo}</span>}
        />
        <InfoModal
          isSaved={true}
          title={staff.edit.cancelEditModalHeading}
          isModalVisible={isResetModalVisible}
          closeModal={setIsResetModalVisible}
          onSubmit={infoBackHandler}
          img={<WarningSvg />}
          text={<span>{staff.edit.cancelEditModalMessagePartOne}<br />{staff.edit.cancelEditModalMessagePartTwo}</span>}
          cancelBtnText={staff.edit.cancelEditModalCancelBtn}
          successBtnText={staff.edit.cancelEditModalStayBtn}
        />
        {single && Object.keys(single).length ?
          <div className="staff-profile-wrapper">
            <>
              <div className="profile-header">
                <h2>{!isEdited ? <><Link to={"/dashboard/staff"} className="goBack"><BackSvg /></Link>{`${single.fio.lastName} ${single.fio.firstName} ${single.fio.patronymic}`}</> : staff.edit.heading}</h2>
                <div className="btn-wrapper">
                  {!isEdited ?
                    <>
                      <Button size="small" type="default" onClick={editHandler} className="edit-staff-btn"><EditSvg />{staff.view.editBtnText}</Button>
                      <Button size="small" type="primary" onClick={modalArchiveHandler}>
                        {single.status === "ACTIVE" || single.status === "NOT_VERIFIED" ? <><BlockSvg />{staff.view.blockBtnText}</> : <><UnarchiveSvg />{staff.view.unBlockBtnText}</>}
                      </Button>
                    </>
                    :
                    <>
                      <Button size="small" type="default" onClick={resetModalHandler} className="edit-staff-cancel-btn"><DeclineSvg />{staff.edit.cancelBtnText}</Button>
                      <Button size="small" type="primary" onClick={saveChangesHandler}><ApproveSvg />{staff.edit.saveChangesBtnText}</Button>
                    </>}
                </div>
              </div>
              <p className="id-number">{`${staff.view.ID} ${single.id}`}</p>
              {!isEdited ?
                <>
                  <Row gutter={24}>
                    <Col span={5}>
                      <span className="title">{staff.fields.position}</span>
                      <p className="name">{single.position}</p>
                    </Col>
                    <Col span={5}>
                      <span className="title">{staff.fields.accessLevel}</span>
                      <p className="name">{roleMapper(single.role)}</p>
                    </Col>
                    <Col span={5}>
                      <span className="title">{staff.fields.phone}</span>
                      <p className="name">{single.phone}</p>
                    </Col>
                    <Col span={9}>
                      <span className="title">{staff.fields.email}</span>
                      <p className="name">{single.email}</p>
                    </Col>
                  </Row>
                  {/* <Row gutter={24} className="action-btns-wrapper">
                    <Col span={8}>
                      <Button type="default"><ChatBlueSvg />{staff.view.chatsBtnText}</Button>
                    </Col>
                  </Row> */}
                </>
                :
                <Form
                  name="edit-staff"
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    lastName: single.fio.lastName,
                    firstName: single.fio.firstName,
                    patronymic: single.fio.patronymic,
                    phone: single.phone.replace('+', ''),
                    email: single.email,
                    position: single.position,
                    role: single.role
                  }}
                >
                  <Row gutter={24} className="form-row">
                    <Form.Item
                      name="lastName"
                      label={staff.fields.lastName}
                      rules={[
                        { required: true, message: staff.fields.requiredFieldErrorMessage }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="firstName"
                      label={staff.fields.firstName}
                      rules={[
                        { required: true, message: staff.fields.requiredFieldErrorMessage }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="patronymic"
                      label={staff.fields.patronymic}
                      rules={[
                        { required: true, message: staff.fields.requiredFieldErrorMessage }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label={staff.fields.phone}
                      rules={[
                        { required: true, message: staff.fields.requiredFieldErrorMessage }
                      ]}
                    >
                      <Input className="tel-input" prefix={"+"} />
                    </Form.Item>
                    <Form.Item
                      label={staff.fields.email}
                      name={['email']}
                      rules={[{ required: true, message: staff.fields.requiredFieldErrorMessage }, { type: 'email', message: staff.fields.emailErrorMessage }]
                      }>
                      <Input />
                    </Form.Item>
                  </Row>
                  <Row gutter={24} className="form-row">
                    <Form.Item
                      name="position"
                      label={staff.fields.position}
                      rules={[
                        { required: true, message: staff.fields.requiredFieldErrorMessage }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="role"
                      label={staff.fields.accessLevel}
                      rules={[
                        { required: true, message: staff.fields.requiredFieldErrorMessage }
                      ]}
                    >
                      <Select>
                        <Option value="ROLE_ADMIN">{roles.admin}</Option>
                        <Option value="ROLE_MANAGER">{roles.manager}</Option>
                      </Select>
                    </Form.Item>
                  </Row>
                </Form>}
            </>

          </div>
          : null}
      </div>
    </>
  );
};

export default connector(EditStaffScreen);
