import Api from "api";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IaddNewTagFailureAction, IaddNewTagPendingAction, IaddNewTagSuccessAction, IAddRememberFailureAction, IAddRememberPendingAction, IAddRememberSuccessAction, ICloseChatFailureAction, ICloseChatPendingAction, ICloseChatSuccessAction, IcreateChatFailureAction, IcreateChatPendingAction, IcreateChatSuccessAction, IcreateTagFailureAction, IcreateTagPendingAction, IcreateTagSuccessAction, IDownloadChatFileAction, IDownloadChatFileFailureAction, IDownloadChatFilePendingAction, IgetAdminChatListAction, IgetAdminChatListFailureAction, IgetAdminChatListPendingAction, IgetChatListAction, IgetChatListFailureAction, IgetChatListPendingAction, IgetReminderListAction, IgetReminderListFailureAction, IgetReminderListPendingAction, IgetTagsListAction, IgetTagsListFailureAction, IgetTagsListPendingAction, IPushNewMessage, IremoveRemindeAction, IremoveRemindeFailureAction, IremoveRemindePendingAction, ISetActiveSingleUserChatAction, ISetSingleChatAction, ISetSingleChatFailureAction, ISetSingleChatPendingAction, ISetSingleRoomAction, ISetSingleRoomFailureAction, ISetSingleRoomPendingAction, ISetSingleUserChatAction, IUpdateFlagChatAction, IUpdateSingleChatAction, IUpdateSingleChatFailureAction, IUpdateSingleChatPendingAction, IUploadChatFileFailureAction, IUploadChatFilePendingAction, IUploadChatFileSuccessAction, IUserActiveSingleChat, IUserSingleChat } from "./types";

export const createChat: ActionCreator<
ThunkAction<
  Promise<IcreateChatSuccessAction | IcreateChatFailureAction>,
  any,
  null,
  IcreateChatSuccessAction | IcreateChatFailureAction
>
> = (data) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IcreateChatPendingAction = {
      type: 'CREATE_CHAT_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/chat-service/administration/chats`, data)
      const preview = response.data
      const createUserSuccessAction: IcreateChatSuccessAction = {
        preview,
        type: 'CREATE_CHAT_SUCCESS',
      };
      return dispatch(createUserSuccessAction)
      //return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IcreateChatFailureAction = {
        type: 'CREATE_CHAT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const getChatList: ActionCreator<
  ThunkAction<
    Promise<IgetChatListAction | IgetChatListPendingAction | IgetChatListFailureAction>,
    any,
    null,
    IgetChatListAction
  >
> = (staffID) => {
  return async (dispatch: Dispatch) => {
    const getAdvertListPendingAction: IgetChatListPendingAction = {
      type: 'GET_CHAT_LIST_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/rooms/${staffID}?page=0&size=2000`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertListSuccessAction: IgetChatListAction = {
        preview,
        type: 'GET_CHAT_LIST_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IgetChatListFailureAction = {
        type: 'GET_CHAT_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}

export const getAdminChatList: ActionCreator<
  ThunkAction<
    Promise<IgetAdminChatListAction | IgetAdminChatListPendingAction | IgetAdminChatListFailureAction>,
    any,
    null,
    IgetAdminChatListAction
  >
> = (data) => {
  return async (dispatch: Dispatch) => {
    const getAdvertListPendingAction: IgetAdminChatListPendingAction = {
      type: 'GET_ADMIN_CHAT_LIST_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      const response = await Api.post(`/chat-service/administration/rooms`, {...data})
      const preview = response.data.data
      console.log(preview)
      const getAdvertListSuccessAction: IgetAdminChatListAction = {
        preview,
        type: 'GET_ADMIN_CHAT_LIST_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IgetAdminChatListFailureAction = {
        type: 'GET_ADMIN_CHAT_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}

export const getSingleRoom:ActionCreator<
ThunkAction<
  Promise<ISetSingleRoomAction | ISetSingleRoomPendingAction | ISetSingleRoomFailureAction>,
  any,
  null,
  ISetSingleRoomAction
>
>  = (id) => {
  return async (dispatch: Dispatch) => {
    const getAdvertSinglePendingAction:ISetSingleRoomPendingAction = {
      type: 'GET_SINGLE_ROOM_PENDING',
    };
    dispatch(getAdvertSinglePendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/rooms/${id}/chats`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertSingleSuccessAction:ISetSingleRoomAction = {
        preview,
        type: 'GET_SINGLE_ROOM_SUCCESS',
      };
      return dispatch(getAdvertSingleSuccessAction);
    } catch (error) {
      const getAdvertSingleFailureAction: ISetSingleRoomFailureAction = {
        type: 'GET_SINGLE_ROOM_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertSingleFailureAction);
    }
  };
}

export const getSingleChat:ActionCreator<
ThunkAction<
  Promise<ISetSingleChatAction | ISetSingleChatPendingAction | ISetSingleChatFailureAction>,
  any,
  null,
  ISetSingleChatAction
>
>  = (id, params) => {
  return async (dispatch: Dispatch) => {
    const getAdvertSinglePendingAction:ISetSingleChatPendingAction = {
      type: 'GET_SINGLE_CHAT_PENDING',
    };
    dispatch(getAdvertSinglePendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/chats/${id}/events${params}`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertSingleSuccessAction:ISetSingleChatAction = {
        preview,
        type: 'GET_SINGLE_CHAT_SUCCESS',
      };
      return dispatch(getAdvertSingleSuccessAction);
    } catch (error) {
      const getAdvertSingleFailureAction: ISetSingleChatFailureAction = {
        type: 'GET_SINGLE_CHAT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertSingleFailureAction);
    }
  };
}

export const updateSingleChat:ActionCreator<
ThunkAction<
  Promise<IUpdateSingleChatAction | IUpdateSingleChatPendingAction | IUpdateSingleChatFailureAction>,
  any,
  null,
  IUpdateSingleChatAction
>
>  = (id, params) => {
  return async (dispatch: Dispatch) => {
    const getAdvertSinglePendingAction:IUpdateSingleChatPendingAction = {
      type: 'UPDATE_SINGLE_CHAT_PENDING',
    };
    dispatch(getAdvertSinglePendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/chats/${id}/events${params}`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertSingleSuccessAction:IUpdateSingleChatAction = {
        preview,
        type: 'UPDATE_SINGLE_CHAT_SUCCESS',
      };
      return dispatch(getAdvertSingleSuccessAction);
    } catch (error) {
      const getAdvertSingleFailureAction: IUpdateSingleChatFailureAction = {
        type: 'UPDATE_SINGLE_CHAT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertSingleFailureAction);
    }
  };
}

export const setSingleUserChatInfo = (single:IUserSingleChat) => {
  return (dispatchFunc):ISetSingleUserChatAction => {
    return dispatchFunc({
      type: "SET_SINGLE_USER_CHAT_INFO_SUCCESS",
      payload: single
    })
  }
};

export const setActiveUserChatInfo = (single:IUserActiveSingleChat) => {
  return (dispatchFunc):ISetActiveSingleUserChatAction => {
    return dispatchFunc({
      type: "SET_ACTIVE_SINGLE_USER_CHAT_INFO_SUCCESS",
      payload: single
    })
  }
};

export const pushNewMessage = (message:IPushNewMessage) => {
  return (dispatchFunc):ISetSingleUserChatAction => {
    return dispatchFunc({
      type: "SET_NEW_MESSAGE_SUCCESS",
      payload: message
    })
  }
};

export const updateTagsFlag = (flag:boolean) => {
  return (dispatchFunc):IUpdateFlagChatAction => {
    return dispatchFunc({
      type: "UPDATE_FLAG_SUCCESS",
      payload: flag
    })
  }
};

export const updateSearch = (search:any) => {
  return (dispatchFunc):IUpdateFlagChatAction => {
    return dispatchFunc({
      type: "UPDATE_SEARCH_SUCCESS",
      payload: search
    })
  }
};

export const uploadChatFile: ActionCreator<
ThunkAction<
  Promise<IUploadChatFileSuccessAction | IUploadChatFileFailureAction>,
  any,                 
  any, 
  IUploadChatFileSuccessAction | IUploadChatFileFailureAction
>
>  = (chatID, documentData) => {
  return async (dispatch: Dispatch) => {
    const sendFilePendingAction: IUploadChatFilePendingAction = {
      type: 'SEND_FILE_PENDING',
    };
    dispatch(sendFilePendingAction);

    try {
      let formData = new FormData();
      formData.append("file", documentData[0]);
      // formData.append("documentName", fileInfo.documentName);
      // formData.append("documentDescription", fileInfo.documentDescription);
      console.log(formData); // not consoled, blet! empty!!
      
      const authDataResponse = await Api.post(`/chat-service/administration/chats/${chatID}/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const preview = authDataResponse.data
      console.log(preview.id)
      const sendFileSuccessAction:IUploadChatFileSuccessAction = {
        type: 'SEND_FILE_SUCCESS',
        preview
      };
      dispatch(sendFileSuccessAction);
      return preview
    } catch (error) {

      const sendFileFailureAction: IUploadChatFileFailureAction = {
        type: 'SEND_FILE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(sendFileFailureAction);
    }
  };
};

export const createTag: ActionCreator<
ThunkAction<
  Promise<IcreateTagSuccessAction | IcreateTagFailureAction>,
  any,
  null,
  IcreateTagSuccessAction | IcreateTagFailureAction
>
> = (data) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IcreateTagPendingAction = {
      type: 'CREATE_TAG_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/chat-service/administration/tags`, data)
      const preview = response.data
      const createUserSuccessAction: IcreateTagSuccessAction = {
        preview,
        type: 'CREATE_TAG_SUCCESS',
      };
      dispatch(createUserSuccessAction)
      return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IcreateTagFailureAction = {
        type: 'CREATE_TAG_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const getTagsList: ActionCreator<
  ThunkAction<
    Promise<IgetTagsListAction | IgetTagsListPendingAction | IgetTagsListFailureAction>,
    any,
    null,
    IgetTagsListAction
  >
> = () => {
  return async (dispatch: Dispatch) => {
    const getAdvertListPendingAction: IgetTagsListPendingAction = {
      type: 'GET_TAGS_LIST_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/tags`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertListSuccessAction: IgetTagsListAction = {
        preview,
        type: 'GET_TAGS_LIST_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IgetTagsListFailureAction = {
        type: 'GET_TAGS_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}

export const removeReminde: ActionCreator<
  ThunkAction<
    Promise<IremoveRemindeAction | IremoveRemindePendingAction | IremoveRemindeFailureAction>,
    any,
    null,
    IremoveRemindeAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const getAdvertListPendingAction: IremoveRemindePendingAction = {
      type: 'GET_REMOVE_REMINDE_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      const response = await Api.patch(`/chat-service/administration/reminders/${id}`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertListSuccessAction: IremoveRemindeAction = {
        preview,
        type: 'GET_REMOVE_REMINDE_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IremoveRemindeFailureAction = {
        type: 'GET_REMOVE_REMINDE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}

export const getReminderList: ActionCreator<
  ThunkAction<
    Promise<IgetReminderListAction | IgetReminderListPendingAction | IgetReminderListFailureAction>,
    any,
    null,
    IgetReminderListAction
  >
> = (roomId) => {
  return async (dispatch: Dispatch) => {
    const getAdvertListPendingAction: IgetReminderListPendingAction = {
      type: 'GET_REMINDER_LIST_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/reminders/${roomId}`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertListSuccessAction: IgetReminderListAction = {
        preview,
        type: 'GET_REMINDER_LIST_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IgetReminderListFailureAction = {
        type: 'GET_REMINDER_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}


export const addNewTag: ActionCreator<
ThunkAction<
  Promise<IaddNewTagSuccessAction | IaddNewTagFailureAction>,
  any,
  null,
  IaddNewTagSuccessAction | IaddNewTagFailureAction
>
> = (data, chatId) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IaddNewTagPendingAction = {
      type: 'ADD_NEW_TAG_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.put(`/chat-service/administration/chats/${chatId}/tags`, data)
      const preview = response.data
      const createUserSuccessAction: IaddNewTagSuccessAction = {
        preview,
        type: 'ADD_NEW_TAG_SUCCESS',
      };
      dispatch(createUserSuccessAction)
      return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IaddNewTagFailureAction = {
        type: 'ADD_NEW_TAG_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const closeChat: ActionCreator<
  ThunkAction<
    Promise<ICloseChatSuccessAction | ICloseChatFailureAction>,
    any,
    any,
    ICloseChatSuccessAction | ICloseChatFailureAction
  >
> = (chatId) => {
  return async (dispatch: Dispatch) => {
    const removeAgentPendingAction: ICloseChatPendingAction = {
      type: "CLOSE_CHAT_PENDING",
    };
    dispatch(removeAgentPendingAction);
    try {
      const response = await Api.post(`/chat-service/administration/chats/${chatId}/close`)
      const preview: any = response.data.data
      const removeAgentSuccessAction: ICloseChatSuccessAction = {
        preview,
        type: "CLOSE_CHAT_SUCCESS",
      };
      return dispatch(removeAgentSuccessAction);
    } catch (error) {
      const removeAgentFailureAction: ICloseChatFailureAction = {
        type: "CLOSE_CHAT_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(removeAgentFailureAction);
    }
  }
}

export const addRemember: ActionCreator<
  ThunkAction<
    Promise<IAddRememberSuccessAction | IAddRememberFailureAction>,
    any,
    any,
    IAddRememberSuccessAction | IAddRememberFailureAction
  >
> = (data) => {
  return async (dispatch: Dispatch) => {
    const removeAgentPendingAction: IAddRememberPendingAction = {
      type: "ADD_REMEMBER_PENDING",
    };
    dispatch(removeAgentPendingAction);
    try {
      const response = await Api.post(`/chat-service/administration/reminders`, data)
      const preview: any = response.data.data
      const removeAgentSuccessAction: IAddRememberSuccessAction = {
        preview,
        type: "ADD_REMEMBER_SUCCESS",
      };
      return dispatch(removeAgentSuccessAction);
    } catch (error) {
      const removeAgentFailureAction: IAddRememberFailureAction = {
        type: "ADD_REMEMBER_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(removeAgentFailureAction);
    }
  }
}

export const downloadChatFile:ActionCreator<
ThunkAction<
  Promise<IDownloadChatFileAction | IDownloadChatFilePendingAction | IDownloadChatFileFailureAction>,
  any,
  null,
  IDownloadChatFileAction
>
>  = (chatId, id) => {
  return async (dispatch: Dispatch) => {
    const getStaffListPendingAction:IDownloadChatFilePendingAction = {
      type: 'GET_DOWNLOAD_CHAT_FILE_PENDING',
    };
    dispatch(getStaffListPendingAction);
    try {
      const response = await Api.get(`/chat-service/administration/chats/${chatId}/documents/${id}`, {responseType: "blob"})
      const preview = response.data
      const getStaffListSuccessAction:IDownloadChatFileAction = {
        preview,
        type: 'GET_DOWNLOAD_CHAT_FILE_SUCCESS',
      };
      dispatch(getStaffListSuccessAction);
      return preview;
    } catch (error) {
      const getStaffListFailureAction: IDownloadChatFileFailureAction = {
        type: 'GET_DOWNLOAD_CHAT_FILE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getStaffListFailureAction);
    }
  };
}