const getShortFileName = ({
  fileName,
  maxLength,
}: {
  fileName: string;
  maxLength: number;
}): string => {
  if (fileName.length < maxLength) return fileName;

  const arr = fileName.split(".");
  const extension = arr.pop();
  const name = arr.join(".");

  return `${name.slice(0, maxLength)}...${extension}`;
};

export default getShortFileName;
