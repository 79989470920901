import { Tabs } from 'antd';
import AdvertFilter from 'components/AdvertFilter/AdvertFilter';
import AdvertList from 'components/AdvertList/AdvertList';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import "./AdvertsScreen.scss";
import {setAdvertFilter} from 'store/adverts/actions'
import {
  IGetAdvertAction
} from 'store/adverts/types';
import {adverts} from '../../globalConstants/textinputs.json';
import { ISaveStaffScreenPage, ISaveStaffScreenPageAction } from 'store/staff/types';
import { saveStaffScreenPage } from 'store/staff/actions';
import { ISaveUsersScreenPage, ISaveUsersScreenPageAction } from 'store/users/types';
import { saveUsersScreenPage } from 'store/users/actions';
import { ISaveContractScreenInfoAction, ISaveContractScreenPage } from 'store/contracts/types';
import { saveContractScreenPage } from 'store/contracts/actions';

const { TabPane } = Tabs;

interface IProps {
  setAdvertFilter: (data: any) => IGetAdvertAction;
  history: any;
  advert: any;
  saveStaffScreenPage: (staffScreenPage: ISaveStaffScreenPage) => ISaveStaffScreenPageAction;
  saveUsersScreenPage: (usersScreenPage :ISaveUsersScreenPage) => ISaveUsersScreenPageAction;
  saveContractScreenPage: (staffScreenPage: ISaveContractScreenPage) => ISaveContractScreenInfoAction;
}

const mapStateToProps = state => ({
  advert: state.advert
});

const mapDispatchToProps = {
  setAdvertFilter,
  saveStaffScreenPage,
  saveUsersScreenPage,
  saveContractScreenPage
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AdvertsScreen: FC<IProps> = (props) => {

  const [advertsOnModeration, setAdvertsOnModeration] = React.useState(props.advert.onModerationCount)

  const [hasSearched, setHasSearched] = React.useState(false);

  const [needRefresh, setNeedRefresh] = React.useState(false);
  const [resetPage, setResetPage] = React.useState<boolean>(false);

  const [activeTabKey, setActiveTabKey] = React.useState<string | undefined>(props.advert.activeTabInfo.activeTab);
  const [activePage, setActivePage] = React.useState<number>(props.advert.activeTabInfo.activePage);
  const [activeTabName, setActiveTabName] = React.useState<string>(props.advert.activeTabInfo.activeTabName);

  const [onModerationFullDataCount, setOnModerationFullDataCount] = React.useState<number>(0);
  const [onModerationIncompleteDataCount, setOnModerationIncompleteDataCount] = React.useState<number>(0);
  const [awaitingDataCount, setAwaitingDataCount] = React.useState<number>(0);
  const [publishedCount, setPublishedCount] = React.useState<number>(0);
  const [inActiveCount, setInActiveCount] = React.useState<number>(0);

  const changeSearchedState = (value) => {
    setHasSearched(value);
  }

  React.useEffect(() => {
    props.saveUsersScreenPage({
      currentPage: 0
    })
    props.saveStaffScreenPage({
      currentPage: 0
    })
    props.saveContractScreenPage({
      currentPage: 0
    })
  }, [])

  React.useEffect(() => {
    setAdvertsOnModeration(props.advert.onModerationCount)
  }, [props.advert.onModerationCount])

  useEffect(() => {
    setActivePage(props.advert.activeTabInfo.activePage)
  }, [props.advert.activeTabInfo.activePage])

  const handleTabChange = (key) => {
    setActiveTabKey(key);
    setActivePage(0);
  }

  return (
    <div className="adverts-wrapper">
      <h2 className="title">{adverts.main.heading}</h2>
      <AdvertFilter changeSearchedState={changeSearchedState} setResetPage={setResetPage}/>
      <div className="tabs-wrapper">
        <Tabs activeKey={activeTabKey} animated={false} onChange={handleTabChange}>
          <TabPane tab={`${adverts.main.moderationTabFullData} (${onModerationFullDataCount})`} key="1" className="adv-tab-1" forceRender={true}>
            <AdvertList
              history={props.history}
              takeWorkBtn={true}
              setCounter={setOnModerationFullDataCount}
              rsql={`&search=adStatus%3Din%3D(ON_MODERATION_REGISTRY_CHECK_OMITTED%2CON_MODERATION_REGISTRY_DATA_RECEIVED%2CON_MODERATION_AD_UPDATED)`}
              needRefresh={needRefresh}
              setNeedRefresh={setNeedRefresh}
              hasSearched={hasSearched}
              activeTab={activeTabKey}
              from="advertsScreen"
              activePage={activePage}
              setActivePage={setActivePage}
              tabName="onModerationFullData"
              activeTabName={activeTabName}
              resetPage={resetPage}
              setResetPage={setResetPage}
              sortAdvertsBy="updatedDate"/>
          </TabPane>
          <TabPane tab={`${adverts.main.moderationTabIncompleteData} (${onModerationIncompleteDataCount})`} key="2" className="adv-tab-2" forceRender={true}>
            <AdvertList
                history={props.history}
                takeWorkBtn={true}
                setCounter={setOnModerationIncompleteDataCount}
                rsql={`&search=adStatus%3Din%3D(ON_MODERATION_REGISTRY_DATA_INCOMPLETE)`}
                needRefresh={needRefresh}
                setNeedRefresh={setNeedRefresh}
                hasSearched={hasSearched}
                activeTab={activeTabKey}
                from="advertsScreen"
                activePage={activePage}
                setActivePage={setActivePage}
                tabName="onModerationIncompleteData"
                activeTabName={activeTabName}
                resetPage={resetPage}
                setResetPage={setResetPage}
                sortAdvertsBy="updatedDate"/>
          </TabPane>
          <TabPane tab={`${adverts.main.awaitingDataTab} (${awaitingDataCount})`} key="3" className="adv-tab-3" forceRender={true}>
            <AdvertList
              history={props.history}
              setCounter={setAwaitingDataCount}
              rsql={`&search=adStatus%3Din%3D(NEW%2CON_MODERATION_REGISTRY_CHECK_IN_PROGRESS%2CON_MODERATION_REGISTRY_DATA_FAILED)`}
              hasSearched={hasSearched}
              needRefresh={needRefresh}
              setNeedRefresh={setNeedRefresh}
              activeTab={activeTabKey}
              from="advertsScreen"
              activePage={activePage}
              setActivePage={setActivePage}
              tabName="awaitingInfo"
              activeTabName={activeTabName}
              resetPage={resetPage}
              setResetPage={setResetPage}
              sortAdvertsBy="updatedDate"/>
          </TabPane>
          <TabPane tab={`${adverts.main.publishedTab} (${publishedCount})`} key="4" className="adv-tab-4" forceRender={true}>
            <AdvertList
              history={props.history}
              isAgreeBtn={true}
              setCounter={setPublishedCount}
              rsql={`&search=adStatus%3Din%3D(DEAL_IN_PROGRESS%2CDEAL_IS_DONE%2CACTIVE)`}
              hasSearched={hasSearched}
              needRefresh={needRefresh}
              setNeedRefresh={setNeedRefresh}
              activeTab={activeTabKey}
              from="advertsScreen"
              activePage={activePage}
              setActivePage={setActivePage}
              tabName="published"
              activeTabName={activeTabName}
              resetPage={resetPage}
              setResetPage={setResetPage}
              sortAdvertsBy="updatedDate"/>
          </TabPane>
          <TabPane tab={`${adverts.main.inactiveTab} (${inActiveCount})`} key="5" className="adv-tab-5" forceRender={true}>
            <AdvertList
              history={props.history}
              isRemoveBtn={true}
              setCounter={setInActiveCount}
              rsql={`&search=adStatus%3Din%3D(MODERATION_FAILED_INVALID_PURPOSE%2CMODERATION_FAILED_NO_REGISTRY_RESPONSE%2CMODERATION_FAILED_NO_CADASTRAL_NUMBER_FOUND%2CBLOCKED%2CDELETED%2CMODERATION_FAILED_INVALID_PROPERTY_TYPE)`}
              needRefresh={needRefresh}
              setNeedRefresh={setNeedRefresh}
              hasSearched={hasSearched}
              activeTab={activeTabKey}
              from="advertsScreen"
              activePage={activePage}
              setActivePage={setActivePage}
              tabName="inActive"
              activeTabName={activeTabName}
              resetPage={resetPage}
              setResetPage={setResetPage}
              sortAdvertsBy="updatedDate"/>
          </TabPane>
        </Tabs>
      </div>
    </div>

  );
};

export default connector(AdvertsScreen);
