import { Col, Pagination, Row, Select } from 'antd';
import ContractFilter from 'components/ContractFilter/ContractFilter';
import { ReactComponent as SuccessFilledSvg } from 'assets/images/ic-success-filled.svg'
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { ISaveContractScreenInfoAction, ISaveContractScreenPage, IsetContractsFailureAction, IsetContractsListAction, IsetContractsPendingAction } from 'store/contracts/types';
import { getContractsList, saveContractScreenPage } from 'store/contracts/actions';
import "./ContractsScreen.scss";
import moment from 'moment';
import { contractColorStatusMapper, contractPrepaymentMapper, contractStatusMapper } from 'services/mapper';
import { Link } from "react-router-dom";
import { contracts } from '../../globalConstants/textinputs.json';
import EmptyContent from "../../components/EmptyContent/EmptyContent";
import SpinComponent from "../../components/SpinComponent/SpinComponent";
import { IGetAdvertAction } from 'store/adverts/types';
import { setAdvertFilter } from 'store/adverts/actions';
const { Option, OptGroup } = Select;

interface IProps {
    getContractsList: (sortBy: string, sortType: string, page: number, size: number, rsql: string) => Promise<IsetContractsListAction | IsetContractsPendingAction | IsetContractsFailureAction>;
    contracts: any;
    filterSettings: any;
    setAdvertFilter: (data: any) => IGetAdvertAction;
    saveContractScreenPage: (staffScreenPage: ISaveContractScreenPage) => ISaveContractScreenInfoAction;
}

const ContractsScreen: FC<IProps> = (props) => {
    useEffect(() => {
        setIsLoading(true)
        props.setAdvertFilter({})
        props.getContractsList('id', 'desc', currentPage, 10, props.filterSettings.rsqlString).then(resp => {
            setIsLoading(false);
        })
    }, [])

    const { list } = props.contracts;

    const [rsql, setRsql] = React.useState("");

    const [hasSearched, setHasSearched] = React.useState<boolean>(false);

    const [currentPage, setCurrentPage] = React.useState<number>(props.contracts?.contractScreenPage?.currentPage)

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const changePages = (pageNumber) => {
        pageNumber = pageNumber - 1;
        setCurrentPage(pageNumber);
        props.getContractsList('id', 'desc', pageNumber, 10, rsql)
    }

    const viewHandler = () => {
        props.saveContractScreenPage({
            currentPage: currentPage
        })
    }

    const renderContracts = () => {
        return list.content.map((item, index) => {
            return (
                <div className="contract-item" key={index}>
                    <Link className="full-size" to={`/dashboard/contracts/${item.id}`} onClick={viewHandler}></Link>
                    <Row gutter={24}>
                        <Col span={5}>
                            <p className="number">{`${contracts.list.cadastrialNo} ${item.cadastralNumber}`}</p>
                            <p className="id">{`${contracts.list.ID} ${item.id}`}</p>
                            <span
                                className="price">{item.price ? `${item.price} ${contracts.list.currency}` : contracts.list.notStated}</span>
                        </Col>
                        <Col span={5}>
                            <div className="info-item">
                                <p className="label">{contracts.list.seller}</p>
                                <p className="info">{item.seller?.fio?.lastName || contracts.list.notStated} {item.seller?.fio?.firstName || contracts.list.notStated}</p>
                            </div>
                            <div className="info-item">
                                <p className="label">{contracts.list.sellerPhone}</p>
                                <p className="info">{item.sellerPhone || contracts.list.notStated}</p>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className="info-item">
                                <p className="label">{contracts.list.buyer}</p>
                                <p className="info">{item.buyer?.fio?.lastName || contracts.list.notStated} {item.buyer?.fio?.firstName || contracts.list.notStated}</p>
                            </div>
                            <div className="info-item">
                                <p className="label">{contracts.list.buyerPhone}</p>
                                <p className="info">{item.buyerPhone || contracts.list.notStated}</p>
                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="info-wrapper">
                                <p className="documents">{contractPrepaymentMapper(item.prepaymentStatus)} <span className="details-text" style={{ backgroundColor: contractColorStatusMapper(item.dealStatus) }}>{contractStatusMapper(item.dealStatus)}</span></p>
                                <p className="date">{moment(`${item.createdDate}Z`).format('DD.MM.YYYY, HH:mm')}</p>
                                {item.documentsVerified &&
                                    <p className="check"><SuccessFilledSvg />{contracts.list.documentsInOrder}</p>}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    const filterSearch = (rsqlString) => {
        setRsql(rsqlString)
        setIsLoading(true)
        setHasSearched(true);
        props.getContractsList('id', 'desc', 0, 10, rsqlString).then(resp => {
            setIsLoading(false);
        })
    }

    return (
        <div className="contracts-wrapper">
            <h2 className="title">{contracts.list.heading}</h2>
            <ContractFilter filterSearch={filterSearch} />
            <div className="contracts-list">

                {
                    isLoading ? <SpinComponent /> :
                        <>
                            {hasSearched ?
                                list && list.content?.length ?
                                    renderContracts() :
                                    <EmptyContent messageOne={contracts.list.noSearchedContractsFoundMessageOne}
                                        messageTwo={contracts.list.noSearchedContractsFoundMessageTwo} />
                                : list && list.content?.length ?
                                    renderContracts() :
                                    <EmptyContent messageOne={contracts.list.noContractsFoundMessageOne} />
                            }
                        </>
                }


            </div>
            {
                isLoading ? null : <>
                    {list && list.totalPages > 1 ?
                        <Pagination
                            onChange={changePages}
                            total={list.totalElements}
                            showSizeChanger={false}
                            current={currentPage + 1}
                            showTotal={total => `Усього угод: ${total}`}
                        />
                        : null}</>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    contracts: state.contracts,
    filterSettings: state.contracts.filterSettings
});

const mapDispatchToProps = {
    getContractsList,
    setAdvertFilter,
    saveContractScreenPage
    // advertTakeModeration,
    // refreshRegistryData
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsScreen);
