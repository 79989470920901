import { Button } from 'antd';
import React, { FC } from 'react';
import "./DropdownComponent.scss";
import { ReactComponent as ArrowSvg } from 'assets/images/ic-arrow-right.svg'

interface IProps {
  title: string;
  id: string;
  items: number;
}

const DropdownComponent:FC <IProps> = (props) => {

  const [isOpened, setIsOpened] = React.useState(false);

  const isOpenedHandler = () => {
    setIsOpened(!isOpened)
  }
  return (
    <div className="dropdown-wrapper">
      <div className="dropdown-head">
      <Button size="small" disabled={!props.items} type="default" id={props.id} onClick={isOpenedHandler}>{props.title}<ArrowSvg className={`arrow ${isOpened && "arrow isOpened"}`}/></Button>
      </div>
      <div className={`dropdown-body ${isOpened && "dropdown-body isOpened"}`}>
        {props.children}
      </div>
    </div>
  );
};

export default DropdownComponent;