import Api from "api";
import { parseJwt, formatSearchParams, loginUserToSystem, logoutUserFromSystem } from "services";
import { AxiosResponse } from "axios";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IAuthUserData, IGetUserInfoFailureAction, IGetUserInfoPendingAction, IGetUserInfoSuccessAction, ILoginData, ILoginUserFailureAction, ILoginUserPendingAction, ILoginUserSuccessAction, IregisterUserFailureAction, IregisterUserPendingAction, IregisterUserSuccessAction, IrestorePasswordFailureAction, IrestorePasswordPendingAction, IrestorePasswordSuccessAction, IRole, RoleActions } from "./types";

export const getTokenData = (role: IRole) => {
  return (dispatchFunc): RoleActions => {
    return dispatchFunc({
      type: "GET_ROLE_SUCCESS",
      payload: role
    })
  }
};

export const loginUser: ActionCreator<
  ThunkAction<
    Promise<ILoginUserSuccessAction | ILoginUserFailureAction>,
    IAuthUserData,
    ILoginData,
    ILoginUserSuccessAction | ILoginUserFailureAction
  >
> = (data: ILoginData) => {
  return async (dispatch: Dispatch) => {

    const loginUserPendingAction: ILoginUserPendingAction = {
      type: 'LOGIN_USER_PENDING',
    };
    dispatch(loginUserPendingAction);
    try {
      Api.defaults.headers['Authorization'] = "";
      const authDataResponse = await Api.post('/core-service/auth/staff', JSON.stringify({ phone: data.phone, password: data.password }));
      const authData = authDataResponse.data;
      const loginUserSuccessAction = {
        authData,
        type: 'LOGIN_USER_SUCCESS',
      };
      Api.defaults.headers['Authorization'] = authData.data.token;
      loginUserToSystem(authData.data.token, data.rememberMe);
      dispatch(loginUserSuccessAction);
      return authData
    } catch (error) {
      console.log(error)
      const loginUserFailureAction: ILoginUserFailureAction = {
        type: 'LOGIN_USER_FAILURE',
        payload: {
          ...error.response.data
        },
        status: ''
      };

      return dispatch(loginUserFailureAction);
    }
  };
};

export const registerUser: ActionCreator<
  ThunkAction<
    Promise<IregisterUserSuccessAction | IregisterUserFailureAction>,
    any,
    null,
    IregisterUserSuccessAction | IregisterUserFailureAction
  >
> = (data) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IregisterUserPendingAction = {
      type: 'CREATE_USER_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/core-service/staff/complete-registration`, { ...data })
      const preview = response.data
      const createUserSuccessAction: IregisterUserSuccessAction = {
        preview,
        type: 'CREATE_USER_SUCCESS',
      };
      Api.defaults.headers['Authorization'] = preview.data.token;
      loginUserToSystem(preview.data.token, data.rememberMe);
      dispatch(createUserSuccessAction)
      return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IregisterUserFailureAction = {
        type: 'CREATE_USER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const restorePassword: ActionCreator< 
  ThunkAction<
    Promise<IrestorePasswordSuccessAction | IrestorePasswordFailureAction>,
    any,
    null,
    IrestorePasswordSuccessAction | IrestorePasswordFailureAction
  >
> = (data) => {
  return async (dispatch: Dispatch) => {
    const restorePasswordPendingAction: IrestorePasswordPendingAction = {
      type: 'RESTORE_PASSWORD_PENDING',
    };
    dispatch(restorePasswordPendingAction);
    try {
      const response = await Api.patch(`/core-service/staff/passwords/restore`, { ...data })
      const preview = response.data
      console.log('kkokoko', preview);
      
      const restorePasswordSuccessAction: IrestorePasswordSuccessAction = {
        preview,
        type: 'RESTORE_PASSWORD_SUCCESS',
      };
      Api.defaults.headers['Authorization'] = preview.data.token;
      loginUserToSystem(preview.data.token, data.rememberMe);
      return dispatch(restorePasswordSuccessAction)
    } catch (error) {
      console.log(error)
      const restorePasswordFailureAction: IrestorePasswordFailureAction = {
        type: 'RESTORE_PASSWORD_FAILURE',
        payload: {
          ...error.response.data
        }
      };
      return dispatch(restorePasswordFailureAction);
    }
  };
}

export const getUsersList = (sortBy: string, sortType: string, page: number = 1, per_page: number = 10) => {
  return async (dispatch) => {
    const getUsersListPendingAction = {
      type: 'GET_USERS_PENDING',
    };
    dispatch(getUsersListPendingAction);
    try {
      //const response = await Api.get(`/users?page=${page}&size=${size}&sort=${sortBy},${sortType}`)
      const response = await Api.get(`/v1/users?page=${page}&per_page=${per_page}`)
      const preview = response.data
      const getUsersListSuccessAction = {
        preview,
        type: 'GET_USERS_SUCCESS',
      };
      dispatch(getUsersListSuccessAction);
    } catch (error) {
      const getUsersListFailureAction = {
        type: 'GET_USERS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getUsersListFailureAction);
    }
  };
}

export const getSingleUser = (id: number) => {
  return async (dispatch) => {
    const getSingleUserPendingAction = {
      type: 'GET_SINGLE_USER_PENDING',
    };
    dispatch(getSingleUserPendingAction);
    try {
      const response = await Api.get(`/v1/users/${id}`)
      const preview = response.data
      console.log(preview)
      const getSingleUserSuccessAction = {
        preview,
        type: 'GET_SINGLE_USER_SUCCESS',
      };
      return dispatch(getSingleUserSuccessAction)
    } catch (error) {
      console.log(error)
      const getSingleUserFailureAction = {
        type: 'GET_SINGLE_USER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getSingleUserFailureAction);
    }
  };
}

export const updateUser = (userId, data) => {
  return async (dispatch) => {
    const getUpdateUserPendingAction = {
      type: 'UPDATE_USER_PENDING',
    };
    dispatch(getUpdateUserPendingAction);
    try {
      const response = await Api.put(`v1/users/${userId}`, { ...data })
      const preview = response.data
      console.log(preview)
      const getUpdateUserSuccessAction = {
        preview,
        type: 'UPDATE_USER_SUCCESS',
      };
      return dispatch(getUpdateUserSuccessAction)
    } catch (error) {
      console.log(error)
      const getUpdateUserFailureAction = {
        type: 'UPDATE_USER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getUpdateUserFailureAction);
    }
  };
}

export const removeUser = (userId, data) => {
  return async (dispatch) => {
    const getRemoveUserPendingAction = {
      type: 'REMOVE_USER_PENDING',
    };
    dispatch(getRemoveUserPendingAction);
    try {
      const response = await Api.delete(`v1/users/${userId}`, { ...data })
      //const responseList = await Api.get(`/v1/users`)
      const preview = response.data
      //const previewList = responseList.data
      console.log(preview)
      const getRemoveUserSuccessAction = {
        preview,
        type: 'REMOVE_USER_SUCCESS',
      };
      // const getListUserSuccessAction = {
      //   previewList,
      //   type: 'GET_USERS_SUCCESS',
      // };
      // return dispatch(getRemoveUserSuccessAction, getListUserSuccessAction)
      return dispatch(getRemoveUserSuccessAction)
    } catch (error) {
      console.log(error)
      const getRemoveUserFailureAction = {
        type: 'REMOVE_USER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getRemoveUserFailureAction);
    }
  };
}


export const logoutUser = () => {
  return async (dispatch) => {

    const logoutUserPendingAction = {
      type: 'LOGOUT_USER_PENDING',
    };
    dispatch(logoutUserPendingAction);

    try {

      const logoutUserSuccessAction = {
        type: 'LOGOUT_USER_SUCCESS',
      };
      // sessionStorage.removeItem(SS_AUTH_TOKEN);
      // console.log('parse token',parseJwt(authData.data.token))
      // sessionStorage.removeItem(SS_ROLES);
      logoutUserFromSystem();
      return dispatch(logoutUserSuccessAction);
    } catch (e) {
      const logoutUserFailureAction = {
        type: 'LOGOUT_USER_FAILURE',
      };

      return dispatch(logoutUserFailureAction);
    }
  };
};

export const getUserInfo: ActionCreator<
  ThunkAction<
    Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>,
    any,  
    null,                    
    IGetUserInfoSuccessAction | IGetUserInfoFailureAction
  >
> = () => {
    return async (dispatch: Dispatch) => {
        
        const getUserInfoPendingAction: IGetUserInfoPendingAction = {
            type: 'GET_USER_INFO_PENDING',
        };
        dispatch(getUserInfoPendingAction);

        try{
            const response = await Api.get('/core-service/staff/me');
            const userInfo = response.data.data; 
            console.log(userInfo)
            const getUserInfoSuccessAction: IGetUserInfoSuccessAction = {
                userInfo,
                type: 'GET_USER_INFO_SUCCESS',
            };
            return dispatch(getUserInfoSuccessAction);
        } catch (error) {
            const getUserInfoFailureAction: IGetUserInfoFailureAction = {
                type: 'GET_USER_INFO_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getUserInfoFailureAction);
        }
    };
};