export const initialState = {
    list: {},
    single: {},
    contractScreenPage: {
      currentPage: 0
    },
    statusNewCount: 0,
    sellerDocuments: {},
    buyerDocuments: {},
    filterSettings: {
     fields: {
      contractNameANDfullSearch: undefined,
      'createdDate|from': undefined,
      'createdDate|to': undefined,
      'dealPayment.prepaymentStatus|in': undefined,
      'staffId|staff': undefined,
      'status|in': undefined
    },
      rsqlString: ''
    }
}
export const ContractsReducer: any = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'SAVE_CONTRACT_FILTER_SETTINGS': {
      return {
        ...state,
        filterSettings: action.payload
      }
    }
    case 'GET_CONTRACTS_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_CONTRACTS_LIST_SUCCESS': {
      return {
        ...state,
        list: action.preview,
        isFetching: false,
      };
    }
    case 'GET_CONTRACTS_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }

    case 'GET_SINGLE_CONTRACT_PENDING' : {
      return { ...defaultStateWithFetchingTrue };
    }

    case "GET_SINGLE_CONTRACT_SUCCESS": {
      return {
        ...state,
        single: action.preview,
        isFetching: false
      }
    }

    case "GET_SINGLE_CONTRACT_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case 'UPDATE_SINGLE_CONTRACT_PENDING' : {
      return { ...defaultStateWithFetchingTrue };
    }

    case "UPDATE_SINGLE_CONTRACT_SUCCESS": {
      return {
        ...state,
        isFetching: false
      }
    }

    case "UPDATE_SINGLE_CONTRACT_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case 'UPDATE_SINGLE_CONTRACT_STATUS_PENDING' : {
      return { ...defaultStateWithFetchingTrue };
    }

    case "UPDATE_SINGLE_CONTRACT_STATUS_SUCCESS": {
      return {
        ...state,
        isFetching: false
      }
    }

    case "UPDATE_SINGLE_CONTRACT_STATUS_FAILURE": {
      return { ...defaultErrorState(action) };
    }


    case 'CANCEL_SINGLE_CONTRACT_PENDING' : {
      return { ...defaultStateWithFetchingTrue };
    }

    case "CANCEL_SINGLE_CONTRACT_SUCCESS": {
      return {
        ...state,
        isFetching: false
      }
    }

    case "CANCEL_SINGLE_CONTRACT_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "GET_NEW_CONTRACTS_COUNT_PENDING": {
      return {...state}
    }

    case "GET_NEW_CONTRACTS_COUNT_SUCCESS": {
      return {
        ...state,
        statusNewCount: action.payload
      }
    }

    case "GET_NEW_CONTRACTS_COUNT_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "GET_CONTRACT_SELLER_DOCUMENTS_PENDING": {
      return {...state}
    }

    case "GET_CONTRACT_SELLER_DOCUMENTS_SUCCESS": {
      return {
        ...state,
        sellerDocuments: action.payload}
    }

    case "GET_CONTRACT_SELLER_DOCUMENTS_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "GET_CONTRACT_BUYER_DOCUMENTS_PENDING": {
      return {...state}
    }

    case "GET_CONTRACT_BUYER_DOCUMENTS_SUCCESS": {
      return {
        ...state,
        buyerDocuments: action.payload}
    }

    case "GET_CONTRACT_BUYER_DOCUMENTS_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "POST_CONTRACT_DOCUMENT_PENDING": {
      return {
        ...state,
      }
    }

    case "POST_CONTRACT_DOCUMENT_SUCCESS": {
      return {
        ...state,
      }
    }

    case "POST_CONTRACT_DOCUMENT_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "DOWNLOAD_SINGLE_CONTRACT_DOCUMENT_PENDING": {
      return {
        ...state,
      }
    }

    case "DOWNLOAD_SINGLE_CONTRACT_DOCUMENT_SUCCESS": {
      return {
        ...state,
      }
    }

    case "DOWNLOAD_SINGLE_CONTRACT_DOCUMENT_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "DELETE_SINGLE_CONTRACT_DOCUMENT_PENDING": {
      return {
        ...state,
      }
    }

    case "DELETE_SINGLE_CONTRACT_DOCUMENT_SUCCESS": {
      return {
        ...state,
      }
    }

    case "DELETE_SINGLE_CONTRACT_DOCUMENT_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "EDIT_CONTRACT_IDENTITY_DOCUMENTS_PENDING": {
      return {
        ...state,
      }
    }

    case "EDIT_CONTRACT_IDENTITY_DOCUMENTS_SUCCESS": {
      return {
        ...state
      }
    }

    case "EDIT_CONTRACT_IDENTITY_DOCUMENTS_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "SAVE_CONTRACT_SCREEN_PAGE_SUCCESS": {
      return {
        ...state,
        contractScreenPage: action.payload
      }
    }

    default: return state
  }
}
export default ContractsReducer;
