import { Button, Form, Input, Row, Select, Upload } from 'antd';
import ScreenHeaderSingle from 'components/ScreenHeaderSingle/ScreenHeaderSingle';
import React, { FC } from 'react';
import "./AddAgentScreen.scss";
import { connect } from 'react-redux';
import { createAgent } from 'store/agents/actions'
import { sendAvatar } from 'store/agents/actions'
import InfoModal from 'components/InfoModal/InfoModal';
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { openNotification } from 'services';
import regionsList from '../../globalConstants/regions.json'
import DistrictSearch from 'components/DistrictSearch';
import { ReactComponent as FolderSvg } from 'assets/images/ic-folder.svg'
import LocalitySearch from 'components/LocalitySearch';
import { IAgentRegisterFailureAction, IAgentRegisterSuccessAction } from 'store/agents/types';
import SpinComponent from 'components/SpinComponent/SpinComponent';
import { getDistrictAdress, getLocalityAdress } from 'store/address/actions';
import { agents, smallNotification } from '../../globalConstants/textinputs.json';
let OnlyRegionsList = regionsList.filter(item => item.title !== "Всі")

interface IProps {
  createAgent: (param: any) => Promise<IAgentRegisterSuccessAction | IAgentRegisterFailureAction>;
  history: any;
  sendAvatar: any;
  getDistrictAdress: any;
  getLocalityAdress: any;
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  createAgent,
  sendAvatar,
  getDistrictAdress,
  getLocalityAdress
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AddAgentScreen: FC<IProps> = (props) => {

  const formRef = React.useRef(null);

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [isResetModalVisible, setIsResetModalVisible] = React.useState(false);

  const [isUserRegModalVisible, setUserRegModalVisible] = React.useState(true);

  const [region, setRegion] = React.useState<any>({});

  const [district, setDistrict] = React.useState<any>([]);

  const [locality, setLocality] = React.useState<any>([]);

  const [localityValue, setlocalityValue] = React.useState<any>("");

  const [localityType, setLocalityType] = React.useState<any>("");

  const [isEmptyFields, setIsEmptyFields] = React.useState(false);

  const [fileList, setfileList] = React.useState([]);

  const [isOverSize, setisOverSize] = React.useState(false);

  const [unknownFormat, setunknownFormat] = React.useState(false);

  const [isFileMissed, setIsFileMissed] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false)

  const [avatarPath, setAvatarPath] = React.useState(null)

  const onFinish = (values: any) => {
    if (fileList.length) {
      setIsLoading(true)
      if (avatarPath) {
        createAgentFunc(values)
      } else {
        props.sendAvatar(fileList).then(resp => {
          if (resp.type === "SEND_AVATAR_SUCCESS") {
            setAvatarPath({ "avatarId": resp.preview.data })
            let ava = { "avatarId": resp.preview.data }
            props.createAgent({ ...prepareAddAgent(values), ...ava }).then(resp => {
              if (resp.type === "AGENT_REGISTER_SUCCESS") {
                props.history.push("/dashboard/agents");
              } else if (resp.payload.error === "ERR_PARAM_INVALID") {
                setIsLoading(false)
                openNotification('error', smallNotification.errorTitle, agents.errors.incorrectPhoneFormat)
              } else {
                setIsLoading(false)
                setErrorCode(resp.payload.error)
              }
            })
          }
        })
      }
    }
  };

  const createAgentFunc = (values) => {
    props.createAgent({ ...prepareAddAgent(values), ...avatarPath }).then(resp => {
      if (resp.type === "AGENT_REGISTER_SUCCESS") {
        props.history.push("/dashboard/agents");
      } else if (resp.payload.error === "ERR_PARAM_INVALID") {
        setIsLoading(false)
        openNotification('error', smallNotification.errorTitle, agents.errors.incorrectPhoneFormat)
      } else {
        setIsLoading(false)
        setErrorCode(resp.payload.error)
      }
    })
  }

  const resetErrorHandler = () => {
    setErrorCode("")
    setUserRegModalVisible(true)
  }

  const switchModals = (name) => {
    switch (name) {
      case 'ERR_AGENT_INFO_003':
        return (<InfoModal
          isSaved={false}
          title={agents.errorModal.heading}
          isModalVisible={isUserRegModalVisible}
          closeModal={setUserRegModalVisible}
          resetErrorHandler={resetErrorHandler}
          backPath={"/dashboard/staff"}
          img={<WarningSvg />}
          text={agents.create.alreadyExistsError}
          successBtnText={agents.errorModal.backBtn}
        />)
      default: return ("")
    }
  }

  const prepareAddAgent = (values) => {
    Object.keys(values).forEach(agentItem => {
      if (values[agentItem] == undefined || values[agentItem] == "") {
        values[agentItem] = null
      }
      return values[agentItem]
    })
    return (
      {
        "district": values.district,
        "email": values.email,
        "phone": `+${values.phone}`,
        "locality": localityValue,
        "region": values.region,
        "localityType": localityType,
        "firstName": values.firstName,
        "lastName": values.lastName,
      }
    )
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    if (district === "" || locality === "") {
      setIsEmptyFields(true)
    }
  };

  const submitHandler = () => {
    setIsFileMissed(false)
    formRef.current.submit();
    if (!fileList.length) {
      console.log(isFileMissed);
      setIsFileMissed(true)
    }
  }

  const resetModalHandler = () => {
    setIsResetModalVisible(true)
  }

  // const districtChangeHandler = ({ suggestion }) => {
  //   setAddress({ region: suggestion.region, district: suggestion.district })
  // }

  const regionSelectHandler = (name) => {
    setRegion(name)
    props.getDistrictAdress(name).then(resp => {
      setDistrict(resp.LocalityData.data)
    })
  }

  const districtSelectHandler = (name) => {
    props.getLocalityAdress(region, name).then(resp => {
      setLocality(resp.LocalityData.data)
    })
  }

  const localitySelectHandler = (name) => {
    setLocalityType(name.split('|')[0])
    setlocalityValue(name.split(['|'])[1])
  }

  // const valueChangeHandler = (value) => (name) => {
  //   if (value === "districtName") {
  //     setDistrict(name)
  //     formRef.current.setFieldsValue({ district: name })
  //   } else {
  //     setLocality(name)
  //     formRef.current.setFieldsValue({ locality: name })
  //   }
  // }

  const fileProps = {
    onRemove: file => {
      setfileList([])
      setisOverSize(false)
      setunknownFormat(false)
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        file.flag = true;
        setisOverSize(true)
        return false;
      }
      setIsFileMissed(false)
      setisOverSize(false)
      setfileList([file])
      return false;
    }
  };

  return (
    <div className="add-agent-wrapper">
      {isLoading && <SpinComponent fullHeight={true} />}
      {switchModals(errorCode)}
      <InfoModal
        isSaved={true}
        title={agents.create.cancelEditModalHeading}
        isModalVisible={isResetModalVisible}
        closeModal={setIsResetModalVisible}
        backPath={"/dashboard/agents"}
        img={<WarningSvg />}
        text={<span>{agents.create.cancelEditModalMessagePartOne}<br />{agents.create.cancelEditModalMessagePartTwo}</span>}
        cancelBtnText={agents.create.cancelEditModalCancelBtn}
        successBtnText={agents.create.cancelEditModalStayBtn}
      />
      <ScreenHeaderSingle
        title={agents.create.heading}
        backPath={"/dashboard/agents"}
        submitHandler={submitHandler}
        resetModalHandler={resetModalHandler}
      />
      <Form
        name="add-user"
        ref={formRef}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h3>{agents.create.subheadingPersonalInfo}</h3>
        <Row gutter={24} className="form-row">
          <Form.Item
            name="lastName"
            label={agents.fields.lastName}
            rules={[
              { required: true, message: agents.fields.requiredFieldErrorMessage },
              { min: 2, message: agents.fields.lastNameErrorMessage },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            name="firstName"
            label={agents.fields.firstName}
            rules={[
              { required: true, message: agents.fields.requiredFieldErrorMessage },
              { min: 2, message: agents.fields.firstNameErrorMessage },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={agents.fields.phone}
            rules={[
              { required: true, message: agents.fields.requiredFieldErrorMessage },
            ]}
          >
            <Input className="tel-input" prefix={"+"} />
          </Form.Item>
          <Form.Item
            label={agents.fields.email}
            name={['email']}
            rules={[{ required: true, message: agents.fields.requiredFieldErrorMessage }, { type: 'email', message: agents.fields.emailErrorMessage }]
            }>
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="patronymic"
            label="По-батьковi"
            rules={[
              { required: true, message: 'Заповнiть це поле' }
            ]}
          >
            <Input />
          </Form.Item> */}
        </Row>
        <h3>Локація</h3>
        <Row gutter={24} className="form-row">
          <Form.Item
            name="region"
            label={agents.fields.region}
            rules={[
              { required: true, message: agents.fields.requiredFieldErrorMessage }
            ]}
          >
            <Select
              dropdownClassName="agents-region"
              onChange={regionSelectHandler}
            >
              {OnlyRegionsList.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="district"
            label={agents.fields.district}
            rules={[
              { required: true, message: agents.fields.requiredFieldErrorMessage }
            ]}
          >
            <Select
              dropdownClassName="agents-district"
              onChange={districtSelectHandler}
              disabled={!region.length}
            >
              {district.map((item, index) => (
                <Select.Option key={index} value={item.district}>
                  {item.district}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <DistrictSearch
            region={address.region}
            districtChangeHandler={districtChangeHandler}
            valueChangeHandler={valueChangeHandler('districtName')}
            district={district}
            isEmptyFields={isEmptyFields}
          /> */}
          <Form.Item
            name="locality"
            label={agents.fields.locality}
            rules={[
              { required: true, message: agents.fields.requiredFieldErrorMessage }
            ]}
          >
            <Select
              dropdownClassName="agents-locality"
              disabled={!district.length}
              onChange={localitySelectHandler}
            >
              {locality.map((item, index) => (
                <Select.Option key={index} value={`${item.localityType}|${item.locality}`}>
                  {`${item.localityType} ${item.locality}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <LocalitySearch
            region={address.region}
            districtChangeHandler={districtChangeHandler}
            valueChangeHandler={valueChangeHandler('localityName')}
            district={district}
            locality={locality}
            isEmptyFields={isEmptyFields}
          /> */}
        </Row>
        <h3>Фото</h3>
        <Row gutter={24} className="form-row">
          <Upload
            className="ant-row ant-form-item"
            maxCount={1}
            beforeUpload={fileProps.beforeUpload}
            onRemove={fileProps.onRemove}
            fileList={fileList}
            multiple={false}
            accept=".jpg, .jpeg, .bmp, .gif, .png"
          >
            <Button className={isFileMissed && "red-border"} icon={<FolderSvg />}>{agents.fields.uploadImageLabel}</Button>
            {unknownFormat && <span className="small-size-normal">{agents.fields.uploadImageErrorFormat}</span>}
            {isOverSize && <span className="small-size-normal">{agents.fields.uploadImageErrorFileTooLarge}</span>}
            {isFileMissed && <span className="small-size-normal" style={{ textAlign: "center" }}>{agents.fields.uploadImageErrorNoFile}</span>}
          </Upload>
        </Row>
      </Form>
    </div>
  );
};

export default connector(AddAgentScreen);