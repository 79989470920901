import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import "./InfoModal.scss";

interface IProps {
  isModalVisible: boolean;
  closeModal: any;
  title: string;
  isSaved?: boolean;
  onSubmit?: any;
  backPath?: string;
  img: any;
  text: any;
  cancelBtnText?: string;
  successBtnText?: string;
  resetErrorHandler?: any;
  openTagsHandler?: any;
}

const InfoModal:FC <IProps> = (props) => {

  const handleCancel = () => {
    props.closeModal(false);
    if(props.resetErrorHandler) {
      props.resetErrorHandler()
    }
    if(props.openTagsHandler) {
      props.openTagsHandler()
    }
  };
  return (
    <Modal title={props.title}
      visible={props.isModalVisible}
      closable={false}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="modal-info"
      centered={true}
    >
      {props.img}
      <p>{props.text}</p>
      {props.children}
      <div className="btn-wrapper">
        {props.isSaved &&
          <Button type="default" className="btn" key={'1'} onClick={props.onSubmit}>
            <Link to={props.backPath}>{props.cancelBtnText}</Link>
          </Button>
        }
        <Button type="primary" id="InfoCancel" className="btn"  key={'2'} onClick={handleCancel}>
          {props.successBtnText}
        </Button>
      </div>
    </Modal>
  )
};

export default InfoModal;