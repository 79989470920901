import {Button, Form, Input, Modal, Row, Select, Tooltip} from 'antd';
import React, { FC } from 'react';
import "./CalculatorModal.scss";
import { connect } from 'react-redux';
import {ReactComponent as ToolTipIcon} from "assets/images/ic-info-question.svg";
import {users} from '../../globalConstants/textinputs.json';
import { useForm } from 'antd/lib/form/Form';
import {
    ICalculateMarketPriceFailureAction,
    ICalculateMarketPricePendingAction,
    ICalculateMarketPriceSuccessAction,
    IGetMarketPricesFailureAction,
    IGetMarketPricesPendingAction,
    IGetMarketPricesSuccessAction
} from "../../store/prices/types";
import {calculateMarketPrice, getMarketPrices} from "../../store/prices/actions";
import { ReactComponent as IconClose } from 'assets/images/ic-close.svg';
import {marketPrices as marketPricesTexts} from '../../globalConstants/textinputs.json';

interface IProps {
    isModalVisible: boolean;
    closeModal: (boolean) => void;
    getMarketPrices: () => Promise<IGetMarketPricesSuccessAction | IGetMarketPricesPendingAction | IGetMarketPricesFailureAction>
    prices: any;
    calculateMarketPrice: (marketPriceId : number, area: number) => Promise<ICalculateMarketPriceSuccessAction | ICalculateMarketPricePendingAction | ICalculateMarketPriceFailureAction>
}

const mapStateToProps = state => ({
    prices: state.prices
});

const mapDispatchToProps = {
    getMarketPrices,
    calculateMarketPrice
};

const connector = connect(mapStateToProps, mapDispatchToProps);


export const CalculatorModal: FC<IProps> = (props) => {

    const [region, setRegion] = React.useState<string>("");

    const [districtValue, setDistrictValue] = React.useState<string>()

    const [marketPrices, setMarketPrices] = React.useState<any>(props.prices.marketPrices);

    const [marketPricesRegions, setMarketPricesRegions] = React.useState<string[]>([])

    const [marketPricesDistricts, setMarketPricesDistricts] = React.useState<string[]>([])

    const [calculatedPriceFrom, setCalculatedPriceFrom] = React.useState<number>(props.prices.calculatedMarketPriceFrom);

    const [calculatedPriceTo, setCalculatedPriceTo] = React.useState<number>(props.prices.calculatedMarketPriceTo);

    const [form] = useForm();

    const {getMarketPrices, calculateMarketPrice} = props;

    React.useEffect(() => {
         getMarketPrices();
    }, [])

    React.useEffect(() => {
        setMarketPrices(props.prices.marketPrices);
        setArrayOfRegions(props.prices.marketPrices);
    }, [props.prices.marketPrices])

    React.useEffect(() => {
        setCalculatedPriceFrom(props.prices.calculatedMarketPriceFrom)
        setCalculatedPriceTo(props.prices.calculatedMarketPriceTo)
    }, [props.prices.calculatedMarketPriceFrom, props.prices.calculatedMarketPriceTo])

    const setArrayOfRegions = (array) => {

        if (array && array.length) {
            const arrayOfRegions = array.map(item => item.region).filter((value, index, self) => self.indexOf(value) === index)
            setMarketPricesRegions(arrayOfRegions)
        }
    }

    const setArrayOfDistricts = (name) => {
        const districtsArray = marketPrices.filter(item => item.region === name).map(item => item.district);
        setMarketPricesDistricts(districtsArray);
    }

    const handleCancel = () => {
        props.closeModal(false);

        form.setFieldsValue({
            "region": "",
            "district": "",
            "area": "",
        })

        setRegion("");
        setDistrictValue("");
        setCalculatedPriceFrom(null);
        setCalculatedPriceTo(null);
    };

    const regionSelectHandler = (name) => {
        setRegion(name)
        setCalculatedPriceFrom(null);
        setCalculatedPriceTo(null);
        form.setFieldsValue({
            "district": "",
        })

        setArrayOfDistricts(name);
    }

    const districtSelectHandler = (name) => {
        setDistrictValue(name);
        setCalculatedPriceFrom(null);
        setCalculatedPriceTo(null);
    }

    const onFinish = (values) => {

        if (marketPrices && marketPrices.length) {
            const correctDistrictArray = marketPrices.filter((item) => {
                return item.region === values.region && item.district === values.district;
            })

            if (correctDistrictArray && correctDistrictArray.length > 0) {
                const marketPriceId = correctDistrictArray[0].id;
                const square = +values.area;
                calculateMarketPrice(marketPriceId, square)
            }

        }

    };


    return (
        <Modal title={marketPricesTexts.calculatorModal.title}
               visible={props.isModalVisible}
               closable={true}
               onCancel={handleCancel}
               footer={null}
               className="calculate-prices-modal"
               centered={true}
               closeIcon={<IconClose/>}
               forceRender={true}
        >
            <Form name="calculator-form" form={form}
                  initialValues={{
                        region: region,
                        district: "",
                        area: ""
                  }}
                  onFinish={onFinish}
            >
                    <Form.Item
                        name="region"
                        label={marketPricesTexts.calculatorModal.regionLabel}
                        rules={[
                            { required: true, message: marketPricesTexts.calculatorModal.requiredFieldErrorMessage }
                        ]}
                    >
                        <Select onChange={regionSelectHandler}>
                            {marketPricesRegions.map((item, index) => (
                                <Select.Option key={index} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="district"
                        label={marketPricesTexts.calculatorModal.districtLabel}
                        rules={[
                            { required: true, message: marketPricesTexts.calculatorModal.requiredFieldErrorMessage }
                        ]}
                    >
                        <Select onChange={districtSelectHandler}>
                            {marketPricesDistricts.map((item, index) => (
                                <Select.Option key={index} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="area"
                        label={marketPricesTexts.calculatorModal.area}
                        rules={[
                            { required: true, message: users.fields.requiredFieldErrorMessage,
                            },
                            {
                                validator: (_, value) =>
                                    value > 0 ? Promise.resolve() : Promise.reject(new Error(marketPricesTexts.calculatorModal.lessOrEqualToZeroError)),
                            },
                        ]}
                    >
                        <Input type="number" />

                    </Form.Item>

                <div className="btn-wrapper">
                    <Button type="primary" htmlType="submit" className="calculate-btn">
                        {marketPricesTexts.calculatorModal.calculateBtn}
                    </Button>
                </div>
            </Form>

            {
                calculatedPriceFrom && calculatedPriceTo &&
                <div className='probable-price-wrapper'>
                    <Tooltip title={marketPricesTexts.calculatorModal.tooltipText} overlayClassName="calculator-tooltip" color="#fff" placement="bottom" overlayStyle={{width: "100%", maxWidth: "428px", borderRadius: "16px" }}><p className="label">{marketPricesTexts.calculatorModal.probablePrice}<ToolTipIcon className="price-tooltip" /> </p></Tooltip>
                    <p className="price">{marketPricesTexts.calculatorModal.fromText} {calculatedPriceFrom} {marketPricesTexts.calculatorModal.priceCurrency}</p>
                    <p className="price">{marketPricesTexts.calculatorModal.toText} {calculatedPriceTo} {marketPricesTexts.calculatorModal.priceCurrency} </p>
                </div>
            }

        </Modal>
    );
};

export default connector(CalculatorModal);