import ChatField from 'components/ChatField/ChatField';
import ChatList from 'components/ChatList/ChatList';
import ChatUserInfo from 'components/ChatUserInfo/ChatUserInfo';
import React from 'react';
import "./ChatScreen.scss";
import { Client } from '@stomp/stompjs'
import { getUserStoredData } from 'services';
const { token } = getUserStoredData();

const ChatScreen = (props) => {
  
  const socketClient = new Client({
    //brokerURL: `ws://163.172.103.125:8060/chat-service/farmland-chats`,
    //brokerURL: `wss://admin.farmland.t.coreteka.com/api/chat-service/farmland-chats`
    brokerURL: `${process.env.REACT_APP_CHAT_BROKER_URL}?auth-token=${token}`,
  });

  return (
    <div className="chat-screen">
        <ChatList socketClient = {socketClient}/>
        <ChatField socketClient = {socketClient}/> 
        <ChatUserInfo />
    </div>
  );
};

export default ChatScreen;