import Api from "api";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  IAgentRegisterFailureAction,
  IAgentRegisterPendingAction,
  IAgentRegisterSuccessAction,
  IAgentRemoveFailureAction,
  IAgentRemovePendingAction,
  IAgentRemoveSuccessAction,
  IAgentUpdateFailureAction,
  IAgentUpdatePendingAction,
  IAgentUpdateSuccessAction,
  IAgentView,
  IDeleteSingleAgentDocumentFailureAction,
  IDeleteSingleAgentDocumentPendingAction,
  IDeleteSingleAgentDocumentSuccessAction,
  IDownloadSingleAgentDocumentFailureAction,
  IDownloadSingleAgentDocumentPendingAction,
  IDownloadSingleAgentDocumentSuccessAction,
  IGetAgentsDocumentsFailureAction,
  IGetAgentsDocumentsPendingAction,
  IGetAgentsDocumentsSuccessAction,
  IPostAgentDocumentsFailureAction,
  IPostAgentDocumentsPendingAction,
  IPostAgentDocumentsSuccessAction,
  IsendAvatarFailureAction,
  IsendAvatarPendingAction,
  IsendAvatarSuccessAction,
  ISetAgentsFailureAction,
  ISetAgentsListAction,
  ISetAgentsPendingAction,
  ISetSingleAgentAction,
  ISetSingleAgentFailureAction,
  ISetSingleAgentPendingAction,
  IupdateAvatarFailureAction,
  IupdateAvatarPendingAction,
  IupdateAvatarSuccessAction
} from "./types";

export const getAgentsList: ActionCreator<
  ThunkAction<
    Promise<ISetAgentsListAction | ISetAgentsPendingAction | ISetAgentsFailureAction>,
    any,
    null,
    ISetAgentsListAction
  >
> = (sortBy, sortType, page = 0, size = 15, rsql = "") => {
  return async (dispatch: Dispatch) => {
    const getAgentsListPendingAction: ISetAgentsPendingAction = {
      type: 'GET_AGENTS_LIST_PENDING',
    };
    dispatch(getAgentsListPendingAction);
    try {
      const response = await Api.get(`/core-service/agent-info?page=${page}&size=${size}&sort=${sortBy},${sortType}${rsql}`)
      const preview = response.data.data
      console.log(preview)
      const getAgentsListSuccessAction: ISetAgentsListAction = {
        preview,
        type: 'GET_AGENTS_LIST_SUCCESS',
      };
      return dispatch(getAgentsListSuccessAction);
    } catch (error) {
      const getAgentsListFailureAction: ISetAgentsFailureAction = {
        type: 'GET_AGENTS_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAgentsListFailureAction);
    }
  };
}

export const createAgent: ActionCreator<
  ThunkAction<
    Promise<IAgentRegisterSuccessAction | IAgentRegisterPendingAction | IAgentRegisterFailureAction>,
    any,
    null,
    IAgentRegisterSuccessAction
  >
> = (data) => {
  return async (dispatch: Dispatch) => {
    const createStaffPendingAction: IAgentRegisterPendingAction = {
      type: 'AGENT_REGISTER_PENDING',
    };
    dispatch(createStaffPendingAction);

    try {
      const agentDataResponse = await Api.post('/core-service/agent-info', { ...data });
      const agentData = agentDataResponse.data;
      const createStaffRegSuccessAction: IAgentRegisterSuccessAction = {
        agentData,
        type: 'AGENT_REGISTER_SUCCESS',
      };
      return dispatch(createStaffRegSuccessAction);
    } catch (error) {
      const createStaffFailureAction: IAgentRegisterFailureAction = {
        type: 'AGENT_REGISTER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createStaffFailureAction);
    }
  };
};

export const removeAgent: ActionCreator<
  ThunkAction<
    Promise<IAgentRemoveSuccessAction | IAgentRemoveFailureAction>,
    any,
    any,
    IAgentRemoveSuccessAction | IAgentRemoveFailureAction
  >
> = (agentId) => {
  return async (dispatch: Dispatch) => {
    const removeAgentPendingAction: IAgentRemovePendingAction = {
      type: "REMOVE_AGENT_PENDING",
    };
    dispatch(removeAgentPendingAction);
    try {
      const response = await Api.delete(`/core-service/agent-info/${agentId}`)
      const preview: any = response.data.data
      const removeAgentSuccessAction: IAgentRemoveSuccessAction = {
        preview,
        type: "REMOVE_AGENT_SUCCESS",
      };
      return dispatch(removeAgentSuccessAction);
    } catch (error) {
      const removeAgentFailureAction: IAgentRemoveFailureAction = {
        type: "REMOVE_AGENT_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(removeAgentFailureAction);
    }
  }
}

export const sendAvatar: ActionCreator<
  ThunkAction<
    Promise<IsendAvatarSuccessAction | IsendAvatarFailureAction>,
    any,
    any,
    IsendAvatarSuccessAction | IsendAvatarFailureAction
  >
> = (documentData) => {
  return async (dispatch: Dispatch) => {
    const sendAvatarPendingAction: IsendAvatarPendingAction = {
      type: 'SEND_AVATAR_PENDING',
    };
    dispatch(sendAvatarPendingAction);

    try {
      let formData = new FormData();
      console.log(documentData[0])
      formData.append("file", documentData[0]);
      console.log(formData); // not consoled, blet! empty!!

      const authDataResponse = await Api.post(`/core-service/agent-info/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const preview = authDataResponse.data
      const sendAvatarSuccessAction: IsendAvatarSuccessAction = {
        type: 'SEND_AVATAR_SUCCESS',
        preview
      };
      return dispatch(sendAvatarSuccessAction);
    } catch (error) {

      const sendAvatarFailureAction: IsendAvatarFailureAction = {
        type: 'SEND_AVATAR_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(sendAvatarFailureAction);
    }
  };
};

export const getSingleAgent: ActionCreator<
  ThunkAction<
    Promise<ISetSingleAgentAction | ISetSingleAgentPendingAction | ISetSingleAgentFailureAction>,
    any,
    null,
    ISetSingleAgentFailureAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const getStaffListPendingAction: ISetSingleAgentPendingAction = {
      type: 'GET_SINGLE_AGENT_PENDING',
    };
    dispatch(getStaffListPendingAction);
    try {
      const response = await Api.get(`/core-service/agent-info/${id}`)
      const preview = response.data.data
      console.log(preview)
      const getStaffListSuccessAction: ISetSingleAgentAction = {
        preview,
        type: 'GET_SINGLE_AGENT_SUCCESS',
      };
      return dispatch(getStaffListSuccessAction);
    } catch (error) {
      const getStaffListFailureAction: ISetSingleAgentFailureAction = {
        type: 'GET_SINGLE_AGENT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getStaffListFailureAction);
    }
  };
}

export const updateAgent: ActionCreator<
    ThunkAction<
        Promise<IAgentUpdateSuccessAction | IAgentUpdatePendingAction | IAgentUpdateFailureAction>,
        any,
        null,
        IAgentUpdateSuccessAction
        >
    > = (id, data) => {
  return async (dispatch: Dispatch) => {
    const updateAgentPendingAction: IAgentUpdatePendingAction = {
      type: 'AGENT_UPDATE_PENDING',
    };
    dispatch(updateAgentPendingAction);

    try {
      const agentDataResponse = await Api.put(`/core-service/agent-info/${id}`, { ...data });
      const agentData = agentDataResponse.data;
      const updateAgentSuccessAction: IAgentUpdateSuccessAction = {
        agentData,
        type: 'AGENT_UPDATE_SUCCESS',
      };
      return dispatch(updateAgentSuccessAction);
    } catch (error) {
      const updateAgentFailureAction: IAgentUpdateFailureAction = {
        type: 'AGENT_UPDATE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateAgentFailureAction);
    }
  };
};

export const updateAvatar: ActionCreator<
    ThunkAction<
        Promise<IupdateAvatarSuccessAction | IupdateAvatarFailureAction>,
        any,
        any,
        IupdateAvatarPendingAction | IupdateAvatarFailureAction
        >
    > = (id, documentData) => {
  return async (dispatch: Dispatch) => {
    const updateAvatarPendingAction: IupdateAvatarPendingAction = {
      type: 'UPDATE_AVATAR_PENDING',
    };
    dispatch(updateAvatarPendingAction);

    try {
      let formData = new FormData();
      console.log(documentData[0])
      formData.append("file", documentData[0]);
      console.log(formData);

      const authDataResponse = await Api.post(`/core-service/agent-info/${id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const preview = authDataResponse.data
      const updateAvatarSuccessAction: IupdateAvatarSuccessAction = {
        type: 'UPDATE_AVATAR_SUCCESS',
        preview
      };
      return dispatch(updateAvatarSuccessAction);
    } catch (error) {

      const updateAvatarFailureAction: IupdateAvatarFailureAction = {
        type: 'UPDATE_AVATAR_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateAvatarFailureAction);
    }
  };
};

export const getAgentsViewType = (view: IAgentView) => {
  return (dispatchFunc) => {
    return dispatchFunc({
      type: "SET_AGENTS_VIEW_SUCCESS",
      payload: view
    })
  }
};

export const getAgentDocuments: ActionCreator<
    ThunkAction<
        Promise<IGetAgentsDocumentsSuccessAction | IGetAgentsDocumentsPendingAction | IGetAgentsDocumentsFailureAction>,
        any,
        null,
        IGetAgentsDocumentsSuccessAction
        >
    > = (id: number) => {
  return async (dispatch: Dispatch) => {

    const getAgentDocumentsPendingAction: IGetAgentsDocumentsPendingAction = {
      type: 'GET_AGENTS_DOCUMENTS_PENDING',
    };

    dispatch(getAgentDocumentsPendingAction);
    try {
      const response = await Api.get(`/core-service/agent-info/${id}/documents/`)
      const documents = response.data.data
      console.log(documents)

      const getAgentDocumentsSuccessAction: IGetAgentsDocumentsSuccessAction = {
        type: 'GET_AGENTS_DOCUMENTS_SUCCESS',
        payload: documents
      };
      return dispatch(getAgentDocumentsSuccessAction);
    } catch (error) {

      const getAgentDocumentsFailureAction: IGetAgentsDocumentsFailureAction = {
        type: 'GET_AGENTS_DOCUMENTS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAgentDocumentsFailureAction);
    }
  };
}

export const postAgentDocuments: ActionCreator<
    ThunkAction<
        Promise<IPostAgentDocumentsSuccessAction | IPostAgentDocumentsFailureAction>,
        any,
        any,
        IPostAgentDocumentsFailureAction
        >
    >  = (agentID, documentData, type) => {
  return async (dispatch: Dispatch) => {
    const postAgentDocumentsPendingAction: IPostAgentDocumentsPendingAction = {
      type: 'POST_AGENT_DOCUMENT_PENDING',
    };
    dispatch(postAgentDocumentsPendingAction);

    try {
      let formData = new FormData();
      console.log(documentData[0])
      formData.append("file", documentData[0]);
      console.log(formData);

      const authDataResponse = await Api.post(`/core-service/agent-info/${agentID}/documents?type=${type}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const preview = authDataResponse.data
      const postAgentDocumentsSuccessAction: IPostAgentDocumentsSuccessAction = {
        type: 'POST_AGENT_DOCUMENT_SUCCESS',
        preview
      };
      return dispatch(postAgentDocumentsSuccessAction);
    } catch (error) {

      const postAgentDocumentsFailureAction: IPostAgentDocumentsFailureAction = {
        type: 'POST_AGENT_DOCUMENT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(postAgentDocumentsFailureAction);
    }
  };
};

export const downloadSingleAgentDocument:ActionCreator<
    ThunkAction<
        Promise<IDownloadSingleAgentDocumentSuccessAction | IDownloadSingleAgentDocumentPendingAction | IDownloadSingleAgentDocumentFailureAction>,
        any,
        null,
        IDownloadSingleAgentDocumentSuccessAction
        >
    >  = (agentID, documentID) => {
  return async (dispatch: Dispatch) => {
    const downloadSingleAgentDocumentPendingAction: IDownloadSingleAgentDocumentPendingAction = {
      type: 'DOWNLOAD_SINGLE_AGENT_DOCUMENT_PENDING',
    };
    dispatch(downloadSingleAgentDocumentPendingAction);
    try {
      const response = await Api.get(`/core-service/agent-info/${agentID}/documents/${documentID}`, {responseType: "blob"})
      const preview = response.data
      const downloadSingleAgentDocumentSuccessAction: IDownloadSingleAgentDocumentSuccessAction = {
        preview,
        type: 'DOWNLOAD_SINGLE_AGENT_DOCUMENT_SUCCESS',
      };
      dispatch(downloadSingleAgentDocumentSuccessAction);
      return preview;
    } catch (error) {
      const downloadSingleAgentDocumentFailureAction: IDownloadSingleAgentDocumentFailureAction = {
        type: 'DOWNLOAD_SINGLE_AGENT_DOCUMENT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(downloadSingleAgentDocumentFailureAction);
    }
  };
}

export const deleteSingleAgentDocument: ActionCreator<
    ThunkAction<
        Promise<IDeleteSingleAgentDocumentSuccessAction | IDeleteSingleAgentDocumentFailureAction>,
        any,
        any,
        IDeleteSingleAgentDocumentSuccessAction | IDeleteSingleAgentDocumentFailureAction
        >
    > = (agentID, documentID) => {
  return async (dispatch: Dispatch) => {
    const deleteSingleAgentDocumentPendingAction: IDeleteSingleAgentDocumentPendingAction = {
      type: "DELETE_SINGLE_AGENT_DOCUMENT_PENDING",
    };
    dispatch(deleteSingleAgentDocumentPendingAction);
    try {
      const response = await Api.delete(`/core-service/agent-info/${agentID}/documents/${documentID}`)
      const preview: any = response.data.data
      const deleteSingleAgentDocumentSuccessAction: IDeleteSingleAgentDocumentSuccessAction = {
        preview,
        type: "DELETE_SINGLE_AGENT_DOCUMENT_SUCCESS",
      };
      return dispatch(deleteSingleAgentDocumentSuccessAction);
    } catch (error) {
      const deleteSingleAgentDocumentFailureAction: IDeleteSingleAgentDocumentFailureAction = {
        type: "DELETE_SINGLE_AGENT_DOCUMENT_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(deleteSingleAgentDocumentFailureAction);
    }
  }
}