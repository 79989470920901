import React, { FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  IAdvertGetOnModerationCountFailureAction,
  IAdvertGetOnModerationCountSuccessAction,
  IAdvertScreenInfo,
  IAdvertTakeModerationFailureAction,
  IAdvertTakeModerationPendingAction,
  IAdvertTakeModerationSuccessAction,
  IRefreshRegistryDataFailureAction,
  IRefreshRegistryDataSuccessAction, ISaveAdvertScreenInfoAction, ISaveUserAdvertScreenInfoAction,
  IsetAddFailureAction,
  IsetAddListAction,
  IsetAddPendingAction, IUserAdvertScreenInfo
} from 'store/adverts/types';
import {
  getAdvertList,
  advertTakeModeration,
  refreshRegistryData,
  setAdvertScreenInfo,
  setUserAdvertScreenInfo, getAdvertOnModerationCount
} from 'store/adverts/actions';
import "./AdvertList.scss";
import { Button, Col, Pagination, Row } from 'antd';
import { ReactComponent as EditSvg } from 'assets/images/ic-edit-w.svg'
import { ReactComponent as SharedSvg } from 'assets/images/ic-share.svg'
import { ReactComponent as DeleteSvg } from 'assets/images/ic-delete-w.svg'
import { ReactComponent as InvoiceSvg } from 'assets/images/ic-invoice.svg'
import { ReactComponent as GetDataSvg } from 'assets/images/ic-resize.svg'
import { ReactComponent as ChangeAuthorSvg } from 'assets/images/ic-profile-black.svg'
import noPhoto from "assets/images/no-data.svg";
import SpinComponent from 'components/SpinComponent/SpinComponent';
import { ReactComponent as SuccessFilledSvg } from 'assets/images/ic-success-filled.svg'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { adStatusMapper, clientTagsColorMapper, clientTagsMapper, clientTagsShortMapper, rentStatusMapper, tenantCanceledMapper } from 'services/mapper';
import { addSpacesToPrice, openNotification } from 'services';
import { ReactComponent as DealSvg } from "../../assets/images/ic-file-white.svg";
import { adverts as advertsText, smallNotification } from '../../globalConstants/textinputs.json';
import EmptyContent from "../EmptyContent/EmptyContent";
import ChangeAuthorModal from 'components/ChangeAuthorModal/ChangeAuthorModal';

interface IProps {
  getAdvertList: (sortBy: string, sortType: string, page: number, size: number, rsql?: string) => Promise<IsetAddListAction | IsetAddPendingAction | IsetAddFailureAction>;
  rsql: string;
  advert: any;
  advertTakeModeration: (id: string) => any;
  refreshRegistryData: (id: string) => Promise<IRefreshRegistryDataSuccessAction | IRefreshRegistryDataFailureAction>;
  getAdvertOnModerationCount: () => Promise<IAdvertGetOnModerationCountSuccessAction | IAdvertGetOnModerationCountFailureAction>;
  isRemoveBtn?: boolean;
  takeWorkBtn?: boolean;
  isAgreeBtn?: boolean;
  history: any;
  hasSearched?: boolean;
  userId?: number;
  setCounter?: (value) => any;
  activeTab?: string;
  setAdvertScreenInfo: (advertScreenInfo: IAdvertScreenInfo) => ISaveAdvertScreenInfoAction;
  setUserAdvertScreenInfo: (userAdvertScreenInfo: IUserAdvertScreenInfo) => ISaveUserAdvertScreenInfoAction;
  from: 'advertsScreen' | 'userAdvertsScreen';
  fromId?: string;
  activePage?: number;
  setActivePage?: (page: number) => void
  needRefresh: boolean;
  setNeedRefresh: (value) => void;
  tabName?: string;
  activeTabName?: string;
  resetPage?: boolean;
  setResetPage?: (value) => void;
  sortAdvertsBy: "createdDate" | "updatedDate"
}

const mapStateToProps = state => ({
  advert: state.advert,
  userId: state.user.userInfo?.id
});

const mapDispatchToProps = {
  getAdvertList,
  advertTakeModeration,
  refreshRegistryData,
  setAdvertScreenInfo,
  setUserAdvertScreenInfo,
  getAdvertOnModerationCount
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AdvertList: FC<IProps> = (props) => {

  const { setAdvertScreenInfo, setUserAdvertScreenInfo } = props;
  const [filterString, setAdvertFilterString] = React.useState(() => {
    const filter = `${props.rsql}${props.advert.filter?.string ? `;${props.advert.filter?.string}` : ""}`
    return filter
  })

  const [currentPage, setCurrentPage] = React.useState(() => {
    const currentPage = props.activeTabName === props.tabName ? props.activePage : 0;
    return currentPage
  });

  const [isChangeAuthorModalVisible, setIsChangeAuthorModalVisible] = React.useState<boolean>(false);

  const [adId, setIsAdId] = React.useState<string>("");
  const [currentAuthorId, setIsCurrentAuthorId] = React.useState<string>("");

  useEffect(() => {
    setIsLoading(false)
    updateAdvertsInfo()
  }, [])

  useEffect(() => {

    if (props.advert.filter.string) {
      setAdvertFilterString(`${props.rsql};${props.advert.filter.string}`)
    } else {
      setAdvertFilterString(props.rsql)
    }

  }, [props.advert.filter.string])

  useEffect(() => {
    if (props.resetPage) {
      setCurrentPage(0);
      setIsLoading(false)

      props.getAdvertList(props.advert.filter.sortBy || "updatedDate", props.advert.filter.sortAsc || "desc", 0, 30, filterString).then(resp => {
        setAdverts(resp.preview)
      })

    }
  }, [props.resetPage])

  const saveTabInfo = (activeTab, activePage, tabName) => {
    switch (props.from) {
      case "advertsScreen":
        setAdvertScreenInfo({ activeTab: activeTab, activePage: activePage, activeTabName: tabName });
        break;
      case "userAdvertsScreen":
        setUserAdvertScreenInfo({ activeTab: activeTab, activePage: activePage, activeTabName: tabName });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (props.needRefresh) {
      setIsLoading(false)
      updateAdvertsInfo()
    }
  }, [props.needRefresh])

  const updateAdvertsInfo = () => {
    props.getAdvertList(props.advert.filter.sortBy || 'updatedDate', props.advert.filter.sortAsc || 'desc', currentPage, 30, filterString).then(resp => {
      return resp.preview
    }).then(respi => {
      const prepareToVisible = () => {
        setAdverts(respi)
        setIsLoading(true)
        props.setNeedRefresh(false);
        if (props.setResetPage) {
          props.setResetPage(false);
        }
        if (props.setCounter) {
          props.setCounter(respi.totalElements);
        }
      }
      setTimeout(() => prepareToVisible(), 1000);

    })
  }

  useEffect(() => {
    updateAdvertsInfo()
  }, [props.advert.filter])

  const [adverts, setAdverts] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const takeModerationHandler = (id) => (e) => {
    props.advertTakeModeration(id).then(resp => {

      if (resp.status === "OK") {
        saveTabInfo(props.activeTab, currentPage, props.tabName);
        props.history.push(`/dashboard/advert/${id}`, { from: props.from, fromId: props.fromId });
        updateAdvertsInfo()
      } else if (resp.payload?.error === "ERR_AD_010") {
        openNotification('error', smallNotification.errorTitle, advertsText.errors.takenIntoWorkByOtherUserError)
      }
    })
  }

  const registryDataHandler = (id) => (e) => {
    props.refreshRegistryData(id).then(resp => {
      if (resp.type === "ADVERT_REFRESH_DATA_SUCCESS") {
        setIsLoading(false)
        props.setNeedRefresh(true);
        props.getAdvertOnModerationCount();
        openNotification('info', smallNotification.infoTitle, advertsText.main.registryRequestSent);
      } else if (resp.payload?.error === "ERR_AD_008") {
        openNotification('error', smallNotification.errorTitle, advertsText.errors.refreshRegistryTryAgainError)
      } else if (resp.payload?.error === "ERR_AD_007") {
        openNotification('error', smallNotification.errorTitle, advertsText.errors.refreshRegistryTooManyAttemptsError)
      } else if (resp.payload?.error === "ERR_AD_004") {
        openNotification('error', smallNotification.errorTitle, advertsText.errors.refreshRegistryAlreduYetError)
      }

    })
  }

  const renderCropsInline = (array) => {
    const arrayOfCrops = array.map(item => {
      return item.crop
    })

    return arrayOfCrops.filter(Boolean).join(", ")

  }

  const openChangeAuthorModal = (addId, authorId) => e => {
    setIsChangeAuthorModalVisible(true);
    setIsAdId(addId);
    setIsCurrentAuthorId(authorId);
  }


  const handleGoToDeal = useCallback((id) => (e) => {
    if (id) {
      saveTabInfo(props.activeTab, currentPage, props.tabName);
      props.history.push(`/dashboard/contracts/${id}`);
    }
  }, [props.activeTab, currentPage, props.tabName])

  const handleGoToAdvert = useCallback((id) => (e) => {
    e.preventDefault();
    if (id) {
      saveTabInfo(props.activeTab, currentPage, props.tabName);
      props.history.push(`/dashboard/advert/${id}`, { from: props.from, fromId: props.fromId });
    }
  }, [props.activeTab, props.from, props.fromId, currentPage, props.tabName])


  const renderAdvert = () => {
    return adverts.content.map((item, index) => {

      const {  fio: { firstName, lastName }, id } = item.moderator ?? { fio: {}, id: null };

      return (
        <div className="advert-item" data-id={`advert-${index}`} key={index}>
          {!props.takeWorkBtn && <Link className="full-size" to={''} onClick={handleGoToAdvert(item.id)}></Link>}
          <div className="left-side">
            <img src={item.photoId ? `${process.env.REACT_APP_AMAZON_URL}/${item.photoId}` : noPhoto} alt="img" />
            {id && <span className="moderator">Взяв в работу: {lastName} {firstName[0]}.</span>}
          </div>
          <div className="right-side">
            <div className="top-line">
              <div className="left">
                <p className="info-id">
                  <span className="number">{advertsText.main.advertsListItem.cadastrialNo} {item.cadastralNumber}</span>
                  <span className="advert-id">{advertsText.main.advertsListItem.ID} {item.id}</span>
                </p>
                <span className="price">{item.price ? `${addSpacesToPrice(item.price)} ${advertsText.main.advertsListItem.currency}` : advertsText.main.advertsListItem.notStated}</span>
              </div>
              <div className="right">
                <div className="status-wrapper">
                  {item.sellAsap && <p className="status status-asap">Терміновe</p>}
                  <p className="date">{moment.utc(item.createdDate).local().format('DD.MM.YYYY, HH:mm')}</p>
                </div>
                <span className="info">{adStatusMapper(item.adStatus)}</span>
              </div>
            </div>
            <div className="bottom-line">
              <Row gutter={24}>
                <Col span={6}>
                  <div className="info-item" data-id={`area-${index}`}>
                    <p className="label">{advertsText.main.advertsListItem.areaLabel}</p>
                    <p className="info">{item.area ? `${item.area} ${advertsText.main.advertsListItem.areaUnits}` : advertsText.main.advertsListItem.notStated}</p>
                  </div>
                  <div className="info-item" data-id={`type-${index}`}>
                    <p className="label">{advertsText.main.advertsListItem.typeOfLandLabel}</p>
                    <p className="info">{item.type || advertsText.main.advertsListItem.notStated}</p>
                  </div>
                  <div className="info-item" data-id={`status-${index}`}>
                    <p className="label">{advertsText.main.advertsListItem.rentStatusLabel}</p>
                    {item.manualRentStatus ? rentStatusMapper(item.manualRentStatus) : rentStatusMapper(item.registryRentStatus) ? rentStatusMapper(item.registryRentStatus) : rentStatusMapper(item.rentStatus) ? rentStatusMapper(item.rentStatus) : rentStatusMapper(advertsText.main.advertsListItem.notStated)}
                  </div>
                </Col>
                <Col span={12} className="top-col">
                  <div className="top-part">
                    <div className="two-item-wrapper">
                      <div className="info-item" data-id={`region-${index}`}>
                        <p className="label">{advertsText.main.advertsListItem.regionLabel}</p>
                        <p className="info">{item.address?.region || advertsText.main.advertsListItem.notStated}</p>
                      </div>
                      <div className="info-item" data-id={`district-${index}`}>
                        <p className="label">{advertsText.main.advertsListItem.districtLabel}</p>
                        <p className="info">{item.address?.district || advertsText.main.advertsListItem.notStated}</p>
                      </div>
                    </div>
                    <div className="info-item" data-id={`culture-${index}`}>
                      <p className="label">{advertsText.main.advertsListItem.typeOfCulturesLabel}</p>
                      <p className="info">{item.cropStatistics.length > 0 ? renderCropsInline(item.cropStatistics) : advertsText.main.advertsListItem.notStated}</p>
                    </div>
                    <div className="info-item" data-id={`culture-${index}`}>
                      <p className="label">{advertsText.main.advertsListItem.purposeOfLandLabel}</p>
                      <p className="info">{item.registryPurpose || advertsText.main.advertsListItem.notStated}</p>
                    </div>
                  </div>
                </Col>
                <Col span={5} offset={1} className="last-row">
                  <div className="info-item" data-id={`city-${index}`}>
                    <p className="label">{advertsText.main.advertsListItem.localityLabel}</p>
                    <p className="info">{item.address?.settlement || advertsText.main.advertsListItem.notStated}</p>
                  </div>
                  <div className="author-info-wrapper">
                    <p className="author-tag" style={{ color: clientTagsColorMapper(item.author.tag) }}>{clientTagsShortMapper(item.author.tag)}</p>
                    <p className="author-name">{`${item.author.fio?.lastName || ""} ${item.author?.fio?.firstName || ""}`}</p>
                  </div>
                  <div className="action-wrapper">
                    {
                      (item.adStatus !== "NEW" && item.adStatus !== "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS" && item.adStatus !== "ON_MODERATION_REGISTRY_DATA_FAILED" && item.adStatus !== "DELETED") &&
                      <Button size="small" className="request-registry-info-btn" type="default" onClick={openChangeAuthorModal(item.id, item.author.id)}><ChangeAuthorSvg />{advertsText.main.advertsListItem.changeAuthorBtn}</Button>
                    }
                    {props.takeWorkBtn &&
                      <Button size="small" type="primary" onClick={takeModerationHandler(item.id)} disabled={id && id !== props.userId}><EditSvg />{advertsText.main.advertsListItem.takeIntoWork}</Button>
                    }
                    {(item.adStatus !== "BLOCKED" && item.adStatus !== "DELETED" && item.adStatus !== "NEW" && item.adStatus !== "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS" && item.adStatus !== "ACTIVE" && item.adStatus !== "DEAL_IN_PROGRESS" && item.adStatus !== "DEAL_IS_DONE") &&
                      <Button size="small" type="default" onClick={registryDataHandler(item.id)}><a><GetDataSvg />{advertsText.main.advertsListItem.requestDataBtn}</a></Button>
                    }
                    {(item.adStatus === "DEAL_IN_PROGRESS" || item.adStatus === "DEAL_IS_DONE") &&
                      item.activeDealId ?
                      <Button size="small" type="primary" className="go-to-contract-btn" onClick={handleGoToDeal(item.activeDealId)}>
                        <DealSvg />
                        {advertsText.main.advertsListItem.goToContract}
                      </Button>
                      : null
                    }
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )
    })
  }

  const changePages = (pageNumber) => {
    pageNumber = pageNumber - 1;
    props.setActivePage(pageNumber);
    setCurrentPage(pageNumber);
    props.getAdvertList(props.advert.filter.sortBy || 'updatedDate', props.advert.filter.sortAsc || 'desc', pageNumber, 30, filterString).then(resp => {
      setAdverts(resp.preview)
    })
  }


  return (
    <>
      {!isLoading && <SpinComponent />}
      {
        adverts &&
        <div className="adverts-list">
          {
            props.hasSearched ?
              adverts.content.length ?
                renderAdvert()
                : <EmptyContent messageOne={advertsText.errors.noSearchedAdvertsFoundMessageOne} messageTwo={advertsText.errors.noSearchedAdvertsFoundMessageTwo} />
              :
              adverts.content.length ?
                renderAdvert()
                : <EmptyContent messageOne={advertsText.errors.noAdvertsFoundMessageOne} />

          }
          {adverts.content.length && adverts.totalPages > 1 ?
            <Pagination
              onChange={changePages}
              total={adverts.totalPages * 30}
              showSizeChanger={false}
              current={currentPage + 1}
              pageSize={30}
            />
            : null}
          <ChangeAuthorModal isModalVisible={isChangeAuthorModalVisible} setModalVisibility={setIsChangeAuthorModalVisible} adId={adId} currentAuthorId={currentAuthorId} updateAdvert={updateAdvertsInfo} />
        </div>

      }
    </>
  );
};


export default connector(AdvertList);
