import { Reducer } from 'redux';
import { IPhoneNumber, PhoneActions } from './types';

export const initialState = {
  phone:"",
  code:""
}
export const RegReducer: Reducer<any, PhoneActions> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PHONE_NUMBER_SUCCESS': {
      return {
        ...state,
        phone:action.payload,
      };
    }
    case 'SET_VERIFY_CODE_SUCCESS': {
      return {
        ...state,
        code:action.payload,
      };
    }
    
    default: return state
  }
}
export default RegReducer;