import { createStore, combineReducers, applyMiddleware, Store, Dispatch, AnyAction, MiddlewareAPI, Middleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { IUserState } from "./user/types";
import { userReducer } from "./user/reducers";

import { ILocaleState } from "./locale/types";
import { localeReducer } from "./locale/reducers";
import { IPhoneNumber } from "./verificationSms/types";
import { RegReducer } from "./verificationSms/reducer";

import { StaffReducer } from "./staff/reducer";

import { UsersReducer } from "./users/reducer";

import { getUserStoredData, parseJwt, logoutUserFromSystem } from "services";
import { ACTIONS_WITHOUT_TOKEN } from "globalConstants";
import { AdvertReducer } from "./adverts/reducer";
import { ContractsReducer } from "./contracts/reducer";
import PricesReducer from "./prices/reducer";
import AgentsReducer from "./agents/reducer";
import ChatReducer from "./chats/reducer";
import serviceMessagesReducer from './serviceMessages/reducer';
// import ProfileReducer from "./profile/reducer";
// import ObjectsReducer from "./objects/reducer";
// import ImagesReducer from "./images/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  locale: localeReducer,
  regInfo: RegReducer,
  staff: StaffReducer,
  users: UsersReducer,
  advert: AdvertReducer,
  contracts: ContractsReducer,
  prices: PricesReducer,
  agents: AgentsReducer,
  chats: ChatReducer,
  serviceMessages: serviceMessagesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface IAppState {
  readonly user: IUserState;
  readonly locale: ILocaleState;
  readonly regInfo: IPhoneNumber;
}

const checkTokenExpirationMiddleware: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  const { token } = getUserStoredData();
  
  if(token){
    // console.log('with token',action)
    if (parseJwt(token).exp < Date.now() / 1000) {
      // next(action);
      // console.log('MIDDLEWARE FOR LOGIN IN 403 = ',parseJwt(token).exp, '<', Date.now() / 1000)
      console.log('Token expired');
      logoutUserFromSystem();
    } else {
      // console.log('no exp')
      return next(action);
    }
  } else {
    // console.log(action)
    if(ACTIONS_WITHOUT_TOKEN.indexOf(action.type) !== -1){
      return next(action);
    } else {
      console.log('Token is null and not have in SessionStorage');
      logoutUserFromSystem();
    }
    
  }
  
};

export default function configureStore(): Store<IAppState> {
  const middlewares = [thunkMiddleware, checkTokenExpirationMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
