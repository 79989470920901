import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import "./ArchiveModal.scss";
import {ReactComponent as WarningSvg} from '../../assets/images/ic-warning.svg'
import {components} from '../../globalConstants/textinputs.json';

interface IProps {
  isModalVisible: boolean;
  handleModalClose: any;
  archivePartnerHandler?: any;
  id: string;
  status: string;
  archivedText: any;
  unArchivedText: any;
}

const ArchiveModal: FC<IProps> = (props) => {

  const handleCloseModalCallback = () => {
    props.handleModalClose(false)
  }

  const onFinish = () => {
    props.handleModalClose(false)
    props.archivePartnerHandler(props.id, props.status)
  }
  
  return (
    <div>
      <Modal title={props.status === "ACTIVE" || props.status === "NOT_VERIFIED" ? components.blockModal.headingBlock : components.blockModal.headingUnBlock}
        className="archive-modal"
        closable={false}
        visible={props.isModalVisible}
        footer={null}
        onCancel={handleCloseModalCallback}
        onOk={onFinish}
        centered={true}
      >
        <WarningSvg />
        <p>{props.status === "ACTIVE" || props.status === "NOT_VERIFIED" ? props.unArchivedText : props.archivedText}</p>
        <div className="btn-wrapper">
            <Button type="default" id="archive-close" className="btn" key={'1'} onClick={handleCloseModalCallback}>
              {components.blockModal.cancelBtn}
            </Button>
          <Button type="primary" id="archive-finish" className="btn" key={'2'} onClick={onFinish}>
          {props.status === "ACTIVE" || props.status === "NOT_VERIFIED" ? components.blockModal.blockBtn : components.blockModal.unBlockBtn}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ArchiveModal;