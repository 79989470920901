import moment from "moment";
import React from "react";
import { roles, contracts, users } from '../globalConstants/textinputs.json';
import { adverts as advertsText } from '../globalConstants/textinputs.json';
import { Select } from "antd";
import { CHECKING_DOCUMENTS_DEAL, CONTRACT_PREPARATION_DEAL, DEAL_CANCELED_DEAL, DEAL_CONCLUDED_DEAL, DISCUSSIONS_WITH_TENANT_DEAL, DOCUMENTS_PROBLEM_DEAL, EXPECTING_CONTRACT_TO_BE_SIGNED_BY_BUYER_DEAL, EXPECTING_CONTRACT_TO_BE_SIGNED_BY_TENANT_DEAL, EXPECTING_DEAL_CONCLUSION_DEAL, EXPECTING_DOCUMENTS_DEAL, EXPECTING_PREPAYMENT_DEAL, NEW_DEAL, NOTARY_SEARCH_DEAL, SALE_INTENTION_ENROLMENT_DEAL, TRANSACTION_NOTARIZATION_DEAL } from "globalConstants";

export const renderHeaders = (headers: string[]): JSX.Element[] => {
  return headers.map((headerItem: string, index: number) => (<div key={index} className='header-item'>{headerItem}</div>))
}

export const roleMapper = (role) => {
  switch (role) {
    case 'ROLE_ADMIN':
      return roles.admin
    case 'ROLE_MANAGER':
      return roles.manager
    case 'ROLE_USER':
      return roles.user
    default: return ("")
  }
}

export const setActualStatus = (status) => {
  switch (status) {
    case ('ACTIVE'): {
      return 'ARCHIVED'
    }
    case ('ARCHIVED'): {
      return 'ACTIVE'
    }
    case ('NOT_VERIFIED'): {
      return 'ARCHIVED'
    }
    default: return '';
  }
}

export const adStatusMapper = (name) => {
  switch (name) {
    case 'NEW':
      return (advertsText.statuses.new)
    case 'ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS':
      return (advertsText.statuses.checkInProgess)
    case 'ON_MODERATION_REGISTRY_CHECK_OMITTED':
      return (advertsText.statuses.checkOmmited)
    case 'ON_MODERATION_REGISTRY_DATA_RECEIVED':
      return (advertsText.statuses.dataReceived)
    case 'ON_MODERATION_REGISTRY_DATA_INCOMPLETE':
      return (advertsText.statuses.dataIncomplete)
    case 'ON_MODERATION_REGISTRY_DATA_FAILED':
      return (advertsText.statuses.registryDataFailed)
    case 'ON_MODERATION_AD_UPDATED':
      return (advertsText.statuses.onModerationAdUpdated)
    case 'MODERATION_FAILED_NO_REGISTRY_RESPONSE':
      return (advertsText.statuses.noRegistryResponse)
    case 'MODERATION_FAILED_INVALID_PURPOSE':
      return (advertsText.statuses.invalidPurpose)
    case 'MODERATION_FAILED_NO_CADASTRAL_NUMBER_FOUND':
      return (advertsText.statuses.noCadastrialNumberFound)
    case 'ACTIVE':
      return (advertsText.statuses.active)
    case 'DEAL_IN_PROGRESS':
      return (advertsText.statuses.dealInProgess)
    case 'DEAL_IS_DONE':
      return (advertsText.statuses.dealIsDone)
    case 'BLOCKED':
      return (advertsText.statuses.blocked)
    case 'DELETED':
      return (advertsText.statuses.deleted)
    case 'MODERATION_FAILED_INVALID_PROPERTY_TYPE':
      return (advertsText.statuses.invalidPropertyType)
    default: return ("")
  }
}

export const rentStatusMapper = (name) => {
  switch (name) {
    case 'RENTED':
      return (advertsText.rentStatuses.rented)
    case 'NOT_FOR_RENT':
      return (advertsText.rentStatuses.notForRent)
    default: return (advertsText.rentStatuses.noStatus)
  }
}

export const rentStatusWithDateMapper = (status, date) => {
  switch (status) {
    case 'RENTED': {
      if (date) {
        return (`${advertsText.rentStatuses.rented} ${advertsText.single.sellerInfo.untilText} ${moment.utc(date.toString()).local().format('YYYY')}`)
      } else {
        return (`${advertsText.rentStatuses.rented}`)
      }
    }
    case 'NOT_FOR_RENT':
      return (advertsText.rentStatuses.notForRent)
    default: return (advertsText.rentStatuses.noStatus)
  }
}

export const contractStatusMapper = (name) => {
  switch (name) {
    case NEW_DEAL:
      return (contracts.statuses.new)
    case EXPECTING_DOCUMENTS_DEAL:
      return (contracts.statuses.expectingDocuments)
    case CHECKING_DOCUMENTS_DEAL:
      return (contracts.statuses.checkingDocuments)
    case DOCUMENTS_PROBLEM_DEAL:
      return (contracts.statuses.documentsProblem)
    case CONTRACT_PREPARATION_DEAL:
      return (contracts.statuses.contractPreparation)
    case EXPECTING_PREPAYMENT_DEAL:
      return (contracts.statuses.expectingPrepayment)
    case NOTARY_SEARCH_DEAL:
      return (contracts.statuses.notarySearch)
    // case TRANSACTION_NOTARIZATION_DEAL:
    //   return (contracts.statuses.transactionNotarization)
    case SALE_INTENTION_ENROLMENT_DEAL:
      return (contracts.statuses.saleIntentionEnrollment)
    case DISCUSSIONS_WITH_TENANT_DEAL:
      return (contracts.statuses.discussionsWithTennant)
    case EXPECTING_DEAL_CONCLUSION_DEAL:
      return (contracts.statuses.expectingDealConclusion)
    case DEAL_CONCLUDED_DEAL:
      return (contracts.statuses.dealConcluded)
    case DEAL_CANCELED_DEAL:
      return (contracts.statuses.dealCanceled)
    case EXPECTING_CONTRACT_TO_BE_SIGNED_BY_BUYER_DEAL:
      return (contracts.statuses.expectingDealConclusionByBuyer)
    case EXPECTING_CONTRACT_TO_BE_SIGNED_BY_TENANT_DEAL:
      return (contracts.statuses.expectingDealConclusionByTenant)
    default: return ("")
  }
}

export const contractPrepaymentMapper = (name) => {
  switch (name) {
    case 'WAITING_FOR_PAYMENT':
      return (contracts.paymentStatuses.waitingForPayment)
    case 'PAYED':
      return (contracts.paymentStatuses.paid)
    case 'PAYMENT_DECLINED':
      return (contracts.paymentStatuses.paymentDeclined)
    default: return ("")
  }
}

export const proposalMapper = (name) => {
  switch (name) {
    case 'OPENED':
      return (advertsText.single.proposalHistory.openedStatus)
    case 'ACCEPTED':
      return (advertsText.single.proposalHistory.acceptedStatus)
    case 'DECLINED':
      return (advertsText.single.proposalHistory.declinedStatus)
    case 'EXPIRED':
      return (advertsText.single.proposalHistory.expiredStatus)
    case 'CANCELED':
      return (advertsText.single.proposalHistory.canceledStatus)
    default: return ("")
  }
}

export const clientTagsColorMapper = (name) => {
  switch (name) {
    case 'CLIENT':
      return "#575757"
    case 'REPRESENTATIVE':
      return "#f5222d"
    case 'REALTOR':
      return "#0f73ea"
    case 'INTERNAL_REALTOR':
      return "#225e22"
    default: return ("")
  }
}

export const clientTagsMapper = (name) => {
  switch (name) {
    case 'CLIENT':
      return (users.tags.client)
    case 'REPRESENTATIVE':
      return (users.tags.representative)
    case 'REALTOR':
      return (users.tags.realtor)
    case 'INTERNAL_REALTOR':
      return (users.tags.internalRealtor)
    default: return ("")
  }
}

export const proposalTypesMapper = (name) => {
  switch (name) {
    case "PURCHASE": {
      return (users.proposalTypes.purchase)
    }
    case "PROPOSITION": {
      return (users.proposalTypes.proposal)
    }
    default: return ("")

  }
}

export const clientTagsShortMapper = (name) => {
  switch (name) {
    case 'CLIENT':
      return (users.tags.client)
    case 'REPRESENTATIVE':
      return (users.tags.representative)
    case 'REALTOR':
      return (users.tags.realtor)
    case 'INTERNAL_REALTOR':
      return (users.tags.internalShortRealtor)
    default: return ("")
  }
}

export const tenantCanceledMapper = (name) => {
  switch (name) {
    case "YES": {
      return (advertsText.single.sellerInfo.tenantCanceledYes)
    }
    case "NO": {
      return (advertsText.single.sellerInfo.tenantCanceledFalse)
    }
    case "N_A": {
      return (advertsText.single.sellerInfo.tenantCanceledUnknown)
    }
    default: return ("")

  }
}

export const OptionsMapper = (options) => options.map((item) =>
  <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
);

export const documentTitleMapper = (type) => {
  switch (type) {
    case 'LAND_TITLE_DOCUMENT': {
      return (contracts.documents.landTitleDocumentsHeading)
    }
    case 'OTHER': {
      return (contracts.documents.OtherDocumentsHeading)
    }

    case 'IDENTITY_DOCUMENT': {
      return (contracts.documents.scannedIdentityDocumentHeading)
    }

    case 'CONTRACT_WITH_REPRESENTATIVE': {
      return (contracts.documents.contractWithRepresentativeHeading)
    }

    case 'TAXPAYER_IDENTIFICATION_NUMBER': {
      return (contracts.documents.scannedTaxDocumentHeading)
    }

    default: return ("")
  }
}

export const documentSubTitleMapper = (type) => {
  switch (type) {
    case 'LAND_TITLE_DOCUMENT': {
      return (contracts.documents.landTitleDocumentsText)
    }
    case 'OTHER': {
      return ("")
    }

    case 'IDENTITY_DOCUMENT': {
      return (contracts.documents.scannedIdentityDocumentText)
    }

    case 'CONTRACT_WITH_REPRESENTATIVE': {
      return (contracts.documents.contractWithRepresentativeHeading)
    }

    case 'TAXPAYER_IDENTIFICATION_NUMBER': {
      return (contracts.documents.scannedTaxDocumentText)
    }

    default: return ("")
  }
}

export const clientTags = [
  { value: 'CLIENT', label: users.tags.client },
  { value: 'REPRESENTATIVE', label: users.tags.representative },
  { value: 'REALTOR', label: users.tags.realtor },
  { value: 'INTERNAL_REALTOR', label: users.tags.internalRealtor },
]

export const contractPrepaymentStatuses = [
  { value: 'WAITING_FOR_PAYMENT', label: contracts.paymentStatuses.waitingForPayment },
  { value: 'PAYED', label: contracts.paymentStatuses.paid },
  { value: 'PAYMENT_DECLINED', label: contracts.paymentStatuses.paymentDeclined }
]

export const contractColorStatusMapper = (name) => {
  switch (name) {
    case NEW_DEAL:
      return "#fed0e6"
    case EXPECTING_DOCUMENTS_DEAL:
      return "#15a8bf"
    case CHECKING_DOCUMENTS_DEAL:
      return "#1e80f0"
    case DOCUMENTS_PROBLEM_DEAL:
      return "#878787"
    case CONTRACT_PREPARATION_DEAL:
      return "#000"
    case EXPECTING_PREPAYMENT_DEAL:
      return "#e16b96"
    case NOTARY_SEARCH_DEAL:
      return "#c17bcf"
    case TRANSACTION_NOTARIZATION_DEAL:
      return "#000"
    case SALE_INTENTION_ENROLMENT_DEAL:
      return "#992bad"
    case DISCUSSIONS_WITH_TENANT_DEAL:
      return "#fece3e"
    case EXPECTING_DEAL_CONCLUSION_DEAL:
      return "#000"
    case DEAL_CONCLUDED_DEAL:
      return "#2eb418"
    case DEAL_CANCELED_DEAL:
      return "#333333"
    case EXPECTING_CONTRACT_TO_BE_SIGNED_BY_BUYER_DEAL:
      return "#fd5934"
    case EXPECTING_CONTRACT_TO_BE_SIGNED_BY_TENANT_DEAL:
      return "#fea409"
    default: return ("")
  }
}

export const dealStatuses = [
  { value: NEW_DEAL, label: contracts.statuses.new, group: 1 },
  { value: EXPECTING_DOCUMENTS_DEAL, label: contracts.statuses.expectingDocuments, group: 1 },
  { value: CHECKING_DOCUMENTS_DEAL, label: contracts.statuses.checkingDocuments, group: 1 },
  { value: DOCUMENTS_PROBLEM_DEAL, label: contracts.statuses.documentsProblem, group: 1 },
  { value: EXPECTING_PREPAYMENT_DEAL, label: contracts.statuses.expectingPrepayment, group: 1 },
  { value: NOTARY_SEARCH_DEAL, label: contracts.statuses.notarySearch, group: 2 },
  { value: SALE_INTENTION_ENROLMENT_DEAL, label: contracts.statuses.saleIntentionEnrollment, group: 2 },
  { value: DISCUSSIONS_WITH_TENANT_DEAL, label: contracts.statuses.discussionsWithTennant, group: 2 },
  { value: EXPECTING_CONTRACT_TO_BE_SIGNED_BY_BUYER_DEAL, label: contracts.statuses.expectingDealConclusionByBuyer, group: 3 },
  { value: EXPECTING_CONTRACT_TO_BE_SIGNED_BY_TENANT_DEAL, label: contracts.statuses.expectingDealConclusionByTenant, group: 3 },
  { value: DEAL_CONCLUDED_DEAL, label: contracts.statuses.dealConcluded, group: 3 },
  { value: DEAL_CANCELED_DEAL, label: contracts.statuses.dealCanceled, group: 3 },
]

export const conludedDealStatuses = [
  { value: DEAL_CONCLUDED_DEAL, label: contracts.statuses.dealConcluded },
]

export const cancledDealStatuses = [
  { value: DEAL_CANCELED_DEAL, label: contracts.statuses.dealCanceled },
]

export const renderCorrectBackPath = (from, id?) => {
  switch (from) {
    case 'advertsScreen':
      return ('/dashboard/advert')
    case 'userAdvertsScreen':
      return (`/dashboard/users/${id}/adverts`)
    case 'dealPage':
      return (`/dashboard/contracts/${id}`)
    default:
      return ("/dashboard/advert")
  }
}
