import { Reducer } from 'redux';
import { StaffActions } from './types';

export const initialState = {
    single: {},
    staffScreenPage: {
      currentPage: 0
    },
    staffScreenInfo: {
      role: "ALL",
      inputValue: "",
      string: ""
    }
}
export const StaffReducer: Reducer<any, StaffActions> = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'GET_STAFF_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_STAFF_LIST_SUCCESS': {
      return {
        ...state,
        results: action.preview,
        isFetching: false,
      };
    }
    case 'GET_STAFF_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'GET_SINGLE_STAFF_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_SINGLE_STAFF_SUCCESS': {
      return {
        ...state,
        single: action.preview,
        isFetching: false,
      };
    }
    case 'GET_SINGLE_STAFF_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'SET_STAFF_VIEW_SUCCESS': {
      return {
        ...state,
        view :action.payload,
      };
    }


    case "SAVE_STAFF_SCREEN_PAGE_SUCCESS": {
      return {
        ...state,
        staffScreenPage: action.payload
      }
    }

    case "SAVE_STAFF_SCREEN_INFO_SUCCESS": {
      return {
        ...state,
        staffScreenInfo: action.payload
      }
    }

    default: return state
  }
}
export default StaffReducer;