import moment from 'moment';
import { Reducer } from 'redux';
import { UsersActions } from './types';

export const initialState = {
    single: {},
    proposalResults: {},
    usersScreenPage: {
      currentPage: 0
    },
    usersScreenInfo: {
      role: "ALL",
      inputValue: "",
      string: "",
      dates: []
    }
}
export const UsersReducer: Reducer<any, UsersActions> = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'GET_USERS_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_USERS_LIST_SUCCESS': {
      return {
        ...state,
        results: action.preview,
        isFetching: false,
      };
    }
    case 'GET_USERS_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'GET_SINGLE_USER_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_SINGLE_USER_SUCCESS': {
      return {
        ...state,
        single: action.preview,
        isFetching: false,
      };
    }
    case 'GET_SINGLE_USER_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'SET_USERS_VIEW_SUCCESS': {
      return {
        ...state,
        view :action.payload,
      };
    }

    case "GET_USER_PROPOSALS_PENDING": {
      return {...state}
    }

    case "GET_USER_PROPOSALS_SUCCESS": {
      return { ...state, proposalResults: action.proposalResults}
    }

    case "GET_USER_PROPOSALS_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "USER_UPDATE_AGENTS_PHONE_PENDING": {
      return {
        ...state
      }
    }

    case "USER_UPDATE_AGENTS_PHONE_SUCCESS": {
      return {
        ...state
      }
    }

    case "USER_UPDATE_AGENTS_PHONE_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "SAVE_USERS_SCREEN_PAGE_SUCCESS": {
      return {
        ...state,
        usersScreenPage: action.payload
      }
    }

    case "SAVE_USERS_SCREEN_INFO_SUCCESS": {
      return {
        ...state,
        usersScreenInfo: action.payload
      }
    }

    default: return state
  }
}
export default UsersReducer;