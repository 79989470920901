import { useForm } from "antd/lib/form/Form";
import BackComponent from 'components/BackComponent/BackComponent';
import React, { FC } from 'react';
import Api from "api";
import "./EditAdvertScreen.scss";
import noPhoto from "assets/images/no-data.svg";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { ReactComponent as NeedSvg } from 'assets/images/ic-resize.svg'
import { ReactComponent as CancelSvg } from 'assets/images/ic-cancel.svg'
import { ReactComponent as PublishSvg } from 'assets/images/ic-publish.svg'
import { ReactComponent as ProfileSvg } from 'assets/images/ic-profile-small.svg'
import { ReactComponent as ChangeAuthorSvg } from 'assets/images/ic-profile-black.svg'
import { ReactComponent as EditSvg } from 'assets/images/ic-edit-w.svg'
import { ReactComponent as EditDateSvg } from 'assets/images/ic-edit-black.svg'
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { ReactComponent as DealSvg } from 'assets/images/ic-file-invoice.svg'
import { ReactComponent as DownloadSvg } from 'assets/images/ic-download-black.svg'
import { connect } from 'react-redux';
import regionsList from 'globalConstants/regions.json'
import {
    getSingleAdvert,
    advertModerationComplete,
    refreshRegistryData,
    AdvertStopModeration,
    getAdvertProposals,
    getAdvertOnModerationCount,
    updateLandRegistryDate,
    updateDobrozemLandRegistryDate,
    updateDobrozemLandRegistryStatus,
    setIsFromAdvertPush,
    updateSellAsap
} from 'store/adverts/actions'
import {
    IAdvertGetOnModerationCountFailureAction,
    IAdvertGetOnModerationCountSuccessAction,
    IAdvertGetProposalsFailureAction,
    IAdvertGetProposalsPendingAction,
    IAdvertGetProposalsSuccessAction,
    IAdvertStopModerationFailureAction,
    IAdvertStopModerationSuccessAction,
    IFromAdvert,
    IFromAdvertAction,
    ImorerationCompleteFailureAction,
    ImorerationCompleteSuccessAction,
    IRefreshRegistryDataFailureAction,
    IRefreshRegistryDataSuccessAction,
    ISetSingleAdvertFailureAction,
    ISetSingleAdvertListAction,
    IUpdateDobrozemLandRegistryDateFailureAction,
    IUpdateDobrozemLandRegistryDateSuccessAction,
    IUpdateDobrozemLandRegistryStatusFailureAction,
    IUpdateDobrozemLandRegistryStatusSuccessAction,
    IUpdateLandRegistryDateFailureAction,
    IUpdateLandRegistryDateSuccessAction,
    IUpdateSellAsapFailureAction,
    IUpdateSellAsapSuccessAction
} from 'store/adverts/types';
import moment from 'moment';
import {
    adStatusMapper,
    clientTagsColorMapper,
    clientTagsMapper,
    contractStatusMapper,
    proposalMapper, renderCorrectBackPath, rentStatusWithDateMapper,
    tenantCanceledMapper
} from 'services/mapper';
import InfoModal from 'components/InfoModal/InfoModal';
import RemoveModal from 'components/RemoveModal/RemoveModal';
import BlockAdvertModal from 'components/BlockAdvertModal/BlockAdvertModal';
import SpinComponent from 'components/SpinComponent/SpinComponent';
import { addSpacesToPrice, openNotification } from 'services';
import { Link } from 'react-router-dom';
import { ReactComponent as ApproveSvg } from "../../assets/images/ic-choise-approve.svg";
import { ReactComponent as DeclineSvg } from "../../assets/images/ic-choise-decline.svg";
import ChangeAuthorModal from "../../components/ChangeAuthorModal/ChangeAuthorModal";
import {
    adverts as advertText,
    smallNotification
} from '../../globalConstants/textinputs.json';
import DocumentsBlock from "../../components/DocumentsBlock/DocumentsBlock";
import { getContractDocuments, postContractDocuments } from "../../store/contracts/actions";
import {
    IGetContractBuyerDocumentsFailureAction,
    IGetContractBuyerDocumentsPendingAction,
    IGetContractBuyerDocumentsSuccessAction,
    IGetContractSellerDocumentsFailureAction,
    IGetContractSellerDocumentsPendingAction,
    IGetContractSellerDocumentsSuccessAction,
} from "../../store/contracts/types";
import IdentityAndTaxDocumentsBlock from "../../components/IdentityAndTaxDocumentsBlock/IdentityAndTaxDocumentsBlock";
import OwnerInfoBlock from "../../components/OwnerInfoBlock/OwnerInfoBlock";
import { isBoolean } from "lodash";
import { DEAL_CANCELED_DEAL } from "globalConstants";
import getShortFileName from "services/getShortFileName";

let OnlyRegionsList = regionsList.filter(item => item.title !== "Всі")

interface IProps {
    match: any;
    history: any;
    location: any;
    getSingleAdvert: (id: string) => Promise<ISetSingleAdvertListAction | ISetSingleAdvertFailureAction>;
    advertModerationComplete: (id: string) => Promise<ImorerationCompleteSuccessAction | ImorerationCompleteFailureAction>;
    refreshRegistryData: (id: string) => Promise<IRefreshRegistryDataSuccessAction | IRefreshRegistryDataFailureAction>;
    AdvertStopModeration: (id: string) => Promise<IAdvertStopModerationSuccessAction | IAdvertStopModerationFailureAction>;
    advert?: any;
    contracts?: any;
    getAdvertProposals: (id: string, page?: number, size?: number) => Promise<IAdvertGetProposalsSuccessAction | IAdvertGetProposalsPendingAction | IAdvertGetProposalsFailureAction>;
    getAdvertOnModerationCount: () => Promise<IAdvertGetOnModerationCountSuccessAction | IAdvertGetOnModerationCountFailureAction>;
    getContractDocuments: (id: number, owner: string) => Promise<IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction | IGetContractSellerDocumentsPendingAction | IGetContractBuyerDocumentsPendingAction | IGetContractSellerDocumentsFailureAction
        | IGetContractBuyerDocumentsFailureAction>;
    updateLandRegistryDate: (cadastrialNumber: string, body: any) => Promise<IUpdateLandRegistryDateSuccessAction | IUpdateLandRegistryDateFailureAction>;
    updateDobrozemLandRegistryDate: (cadastrialNumber: string, body: any) => Promise<IUpdateDobrozemLandRegistryDateSuccessAction | IUpdateDobrozemLandRegistryDateFailureAction>;
    setIsFromAdvertPush: (isFromAdvert: IFromAdvert) => IFromAdvertAction;
    updateSellAsap: (addId: boolean, body: any) => Promise<IUpdateSellAsapSuccessAction | IUpdateSellAsapFailureAction>;
    updateDobrozemLandRegistryStatus: (cadastrialNumber: string, body: any) => Promise<IUpdateDobrozemLandRegistryStatusSuccessAction | IUpdateDobrozemLandRegistryStatusFailureAction>;
}

const mapStateToProps = state => ({
    advert: state.advert,
    contracts: state.contracts
});

const mapDispatchToProps = {
    getSingleAdvert,
    advertModerationComplete,
    refreshRegistryData,
    AdvertStopModeration,
    getAdvertProposals,
    getAdvertOnModerationCount,
    getContractDocuments,
    postContractDocuments,
    updateLandRegistryDate,
    updateDobrozemLandRegistryDate,
    updateDobrozemLandRegistryStatus,
    setIsFromAdvertPush,
    updateSellAsap
};

const connector = connect(mapStateToProps, mapDispatchToProps)

export const EditAdvertScreen: FC<IProps> = (props) => {

    const { single } = props.advert;

    const [isResetModalVisible, setIsResetModalVisible] = React.useState(false);

    const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);

    const [isBlockModalVisible, setIsBlockModalVisible] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const [userProfile, isUserProfile] = React.useState(false)

    const [areProposalsLoading, setAreProposalsLoading] = React.useState(false);

    const [otherDocuments, setOtherDocuments] = React.useState([]);

    const [landTitleDocuments, setLandTitleDocuments] = React.useState([]);

    const [identityAndTaxDocuments, setIdentityAndTaxDocuments] = React.useState<any>([]);

    const [backPath, setBackPath] = React.useState<string>(() => {
        const initialState = renderCorrectBackPath(props.location?.state?.from, props.location?.state?.fromId);
        return initialState;
    })

    const [isChangeAuthorModalVisible, setIsChangeAuthorModalVisible] = React.useState<boolean>(false);

    const [isRegistryYearFormActive, setIsRegistryYearFormActive] = React.useState<boolean>(false);

    const [isDobrozemRegistryYearFormActive, setIsDobrozemRegistryFormActive] = React.useState<boolean>(false);

    const [isDobrozemRentStatus, setIsDobrozemRentStatus] = React.useState("");

    const [registryDateForm] = useForm();

    const [dobrozemInfoForm] = useForm();

    const advertStatusForSellAsap = [
        "NEW",
        "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS",
        "ON_MODERATION_REGISTRY_DATA_FAILED",
        "DEAL_IS_DONE",
        "MODERATION_FAILED_INVALID_PURPOSE",
        "MODERATION_FAILED_NO_REGISTRY_RESPONSE",
        "MODERATION_FAILED_NO_CADASTRAL_NUMBER_FOUND",
        "BLOCKED",
        "DELETED",
        "MODERATION_FAILED_INVALID_PROPERTY_TYPE"
    ]

    React.useEffect(() => {
        UpdateSingleAdvert()
        return () => {
            if (renderAdvertType(single.adStatus) === "inModeration") {
                props.AdvertStopModeration(single.id)
            }
        };
    }, [single.adStatus, single.id])

    React.useEffect(() => {
        setAreProposalsLoading(true);
        props.getAdvertProposals(props.match.params.id).then(resp => {
            setAreProposalsLoading(false);
        }).catch(e => {
            setAreProposalsLoading(false);
        })
    }, [])

    React.useEffect(() => {
        if (single.activeDealId) {

            if (single.adStatus === "DEAL_IN_PROGRESS" && single.dealStatus !== 'NEW' && single.dealStatus !== 'DEAL_CANCELED') {
                props.getContractDocuments(single.activeDealId, "SELLER")
            }

        }
    }, [single.activeDealId])

    React.useEffect(() => {

        if (props.contracts?.sellerDocuments?.documents && props.contracts?.sellerDocuments?.documents?.length) {

            let otherDocumentsTemp = props.contracts.sellerDocuments.documents.filter(item => item.documentType === "OTHER");
            let landTitleDocumentsTemp = props.contracts.sellerDocuments.documents.filter(item => item.documentType === "LAND_TITLE_DOCUMENT");
            let identityAndTaxDocuments = {
                taxpayerIdentificationNumber: props.contracts.sellerDocuments.taxpayerIdentificationNumber,
                identityDocument: {
                    idCardNumber: props.contracts.sellerDocuments.identityDocument?.idCardNumber || null,
                    number: props.contracts.sellerDocuments.identityDocument?.number || null,
                    series: props.contracts.sellerDocuments.identityDocument?.series || null,
                }
            }
            setOtherDocuments(otherDocumentsTemp);
            setLandTitleDocuments(landTitleDocumentsTemp);
            setIdentityAndTaxDocuments(identityAndTaxDocuments)
        }


    }, [props.contracts])

    const UpdateSingleAdvert = () => {
        setIsLoading(false)
        props.getSingleAdvert(props.match.params.id).then(resp => {
            setIsDobrozemRentStatus(resp.preview.manualRentStatus)
            setIsLoading(true)
            window.scrollTo(0, 0)
        })
    }

    const renderOptions = () => {
        if (single && single.technicalFeature) {
            return Object.keys(single.technicalFeature).filter(item => Boolean(single.technicalFeature[item])).map(item => naming(item)).filter(Boolean).join(', ');
        }
    }

    const naming = (name) => {
        switch (name) {
            case 'adjacentToForest':
                return advertText.technicalProperties.adjacentToForest
            case 'adjacentToRoad':
                return advertText.technicalProperties.adjacentToRoad
            case 'adjacentToRailway':
                return advertText.technicalProperties.adjacentToRailway
            case 'adjacentToReservoir':
                return advertText.technicalProperties.adjacentToReservoir
            case 'mainPipelines':
                return advertText.technicalProperties.mainPipelines
            case 'powerGrid':
                return advertText.technicalProperties.powerGrid
            case 'otherText':
                return single.technicalFeature.otherText
            default:
                return "";
        }
    }

    const renderCulture = (name) => {
        if (single && single.cropStatistics) {
            return Object.keys(single.cropStatistics)
                .map(item => {
                    return (
                        <div className="item advert-item-wrapper-eos-cropinfo">
                            <span>{single.cropStatistics[item][name]}</span>
                        </div>
                    )
                })
        }
    }

    const publishAdvertHandler = () => {
        setIsLoading(false)
        props.advertModerationComplete(props.match.params.id).then(resp => {
            if (resp.status === "OK") {
                props.getSingleAdvert(props.match.params.id).then(resp => {
                    setIsLoading(true)
                    props.getAdvertOnModerationCount();
                })
            }
        })
    }

    const blockAdvertHanler = () => {
        setIsBlockModalVisible(true)
    }

    const renderAdvertType = (name) => {
        switch (name) {
            case 'ON_MODERATION_REGISTRY_CHECK_OMITTED':
            case 'ON_MODERATION_REGISTRY_DATA_RECEIVED':
            case 'ON_MODERATION_REGISTRY_DATA_INCOMPLETE':
            case 'ON_MODERATION_AD_UPDATED':
                return ("inModeration")
            case 'NEW':
            case 'ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS':
            case 'ON_MODERATION_REGISTRY_DATA_FAILED':
                return ("needInfo")
            case 'DEAL_IN_PROGRESS':
            case 'DEAL_IS_DONE':
            case 'ACTIVE':
                return ("active")
            case 'MODERATION_FAILED_INVALID_PURPOSE':
            case 'MODERATION_FAILED_NO_REGISTRY_RESPONSE':
            case 'BLOCKED':
            case 'MODERATION_FAILED_NO_CADASTRAL_NUMBER_FOUND':
                return ("inactive")
            case 'DELETED':
                return ("deleted")
            default:
                return ("")
        }
    }

    const getDataHandler = () => {
        props.refreshRegistryData(props.match.params.id).then(resp => {
            if (resp.type === "ADVERT_REFRESH_DATA_SUCCESS") {
                // console.log('tarasd dsa dsadsd');
                setIsLoading(false)
                props.getSingleAdvert(props.match.params.id).then(resp => {
                    setIsLoading(true)
                    props.getAdvertOnModerationCount();
                    openNotification('info', smallNotification.infoTitle, advertText.main.registryRequestSent);
                })
            } else if (resp.payload?.error === "ERR_AD_008") {
                openNotification('error', smallNotification.errorTitle, advertText.errors.refreshRegistryTryAgainError)
            }
            else if (resp.payload?.error === "ERR_AD_007") {
                openNotification('error', smallNotification.errorTitle, advertText.errors.refreshRegistryTooManyAttemptsError)
            }
            else if (resp.payload?.error === "ERR_AD_004") {
                openNotification('error', smallNotification.errorTitle, advertText.errors.refreshRegistryAlreduYetError)
            }
        })
    }
    const UserProfileHandler = () => {
        isUserProfile(true)
        props.setIsFromAdvertPush({ isFromAdvert: props.history.location.pathname })
    }

    const tableColumns: Array<any> = [
        {
            title: advertText.single.proposalHistory.tableColDateAndTime,
            dataIndex: 'createdDate',
            className: "createdDate",
            render: (date) => {
                return <span>{moment.utc(date).local().format('DD.MM.YYYY, HH:mm')}</span>
            },
            width: "25%"
        },
        {
            title: advertText.single.proposalHistory.tableColAuthor,
            dataIndex: 'author',
            className: "author",
            render: (author) => {
                return <span>
                    {`${author.fio?.firstName} ${author.fio?.lastName.charAt(0)}.`}
                </span>
            },
            width: "25%"
        },
        {
            title: advertText.single.proposalHistory.tableColPrice,
            dataIndex: 'proposedPrice',
            className: "proposedPrice",
            width: "25%",
            render: (price) => {
                return <span>
                    {price ? `${price} ${advertText.single.proposalHistory.tableColPriceCurrency}` : advertText.single.notStated}
                </span>
            }
        },
        {
            title: advertText.single.proposalHistory.tableColStatus,
            dataIndex: 'status',
            className: "status",
            render: (status) => {
                return <span>{proposalMapper(status)}</span>
            },
            width: "25%"
        },
    ];

    const tableOnChangeHandler = (pagination: any) => {
        props.getAdvertProposals(props.match.params.id, pagination.current - 1)
    }

    const openChangeAuthorModal = () => {
        setIsChangeAuthorModalVisible(true);
    }

    const compareRegistryAndSellerInfo = () => {
        if ((single.rentStatus === "RENTED") && (single.registryRentStatus === "RENTED")) {

            if (single.rentedTill) {
                if (moment(single.rentedTill.toString()).isSame(single.registryRentedTill, 'year')) {
                    return ""
                } else {
                    return "different-info"
                }
            } else {
                if (single.registryRentedTill) {
                    return "different-info"
                } else {
                    return ""
                }
            }

        } else {
            if ((single.rentStatus === "NOT_FOR_RENT") && (single.registryRentStatus === "NOT_FOR_RENT")) {
                return ""
            } else {
                if (!single.rentStatus && !single.registryRentStatus) {
                    return ""
                } else {
                    return "different-info"
                }
            }
        }

    }

    const compareRegistryPrice = () => {
        if (single.registryRentPrice !== single.rentPrice) {

            if (single.registryRentPrice || single.rentPrice) {
                return "different-info"
            } else {
                return ""
            }

        } else {
            return ""
        }
    }

    const setValidDatesRange = (givenYear) => {
        const currentYear = moment()
        const endYear = moment().add(49, "years")

        if (givenYear < currentYear) {
            return true;
        }
        else return givenYear > endYear;


    }

    const openRegistryRentedTillForm = () => {
        setIsRegistryYearFormActive(true);
    }

    const openDobrozemInfoForm = () => {
        setIsDobrozemRegistryFormActive(true);
    }

    const closeRegistryRentedTillForm = () => {
        registryDateForm.resetFields();
        setIsRegistryYearFormActive(false);
    }


    const closeDobrozemInfoForm = () => {
        dobrozemInfoForm.resetFields();
        setIsDobrozemRegistryFormActive(false);
        setIsDobrozemRentStatus(single.manualRentStatus)
    }

    const submitRegistryRentedTillFormHandler = () => {
        registryDateForm.submit();
    }

    const submitDobrozemRentedTillFormHandler = () => {
        dobrozemInfoForm.submit();
    }

    const onYearFormFinish = (values) => {
        if (values.registryRentedTill) {
            const yearForSend = values.registryRentedTill.format("YYYY");

            const valueForSendObj = {
                "registryRentedTill": `${yearForSend}-12-31`
            }
            props.updateLandRegistryDate(single.cadastralNumber, valueForSendObj).then(resp => {
                if (resp.status === "OK") {
                    registryDateForm.resetFields();
                    setIsRegistryYearFormActive(false);

                    setIsLoading(false)
                    props.getSingleAdvert(props.match.params.id).then(resp => {
                        setIsLoading(true)
                    })
                }

            });

        }

    }

    const updateData = () => {
        dobrozemInfoForm.resetFields();
        setIsDobrozemRegistryFormActive(false);
        setIsLoading(false)
        props.getSingleAdvert(props.match.params.id).then(resp => {
            setIsLoading(true)
            setIsDobrozemRentStatus(resp.preview.manualRentStatus)
            dobrozemInfoForm.setFieldsValue({
                registryDobrozemRentedStatus: resp.preview.manualRentStatus === null ? "null" : resp.preview.manualRentStatus,
                registryDobrozemRentedTill: resp.preview.manualRentedTill && moment().year(resp.preview.manualRentedTill),
                registryDobrozemRentedRegion: resp.preview.address?.region,
                registryDobrozemRentedDistrict: resp.preview.address?.district,
                registryDobrozemRentedSettlement: resp.preview.address?.settlement,
                registryDobrozemRentedPrice: resp.preview.manualRentPrice,
                registryDobrozemComment: resp.preview.comment
            })
        })
    }

    const onDobrozemYearFormFinish = (values) => {
        const { registryDobrozemComment: comment } = values
        const commentChanged = single.comment !== comment
        commentChanged && Api.patch(`/core-service/offers/${props.match.params.id}/comment`, { comment }).then(() =>  updateData())
        if (isDobrozemRentStatus === "RENTED") {
            const yearForSend = values.registryDobrozemRentedTill.format("YYYY");


            let valueForSendObj = {
                manualRentedTill: +yearForSend,
                manualRentStatus: values.registryDobrozemRentedStatus,
                manualRentPrice: values.registryDobrozemRentedPrice,
                addressDto: {
                    district: values.registryDobrozemRentedDistrict,
                    region: values.registryDobrozemRentedRegion,
                    settlement: values.registryDobrozemRentedSettlement
                }
            }
            props.updateDobrozemLandRegistryDate(single.cadastralNumber, valueForSendObj).then(resp => {
                if (resp.status === "OK") {
                    updateData()
                }

            });
        } else if (isDobrozemRentStatus !== "RENTED") {
            let valueForSendObj = {
                manualRentedTill: null,
                manualRentStatus: values.registryDobrozemRentedStatus,
                manualRentPrice: values.registryDobrozemRentedPrice,
                comment: values.registryDobrozemComment,
                addressDto: {
                    district: values.registryDobrozemRentedDistrict,
                    region: values.registryDobrozemRentedRegion,
                    settlement: values.registryDobrozemRentedSettlement
                }
            }
            if (values.registryDobrozemRentedStatus === "null") {
                valueForSendObj = { ...valueForSendObj, "manualRentStatus": null }
            }
            props.updateDobrozemLandRegistryDate(single.cadastralNumber, valueForSendObj).then(resp => {
                if (resp.status === "OK") {
                   updateData()
                }
            });
        }

    }

    const rentStatusHandler = (value) => {
        setIsDobrozemRentStatus(value)
    }

    const asapHandler = e => {
        let sellAsap = { sellAsap: e.target.checked }
        props.updateSellAsap(single.id, sellAsap).then(resp => {
            UpdateSingleAdvert()
        })
        //setAsap(e.target.checked)
    }

    const makeHandleDownloadFile = (fileName: string, rentDocumentId: number) => async () => {
        const response = await Api.get(`/core-service/offers/rent-documents/${rentDocumentId}`, { responseType: "blob" });

        const blob = new Blob([response.data]);
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="single-advert-wrapper">
            {!isLoading && <SpinComponent fullHeight={true} />}
            <InfoModal
                isSaved={true}
                title={advertText.goBackModal.heading}
                isModalVisible={isResetModalVisible}
                closeModal={setIsResetModalVisible}
                backPath={backPath}
                img={<WarningSvg />}
                text={<span>{advertText.goBackModal.messagePartOne}<br />{advertText.goBackModal.messagePartTwo}</span>}
                cancelBtnText={advertText.goBackModal.cancelBtn}
                successBtnText={advertText.goBackModal.stayBtn}
            />
            <RemoveModal
                isSaved={true}
                title={advertText.deleteModal.title}
                isModalVisible={isDeleteModalVisible}
                closeModal={setIsDeleteModalVisible}
                img={<WarningSvg />}
                text={<span>{advertText.deleteModal.messagePartOne}<br /> {advertText.deleteModal.messagePartTwo}</span>}
                cancelBtnText={advertText.deleteModal.cancelBtn}
                successBtnText={advertText.deleteModal.deleteBtn}
            />
            <ChangeAuthorModal isModalVisible={isChangeAuthorModalVisible} setModalVisibility={setIsChangeAuthorModalVisible} adId={props.match.params.id} currentAuthorId={single.author?.id} updateAdvert={UpdateSingleAdvert} />
            <BlockAdvertModal
                id={props.match.params.id}
                isModalVisible={isBlockModalVisible}
                closeModal={setIsBlockModalVisible}
                updateSingleAdvert={UpdateSingleAdvert}
            />
            <BackComponent modalExit={setIsResetModalVisible} path={backPath}
                advertType={renderAdvertType(single.adStatus)} />
            <div className="advert-item-wrapper advert-item-top">
                <div className="top-info-wrapper">
                    <div className="img-wrapper">
                        <img src={single.photoId ? `${process.env.REACT_APP_AMAZON_URL}/${single.photoId}` : noPhoto}
                            alt="img" className="advert-item-top-image" />
                    </div>
                    <div className="info-wrapper">
                        <div className="left-col">
                            <h3 className="advert-item-top-area">{single.area ? `${single.area} га` : advertText.single.notStated} / {single.address?.settlement ? `${single.address?.settlement}` : advertText.single.notStated}</h3>
                            <h4 className="advert-item-top-region">{single.address?.region ? `${single.address?.region} обл.,` : advertText.single.notStated} {single.address?.district ? `${single.address?.district} р.` : advertText.single.notStated}</h4>
                            <h5 className="advert-item-top-cadastrial-number">{advertText.single.cadastrialNo} {single.cadastralNumber}</h5>
                            <h6 className="id advert-item-top-id">{advertText.single.ID} {single.id}</h6>
                            {(
                                !advertStatusForSellAsap.includes(single.adStatus)
                                && !isDobrozemRegistryYearFormActive
                                && isBoolean(single.sellAsap)
                                && !props.advert.isFetching
                            ) && <div className="asap-wrapper"><Checkbox defaultChecked={props.advert.single.sellAsap} onChange={asapHandler}>Терміновий продаж</Checkbox></div>}
                        </div>
                        <div className="right-col">
                            <div className="status-wrapper">
                                <p className="status advert-item-top-status"> <span className="advert-item-top-status-ad">{adStatusMapper(single.adStatus)}</span>  {single.adStatus === "DEAL_IN_PROGRESS" ? <span className="advert-item-top-status-deal"> <>{'\u2022'}</> {contractStatusMapper(single.dealStatus)}</span> : null} </p>
                                {single.sellAsap && <p className="status status-asap">Терміново</p>}
                            </div>
                            <p className="price advert-item-top-price">
                                {single.price ? `${addSpacesToPrice(single.price)} ${advertText.single.sellerInfo.priceCurrency}` : advertText.single.notStated}
                            </p>
                            <div className="date-wrapper">
                                <p className="date advert-item-top-date-created">{moment.utc(single.createdDate).local().format('DD.MM.YYYY, HH:mm')}</p>
                                <p className="date advert-item-top-date-updated">
                                    <b>Відредаговане</b>: {moment.utc(single.updatedDate).local().format('DD.MM.YYYY, HH:mm')}
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                {single.adBlockDetails &&
                    <div className="blocked-info-wrapper">
                        <div className="date-wrapper">
                            <span className="title">
                                {advertText.single.dateOfBlock}
                            </span>
                            <p className="name advert-item-top-date-blocked">
                                {moment.utc(single.adBlockDetails?.createdDate).local().format('DD.MM.YYYY, HH:mm')}
                            </p>
                        </div>
                        <div className="info-wrapper">
                            <div>
                                <span className="title">
                                    {advertText.single.blockedBy}
                                </span>
                                <p className="name advert-item-top-blocked-by">
                                    {single.adBlockDetails?.actor?.fio.lastName} {single.adBlockDetails?.actor?.fio.firstName}
                                </p>
                            </div>
                            <div>
                                <span className="title">
                                    {advertText.single.blockReason}
                                </span>
                                <p className="name advert-item-top-blocked-description">
                                    {single.adBlockDetails?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                }
                <Row gutter={24} className="action-btns-wrapper">
                    {(renderAdvertType(single.adStatus) === "inModeration") &&
                        <Col span={8}>
                            <Button type="default" className="advert-item-top-cancel-btn" onClick={blockAdvertHanler}><CancelSvg />{advertText.single.blockBtn}
                            </Button>
                        </Col>
                    }
                    {(renderAdvertType(single.adStatus) === "inModeration") &&
                        <Col span={8}>
                            <Button type="default" className="advert-item-top-publish-btn"
                                onClick={publishAdvertHandler}><PublishSvg />{advertText.single.publishBtn}</Button>
                        </Col>
                    }
                    {
                        (single.adStatus !== "NEW" && single.adStatus !== "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS" && single.adStatus !== "ON_MODERATION_REGISTRY_DATA_FAILED" && single.adStatus !== "DELETED") &&
                        <Col span={8}>
                            <Button type="default" className="advert-item-top-change-author-btn"
                                onClick={openChangeAuthorModal}><ChangeAuthorSvg />{advertText.single.changeAuthorBtn}</Button>
                        </Col>
                    }
                    {(single.adStatus !== "BLOCKED" && single.adStatus !== "DELETED" && single.adStatus !== "NEW" && single.adStatus !== "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS" && single.adStatus !== "ACTIVE" && single.adStatus !== "DEAL_IN_PROGRESS" && single.adStatus !== "DEAL_IS_DONE") &&
                        <Col span={8}>
                            <Button type="default" className="advert-item-top-request-btn"
                                onClick={getDataHandler}
                            ><NeedSvg />{advertText.single.dataRequestBtn}</Button>
                        </Col>
                    }
                    {(renderAdvertType(single.adStatus) === "needInfo" && single.adStatus !== "NEW" && single.adStatus !== "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS") &&
                        <Col span={8}>
                            <Button type="default" className="advert-item-top-cancel-btn" onClick={blockAdvertHanler}><CancelSvg />{advertText.single.blockBtn}
                            </Button>
                        </Col>
                    }

                    {/* active tab */}

                    {(single.adStatus === "DEAL_IN_PROGRESS" || single.adStatus === "DEAL_IS_DONE") &&

                        single.activeDealId ?
                        <Col span={8}>
                            <Link to={`/dashboard/contracts/${single.activeDealId}`}>
                                <Button type="default">
                                    <DealSvg />
                                    {advertText.single.goToContractBtn}
                                </Button>
                            </Link>

                        </Col> : null
                    }
                    {(renderAdvertType(single.adStatus) === "active" && single.adStatus == "ACTIVE") &&
                        <Col span={8}>
                            <Button type="default" className="advert-item-top-cancel-btn" onClick={blockAdvertHanler}><CancelSvg />{advertText.single.blockBtn}
                            </Button>
                        </Col>
                    }
                    {single.adStatus === "BLOCKED" &&
                        <Col span={8}>
                            <Button type="default" className="publish-advert-btn advert-item-top-publish-btn"
                                onClick={publishAdvertHandler}><PublishSvg />{advertText.single.publishBtn}</Button>
                        </Col>
                    }
                </Row>
            </div>
            <div className="advert-item-wrapper advert-item-seller">
                <div className="top-seller-wrapper">
                    <h3>{advertText.single.buyerInfo.title}</h3>
                    <div className="btn-wrapper">
                        <Link to={`/dashboard/users/${single.author?.id}`} onClick={UserProfileHandler}><Button className="advert-item-seller-profile-btn"
                            size="small" type="primary"><ProfileSvg />{advertText.single.buyerInfo.profileBtn}
                        </Button></Link>
                    </div>
                </div>
                <Row gutter={24} className="seller-info-wrapper">
                    <Col span={9}>
                        <span className="title">
                            {advertText.single.buyerInfo.nameLabel}
                        </span>
                        <p className="name advert-item-seller-fullname">
                            {single.author?.fio?.lastName || advertText.single.notStated} {single.author?.fio?.firstName || advertText.single.notStated}  {single.author?.fio?.patronymic || null}
                        </p>
                    </Col>
                    <Col span={5}>
                        <span className="title">
                            {advertText.single.buyerInfo.phoneLabel}
                        </span>
                        <p className="name advert-item-seller-phone">
                            {single.author?.phone || advertText.single.notStated}
                        </p>
                    </Col>
                    <Col span={5}>
                        <span className="title">
                            {advertText.single.buyerInfo.emailLabel}
                        </span>
                        <p className="name advert-item-seller-email">
                            {single.author?.email || advertText.single.notStated}
                        </p>
                    </Col>
                    <Col span={5}>
                        <span className="title">
                            {advertText.single.buyerInfo.tagLabel}
                        </span>
                        <p className="name author-tag" style={{ color: clientTagsColorMapper(single.author?.tag) }}>{clientTagsMapper(single.author?.tag)}</p>
                    </Col>
                </Row>
                {single.ownerFirstName && <Row gutter={24} className="owner-info-wrapper">
                    <Col span={9}>
                        <span className="title">
                            {advertText.single.buyerInfo.ownerLabel}
                        </span>
                        <p className="name advert-item-seller-owner-fullname">
                            {single.ownerLastName || advertText.single.notStated} {single.ownerFirstName || advertText.single.notStated} {single.ownerPatronymic || null}
                        </p>
                    </Col>
                    <Col span={5}>
                        <span className="title">
                            {advertText.single.buyerInfo.ownersPhoneLabel}
                        </span>
                        <p className="name advert-item-seller-owner-phone">
                            {single.ownerPhone || advertText.single.notStated}
                        </p>
                    </Col>
                </Row>}
            </div>
            <div className="advert-item-wrapper proposals-wrapper">
                <h3 className="proposals-heading">{advertText.single.proposalHistory.title}</h3>
                <div className="proposals-table">
                    <Table columns={tableColumns}
                        dataSource={props.advert.proposalResults?.content || []}
                        locale={{ emptyText: advertText.single.proposalHistory.emptyText }}
                        onChange={tableOnChangeHandler}
                        pagination={{
                            size: "small",
                            defaultPageSize: 15,
                            total: props.advert.proposalResults?.totalElements,
                            showSizeChanger: false,
                            position: ["bottomCenter"],
                            hideOnSinglePage: true
                        }}>
                    </Table>
                </div>
            </div>
            <div className="advert-item-wrapper dobrozem-data">
                <div className="top-seller-wrapper">
                    <h3>{advertText.single.dobrozemInfo.title}</h3>
                    <div className="btn-wrapper">
                        {(
                            single.adStatus !== "NEW"
                            && single.adStatus !== "ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS"
                            && single.adStatus !== "ON_MODERATION_REGISTRY_DATA_FAILED"
                            && single.adStatus !== "DEAL_IS_DONE"
                            && single.adStatus !== "MODERATION_FAILED_INVALID_PURPOSE"
                            && single.adStatus !== "MODERATION_FAILED_NO_REGISTRY_RESPONSE"
                            && single.adStatus !== "MODERATION_FAILED_NO_CADASTRAL_NUMBER_FOUND"
                            && single.adStatus !== "BLOCKED"
                            && single.adStatus !== "DELETED"
                            && single.adStatus !== "MODERATION_FAILED_INVALID_PROPERTY_TYPE"
                            && !isDobrozemRegistryYearFormActive
                        ) &&
                            <Button className="advert-item-seller-profile-btn" size="small" onClick={openDobrozemInfoForm} type="primary">
                                <EditSvg />Редагувати дані
                            </Button>
                        }
                        {
                            isDobrozemRegistryYearFormActive &&
                            <div className="btn-wrapper">
                                <Button size="small" type="default" onClick={closeDobrozemInfoForm}> <DeclineSvg />{advertText.single.vkursiZemliInfo.cancelBtn}</Button>
                                <Button size="small" type="primary" onClick={submitDobrozemRentedTillFormHandler}> <ApproveSvg />{advertText.single.vkursiZemliInfo.saveBtn}</Button>
                            </div>
                        }
                    </div>
                </div>
                {isDobrozemRegistryYearFormActive ?
                    <Row gutter={24} className="dobrozem-info-wrapper">
                        <Col span={24}>
                            <Form
                                form={dobrozemInfoForm}
                                name="dobrozem-info-form"
                                onFinish={onDobrozemYearFormFinish}
                                className="dobrozem-info-form"
                                initialValues={{
                                    registryDobrozemRentedStatus: single.manualRentStatus === null ? "null" : single.manualRentStatus,
                                    registryDobrozemRentedTill: single.manualRentedTill && moment().year(single.manualRentedTill),
                                    registryDobrozemRentedRegion: single.address?.region,
                                    registryDobrozemRentedDistrict: single.address?.district,
                                    registryDobrozemRentedSettlement: single.address?.settlement,
                                    registryDobrozemRentedPrice: single.manualRentPrice,
                                    registryDobrozemComment: single.comment
                                }}>
                                <div className="row">
                                    <Form.Item name="registryDobrozemRentedStatus" rules={[
                                        { required: true, message: advertText.single.vkursiZemliInfo.requiredFieldErrorMessage }
                                    ]}>
                                        <Select placeholder="Виберіть статус..." onChange={rentStatusHandler}
                                        >
                                            <Select.Option value="RENTED">{advertText.single.inRent}</Select.Option>
                                            <Select.Option value="NOT_FOR_RENT">{advertText.single.noRent}</Select.Option>
                                            <Select.Option value="null">{advertText.single.emptyRent}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    {isDobrozemRentStatus === "RENTED" && <Form.Item name="registryDobrozemRentedTill" rules={[
                                        { required: true, message: advertText.single.vkursiZemliInfo.requiredFieldErrorMessage }
                                    ]}>
                                        <DatePicker picker="year" placeholder={advertText.single.vkursiZemliInfo.yearFieldPlaceholder} disabledDate={setValidDatesRange} />
                                    </Form.Item>}
                                    <Form.Item name="registryDobrozemRentedRegion"
                                    // rules={[
                                    //     { required: true, message: advertText.single.vkursiZemliInfo.requiredFieldErrorMessage }
                                    // ]}
                                    >
                                        <Select placeholder={advertText.main.advertsListItem.regionLabel}>
                                            {OnlyRegionsList.map((item, index) => (
                                                <Select.Option key={index} value={item.value}>
                                                    {item.title}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="registryDobrozemRentedDistrict"
                                    // rules={[
                                    //     { required: true, message: advertText.single.vkursiZemliInfo.requiredFieldErrorMessage }
                                    // ]}
                                    >
                                        <Input placeholder={advertText.main.advertsListItem.districtLabel} />
                                    </Form.Item>
                                    <Form.Item name="registryDobrozemRentedSettlement"
                                    // rules={[
                                    //     { required: true, message: advertText.single.vkursiZemliInfo.requiredFieldErrorMessage }
                                    // ]}
                                    >
                                        <Input placeholder={advertText.main.advertsListItem.localityLabel} />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item name="registryDobrozemRentedPrice"
                                    >
                                        <Input type="number" placeholder={advertText.main.advertsListItem.paymentLabel} />
                                    </Form.Item>
                                </div>
                                <div className="row">
                                    <Form.Item name="registryDobrozemComment" style={{ width: '100%' }}>
                                        <Input.TextArea rows={10} autoSize={{ minRows: 4, maxRows: 4  }} placeholder="КОМЕНТАР ОПЕРАТОРА" />
                                    </Form.Item>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    :
                    <>
                        <Row gutter={24} className="dobrozem-info-wrapper">
                            <Col span={5}>
                                <span className="title">
                                    {advertText.single.dobrozemInfo.label}
                                </span>
                                <p className="name advert-item-data-dobrozem">
                                    {(single.manualRentStatus === "RENTED" && single.manualRentedTill) && `${advertText.single.dobrozemInfo.text} до ${single.manualRentedTill} ${advertText.single.dobrozemInfo.textYear}`}
                                    {single.manualRentStatus === "NOT_FOR_RENT" && advertText.single.noRented}
                                    {(single.manualRentStatus === null && !single.manualRentedTill) && advertText.single.notStated}
                                    {(single.manualRentStatus === "RENTED" && !single.manualRentedTill) && advertText.single.dobrozemInfo.text}
                                    {(single.manualRentStatus === null && single.manualRentedTill) && `${advertText.single.notStated} до ${single.manualRentedTill} ${advertText.single.dobrozemInfo.textYear}`}
                                </p>
                            </Col>
                            <Col span={4}>
                                <span className="title">
                                    {advertText.main.advertsListItem.priceLabel}
                                </span>
                                <p className="name advert-item-data-dobrozem">
                                    {single.manualRentPrice ? `${addSpacesToPrice(single.manualRentPrice)} ${advertText.single.sellerInfo.priceCurrency}` : "Не вказано"}
                                </p>
                            </Col>
                            <Col span={5}>
                                <span className="title">
                                    {advertText.main.advertsListItem.regionLabel}
                                </span>
                                <p className="name advert-item-data-dobrozem">
                                    {single.address?.region ? single.address.region : "Не вказано"}
                                </p>
                            </Col>
                            <Col span={5}>
                                <span className="title">
                                    {advertText.main.advertsListItem.districtLabel}
                                </span>
                                <p className="name advert-item-data-dobrozem">
                                    {single.address?.district ? single.address.district : "Не вказано"}
                                </p>
                            </Col>
                            <Col span={5}>
                                <span className="title">
                                    {advertText.main.advertsListItem.localityLabel}
                                </span>
                                <p className="name advert-item-data-dobrozem">
                                    {single.address?.settlement ? single.address.settlement : "Не вказано"}
                                </p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col span={24}>
                                 <span className="title">
                                    КОМЕНТАР ОПЕРАТОРА
                                </span>
                                <p className="name advert-item-data-dobrozem">
                                    {single.comment ?? "Не вказано"}
                                </p>
                            </Col>
                        </Row>
                    </>
                }
            </div>
            {
                single.adStatus === "DEAL_IN_PROGRESS" && single.dealStatus !== 'NEW' && single.dealStatus !== 'DEAL_CANCELED' ?
                    <div className="advert-item-wrapper documents-wrapper advert-item-wrapper-documents">
                        <h3 className="documents-heading">
                            {advertText.single.documents.title}
                        </h3>
                        <OwnerInfoBlock ownerLastName={single.ownerLastName} ownerFirstName={single.ownerFirstName}
                            ownerPatronymic={single.ownerPatronymic} ownerPhone={single.ownerPhone}
                            adID={single.id} updateAdvert={UpdateSingleAdvert} />
                        {landTitleDocuments && single.dealStatus !== DEAL_CANCELED_DEAL ? <DocumentsBlock sourceEntity="DEAL" activeDealID={single.activeDealId} type="LAND_TITLE_DOCUMENT" owner="SELLER"
                            documentsList={landTitleDocuments} />
                            : null}
                        {identityAndTaxDocuments && single.dealStatus !== DEAL_CANCELED_DEAL ? <IdentityAndTaxDocumentsBlock documentsObj={identityAndTaxDocuments}
                            dealID={single.activeDealId} owner="SELLER" />
                            : null}
                        {otherDocuments && single.dealStatus !== DEAL_CANCELED_DEAL ? <DocumentsBlock sourceEntity="DEAL" activeDealID={single.activeDealId} type="OTHER" owner="SELLER"
                            documentsList={otherDocuments} />
                            : null}
                    </div>
                    : null
            }
            <div className="advert-item-wrapper vkursi advert-item-wrapper-vkursi">
                <div className="top-seller-wrapper">
                    <h3>{advertText.single.vkursiZemliInfo.title}</h3>
                    {
                        isRegistryYearFormActive &&
                        <div className="btn-wrapper">
                            <Button size="small" type="default" onClick={closeRegistryRentedTillForm}> <DeclineSvg />{advertText.single.vkursiZemliInfo.cancelBtn}</Button>
                            <Button size="small" type="primary" onClick={submitRegistryRentedTillFormHandler}> <ApproveSvg />{advertText.single.vkursiZemliInfo.saveBtn}</Button>
                        </div>
                    }

                </div>
                <div className="vkursi-info-wrapper">
                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.vkursiZemliInfo.areaLabel}
                        </span>
                        <p className="name advert-item-wrapper-vkursi-area">
                            {single.area ? `${single.area} ${advertText.single.vkursiZemliInfo.areaUnits}` : advertText.single.notStated}
                        </p>
                    </div>
                    <div className={`vkursi-item vkursi-item-status-compared ${compareRegistryAndSellerInfo()}`}>
                        <span className="title">
                            {advertText.single.sellerInfo.rentStatusLabel}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-rentStatus">
                            {rentStatusWithDateMapper(single.registryRentStatus, single.registryRentedTill)}
                            {
                                (single.adStatus === "ON_MODERATION_REGISTRY_DATA_INCOMPLETE" && single.registryRentStatus === "RENTED" && single.registryRentedTill === null && !isRegistryYearFormActive) &&
                                <button onClick={openRegistryRentedTillForm} id="registry-rentedTill-form-btn" className="show-registry-rentedTill-form">
                                    <EditDateSvg />
                                </button>
                            }


                        </p>
                        {
                            isRegistryYearFormActive &&
                            <Form form={registryDateForm} name="registry-rentedTill-form" onFinish={onYearFormFinish} className="registry-rentedTill-form" initialValues={{
                                registryRentedTill: null
                            }}>
                                <Form.Item name="registryRentedTill" rules={[
                                    { required: true, message: advertText.single.vkursiZemliInfo.requiredFieldErrorMessage }
                                ]}>
                                    <DatePicker picker="year" placeholder={advertText.single.vkursiZemliInfo.yearFieldPlaceholder} disabledDate={setValidDatesRange} />
                                </Form.Item>
                            </Form>
                        }

                    </div>
                    <div className={`vkursi-item vkursi-item-rentPrice-compared ${compareRegistryPrice()}`}>
                        <span className="title">
                            {advertText.single.sellerInfo.rentPaymentAmountLabel}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-rentPrice">
                            {single.registryRentPrice ? `${addSpacesToPrice(single.registryRentPrice)} ${advertText.single.sellerInfo.rentPaymentCurrency}` : advertText.single.notStated}
                        </p>
                    </div>
                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.sellerInfo.tennantName}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-tennant">
                            {single.tenantName || advertText.single.notStated}
                        </p>
                    </div>
                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.vkursiZemliInfo.typeOfLandLabel}
                        </span>
                        <p className="name advert-item-wrapper-vkursi-type">
                            {single.type || advertText.single.notStated}
                        </p>
                    </div>
                    <div className="vkursi-item land-purpose">
                        <span className="title">
                            {advertText.single.vkursiZemliInfo.purposeOfLandLabel}
                        </span>
                        <p className="name advert-item-wrapper-vkursi-purpose">
                            {single.registryPurpose}
                        </p>
                    </div>
                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.vkursiZemliInfo.ngoLabel}
                        </span>
                        <p className="name advert-item-wrapper-vkursi-registry-price">
                            {single.registryPrice ? `${addSpacesToPrice(single.registryPrice)} ${advertText.single.vkursiZemliInfo.ngoCurrency}` : advertText.single.notStated}
                        </p>
                    </div>
                    <div className="vkursi-item registry-comment">
                        <span className="title">
                            {advertText.single.vkursiZemliInfo.commentLabel}
                        </span>
                        <p className="name advert-item-wrapper-vkursi-registry-price">
                            {single.registryComment || advertText.single.notStated}
                        </p>
                    </div>

                </div>
            </div>
            <div className="advert-item-wrapper advert-item-wrapper-from-seller info-from-seller-wrapper">
                <div className="top-seller-wrapper">
                    <h3>{advertText.single.sellerInfo.title}</h3>
                    <div className="btn-wrapper">
                        {/*<Button size="small" type="primary"><EditSvg />{advertText.single.sellerInfo.editBtn}</Button>*/}
                    </div>
                </div>
                <div className="vkursi-info-wrapper">
                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.sellerInfo.priceLabel}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-price">
                            {single.price ? `${addSpacesToPrice(single.price)} ${advertText.single.sellerInfo.priceCurrency}` : advertText.single.notStated}
                        </p>
                    </div>
                    <div className={`vkursi-item ${compareRegistryAndSellerInfo()}`}>
                        <span className="title">
                            {advertText.single.sellerInfo.rentStatusLabel}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-rentStatus">
                            {single.rentStatus ? rentStatusWithDateMapper(single.rentStatus, single.rentedTill) : advertText.single.notStated}
                        </p>
                    </div>

                    <div className={`vkursi-item ${compareRegistryPrice()}`}>
                        <span className="title">
                            {advertText.single.sellerInfo.rentPaymentAmountLabel}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-rentPrice">
                            {single.rentPrice ? `${addSpacesToPrice(single.rentPrice)} ${advertText.single.sellerInfo.rentPaymentCurrency}` : advertText.single.notStated}
                        </p>
                    </div>

                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.sellerInfo.sellersDocuments}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-properties">
                            {(single?.rentDocuments || []).map(({ fileName, rentDocumentId }) => (
                                <span className="document">{getShortFileName({ fileName, maxLength: 22 })} <DownloadSvg cursor="pointer" onClick={makeHandleDownloadFile(fileName, rentDocumentId)} /></span>
                            ))}
                        </p>
                    </div>

                    <div className="vkursi-item">
                        <span className="title">
                            {advertText.single.sellerInfo.technicalProperties}
                        </span>
                        <p className="name advert-item-wrapper-from-seller-properties">
                            {renderOptions()}
                        </p>
                    </div>
                </div>
                <div className="description-wrapper">
                    <span className="title">
                        {advertText.single.sellerInfo.areaDescription}
                    </span>
                    <p className="name advert-item-wrapper-from-seller-description">
                        {single.description}
                    </p>

                </div>
                <div className="images-wrapper">
                    <span className="title">
                        {advertText.single.sellerInfo.imagesLabel}
                    </span>
                    <div className="images-list">
                        {single.imageIds && single.imageIds.length > 0 &&
                            single.imageIds.filter(Boolean).map(item => (
                                <div className="list-item">
                                    <img src={`${process.env.REACT_APP_AMAZON_URL}/${item}`}
                                        className="land-img" alt="land image" key={item} />
                                </div>))
                        }
                    </div>
                </div>
            </div>
            <div className="advert-item-wrapper advert-item-wrapper-eos">
                <div className="top-seller-wrapper">
                    <h3>{advertText.single.EOSdata.title}</h3>
                </div>
                {single.cropStatistics?.length ?
                    <div className="culture-wrapper">
                        <h4>{advertText.single.EOSdata.cultureFirstSubtitle}</h4>
                        <div className="table-wrapper">
                            <div className="years">
                                <div className="item">
                                    <span>{advertText.single.EOSdata.yearSubtitle}</span>
                                </div>
                                {renderCulture('year')}
                            </div>
                            <div className="culture">
                                <div className="item">
                                    <span>{advertText.single.EOSdata.cultureSecondSubtitle}</span>
                                </div>
                                {renderCulture('crop')}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    );
};

export default connector(EditAdvertScreen);
