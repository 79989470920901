import { Button } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as WarningSvg} from '../../assets/images/ic-search-no-results.svg'
import {userNotRegisteredScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  goBackHandler: () => void;
}

const UserNotRegistered: FC<IProps> = (props) => {
  return (
    <div id="userNotFound">
      <h3>{userNotRegisteredScreen.title}</h3>
      <WarningSvg className="error-img"/>
      <p>{userNotRegisteredScreen.messagePartOne}
{userNotRegisteredScreen.messagePartTwo}</p>
      <div className="btn-wrapper">
        <Button id="back-btn" type="default" onClick={props.goBackHandler}>{userNotRegisteredScreen.backBtn}</Button>
        <Button type="primary" id="restore-pass-btn"><Link to="/signUp">{userNotRegisteredScreen.registerBtn}</Link></Button>
      </div>
    </div>
  );
};

export default UserNotRegistered;