import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as SearchSmallSvg } from 'assets/images/ic-search-small.svg'
import { ReactComponent as SearchWSvg } from 'assets/images/ic-search-w.svg'
import { ReactComponent as ReloadSvg } from 'assets/images/ic-reload.svg';
import { useForm } from 'antd/lib/form/Form';
// import { sidebar } from '../../globalConstants/textinputs.json';

interface IProps {
  tagList: any;
  staffList: any;
  searchParams: any;
  searchHandler: any;
  userRole: string;
  // collapsed: boolean
  // onCollapsed: any;
}
const ChatSearch: FC<IProps> = (props) => {
  const [form] = useForm();

  const [toggle, setToggle] = React.useState(false);

  const onFinish = (values: any) => {
    props.searchHandler(values)
  };

  const onFinishFailed = (errorInfo: any) => {

  };

  const toggleHandler = () => {
    setToggle(!toggle)
  }

  const resetFilterHandler = () => {
    form.resetFields();
    //form.submit();
    props.searchHandler(null)
    props.searchParams({})
  }

  return (
    <Form
      form={form}
      name="chat-search"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="top-chat-search">
        <Form.Item name="searchLine">
          <Input size="small" placeholder={"Пошук по чату..."} prefix={<SearchSmallSvg />} />
        </Form.Item>
        <Button type="default" id="chat-search-btn" htmlType="submit" onClick={onFinish}><SearchWSvg /></Button>
      </div>
      <Button type="primary" className="filter-btn" onClick={toggleHandler}>
        Параметри пошуку
      </Button>
      <div className={toggle ? "bottom-line active" : "bottom-line"}>
        <Row gutter={24}>
        {props.userRole === "ROLE_ADMIN" && <Col span={24}>
            <Form.Item
              name="manager"
            >
              <Select placeholder={"Менеджер"}>
                {props.staffList.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.fio?.lastName} {item.fio?.firstName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>}
          <Col span={12}>
            <Form.Item
              name="startDate"
            >
              <DatePicker placeholder="Дата, від" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="finishDate"
            >
              <DatePicker placeholder="Дата, до" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="tag"
            >
              <Select placeholder={"Тег"}>
                {props.tagList.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
          </Col>
          <Col span={10}>
            <Button type="default" size="small" id="reset-filters" onClick={resetFilterHandler}><ReloadSvg />Скинути</Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ChatSearch;
