import { Tabs } from 'antd';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import './UserAdvertsScreen.scss';
import { adverts } from '../../globalConstants/textinputs.json';
import { ReactComponent as BackSvg } from "../../assets/images/ic-back.svg";
import { Link } from "react-router-dom";
import AdvertList from "../../components/AdvertList/AdvertList";

const { TabPane } = Tabs;

interface IProps {
    history: any;
    advert: any;
    match: any;
}

const mapStateToProps = state => ({
    advert: state.advert
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UserAdvertsScreen: FC<IProps> = (props) => {

    const [inDealCount, setInDealCount] = React.useState<number>(0);
    const [publishedCount, setPublishedCount] = React.useState<number>(0);
    const [soldCount, setSoldCount] = React.useState<number>(0);
    const [onModerationCount, setOnModerationCount] = React.useState<number>(0);
    const [blockedCount, setBlockedCount] = React.useState<number>(0);
    const [deletedCount, setDeletedCount] = React.useState<number>(0);

    const [needRefresh, setNeedRefresh] = React.useState(false);

    const [activeTabKey, setActiveTabKey] = React.useState<string | undefined>(props.advert.activeUserTabInfo.activeTab);
    const [activePage, setActivePage] = React.useState<number>(props.advert.activeUserTabInfo.activePage);
    const [activeTabName, setActiveTabName] = React.useState<string>(props.advert.activeUserTabInfo.activeTabName)

    const handleTabChange = (key) => {
        setActiveTabKey(key);
        setActivePage(0);
    }

    return (
        <div className="user-adverts-wrapper">
            <h2 className="title"><Link to={`/dashboard/users/${props.match.params.id}`} className="goBack"><BackSvg /></Link> {adverts.main.userAdvertsTitle}</h2>
            <div className="tabs-wrapper">
                <Tabs activeKey={activeTabKey} animated={false} onChange={handleTabChange}>
                    <TabPane tab={`${adverts.main.userAdvertsPublishedTab} (${publishedCount})`} key="2" className="adv-tab-2" forceRender={true}>
                        <AdvertList
                            history={props.history}
                            isAgreeBtn={true}
                            rsql={`&search=adStatus%3Din%3DACTIVE%3Bauthor.id%3D%3D${props.match.params.id}`}
                            setCounter={setPublishedCount}
                            activeTab={activeTabKey}
                            from="userAdvertsScreen"
                            fromId={props.match.params.id}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            needRefresh={needRefresh}
                            setNeedRefresh={setNeedRefresh}
                            tabName="published"
                            activeTabName={activeTabName}
                            sortAdvertsBy="createdDate"
                        />
                    </TabPane>
                    <TabPane tab={`${adverts.main.userAdvertsOnModerationTab} (${onModerationCount})`} key="4" className="adv-tab-4" forceRender={true}>
                        <AdvertList
                            history={props.history}
                            takeWorkBtn={true}
                            rsql={`&search=adStatus%3Din%3D(ON_MODERATION_REGISTRY_CHECK_OMITTED%2CON_MODERATION_REGISTRY_DATA_RECEIVED%2CON_MODERATION_REGISTRY_DATA_INCOMPLETE%2CON_MODERATION_AD_UPDATED);author.id%3D%3D${props.match.params.id}`}
                            setCounter={setOnModerationCount}
                            activeTab={activeTabKey}
                            from="userAdvertsScreen"
                            fromId={props.match.params.id}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            needRefresh={needRefresh}
                            setNeedRefresh={setNeedRefresh}
                            tabName="onModeration"
                            activeTabName={activeTabName}
                            sortAdvertsBy="createdDate"
                        />
                    </TabPane>
                    <TabPane tab={`${adverts.main.userAdvertsInDealTab} (${inDealCount})`} key="1" className="adv-tab-1" forceRender={true}>
                        <AdvertList
                            history={props.history}
                            isAgreeBtn={true}
                            rsql={`&search=adStatus%3Din%3DDEAL_IN_PROGRESS%3Bauthor.id%3D%3D${props.match.params.id}`}
                            setCounter={setInDealCount}
                            needRefresh={needRefresh}
                            setNeedRefresh={setNeedRefresh}
                            activeTab={activeTabKey}
                            from="userAdvertsScreen"
                            fromId={props.match.params.id}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            tabName="inAgreement"
                            activeTabName={activeTabName}
                            sortAdvertsBy="createdDate" />
                    </TabPane>
                    <TabPane tab={`${adverts.main.userAdvertsSoldTab} (${soldCount})`} key="3" className="adv-tab-3" forceRender={true}>
                        <AdvertList
                            history={props.history}
                            isAgreeBtn={true}
                            rsql={`&search=adStatus%3Din%3DDEAL_IS_DONE%3Bauthor.id%3D%3D${props.match.params.id}`}
                            setCounter={setSoldCount}
                            needRefresh={needRefresh}
                            setNeedRefresh={setNeedRefresh}
                            activeTab={activeTabKey}
                            from="userAdvertsScreen"
                            fromId={props.match.params.id}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            tabName="sold"
                            activeTabName={activeTabName}
                            sortAdvertsBy="createdDate"
                        />
                    </TabPane>
                    <TabPane tab={`${adverts.main.userAdvertsBlockedTab} (${blockedCount})`} key="5" className="adv-tab-5" forceRender={true}>
                        <AdvertList
                            history={props.history}
                            isRemoveBtn={true}
                            rsql={`&search=adStatus%3Din%3D(MODERATION_FAILED_INVALID_PURPOSE%2CMODERATION_FAILED_NO_REGISTRY_RESPONSE%2CMODERATION_FAILED_NO_CADASTRAL_NUMBER_FOUND%2CMODERATION_FAILED_INVALID_PROPERTY_TYPE%2CBLOCKED);author.id%3D%3D${props.match.params.id}`}
                            setCounter={setBlockedCount}
                            activeTab={activeTabKey}
                            from="userAdvertsScreen"
                            fromId={props.match.params.id}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            needRefresh={needRefresh}
                            setNeedRefresh={setNeedRefresh}
                            tabName="blocked"
                            activeTabName={activeTabName}
                            sortAdvertsBy="createdDate"
                        />
                    </TabPane>
                    <TabPane tab={`${adverts.main.userAdvertsDeletedTab} (${deletedCount})`} key="6" className="adv-tab-6" forceRender={true}>
                        <AdvertList
                            history={props.history}
                            rsql={`&search=adStatus%3Din%3D(DELETED)%3Bauthor.id%3D%3D${props.match.params.id}`}
                            setCounter={setDeletedCount}
                            activeTab={activeTabKey}
                            from="userAdvertsScreen"
                            fromId={props.match.params.id}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            needRefresh={needRefresh}
                            setNeedRefresh={setNeedRefresh}
                            tabName="deleted"
                            activeTabName={activeTabName}
                            sortAdvertsBy="createdDate"
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>

    );
};

export default connector(UserAdvertsScreen);