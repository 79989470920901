import React from "react";
import { Table } from "antd";
import moment from "moment";
import type { ColumnsType } from "antd/es/table";

import type { ServiceMessage } from "store/serviceMessages/types";
import useServiceMessagesScreen from "./useServiceMessagesScreen";

import { serviceMessages } from "../../globalConstants/textinputs.json";
import "./serviceMessagesScreen.scss";

const columns: ColumnsType<ServiceMessage> = [
  {
    title: serviceMessages.tableColumnDate,
    dataIndex: "createdDate",
    className: "createdDate",
    width: 200,
    render: (createdDate: string) =>
      moment(`${createdDate}Z`).format("DD.MM.YYYY, HH:mm"),
  },
  {
    title: serviceMessages.tableColumnServiceName,
    dataIndex: "integrationType",
    width: 250,
  },
  {
    title: serviceMessages.tableColumnTextMessage,
    dataIndex: "errorTypeText",
  },
];

const ServiceMessagesScreen: React.FC = () => {
  const {
    isFetchingList,
    pageNumber,
    totalElements,
    serviceMessagesList,
    handleChangePageNumber,
  } = useServiceMessagesScreen();

  return (
    <>
      <h2 className="service-messages-title">{serviceMessages.mainHeading}</h2>

      <div className="service-messages-list">
        <Table<ServiceMessage>
          columns={columns}
          dataSource={serviceMessagesList}
          loading={isFetchingList}
          rowKey={(record) => record.id}
          pagination={{
            onChange: handleChangePageNumber,
            current: pageNumber + 1,
            total: totalElements,
            showSizeChanger: false,
            defaultPageSize: 30,
            position: ["bottomCenter"],
          }}
          locale={{
            emptyText: serviceMessages.tableEmptyText,
          }}
        />
      </div>
    </>
  );
};

export default ServiceMessagesScreen;
