import Api from "api";
import { AxiosResponse } from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IDefaultServerResponse } from "store/types";
import { IPhoneNumber, IverificationCodeAction, IVerificationCodeData, IverificationCodeFailureAction, IverificationCodePendingAction, IVerificationCodePostData, IVerificationData, IverificationPhoneAction, IVerificationPhoneData, IverificationPhoneFailureAction, IverificationPhonePendingAction, IVerificationPhonePostData, IverificationPhoneUsedAction, IverificationPhoneUsedFailureAction, IverificationPhoneUsedPendingAction, IVerificationPostData, IverificationSmsAction, IverificationSmsFailureAction, IverificationSmsPendingAction, IVerifiCode, PhoneActions } from "./types";



export const verificationSms: ActionCreator<
  ThunkAction<
    Promise<IverificationSmsAction | IverificationSmsPendingAction |IverificationSmsFailureAction>,
    IVerificationData,
    IVerificationPostData,
    IverificationSmsAction
  >
> = (data: IVerificationPostData) => {
  return async (dispatch: Dispatch) => {
    const getVerifiCodePendingAction: IverificationSmsPendingAction = {
      type: 'GET_VERIFICATION_SMS_PENDING',
    };
    dispatch(getVerifiCodePendingAction);
    try {
      const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/core-service/verifications/sms`, {...data})
      const verifiCode = response.data
      console.log(verifiCode)
      const getVerifiCodeSuccessAction: IverificationSmsAction = {
        verifiCode,
        type: 'GET_VERIFICATION_SMS_SUCCESS',
      };
      dispatch(getVerifiCodeSuccessAction)
      return getVerifiCodeSuccessAction
    } catch (error) {
      console.log(error)
      const getVerifiCodeFailureAction: IverificationSmsFailureAction = {
        type: 'GET_VERIFICATION_SMS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      dispatch(getVerifiCodeFailureAction)
      return getVerifiCodeFailureAction
    }
  };
}

export const verificationPhone: ActionCreator<
  ThunkAction<
    Promise<IverificationPhoneAction | IverificationPhonePendingAction |IverificationPhoneFailureAction>,
    IVerificationPhoneData,
    IVerificationPhonePostData,
    IverificationPhoneAction
  >
> = (data: IVerificationPhonePostData) => {
  return async (dispatch: Dispatch) => {
    const getVerifiPhonePendingAction: IverificationPhonePendingAction = {
      type: 'GET_VERIFICATION_PHONE_PENDING',
    };
    dispatch(getVerifiPhonePendingAction);
    try {
      const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/core-service/staff/verification`, {...data})
      const verifiPhone = response.data
      console.log(verifiPhone)
      const getVerifiPhoneSuccessAction: IverificationPhoneAction = {
        verifiPhone,
        type: 'GET_VERIFICATION_PHONE_SUCCESS',
      };
      dispatch(getVerifiPhoneSuccessAction)
      return getVerifiPhoneSuccessAction
    } catch (error) {
      console.log(error)
      const getVerifiPhoneFailureAction: IverificationPhoneFailureAction = {
        type: 'GET_VERIFICATION_PHONE_FAILURE',
        payload: {
          ...error.response.data
        } 
      };

      dispatch(getVerifiPhoneFailureAction)
      return getVerifiPhoneFailureAction
    }
  };
}

export const verificationPhoneIsUsed: ActionCreator<
  ThunkAction<
    Promise<IverificationPhoneUsedAction | IverificationPhoneUsedPendingAction |IverificationPhoneUsedFailureAction>,
    // IVerificationPhoneData,
    // IVerificationPhonePostData,
    null,
    any,
    IverificationPhoneUsedAction
  >
> = (data: IVerificationPhonePostData) => {
  return async (dispatch: Dispatch) => {
    const getVerifiPhoneUsedPendingAction: IverificationPhoneUsedPendingAction = {
      type: 'GET_VERIFICATION_PHONE_USED_PENDING',
    };
    dispatch(getVerifiPhoneUsedPendingAction);
    try {
      const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/core-service/verifications/phones`, {...data})
      const verifiPhoneUsed = response.data
      console.log(verifiPhoneUsed)
      const getVerifiPhoneUsedSuccessAction: IverificationPhoneUsedAction = {
        verifiPhoneUsed,
        type: 'GET_VERIFICATION_PHONE_USED_SUCCESS',
      };
      dispatch(getVerifiPhoneUsedSuccessAction)
      return getVerifiPhoneUsedSuccessAction
    } catch (error) {
      console.log(error)
      const getVerifiPhoneUsedFailureAction: IverificationPhoneUsedFailureAction = {
        type: 'GET_VERIFICATION_PHONE_USED_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      dispatch(getVerifiPhoneUsedFailureAction)
      return getVerifiPhoneUsedFailureAction
    }
  };
}

export const verificationCode: ActionCreator<
  ThunkAction<
    Promise<IverificationCodeAction | IverificationCodePendingAction |IverificationCodeFailureAction>,
    IVerificationCodeData,
    IVerificationCodePostData,
    IverificationCodeAction
  >
> = (data: IVerificationPhonePostData) => {
  return async (dispatch: Dispatch) => {
    const getVerifiCodePendingAction: IverificationCodePendingAction = {
      type: 'GET_VERIFICATION_CODE_PENDING',
    };
    dispatch(getVerifiCodePendingAction);
    try {
      const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/core-service/verifications`, {...data})
      const verifiCodeStatus = response.data
      console.log(verifiCodeStatus)
      const getVerifiCodeSuccessAction: IverificationCodeAction = {
        verifiCodeStatus,
        type: 'GET_VERIFICATION_CODE_SUCCESS',
      };
      dispatch(getVerifiCodeSuccessAction)
      return getVerifiCodeSuccessAction
    } catch (error) {
      console.log(error)
      const getVerifiCodeFailureAction: IverificationCodeFailureAction = {
        type: 'GET_VERIFICATION_CODE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      dispatch(getVerifiCodeFailureAction)
      return getVerifiCodeFailureAction
    }
  };
}

export const setPhoneNumber = (phone:IPhoneNumber) => {
  return (dispatchFunc):PhoneActions => {
    return dispatchFunc({
      type: "SET_PHONE_NUMBER_SUCCESS",
      payload: phone
    })
  }
};

export const setVerifiCode = (code:IVerifiCode) => {
  return (dispatchFunc):PhoneActions => {
    return dispatchFunc({
      type: "SET_VERIFY_CODE_SUCCESS",
      payload: code
    })
  }
};