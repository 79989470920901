import React, { FC } from 'react';

import { Form, Input, Button, Checkbox } from 'antd';
import { ReactComponent as LightLogo } from "../../assets/images/lightLogo.svg"
import { loginUser } from 'store/user/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserNotFound from 'components/UserNotFound/UserNotFound';
import UserBadCredentials from 'components/UserBadCredentials/UserBadCredentials';
import { ILoginData, ILoginUserFailureAction, ILoginUserSuccessAction } from 'store/user/types';
import UserNotRegistered from 'components/UserNotRegistered/UserNotRegistered';
import UserBlocked from 'components/UserBlocked/UserBlocked';
import {loginScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  loginUser: (data: ILoginData) => Promise<ILoginUserSuccessAction | ILoginUserFailureAction>;
  history?: any;
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const LoginScreen: FC<IProps> = ({ loginUser, history, ...props }) => {

  const [isLoginSuccess, setisLoginSuccess] = React.useState<boolean>(true);

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [codePhoneError, setCodePhoneError] = React.useState<boolean>(false);

  const onFinish = React.useCallback(values => {
    loginUser({ ...values, phone: `+${values.phone}` }).then(authData => {
      if (authData.status == "OK") {
        history.push('/dashboard/contracts');
      } else if (authData.payload.error === "ERR_PARAM_INVALID") {
        setCodePhoneError(true)
      } else {
        setisLoginSuccess(false)
        setErrorCode(authData.payload.error)
      }
    })
  }, [loginUser]);

  const onFinishFailed = React.useCallback(errorInfo => {
  }, []);

  const goBackHandler = () => {
    setErrorCode("")
    setisLoginSuccess(true)
  }

  const switchTemplates = (name) => {
    switch (name) {
      case 'ERR_AUTH_000':
        return (<UserBadCredentials goBackHandler={goBackHandler} />)
      case 'ERR_PARAM_INVALID':
        return (<UserBadCredentials goBackHandler={goBackHandler} />)
      case 'ERR_AUTH_006':
        return (<UserNotRegistered goBackHandler={goBackHandler} />)
        case 'ERR_STAFF_006':
        return (<UserNotRegistered goBackHandler={goBackHandler} />)
        case 'ERR_STAFF_007':
        return (<UserBlocked goBackHandler={goBackHandler} />)
      case 'ERR_AUTH_003':
        return (<UserNotFound goBackHandler={goBackHandler} />)
      default: return ("")
    }
  }

  return (
    <div className="login-wrapper">
      <div className="login-inner">
            {isLoginSuccess && <Form
              name="login"
              initialValues={{ rememberMe: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="img-wrapper">
                <LightLogo />
              </div>
              <h3 id="enter-to-system">{loginScreen.heading}</h3>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: loginScreen.requiredFieldErrorMessage }
                ]}
              >
                <Input className="tel-input" prefix={"+"} placeholder={loginScreen.phonePlaceholder} />
              </Form.Item>
              {codePhoneError ? <span className="small-size">{loginScreen.phoneError}</span> : ""}
              <Form.Item
                name="password"
                rules={[{ required: true, message: loginScreen.passwordError }]}
              >
                <Input.Password placeholder={loginScreen.passwordPlaceholder} />
              </Form.Item>

              <Form.Item name="rememberMe" valuePropName="checked" className="checkbox-wrapper">
                <Checkbox>{loginScreen.stayLoggedIn}</Checkbox>
                <Link to={"/restorePassword"}>{loginScreen.restorePassword}</Link>
              </Form.Item>
              <div className="btn-wrapper">
                <Button type="default" id="signUp-btn">
                  <Link to="/signUp">{loginScreen.registerBtn}</Link>
                </Button>
                <Button type="primary" id="login-btn" htmlType="submit">
                  {loginScreen.loginBtn}
                </Button>
              </div>
              {!isLoginSuccess ? <p className="login-error">{loginScreen.wrongLoginOrPassword}</p> : null}
            </Form>}
            {switchTemplates(errorCode)}
      </div>
    </div>
  )
}

export default connector(LoginScreen);
