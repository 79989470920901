import { AnyAction } from "redux";

export const AUTHORITIES_FULL_LIST = {
    ROLE_LOGIST: 'ROLE_LOGIST',
    ROLE_MANAGER_LOGIST: 'ROLE_MANAGER_LOGIST',
    ROLE_TC_MANAGER: 'ROLE_TC_MANAGER',
    ROLE_WAREHOUSE_MANAGER: 'ROLE_WAREHOUSE_MANAGER',
    CLIENT_LOGIST: 'CLIENT_LOGIST'
};

export const ACTIONS_WITHOUT_TOKEN: string[] = [
    
    'LOGIN_USER_PENDING',
    'LOGIN_USER_SUCCESS',
    'LOGIN_USER_FAILURE',
    'LOGOUT_USER_PENDING',
    'LOGOUT_USER_SUCCESS',
    'LOGOUT_USER_FAILURE',
    'CREATE_USER_PASSWORD_PENDING',
    'CREATE_USER_PASSWORD_SUCCESS',
    'CREATE_USER_PASSWORD_FAILURE',
    'EDIT_USER_PASSWORD_PENDING',
    'EDIT_USER_PASSWORD_SUCCESS',
    'EDIT_USER_PASSWORD_FAILURE',
    'RESET_PASSWORD_BY_EMAIL_PENDING',
    'RESET_PASSWORD_BY_EMAIL_SUCCESS',
    'RESET_PASSWORD_BY_EMAIL_FAILURE',
    'SET_PHONE_NUMBER_SUCCESS',
    'GET_VERIFICATION_SMS_SUCCESS',
    'SET_VERIFY_CODE_SUCCESS',
    'RESTORE_PASSWORD_PENDING',
    'RESTORE_PASSWORD_SUCCESS',
    'RESTORE_PASSWORD_FAILURE'
]

export const NEW_DEAL = "NEW"
export const EXPECTING_DOCUMENTS_DEAL = 'EXPECTING_DOCUMENTS'
export const CHECKING_DOCUMENTS_DEAL = 'CHECKING_DOCUMENTS'
export const DOCUMENTS_PROBLEM_DEAL = 'DOCUMENTS_PROBLEM'
export const CONTRACT_PREPARATION_DEAL = 'CONTRACT_PREPARATION'
export const EXPECTING_PREPAYMENT_DEAL = 'EXPECTING_PREPAYMENT'
export const NOTARY_SEARCH_DEAL = 'NOTARY_SEARCH'
export const TRANSACTION_NOTARIZATION_DEAL = 'TRANSACTION_NOTARIZATION'
export const SALE_INTENTION_ENROLMENT_DEAL = 'SALE_INTENTION_ENROLMENT'
export const DISCUSSIONS_WITH_TENANT_DEAL = 'DISCUSSIONS_WITH_TENANT' 
export const EXPECTING_DEAL_CONCLUSION_DEAL = 'EXPECTING_DEAL_CONCLUSION'
export const DEAL_CONCLUDED_DEAL = 'DEAL_CONCLUDED'
export const DEAL_CANCELED_DEAL = 'DEAL_CANCELED'
export const EXPECTING_CONTRACT_TO_BE_SIGNED_BY_BUYER_DEAL = 'EXPECTING_CONTRACT_TO_BE_SIGNED_BY_BUYER'
export const EXPECTING_CONTRACT_TO_BE_SIGNED_BY_TENANT_DEAL = 'EXPECTING_CONTRACT_TO_BE_SIGNED_BY_TENANT'







