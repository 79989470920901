import { Button } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as WarningSvg} from '../../assets/images/ic-search-no-results.svg'
import {userAlreadyRegisteredScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  goBackHandler: () => void;
}

const UserAlreadyRegistered: FC<IProps> = (props) => {
  return (
    <div id="userNotFound">
      <h3>{userAlreadyRegisteredScreen.title}</h3>
      <WarningSvg className="error-img"/>
      <p>{userAlreadyRegisteredScreen.messagePartOne}
          {userAlreadyRegisteredScreen.messagePartTwo}</p>
      <div className="btn-wrapper">
        <Button id="back-btn" type="default" onClick={props.goBackHandler}>{userAlreadyRegisteredScreen.backBtn}</Button>
        <Button type="primary" id="restore-pass-btn"><Link to="/">{userAlreadyRegisteredScreen.loginBtn}</Link></Button>
      </div>
    </div>
  );
};

export default UserAlreadyRegistered;