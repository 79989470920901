import Api from "api";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  IAdvertBlockFailureAction,
  IAdvertBlockPendingAction,
  IAdvertBlockSuccessAction,
  IAdvertGetOnModerationCountFailureAction,
  IAdvertGetOnModerationCountPendingAction,
  IAdvertGetOnModerationCountSuccessAction,
  IAdvertGetProposalsFailureAction,
  IAdvertGetProposalsPendingAction,
  IAdvertGetProposalsSuccessAction,
  IAdvertRsql,
  IAdvertScreenInfo,
  IAdvertStopModerationFailureAction,
  IAdvertStopModerationPendingAction,
  IAdvertStopModerationSuccessAction,
  IAdvertTakeModerationFailureAction,
  IAdvertTakeModerationPendingAction,
  IAdvertTakeModerationSuccessAction,
  IEditAdvertOwnerFailureAction,
  IEditAdvertOwnerPendingAction,
  IEditAdvertOwnerSuccessAction,
  IGetAdvertAction,
  ImorerationCompleteFailureAction,
  ImorerationCompletePendingAction,
  ImorerationCompleteSuccessAction,
  IRefreshRegistryDataFailureAction,
  IRefreshRegistryDataPendingAction,
  IRefreshRegistryDataSuccessAction,
  ISaveAdvertScreenInfoAction,
  ISaveUserAdvertScreenInfoAction,
  IsetAddFailureAction,
  IsetAddListAction,
  IsetAddPendingAction,
  ISetSingleAdvertFailureAction,
  ISetSingleAdvertListAction,
  ISetSingleAdvertPendingAction,
  IUserAdvertScreenInfo,
  IUpdateAdvertAuthorFailureAction,
  IUpdateAdvertAuthorPendingAction,
  IUpdateAdvertAuthorSuccessAction,
  IUpdateLandRegistryDateSuccessAction,
  IUpdateLandRegistryDatePendingAction,
  IUpdateLandRegistryDateFailureAction, IUpdateLandRegistryDateBody, IUpdateDobrozemLandRegistryDateSuccessAction, IUpdateDobrozemLandRegistryDatePendingAction, IUpdateDobrozemLandRegistryDateFailureAction, IUpdateDobrozemLandRegistryDateBody, IUpdateDobrozemLandRegistryStatusPendingAction, IUpdateDobrozemLandRegistryStatusSuccessAction, IUpdateDobrozemLandRegistryStatusFailureAction, IFromAdvert, IFromAdvertAction, IUpdateSellAsapSuccessAction, IUpdateSellAsapBody, IUpdateSellAsapPendingAction, IUpdateSellAsapFailureAction
} from "./types";

export const getAdvertList: ActionCreator<
  ThunkAction<
    Promise<IsetAddListAction | IsetAddPendingAction | IsetAddFailureAction>,
    any,
    null,
    IsetAddListAction
  >
> = (sortBy, sortType, page = 0, size = 15, rsql = "") => {
  return async (dispatch: Dispatch) => {
    //let rsqli = `&search=role==ALL,phone=in=Руденко,email=in=Руденко,fio.firstName=in=Руденко,fio.lastName=in=Руденко,fio.patronymic=in=Руденко`
    const getAdvertListPendingAction: IsetAddPendingAction = {
      type: 'GET_ADD_LIST_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      // let rsqli = `&search=adStatus=in=(ON_MODERATION_REGISTRY_CHECK_IN_PROGRESS, ACTIVE)`
      const response = await Api.get(`/core-service/offers/second-level?page=${page}&size=${size}&sort=${sortBy},${sortType}${rsql}`)
      const preview = response.data.data
      const getAdvertListSuccessAction: IsetAddListAction = {
        preview,
        type: 'GET_ADD_LIST_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IsetAddFailureAction = {
        type: 'GET_ADD_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}

export const getSingleAdvert: ActionCreator<
  ThunkAction<
    Promise<ISetSingleAdvertListAction | ISetSingleAdvertPendingAction | ISetSingleAdvertFailureAction>,
    any,
    null,
    ISetSingleAdvertListAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const getAdvertSinglePendingAction: ISetSingleAdvertPendingAction = {
      type: 'GET_SINGLE_ADVERT_PENDING',
    };
    dispatch(getAdvertSinglePendingAction);
    try {
      const response = await Api.get(`/core-service/offers/${id}/third-level`)
      const preview = response.data.data
      const getAdvertSingleSuccessAction: ISetSingleAdvertListAction = {
        preview,
        type: 'GET_SINGLE_ADVERT_SUCCESS',
      };
      return dispatch(getAdvertSingleSuccessAction);
    } catch (error) {
      const getAdvertSingleFailureAction: ISetSingleAdvertFailureAction = {
        type: 'GET_SINGLE_ADVERT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertSingleFailureAction);
    }
  };
}

export const advertModerationComplete: ActionCreator<
  ThunkAction<
    Promise<ImorerationCompleteSuccessAction | ImorerationCompleteFailureAction>,
    any,
    null,
    ImorerationCompleteSuccessAction | ImorerationCompleteFailureAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: ImorerationCompletePendingAction = {
      type: 'MODERATION_ADVERT_COMPLETE_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/core-service/offers/${id}/complete-moderation`)
      const preview = response.data
      const createUserSuccessAction: ImorerationCompleteSuccessAction = {
        preview,
        type: 'MODERATION_ADVERT_COMPLETE_SUCCESS',
      };
      dispatch(createUserSuccessAction)
      return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: ImorerationCompleteFailureAction = {
        type: 'MODERATION_ADVERT_COMPLETE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const advertBlock: ActionCreator<
  ThunkAction<
    Promise<IAdvertBlockSuccessAction | IAdvertBlockFailureAction>,
    any,
    null,
    IAdvertBlockSuccessAction | IAdvertBlockFailureAction
  >
> = (id, data) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IAdvertBlockPendingAction = {
      type: 'ADVERT_BLOCK_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/core-service/offers/${id}/block`, { ...data })
      const preview = response.data
      const createUserSuccessAction: IAdvertBlockSuccessAction = {
        preview,
        type: 'ADVERT_BLOCK_SUCCESS',
      };
      dispatch(createUserSuccessAction)
      return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IAdvertBlockFailureAction = {
        type: 'ADVERT_BLOCK_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const refreshRegistryData: ActionCreator<
  ThunkAction<
    Promise<IRefreshRegistryDataSuccessAction | IRefreshRegistryDataFailureAction>,
    any,
    null,
    IRefreshRegistryDataSuccessAction | IRefreshRegistryDataFailureAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IRefreshRegistryDataPendingAction = {
      type: 'ADVERT_REFRESH_DATA_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/core-service/offers/${id}/refresh-registry-data`)
      const preview = response.data
      const createUserSuccessAction: IRefreshRegistryDataSuccessAction = {
        preview,
        type: 'ADVERT_REFRESH_DATA_SUCCESS',
      };
      return dispatch(createUserSuccessAction)
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IRefreshRegistryDataFailureAction = {
        type: 'ADVERT_REFRESH_DATA_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const advertTakeModeration: ActionCreator<
  ThunkAction<
    Promise<IAdvertTakeModerationSuccessAction | IAdvertTakeModerationFailureAction>,
    any,
    null,
    IAdvertTakeModerationSuccessAction | IAdvertTakeModerationFailureAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IAdvertTakeModerationPendingAction = {
      type: 'ADVERT_TAKE_MODERATION_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/core-service/offers/${id}/moderate`)
      const preview = response.data
      const createUserSuccessAction: IAdvertTakeModerationSuccessAction = {
        preview,
        type: 'ADVERT_TAKE_MODERATION_SUCCESS',
      };
      dispatch(createUserSuccessAction)
      return preview
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IAdvertTakeModerationFailureAction = {
        type: 'ADVERT_TAKE_MODERATION_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}


export const AdvertStopModeration: ActionCreator<
  ThunkAction<
    Promise<IAdvertStopModerationSuccessAction | IAdvertStopModerationFailureAction>,
    any,
    null,
    IAdvertStopModerationSuccessAction | IAdvertStopModerationFailureAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const createUserPendingAction: IAdvertStopModerationPendingAction = {
      type: 'ADVERT_STOP_MODERATION_PENDING',
    };
    dispatch(createUserPendingAction);
    try {
      const response = await Api.post(`/core-service/offers/${id}/stop-moderation`)
      const preview = response.data
      const createUserSuccessAction: IAdvertStopModerationSuccessAction = {
        preview,
        type: 'ADVERT_STOP_MODERATION_SUCCESS',
      };
      return dispatch(createUserSuccessAction)
    } catch (error) {
      console.log(error)
      const createUserFailureAction: IAdvertStopModerationFailureAction = {
        type: 'ADVERT_STOP_MODERATION_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createUserFailureAction);
    }
  };
}

export const setAdvertFilter = (filterObj: IAdvertRsql) => {
  return (dispatchFunc): IGetAdvertAction => {
    return dispatchFunc({
      type: "GET_ADVERT_FILTER_SUCCESS",
      payload: {
        string: filterObj.filter,
        region: filterObj.region,
        district: filterObj.district,
        inputQuery: filterObj.inputQuery,
        locality: filterObj.locality,
        priceFrom: filterObj.priceFrom,
        priceTo: filterObj.priceTo,
        tag: filterObj.tag,
        sortBy: filterObj.sortBy,
        sortAsc: filterObj.sortAsc,
        asap: filterObj.asap
      }
    })
  }
};

export const setAdvertScreenInfo = (advertScreenInfo: IAdvertScreenInfo) => {
  return (dispatch): ISaveAdvertScreenInfoAction => {
    return dispatch({
      type: "SAVE_ADVERT_SCREEN_INFO_SUCCESS",
      payload: advertScreenInfo
    })
  }
}

export const setUserAdvertScreenInfo = (userAdvertScreenInfo: IUserAdvertScreenInfo) => {
  return (dispatch): ISaveUserAdvertScreenInfoAction => {
    return dispatch({
      type: "SAVE_USER_ADVERT_SCREEN_INFO_SUCCESS",
      payload: userAdvertScreenInfo
    })
  }
}

export const setIsFromAdvertPush = (isFromAdvert: IFromAdvert) => {
  return (dispatch): IFromAdvertAction => {
    return dispatch({
      type: "FROM_ADVERT_INFO_SUCCESS",
      payload: isFromAdvert
    })
  }
}

export const getAdvertProposals: ActionCreator<
  ThunkAction<
    Promise<IAdvertGetProposalsSuccessAction | IAdvertGetProposalsPendingAction | IAdvertGetProposalsFailureAction>,
    any,
    null,
    IAdvertGetProposalsSuccessAction
  >
> = (id, page = 0, size = 15) => {
  return async (dispatch: Dispatch) => {
    const getAdvertProposalsPendingAction: IAdvertGetProposalsPendingAction = {
      type: 'ADVERT_GET_PROPOSALS_PENDING',
    };
    dispatch(getAdvertProposalsPendingAction);
    try {
      const response = await Api.get(`/core-service/advert/${id}/proposals?page=${page}&size=${size}`)
      const proposalList = response.data.data
      // console.log(proposalList)
      const getAdvertProposalsSuccessAction: IAdvertGetProposalsSuccessAction = {
        type: 'ADVERT_GET_PROPOSALS_SUCCESS',
        proposalList,
      };
      return dispatch(getAdvertProposalsSuccessAction);
    } catch (error) {
      const getAdvertProposalsFailureAction: IAdvertGetProposalsFailureAction = {
        type: 'ADVERT_GET_PROPOSALS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertProposalsFailureAction);
    }
  };
}

export const getAdvertOnModerationCount: ActionCreator<
  ThunkAction<
    Promise<IAdvertGetOnModerationCountSuccessAction | IAdvertGetOnModerationCountPendingAction | IAdvertGetOnModerationCountFailureAction>,
    any,
    null,
    IAdvertGetOnModerationCountSuccessAction
  >
> = () => {
  return async (dispatch: Dispatch) => {
    const getAdvertOnModerationCountPendingAction: IAdvertGetOnModerationCountPendingAction = {
      type: 'ADVERT_GET_ON_MODERATION_COUNT_PENDING',
    };
    dispatch(getAdvertOnModerationCountPendingAction);
    try {
      const response = await Api.get(`/core-service/offers/stats/on-moderation`)
      const onModerationCount = response.data.data.onModerationCount
      // console.log(proposalList)
      const getAdvertOnModerationCountSuccessAction: IAdvertGetOnModerationCountSuccessAction = {
        type: 'ADVERT_GET_ON_MODERATION_COUNT_SUCCESS',
        payload: onModerationCount,
      };
      return dispatch(getAdvertOnModerationCountSuccessAction);
    } catch (error) {
      const getAdvertOnModerationCountFailureAction: IAdvertGetOnModerationCountFailureAction = {
        type: 'ADVERT_GET_ON_MODERATION_COUNT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertOnModerationCountFailureAction);
    }
  };
}

export const editAdvertOwner: ActionCreator<
  ThunkAction<
    Promise<IEditAdvertOwnerSuccessAction | IEditAdvertOwnerPendingAction | IEditAdvertOwnerFailureAction>,
    any,
    null,
    IEditAdvertOwnerSuccessAction
  >
> = (adID, body) => {
  return async (dispatch: Dispatch) => {
    const editAdvertOwnerPendingAction: IEditAdvertOwnerPendingAction = {
      type: 'EDIT_ADVERT_OWNER_PENDING',
    };
    dispatch(editAdvertOwnerPendingAction);
    try {
      const response = await Api.put(`/core-service/offers/${adID}/owner`, body)
      const preview = response.data
      const editAdvertOwnerSuccessAction: IEditAdvertOwnerSuccessAction = {
        preview,
        type: 'EDIT_ADVERT_OWNER_SUCCESS',
      };
      dispatch(editAdvertOwnerSuccessAction);
      return preview;
    } catch (error) {
      const editAdvertOwnerFailureAction: IEditAdvertOwnerFailureAction = {
        type: 'EDIT_ADVERT_OWNER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(editAdvertOwnerFailureAction);
    }
  };
}

export const updateAdvertAuthor: ActionCreator<
  ThunkAction<
    Promise<IUpdateAdvertAuthorSuccessAction | IUpdateAdvertAuthorPendingAction | IUpdateAdvertAuthorFailureAction>,
    any,
    null,
    IUpdateAdvertAuthorSuccessAction
  >
> = (adID, newAuthorId) => {
  return async (dispatch: Dispatch) => {
    const updateAdvertAuthorPendingAction: IUpdateAdvertAuthorPendingAction = {
      type: 'UPDATE_ADVERT_AUTHOR_PENDING',
    };
    dispatch(updateAdvertAuthorPendingAction);
    try {
      const response = await Api.put(`/core-service/offers/${adID}/author/${newAuthorId}`)
      const preview = response.data
      const updateAdvertAuthorSuccessAction: IUpdateAdvertAuthorSuccessAction = {
        preview,
        type: 'UPDATE_ADVERT_AUTHOR_SUCCESS',
      };
      dispatch(updateAdvertAuthorSuccessAction);
      return preview;
    } catch (error) {
      const updateAdvertAuthorFailureAction: IUpdateAdvertAuthorFailureAction = {
        type: 'UPDATE_ADVERT_AUTHOR_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateAdvertAuthorFailureAction);
    }
  };
}

export const updateLandRegistryDate: ActionCreator<
  ThunkAction<
    Promise<IUpdateLandRegistryDateSuccessAction | IUpdateLandRegistryDatePendingAction | IUpdateLandRegistryDateFailureAction>,
    any,
    null,
    IUpdateLandRegistryDateSuccessAction
  >
> = (cadastrialNumber, body: IUpdateLandRegistryDateBody) => {
  return async (dispatch: Dispatch) => {
    const updateLandRegistryDatePendingAction: IUpdateLandRegistryDatePendingAction = {
      type: 'UPDATE_LAND_REGISTRY_DATE_PENDING',
    };
    dispatch(updateLandRegistryDatePendingAction);
    try {
      const response = await Api.patch(`/core-service/lands/${cadastrialNumber}/registry-rented-till`, body)
      const preview = response.data
      const updateLandRegistryDateSuccessAction: IUpdateLandRegistryDateSuccessAction = {
        preview,
        type: 'UPDATE_LAND_REGISTRY_DATE_SUCCESS',
      };
      dispatch(updateLandRegistryDateSuccessAction);
      return preview;
    } catch (error) {
      const updateLandRegistryDateFailureAction: IUpdateLandRegistryDateFailureAction = {
        type: 'UPDATE_LAND_REGISTRY_DATE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateLandRegistryDateFailureAction);
    }
  };
}

export const updateDobrozemLandRegistryDate: any = (cadastrialNumber, body: any) => {
  return async (dispatch: Dispatch) => {
    const updateDobrozemLandRegistryDatePendingAction: IUpdateDobrozemLandRegistryDatePendingAction = {
      type: 'UPDATE_DOBROZEM_LAND_REGISTRY_DATE_PENDING',
    };
    dispatch(updateDobrozemLandRegistryDatePendingAction);
    try {
      const response = await Api.patch(`/core-service/lands/${cadastrialNumber}`, body)
      //const responseRent = await Api.patch(`/core-service/lands/${cadastrialNumber}/manual-rent-status`, bodyStatus)
      const preview = response.data
      const updateDobrozemLandRegistryDateSuccessAction: IUpdateDobrozemLandRegistryDateSuccessAction = {
        preview,
        type: 'UPDATE_DOBROZEM_LAND_REGISTRY_DATE_SUCCESS',
      };
      dispatch(updateDobrozemLandRegistryDateSuccessAction);
      return preview;
    } catch (error) {
      const updateDobrozemLandRegistryDateFailureAction: IUpdateDobrozemLandRegistryDateFailureAction = {
        type: 'UPDATE_DOBROZEM_LAND_REGISTRY_DATE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateDobrozemLandRegistryDateFailureAction);
    }
  };
}

export const updateDobrozemLandRegistryStatus: ActionCreator<
  ThunkAction<
    Promise<IUpdateDobrozemLandRegistryStatusSuccessAction | IUpdateDobrozemLandRegistryStatusPendingAction | IUpdateDobrozemLandRegistryStatusFailureAction>,
    any,
    null,
    IUpdateDobrozemLandRegistryStatusSuccessAction
  >
> = (cadastrialNumber, body) => {
  return async (dispatch: Dispatch) => {
    const updateDobrozemLandRegistryDatePendingAction: IUpdateDobrozemLandRegistryStatusPendingAction = {
      type: 'UPDATE_DOBROZEM_LAND_REGISTRY_STATUS_PENDING',
    };
    dispatch(updateDobrozemLandRegistryDatePendingAction);
    try {
      const response = await Api.patch(`/core-service/lands/${cadastrialNumber}/manual-rent-status`, body)
      const preview = response.data
      const updateDobrozemLandRegistryDateSuccessAction: IUpdateDobrozemLandRegistryStatusSuccessAction = {
        preview,
        type: 'UPDATE_DOBROZEM_LAND_REGISTRY_STATUS_SUCCESS',
      };
      dispatch(updateDobrozemLandRegistryDateSuccessAction);
      return preview;
    } catch (error) {
      const updateDobrozemLandRegistryDateFailureAction: IUpdateDobrozemLandRegistryStatusFailureAction = {
        type: 'UPDATE_DOBROZEM_LAND_REGISTRY_STATUS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateDobrozemLandRegistryDateFailureAction);
    }
  };
}

export const updateSellAsap: ActionCreator<
  ThunkAction<
    Promise<IUpdateSellAsapSuccessAction | IUpdateSellAsapPendingAction | IUpdateSellAsapFailureAction>,
    any,
    null,
    IUpdateSellAsapSuccessAction
  >
> = (addId, body: IUpdateSellAsapBody) => {
  return async (dispatch: Dispatch) => {
    const updateSellAsapPendingAction: IUpdateSellAsapPendingAction = {
      type: 'UPDATE_SELL_ASAP_PENDING',
    };
    dispatch(updateSellAsapPendingAction);
    try {
      const response = await Api.patch(`/core-service/offers/${addId}/sell-asap`, body)
      const preview = response.data
      const updateSellAsapSuccessAction: IUpdateSellAsapSuccessAction = {
        preview,
        type: 'UPDATE_SELL_ASAP_SUCCESS',
      };
      dispatch(updateSellAsapSuccessAction);
      return preview;
    } catch (error) {
      const updateSellAsapFailureAction: IUpdateSellAsapFailureAction = {
        type: 'UPDATE_SELL_ASAP_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateSellAsapFailureAction);
    }
  };
}
