import type { Dispatch } from "redux";

import Api from "api";
import {
  GET_SERVICE_MESSAGES_LIST_FAILURE,
  GET_SERVICE_MESSAGES_LIST_SUCCESS,
  GET_SERVICE_MESSAGES_UNREAD_COUNT_SUCCESS,
} from "./constants";

export const getServiceMessagesUnreadCount =
  () => async (dispatch: Dispatch) => {
    try {
      const { data } = await Api.get(
        `/core-service/integration-notifications/count`
      );

      return dispatch({
        type: GET_SERVICE_MESSAGES_UNREAD_COUNT_SUCCESS,
        payload: data.data || 0,
      });
    } catch (error) {}
  };

export const getServiceMessagesList =
  ({ pageNumber }: { pageNumber: number }) =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await Api.get(
        `/core-service/integration-notifications?page=${pageNumber}&size=30&sort=createdDate,desc`
      );

      const { content, totalElements } = data.data;

      return dispatch({
        type: GET_SERVICE_MESSAGES_LIST_SUCCESS,
        payload: {
          list: content || [],
          totalElements,
        },
      });
    } catch (error) {
      return dispatch({
        type: GET_SERVICE_MESSAGES_LIST_FAILURE,
      });
    }
  };
