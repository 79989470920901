import axios, { AxiosRequestConfig } from "axios";
import { getUserStoredData } from "services";

const { token, roles } = getUserStoredData();

const axiosConfig: AxiosRequestConfig = {
    headers: {
        Authorization: token || "NONE",
        post: {
            "Content-Type": "application/json"
        },
        put: {
            "Content-Type": "application/json"
        }
    }
}

if (process.env.NODE_ENV === 'production') {
    axiosConfig.baseURL = "/api";
}

const instance = axios.create(axiosConfig);

export default instance;
