import { Button, Form, Input, Modal } from 'antd';
import React, { FC } from 'react';
import "./VerifyPhoneModal.scss";
import { verificationSms, verificationCode } from 'store/verificationSms/actions';
import { IverificationCodeAction, IverificationCodeFailureAction, IVerificationCodePostData, IVerificationPostData, IverificationSmsAction, IverificationSmsFailureAction } from 'store/verificationSms/types';
import { connect } from 'react-redux';
import { IUserUpdatePhoneFailureAction, IUserUpdatePhoneSuccessAction } from 'store/users/types';
import { userUpdatePhone } from 'store/users/actions';
import InfoModal from 'components/InfoModal/InfoModal';
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg';
import {components} from '../../globalConstants/textinputs.json';

interface IProps {
  closeModal: (boolean) => void;
  isModalVisible: boolean;
  verificationSms: (data: IVerificationPostData) => Promise<IverificationSmsAction | IverificationSmsFailureAction>;
  phoneNumber: string;
  updatedPhoneNumber: string;
  submitWithoutPhoneHandler: () => void;
  verificationCode: (data: IVerificationCodePostData) => Promise<IverificationCodeAction | IverificationCodeFailureAction>;
  userUpdatePhone: (id: number, data: any) => Promise<IUserUpdatePhoneSuccessAction | IUserUpdatePhoneFailureAction>;
  id: number;
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  verificationSms,
  verificationCode,
  userUpdatePhone
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const VerifyPhoneModal: FC<IProps> = (props) => {

  React.useEffect(() => {
    setTimeCodeError(false)
    if (props.isModalVisible) {
      props.verificationSms({ "phone": props.phoneNumber, "verificationType": "CHANGE_PHONE_NUMBER", "verificationSubject": "CUSTOMER" }).then(resp => {
        console.log('aror', resp)
        if (resp.type === "GET_VERIFICATION_SMS_SUCCESS") {
          setStartCounter(true)
          setMinutes(0)
          setSeconds(59)
        } else if (resp.type === "GET_VERIFICATION_SMS_FAILURE" && resp.payload.error === "ERR_OTP_003") {
          setStartCounter(true)
          setTimeCodeError(true)
          setMinutes(4)
          setSeconds(59)
        } else if (resp.type === "GET_VERIFICATION_SMS_FAILURE" && resp.payload.error === "ERR_OTP_001") {
          setStartCounter(true)
          setTimeCodeError(true)
          setMinutes(0)
          setSeconds(59)
        }
      })
    }
  }, [props.isModalVisible]);

  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const [startСounter, setStartCounter] = React.useState(false);

  const [isVerify, setisVerify] = React.useState(false);

  const [codeNumber, setCodeNumber] = React.useState<string>("");

  const [codeError, setCodeError] = React.useState<boolean>(false);

  const [timeCodeError, setTimeCodeError] = React.useState<boolean>(false);

  const [isPhoneModalVisible, setPhoneModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (startСounter) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
            setisVerify(true);
            setStartCounter(false);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const verifiCodeHandler = () => {
    props.verificationCode({ code: codeNumber, phone: props.phoneNumber, verificationType: "CHANGE_PHONE_NUMBER", verificationSubject: "CUSTOMER" }).then(resp => {
      if (resp.verifiCodeStatus.data) {
        setCodeError(false)
        props.userUpdatePhone(props.id, { phone: `+${props.updatedPhoneNumber}`, passcode: codeNumber }).then(resp => {
          if (resp.type === "USER_UPDATE_PHONE_SUCCESS") {
            props.submitWithoutPhoneHandler()
            setCodeNumber("")
          } else {
            setPhoneModalVisible(true)
            setCodeNumber("")
          }
        })

      } else {
        setCodeError(true)
      }
    })
  }

  const renewCode = () => {
    props.verificationSms({ "phone": props.phoneNumber, "verificationType": "CHANGE_PHONE_NUMBER", "verificationSubject": "CUSTOMER" }).then(resp => {
      if (resp?.verifiCode.status === "OK") {
        setisVerify(false)
        setMinutes(0)
        setSeconds(59)
        setStartCounter(true)
      } else {
        setisVerify(false)
        setMinutes(4)
        setSeconds(59)
      }
    })
  }

  const codeHandler = (e) => {
    setCodeNumber(e.target.value)
  }

  const handleCancel = () => {
    setCodeNumber("")
    props.closeModal(false);
  };

  return (
    <>
      <InfoModal
        isSaved={false}
        title={components.verifyPhoneModal.errorModal.heading}
        isModalVisible={isPhoneModalVisible}
        closeModal={setPhoneModalVisible}
        backPath={"/dashboard/users"}
        img={<WarningSvg />}
        text={components.verifyPhoneModal.errors.phoneAlreadyExists}
        successBtnText={components.verifyPhoneModal.errorModal.backBtn}
      />
      <Modal title={components.verifyPhoneModal.heading}
        visible={props.isModalVisible}
        closable={false}
        onCancel={handleCancel}
        footer={null}
        className="modal-verify-phone"
        centered={true}
      >
        <p id="verify-subtitle">{components.verifyPhoneModal.message}</p>
        <Input
          className="verify-code-input"
          onChange={codeHandler}
          value={codeNumber}
          placeholder={components.verifyPhoneModal.inputPlaceholder}
        />
        {codeError ? <span className="small-size-normal">{components.verifyPhoneModal.wrongCodeError}</span> : ""}
        <Button id="verifiCode" type="default" onClick={renewCode} disabled={isVerify ? false : true}>{components.verifyPhoneModal.sendCodeAgainBtn}
      {minutes === 0 && seconds === 0
            ? null
            : <span className="time-counter"> ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})</span>
          }
        </Button>
        {timeCodeError ? <span className="small-size-normal">{components.verifyPhoneModal.tooManyRequestsError}</span> : ""}
        <div className="btn-wrapper">
          <Button id="back-btn" type="default" onClick={handleCancel}>{components.verifyPhoneModal.backBtn}</Button>
          <Button type="primary" id="verify-ok" onClick={verifiCodeHandler} disabled={codeNumber.length > 3 ? false : true}>{components.verifyPhoneModal.goBtn}</Button>
        </div>
        <Button type="default" onClick={props.submitWithoutPhoneHandler}>{components.verifyPhoneModal.saveWithoutBtn}</Button>
      </Modal>
    </>
  );
};

export default connector(VerifyPhoneModal);