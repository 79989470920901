import { Button, Col, Form, Input, Row, Select, Tabs, Table } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as EditSvg } from 'assets/images/ic-edit.svg';
import { ReactComponent as BlockSvg } from 'assets/images/ic-block-w.svg'
import { ReactComponent as DeclineSvg } from 'assets/images/ic-choise-decline.svg'
import { ReactComponent as ApproveSvg } from 'assets/images/ic-choise-approve.svg'
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { ReactComponent as UnarchiveSvg } from 'assets/images/ic-unarchive.svg'
import { ReactComponent as FileBlueSvg } from 'assets/images/ic-file-blue.svg'
import { ReactComponent as HandshakeBlueSvg } from 'assets/images/ic-handshake-blue.svg'
import { ReactComponent as ChatBlueSvg } from 'assets/images/ic-chat-message-blue.svg'
import regionsList from '../../globalConstants/regions.json'
import {
  getSingleUser,
  updateUser,
  archiveUser,
  unArchiveUser,
  getUserProposals,
  userUpdateAgentsPhone,
} from 'store/users/actions'
import { connect } from 'react-redux';
import "./EditUserScreen.scss";
import InfoModal from 'components/InfoModal/InfoModal';
import VerifyPhoneModal from 'components/VerifyPhoneModal/VerifyPhoneModal';
import {
  ISetSingleUserFailureAction,
  ISetSingleUserListAction,
  IUserArchiveFailureAction,
  IUserArchiveSuccessAction,
  IUserGetProposalsFailureAction,
  IUserGetProposalsPendingAction,
  IUserGetProposalsSuccessAction,
  IUserUnArchiveFailureAction,
  IUserUnArchiveSuccessAction,
  IUserUpdAgentsPhoneData,
  IUserUpdateAgentsPhoneFailureAction,
  IUserUpdateAgentsPhoneSuccessAction,
  IUserUpdateFailureAction,
  IUserUpdateSuccessAction
} from 'store/users/types';
import UserDocuments from 'components/UserDocuments/UserDocuments';
import { ReactComponent as BackSvg } from 'assets/images/ic-back.svg'
import { useForm } from 'antd/lib/form/Form';
import SpinComponent from 'components/SpinComponent/SpinComponent';
import ArchiveModal from 'components/ArchiveModal/ArchiveModal';
import { Link } from 'react-router-dom';
import { adverts as advertText, users } from '../../globalConstants/textinputs.json';
import { clientTags, clientTagsMapper, OptionsMapper, proposalMapper, proposalTypesMapper } from "../../services/mapper";
import moment from 'moment';
import { ISetLocationDistrictListAction } from 'store/address/types';
import { getLocationDistrictAdress } from 'store/address/actions'
import { getDistrictAdress, getLocalityAdress } from 'store/address/actions';
let OnlyRegionsList = regionsList.filter(item => item.title !== "Всі")

const { TabPane } = Tabs;

interface IProps {
  users: any;
  advert: any;
  user: any;
  history: any;
  match: any;
  getSingleUser: (id: string) => Promise<ISetSingleUserListAction | ISetSingleUserFailureAction>;
  updateUser: (id: number, param: any) => Promise<IUserUpdateSuccessAction | IUserUpdateFailureAction>;
  archiveUser: (id: number) => Promise<IUserArchiveSuccessAction | IUserArchiveFailureAction>;
  unArchiveUser: (id: number) => Promise<IUserUnArchiveSuccessAction | IUserUnArchiveFailureAction>;
  getUserProposals: (id: string, page?: number, size?: number) => Promise<IUserGetProposalsSuccessAction | IUserGetProposalsPendingAction | IUserGetProposalsFailureAction>;
  userUpdateAgentsPhone: (id: string, data: IUserUpdAgentsPhoneData) => Promise<IUserUpdateAgentsPhoneSuccessAction | IUserUpdateAgentsPhoneFailureAction>;
  getLocationDistrictAdress: (region?: string) => Promise<ISetLocationDistrictListAction>;
  getDistrictAdress: any;
}

const mapStateToProps = state => ({
  users: state.users,
  user: state.user,
  advert: state.advert
});

const mapDispatchToProps = {
  getSingleUser,
  updateUser,
  unArchiveUser,
  archiveUser,
  getUserProposals,
  userUpdateAgentsPhone,
  getLocationDistrictAdress,
  getDistrictAdress
};

const connector = connect(mapStateToProps, mapDispatchToProps)

export const EditUserScreen: FC<IProps> = (props) => {
  React.useEffect(() => {
    form.resetFields();
    props.getSingleUser(props.match.params.id).then(resp => {
      if (resp.agentRegion) {
        props.getDistrictAdress(resp.agentRegion).then(resp => {
          setDistrict(resp.LocalityData.data)
        })
      }
    })
  }, [])

  const { single } = props.users;

  const [form] = useForm();

  const [isEdited, setIsEdited] = React.useState<boolean>(Boolean(props.users.view));

  const [regionList, setRegionList] = React.useState<string[]>([]);

  const [region, setRegion] = React.useState<any>({});

  const [districtList, setDistrictList] = React.useState([])

  const [district, setDistrict] = React.useState<any>([]);

  const [isResetModalVisible, setIsResetModalVisible] = React.useState(false);

  const [isVerifyModalVisible, setVerifyModalVisible] = React.useState(false);

  const [isArchiveModalVisible, setIsArchiveModalVisible] = React.useState(false);

  const [codePhoneError, setCodePhoneError] = React.useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = React.useState<string>("");

  const [isMailModalVisible, setMailModalVisible] = React.useState(false);

  const [editTag, setEditTag] = React.useState<string>(single.tag);

  const [areProposalsLoading, setAreProposalsLoading] = React.useState(false);

  React.useEffect(() => {
    form.setFieldsValue({ ...single, lastName: single.fio?.lastName, firstName: single.fio?.firstName, patronymic: single.fio?.patronymic, phone: single?.phone?.replace('+', ''), tag: single.tag, bankCardNumber: single.bankCardNumber, agentPhone: single?.agentPhoneNumber?.replace('+', '') })
    setEditTag(single.tag)
  }, [single])

  React.useEffect(() => {
    setAreProposalsLoading(true);
    props.getUserProposals(props.match.params.id).then(resp => {
      setAreProposalsLoading(false);
    }).catch(e => {
      setAreProposalsLoading(false);
    })
  }, [])

  const modalArchiveHandler = (e) => {
    setIsArchiveModalVisible(true)
  }

  const editHandler = () => {
    setIsEdited(!isEdited)
  }

  const resetModalHandler = () => {
    setIsResetModalVisible(true)
    setEditTag(single.tag);
  }

  const saveChangesHandler = () => {
    form.submit();
  }

  const onFinish = (values: any) => {
    if (values.phone !== single.phone.replace('+', '')) {
      setVerifyModalVisible(true)
    } else {
      submitWithoutPhoneHandler();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  const infoBackHandler = () => {
    setIsEdited(false)
    form.resetFields();
    setIsResetModalVisible(false)
  }

  const changePhoneHandler = (e) => {
    setPhoneNumber(e.target.value)
  }

  const submitWithoutPhoneHandler = () => {
    let values = form.getFieldsValue()
    let data = {
      "email": values.email || null,
      "fio": {
        "firstName": values.firstName,
        "lastName": values.lastName,
        "patronymic": values.patronymic
      },
      "tag": values.tag,
      "bankCardNumber": values.bankCardNumber || null,
      "messageNotificationEmail": single.messageNotificationEmail,
      "messageNotificationSms": single.messageNotificationSms,
      "orderStatusNotificationEmail": single.orderStatusNotificationEmail,
      "orderStatusNotificationSms": single.orderStatusNotificationSms,
      "replyAdsNotificationEmail": single.replyAdsNotificationEmail,
      "replyAdsNotificationSms": single.replyAdsNotificationSms,
      "agentDistrict": values.agentDistrict,
      "agentRegion": values.agentRegion
    }

    let agentsPhoneData = {
      "agentNewPhone": `+${values.agentPhone}`
    }

    props.updateUser(props.match.params.id, data).then(resp => {
      if (resp.type === "USER_UPDATE_SUCCESS") {
        setVerifyModalVisible(false)

        if (props.user?.userRole === "ROLE_ADMIN" && single?.agentPhoneNumber) {
          props.userUpdateAgentsPhone(props.match.params.id, agentsPhoneData).then(resp => {
            if (resp.type === "USER_UPDATE_AGENTS_PHONE_SUCCESS") {
              setIsEdited(false)
              props.getSingleUser(props.match.params.id)
            }
          })
        } else {
          setIsEdited(false)
          props.getSingleUser(props.match.params.id)
        }

      }
      else {
        setMailModalVisible(true)
      }
    })
  }

  const regionSelectHandler = value => {
    setRegion(value)
    form.setFieldsValue({
      agentDistrict: ""
    })
    props.getDistrictAdress(value).then(resp => {
      setDistrict(resp.LocalityData.data)
    })
  }

  const handleModalClose = () => {
    setIsArchiveModalVisible(false)
  }

  const archiveHandlerCallback = (id, status) => {
    if (status === "BLOCKED") {
      props.unArchiveUser(id).then(resp => {
        if (resp.type === "UNARCHIVE_USER_SUCCESS") {
          props.getSingleUser(props.match.params.id)
        } else {
          // if (resp.payload.error === "ERR_STAFF_008") {
          //   openNotification('error', smallNotification.errorTitle, "Спiвробiтник не може заблокувати сам себе!")
          // }
        }
      })
    } else {
      props.archiveUser(id).then(resp => {
        if (resp.type === "ARCHIVE_USER_SUCCESS") {
          props.getSingleUser(props.match.params.id)
        } else {
          // if (resp.payload.error === "ERR_STAFF_008") {
          //   openNotification('error', smallNotification.errorTitle, "Спiвробiтник не може заблокувати сам себе!")
          // }
        }
      })
    }
    // props.archiveStaff(id, { "status": setActualStatus(status) }).then(resp => {
    //   if (resp.type === "ARCHIVE_STAFF_SUCCESS") {
    //     props.getSingleStaff(props.match.params.id)
    //   }
    // })
  }
  const goBackHanlder = (e) => {
    e.preventDefault()
    props.history.push(props.advert.isFromAdvert)
  }

  const tagSelectHandler = (tag) => {
    setEditTag(tag);
  }

  const tableOnChangeHandler = (pagination: any) => {
    props.getUserProposals(props.match.params.id, pagination.current - 1)
  }

  const viewUserAdsHandler = () => {
    props.history.push(`/dashboard/users/${props.match.params.id}/adverts`)
  }

  const tableColumns: Array<any> = [
    {
      title: users.proposalsTable.proposalID,
      dataIndex: 'id',
    },
    {
      title: users.proposalsTable.advertID,
      dataIndex: 'ad.id',
      render: (id, record) => {
        return <Link to={`/dashboard/advert/${record.ad.id}`}>{record.ad.id}</Link>
      },
    },
    {
      title: users.proposalsTable.proposalType,
      dataIndex: 'proposalType',
      render: (proposalType) => {
        return <span>
          {proposalTypesMapper(proposalType)}
        </span>
      },
    },
    {
      title: users.proposalsTable.status,
      dataIndex: 'status',
      render: (status) => {
        return <span>
          {proposalMapper(status)}
        </span>
      }
    },
    {
      title: users.proposalsTable.price,
      dataIndex: 'proposedPrice',
    },
    {
      title: users.proposalsTable.cancellationReason,
      dataIndex: 'declineReason',
      render: (declineReason) => {
        return <span>{declineReason ? declineReason : "-"}</span>
      }
    }
  ];


  return (
    <div className="edit-user-wrapper">
      {props.users.isFetching && <SpinComponent fullHeight={true} />}
      <InfoModal
        isSaved={false}
        title={users.errorModal.heading}
        isModalVisible={isMailModalVisible}
        closeModal={setMailModalVisible}
        backPath={"/dashboard/staff"}
        img={<WarningSvg />}
        text={users.errors.emailAlreadyExists}
        successBtnText={users.errorModal.backBtn}
      />
      <VerifyPhoneModal
        isModalVisible={isVerifyModalVisible}
        closeModal={setVerifyModalVisible}
        phoneNumber={single.phone}
        updatedPhoneNumber={phoneNumber}
        submitWithoutPhoneHandler={submitWithoutPhoneHandler}
        id={props.match.params.id}
      />
      <ArchiveModal
        isModalVisible={isArchiveModalVisible}
        handleModalClose={handleModalClose}
        id={single.id}
        status={single.status}
        archivePartnerHandler={archiveHandlerCallback}
        archivedText={<span>{users.blockModal.unBlockMessagePartOne}<br />{users.blockModal.unBlockMessagePartTwo}</span>}
        unArchivedText={<span>{users.blockModal.blockMessagePartOne}<br />{users.blockModal.blockMessagePartTwo}</span>}
      />
      <InfoModal
        isSaved={true}
        title={"Увага!"}
        isModalVisible={isResetModalVisible}
        closeModal={setIsResetModalVisible}
        onSubmit={infoBackHandler}
        img={<WarningSvg />}
        text={<span>{users.edit.cancelEditModalMessagePartOne}<br />{users.edit.cancelEditModalMessagePartTwo}</span>}
        cancelBtnText={users.edit.cancelEditModalCancelBtn}
        successBtnText={users.edit.cancelEditModalStayBtn}
      />
      {single && Object.keys(single).length ?
        <>
          <div className="user-profile-wrapper">
            <>
              <div className="profile-header">
                <h2>{!isEdited ? <><Link to={"/dashboard/users"} className="goBack" onClick={goBackHanlder}><BackSvg /></Link>{`${single.fio?.lastName || ""} ${single.fio?.firstName || ""}`}</> : "Редагування особистих даних"}</h2>
                {props.user?.userRole === "ROLE_ADMIN" && <div className="btn-wrapper">
                  {!isEdited ?
                    <>
                      <Button size="small" type="default" onClick={editHandler} className="edit-user-btn" disabled={single.status === "DELETED"}><EditSvg />{users.view.editBtnText}</Button>
                      <Button size="small" type="primary" onClick={modalArchiveHandler} disabled={single.status === "DELETED"}>
                        {single.status === "ACTIVE" || single.status === "NOT_VERIFIED" ? <><BlockSvg />{users.view.blockBtnText}</> : <><UnarchiveSvg />{users.view.unBlockBtnText}</>}
                      </Button>
                    </>
                    :
                    <>
                      <Button size="small" type="default" onClick={resetModalHandler} className="edit-user-cancel-btn"><DeclineSvg />{users.edit.cancelBtnText}</Button>
                      <Button size="small" type="primary" onClick={saveChangesHandler}><ApproveSvg />{users.edit.saveChangesBtnText}</Button>
                    </>}
                </div>
                }
              </div>
              <div className="id-date-wrapper">
                <p className="id-number">{`ID ${single.id}`}</p>
                <p className="last-date">{single.lastSeenDate && `${users.fields.lastDate} ${moment(single.lastSeenDate).format('DD.MM.YYYY')}`}</p>
              </div>
              {!isEdited ?
                <>
                  <Row gutter={24} className="view-info-row">
                    <Col span={5}>
                      <span className="title">{users.fields.phone}</span>
                      <p className="name">{single.phone || users.fields.notStated}</p>
                    </Col>
                    <Col span={5}>
                      <span className="title">{users.fields.email}</span>
                      <p className="name">{single.email || users.fields.notStated}</p>
                    </Col>
                    <Col span={5}>
                      <span className="title">{users.fields.tag}</span>
                      <p className="name">{clientTagsMapper(single.tag)}</p>
                    </Col>
                    {
                      single.tag && single.tag === "REPRESENTATIVE" ?
                        <Col span={5}>
                          <span className="title">{users.fields.cardNo}</span>
                          <p className="name">{single.bankCardNumber || users.fields.notStated}</p>
                        </Col>
                        : null
                    }

                    {
                      (single.createdDate && single.tag === "REPRESENTATIVE") &&
                      <Col span={4}>
                        <span className="title">{users.fields.createdDate}</span>
                        <p className="name">{moment(single.createdDate).format('DD.MM.YYYY')}</p>
                      </Col>
                    }

                    {
                      (single.agentFio?.firstName && single.agentFio?.lastName) &&
                      <Col span={5}>
                        <span className="title">{users.fields.agentName}</span>
                        {single.agentInfo.id ?
                          <p className="name"> <Link to={`/dashboard/agents/${single.agentInfo?.id}`}>{single.agentFio?.lastName} {single.agentFio?.firstName} {single.agentFio?.patronymic ? single.agentFio?.patronymic : ""}</Link> </p>
                          :
                          <p className="name">{single.agentFio?.lastName} {single.agentFio?.firstName} {single.agentFio?.patronymic ? single.agentFio?.patronymic : ""}</p>
                        }
                      </Col>
                    }

                    <Col span={5}>
                      <span className="title">{users.fields.agentPhone}</span>
                      <p className="name">{single.agentPhoneNumber || users.fields.notStated}</p>
                    </Col>

                    {
                      (single.createdDate && single.tag !== "REPRESENTATIVE") &&
                      <Col span={4}>
                        <span className="title">{users.fields.createdDate}</span>
                        <p className="name">{moment(single.createdDate).format('DD.MM.YYYY')}</p>
                      </Col>
                    }

                    {
                      single.agentRegion &&
                      <Col span={5}>
                        <span className="title">{users.fields.region}</span>
                        <p className="name">{single.agentRegion}</p>
                      </Col>
                    }

                    {
                      single.agentDistrict &&
                      <Col span={5}>
                        <span className="title">{users.fields.district}</span>
                        <p className="name">{single.agentDistrict}</p>
                      </Col>
                    }

                  </Row>
                  {single.offersCount > 0 && <Row gutter={24} className="action-btns-wrapper">
                     <Col span={8}>
                      <Button type="default" onClick={viewUserAdsHandler}><FileBlueSvg />{users.view.advertsBtnText}</Button>
                    </Col>
                    {/* <Col span={8}>
                      <Button type="default"><HandshakeBlueSvg />{users.view.contractsBtnText}</Button>
                    </Col>
                    <Col span={8}>
                      <Button type="default"><ChatBlueSvg />{users.view.chatsBtnText}</Button>
                    </Col> */}
                  </Row>}
                </>
                :
                <Form
                  name="edit-staff"
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    lastName: single.fio?.lastName,
                    firstName: single.fio?.firstName,
                    patronymic: single.fio?.patronymic,
                    phone: single.phone.replace('+', ''),
                    email: single.email,
                    tag: single.tag,
                    bankCardNumber: single.bankCardNumber,
                    agentPhone: single?.agentPhoneNumber?.replace('+', '') || null,
                    "agentDistrict": single.agentDistrict,
                    "agentRegion": single.agentRegion
                  }}
                >
                  <Row gutter={24} className="form-row" wrap={true}>
                    <Col span={5}>
                      <Form.Item
                        name="lastName"
                        label={users.fields.lastName}
                        rules={[
                          { required: true, message: users.fields.requiredFieldErrorMessage }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name="firstName"
                        label={users.fields.firstName}
                        rules={[
                          { required: true, message: users.fields.requiredFieldErrorMessage }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      {/* <Form.Item
                      name="patronymic"
                      label="По-батьковi"
                      rules={[
                        { required: true, message: 'Заповнiть це поле' }
                      ]}
                    >
                      <Input />
                    </Form.Item> */}
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name="phone"
                        label={users.fields.phone}
                        rules={[
                          { required: true, message: users.fields.requiredFieldErrorMessage }
                        ]}
                      >
                        <Input className="tel-input" onChange={changePhoneHandler} prefix={"+"} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        label={users.fields.email}
                        name={['email']}
                        rules={[
                          // { required: true, message: users.fields.requiredFieldErrorMessage },
                          { type: 'email', message: users.fields.emailErrorMessage }
                        ]
                        }>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label={users.fields.tag}
                        name="tag">
                        <Select onChange={tagSelectHandler}>
                          {OptionsMapper(clientTags)}
                        </Select>
                      </Form.Item>
                    </Col>
                    {
                      editTag && editTag === "REPRESENTATIVE" ?
                        <Col span={5}>
                          <Form.Item
                            label={users.fields.cardNo}
                            name="bankCardNumber">
                            <Input />
                          </Form.Item>
                        </Col>
                        : null
                    }
                    {
                      single.agentPhoneNumber &&
                      <Col span={5}>
                        <Form.Item
                          label={users.fields.agentPhone}
                          name="agentPhone"
                          rules={[
                            { required: true, message: users.fields.requiredFieldErrorMessage },
                            { min: 12, message: users.fields.phoneIncompleteErrorMessage }
                          ]}>
                          <Input type="number" className="tel-input" prefix={"+"} disabled={props.user?.userRole !== "ROLE_ADMIN"} />
                        </Form.Item>
                      </Col>
                    }
                    {
                      editTag && editTag === "REPRESENTATIVE" ?
                        <>
                          <Col span={5}>
                            <Form.Item
                              label={users.fields.region}
                              name="agentRegion">
                              <Select
                                placeholder={users.fields.region}
                                onChange={regionSelectHandler}
                                allowClear
                              >
                                {OnlyRegionsList.map((item, index) => (
                                  <Select.Option key={index} value={item.value}>
                                    {item.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              label={users.fields.district}
                              name="agentDistrict">
                              <Select placeholder={users.fields.district} allowClear
                              >
                                {district.map((item, index) => (
                                  <Select.Option key={index} value={item.district}>
                                    {item.district}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </>
                        : null
                    }
                  </Row>
                </Form>}
            </>
          </div>
          {
            props.users.proposalResults?.content && props.users.proposalResults?.content.length > 0 ?
              <div className="proposals-wrapper">
                <h3 className="title">Заявки на покупку</h3>
                <Table columns={tableColumns}
                  dataSource={props.users.proposalResults?.content || []}
                  onChange={tableOnChangeHandler}
                  pagination={{
                    size: "small",
                    defaultPageSize: 15,
                    total: props.users.proposalResults?.totalElements,
                    showSizeChanger: false,
                    position: ["bottomCenter"],
                    hideOnSinglePage: true
                  }}
                  loading={areProposalsLoading}
                >

                </Table>
              </div>
              : null
          }

        </>
        : null}

    </div>
  );
};

export default connector(EditUserScreen);
