import { Reducer } from 'redux';
import { AgentsActions } from './types';

export const initialState = {
    single: {},
    documentsList: []
}
export const AgentsReducer: Reducer<any, AgentsActions> = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'GET_AGENTS_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_AGENTS_LIST_SUCCESS': {
      return {
        ...state,
        results: action.preview,
        isFetching: false,
      };
    }
    case 'GET_AGENTS_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'GET_SINGLE_AGENT_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_SINGLE_AGENT_SUCCESS': {
      return {
        ...state,
        single: action.preview,
        isFetching: false,
      };
    }
    case 'GET_SINGLE_AGENT_FAILURE': {
      return { ...defaultErrorState(action) };
    }

    case 'AGENT_UPDATE_PENDING' : {
      return { ...defaultStateWithFetchingTrue };
    }

    case 'AGENT_UPDATE_SUCCESS' : {
      return {
        ...state,
        isFetching: false,
      }
    }

    case 'AGENT_UPDATE_FAILURE' : {
      return { ...defaultErrorState(action) };
    }

    case 'SET_AGENTS_VIEW_SUCCESS': {
      return {
        ...state,
        view :action.payload,
      };
    }

    // case 'SET_USERS_VIEW_SUCCESS': {
    //   return {
    //     ...state,
    //     view :action.payload,
    //   };
    // }

    case "GET_AGENTS_DOCUMENTS_PENDING": {
      return {...state}
    }

    case "GET_AGENTS_DOCUMENTS_SUCCESS": {
      return {
        ...state,
        documentsList: action.payload
      }
    }

    case "GET_AGENTS_DOCUMENTS_FAILURE":{
      return {...defaultErrorState(action)}
    }

    case "POST_AGENT_DOCUMENT_PENDING": {
      return {
        ...state
      }
    }

    case "POST_AGENT_DOCUMENT_SUCCESS": {
      return {
        ...state
      }
    }

    case "POST_AGENT_DOCUMENT_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "DOWNLOAD_SINGLE_AGENT_DOCUMENT_PENDING": {
      return {
        ...state
      }
    }

    case "DOWNLOAD_SINGLE_AGENT_DOCUMENT_SUCCESS": {
      return {
        ...state
      }
    }

    case "DOWNLOAD_SINGLE_AGENT_DOCUMENT_FAILURE": {
      return {...defaultErrorState(action)}
    }

    case "DELETE_SINGLE_AGENT_DOCUMENT_PENDING": {
      return  {
        ...state
      }
    }

    case "DELETE_SINGLE_AGENT_DOCUMENT_SUCCESS": {
      return  {
        ...state
      }
    }

    case "DELETE_SINGLE_AGENT_DOCUMENT_FAILURE": {
      return {...defaultErrorState(action)}
    }

    default: return state
  }
}
export default AgentsReducer;