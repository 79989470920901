import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import {deleteSingleAgentDocument, getAgentDocuments} from "../../store/agents/actions";
import {
    IDeleteSingleAgentDocumentFailureAction,
    IDeleteSingleAgentDocumentSuccessAction,
    IGetAgentsDocumentsFailureAction,
    IGetAgentsDocumentsPendingAction,
    IGetAgentsDocumentsSuccessAction
} from "../../store/agents/types";
import {deleteSingleContractDocument, getContractDocuments} from "../../store/contracts/actions";
import {connect} from "react-redux";
import {
    IDeleteSingleContractDocumentFailureAction,
    IDeleteSingleContractDocumentSuccessAction, IGetContractBuyerDocumentsFailureAction,
    IGetContractBuyerDocumentsPendingAction,
    IGetContractBuyerDocumentsSuccessAction,
    IGetContractSellerDocumentsFailureAction,
    IGetContractSellerDocumentsPendingAction,
    IGetContractSellerDocumentsSuccessAction
} from "../../store/contracts/types";

interface IProps {
    sourceEntity: "DEAL" | "AGENT";
    isModalVisible: boolean;
    closeModal: any;
    title: string;
    img: any;
    text: any;
    entityID: number;
    documentID: number;
    cancelBtnText?: string;
    successBtnText?: string;
    owner?: "SELLER" | "BUYER";
    deleteSingleContractDocument: (dealID: number, documentID: number, owner: string) => Promise<IDeleteSingleContractDocumentSuccessAction | IDeleteSingleContractDocumentFailureAction>;
    getContractDocuments: (id: number, owner: string) => Promise<IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction | IGetContractSellerDocumentsPendingAction | IGetContractBuyerDocumentsPendingAction | IGetContractSellerDocumentsFailureAction
        | IGetContractBuyerDocumentsFailureAction>;
    deleteSingleAgentDocument: (agentID: number, documentID: number) => Promise<IDeleteSingleAgentDocumentSuccessAction | IDeleteSingleAgentDocumentFailureAction>;
    getAgentDocuments: (id: number) => Promise<IGetAgentsDocumentsSuccessAction | IGetAgentsDocumentsPendingAction | IGetAgentsDocumentsFailureAction>;
}

const mapDispatchToProps = {
    deleteSingleContractDocument,
    getContractDocuments,
    deleteSingleAgentDocument,
    getAgentDocuments

};

const connector = connect(null, mapDispatchToProps);

const RemoveDocumentModal:FC<IProps> = (props) => {

    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

    const handleCancel = () => {
        props.closeModal();
    };

    const onFinish = () => {
        setIsDeleting(true);
        switch (props.sourceEntity) {
            case "DEAL": {
                props.deleteSingleContractDocument(props.entityID, props.documentID, props.owner).then(resp => {
                    if (resp.type === "DELETE_SINGLE_CONTRACT_DOCUMENT_SUCCESS") {
                        props.getContractDocuments(props.entityID, props.owner)
                        props.closeModal();
                        setIsDeleting(false);
                    }
                })
                break;
            }

            case "AGENT": {
                props.deleteSingleAgentDocument(props.entityID, props.documentID).then(resp => {
                    if (resp.type === "DELETE_SINGLE_AGENT_DOCUMENT_SUCCESS") {
                        props.getAgentDocuments(props.entityID)
                        props.closeModal();
                        setIsDeleting(false);
                    }
                })
                break;
            }

            default : {
                break;
            }

        }



    }

    return (
        <Modal title={props.title}
               visible={props.isModalVisible}
               closable={false}
               onOk={onFinish}
               onCancel={handleCancel}
               footer={null}
               className="modal-info"
               centered={true}
        >
            {props.img}
            <p>{props.text}</p>
            {props.children}
            <div className="btn-wrapper">
                <Button type="default" id="removeCancel" className="btn" key={'1'} onClick={handleCancel}>
                    {props.cancelBtnText}
                </Button>
                <Button id="removeSuccess" type="primary" className="btn"  key={'2'} onClick={onFinish} disabled={isDeleting}>
                    {props.successBtnText}
                </Button>
            </div>
        </Modal>
    );
};

export default connector(RemoveDocumentModal);