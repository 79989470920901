import { Button, Modal, Radio, Input, Space } from 'antd';
import React, { FC } from 'react';
import './CancelModal.scss';
import {contracts} from '../../globalConstants/textinputs.json';

interface IProps {
  isModalVisible: boolean;
  handleModalClose: any;
  handleDealCancel: any;
  cancelEdit: any;
  id: string
}

const CancelModal: FC<IProps> = (props) => {

  const [reason, setReason] = React.useState(contracts.cancelModal.sellersRefusal);
  const [otherReason, setOtherReason] = React.useState('');
  const [hasError, setHasError] = React.useState(false);

  const handleCloseModalCallback = () => {
    setReason(contracts.cancelModal.sellersRefusal);
    setOtherReason("");
    setHasError(false)
    props.handleModalClose(false)
  }

  const cancelDeal = (reason) => {
    props.handleDealCancel(props.id, {"reason": reason});
  }

  const onFinish = () => {

    if (reason === contracts.cancelModal.otherReasonValue) {
      if (otherReason.trim().length > 0) {
        cancelDeal(otherReason);
      } else {
        setHasError(true);
      }
    } else {
      cancelDeal(reason);
    }
  }

  const reasonChangeHandler = (e) => {
    setReason(e.target.value);
  }

  const otherReasonChangeHandler = (e) => {
    setOtherReason(e.target.value);
  }
  
  return (
    <div>
      <Modal title={contracts.cancelModal.heading}
        className="cancel-deal-modal"
        closable={false}
        visible={props.isModalVisible}
        footer={null}
        onCancel={handleCloseModalCallback}
        onOk={onFinish}
        centered={true}
      >
        <div className="modal-body-inner">
          <Radio.Group onChange={reasonChangeHandler} value={reason}>
              <Radio value={contracts.cancelModal.sellersRefusal}>{contracts.cancelModal.sellersRefusal}</Radio>
              <Radio value={contracts.cancelModal.buyersRefusal}>{contracts.cancelModal.buyersRefusal}</Radio>
              <Radio value={contracts.cancelModal.wrongInfoInAdvert}>{contracts.cancelModal.wrongInfoInAdvert}</Radio>
              <Radio value={contracts.cancelModal.otherReasonValue} className="other-reason-radio">
                {contracts.cancelModal.otherReasonLabel}
              </Radio>
              {reason === contracts.cancelModal.otherReasonValue ?
                  <>
                    <Input className="reason-input" value={otherReason} onChange={otherReasonChangeHandler} />
                    {hasError? <p className="reason-error">{contracts.cancelModal.otherReasonError}</p> : null}
                  </>
                  : null
              }
          </Radio.Group>
        </div>

        <div className="btn-wrapper">
          <div className="btn-inner">
            <Button type="default" id="deal-close" className="btn" key={'1'} onClick={handleCloseModalCallback}>
              {contracts.cancelModal.cancelBtn}
            </Button>
          </div>
            <div className="btn-inner">
              <Button type="primary" id="deal-finish" className="btn" key={'2'} onClick={onFinish}>
                {contracts.cancelModal.cancelContractBtn}
              </Button>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default CancelModal;