import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BackSvg } from 'assets/images/ic-back-small.svg'
import "./BackComponent.scss";

interface IProps {
  path?: string;
  modalExit?: (boolean) => void;
  advertType?: string;
}

const BackComponent:FC <IProps> = (props) => {

  const exitModalHandler = (e) => {
    props.modalExit(true)
  }

  console.log(props.advertType);
  

  return (
    <div className="go-back-wrapper">
      <Link to={props.advertType !== "inModeration" && props.path} id="goBack" onClick={exitModalHandler}><BackSvg />Назад до оголошень</Link>
    </div>
  );
};

export default BackComponent;