import {Button, Form, Modal, Select} from 'antd';
import {useForm} from "antd/lib/form/Form";
import React, {FC} from 'react';
import {connect} from "react-redux";
import './ChangeAuthorModal.scss';
import {adverts as advertsText} from '../../globalConstants/textinputs.json';
import {
    IUpdateAdvertAuthorFailureAction,
    IUpdateAdvertAuthorSuccessAction
} from "../../store/adverts/types";
import {getUsersList} from "../../store/users/actions";
import {ISetUsersListAction} from "../../store/users/types";
import {debounce} from "lodash";
import {updateAdvertAuthor} from '../../store/adverts/actions';

interface IProps {
    isModalVisible: boolean;
    setModalVisibility: (value: boolean) => void;
    getUsersList: (sortBy: string, sortType: string, page?: number, size?: number, rsql?: string) => Promise<ISetUsersListAction>;
    updateAdvertAuthor: (adID: string, newAuthorId: string) => Promise<IUpdateAdvertAuthorSuccessAction | IUpdateAdvertAuthorFailureAction>;
    adId: string;
    currentAuthorId: string;
    updateAdvert: () => void;
}

const mapDispatchToProps = {
    getUsersList,
    updateAdvertAuthor
};


const connector = connect(null, mapDispatchToProps);


export const ChangeAuthorModal: FC<IProps> = (props) => {

    const [currentUsers, setCurrentUsers] = React.useState<any>([]);

    const [currentPage, setCurrentPage] = React.useState<number>(0);

    const [totalPages, setTotalPages] = React.useState<number>(0);

    const [searchValue, setSearchValue] = React.useState<string>("");

    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

    const [form] = useForm();

    const {getUsersList, updateAdvertAuthor} = props;
    
    React.useEffect(() => {

        if (props.isModalVisible) {
            getUsersList('id', 'asc', currentPage, 20, `&search=status%3D%3DACTIVE;(phone%3D%3D*${searchValue}*,fio.firstName%3D%3D*${searchValue}*,fio.lastName%3D%3D*${searchValue}*)`).then(resp => {
                setCurrentUsers(resp.preview.content);
                setTotalPages(resp.preview.totalPages);
            })
        }

    }, [props.isModalVisible])

    const resetResults = () => {
        setCurrentUsers([]);
        setCurrentPage(0)
        setTotalPages(0);
        setSearchValue("");
        form.resetFields();
        props.setModalVisibility(false);
    }

    const handleSelectScroll = (e) => {

        e.persist();
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {

            if (currentPage < totalPages) {

                getUsersList('id', 'asc', currentPage + 1, 20, `&search=status%3D%3DACTIVE;(phone%3D%3D*${searchValue}*,fio.firstName%3D%3D*${searchValue}*,fio.lastName%3D%3D*${searchValue}*)`).then(resp => {
                    setCurrentPage(currentPage + 1)

                    setCurrentUsers(prevState => (
                        [...prevState, ...resp.preview.content]
                    ))
                })
            }


        }
    }

    const handleCancelAuthorChange = () => {
        resetResults();
    }

    const handleSearch = (value) => {
        let newValue = value.replace(/[`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, '');
        setSearchValue(newValue);
        setCurrentUsers([]);
        setCurrentPage(0);
        setTotalPages(0);
        getUsersList('id', 'asc', 0, 20, `&search=status%3D%3DACTIVE;(phone%3D%3D*${newValue}*,fio.firstName%3D%3D*${newValue}*,fio.lastName%3D%3D*${newValue}*)`).then(resp => {
            setCurrentUsers(resp.preview.content);
            setTotalPages(resp.preview.totalPages);
        })
    }

    const debouncedSearchHandler = React.useMemo(() => debounce(handleSearch, 300), [])

    React.useEffect(() => {
        return () => {
            debouncedSearchHandler.cancel();
        }
    }, []);

    const handleFormSubmit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        setIsUpdating(true);
        updateAdvertAuthor(props.adId, values.newAuthorId).then(resp => {
            if (resp.status === "OK") {
                resetResults();
                setIsUpdating(false);
                props.updateAdvert();
            }
        })

    }


    return (
        <div>
            <Modal title={advertsText.changeAuthorModal.title}
                   className="changeAuthorModal"
                   closable={false}
                   visible={props.isModalVisible}
                   footer={null}
                   onCancel={handleCancelAuthorChange}
                   onOk={handleFormSubmit}
                   centered={true}
                   width={500}
            >
                <Form name="changeAuthorForm" form={form} initialValues={{
                    newAuthorId: null
                }}
                      onFinish={onFinish}>

                    <Form.Item
                        name="newAuthorId" rules={[
                        {required: true, message: advertsText.changeAuthorModal.requiredFieldErrorMessage}
                    ]}>

                        <Select showSearch allowClear placeholder={advertsText.changeAuthorModal.placeholder}
                                onPopupScroll={handleSelectScroll} onSearch={debouncedSearchHandler} optionFilterProp="children" filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        } notFoundContent={<p>{advertsText.changeAuthorModal.noCustomersFound}</p>}>
                            {currentUsers.filter(item => item.id !== props.currentAuthorId).map((item) => (
                                <Select.Option key={item.id} value={item.id} disabled={item.id === props.currentAuthorId}>
                                    {`${item.fio?.lastName} ${item.fio?.firstName}, ${item.phone}`}
                                </Select.Option>
                            ))}
                        </Select>

                    </Form.Item>

                </Form>

                <div className="btn-wrapper">
                    <div className="btn-inner">
                        <Button type="default" className="btn" key={'1'} onClick={handleCancelAuthorChange}>
                            {advertsText.changeAuthorModal.cancelBtn}
                        </Button>
                    </div>
                    <div className="btn-inner">
                        <Button type="primary" className="btn" key={'2'} onClick={handleFormSubmit} loading={isUpdating}>
                            {advertsText.changeAuthorModal.submitBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connector(ChangeAuthorModal);