import {
	REQUEST,
	SUCCESS,
	FAILURE,
	IErrorPayload
} from '../types';

import {
	IUserState,
	UserActions,
} from "./types";

import { Reducer } from 'redux';
import { parseJwt } from 'services';

export const initialUserState = {
	token: "",
	userRole: [""],
	isFetching: false,
	userInfo:{}
}


export const userReducer: Reducer<any, any> = (
	state = initialUserState,
	action,
) => {

	const defaultStateWithFetchingTrue = {
		...state,
		isFetching: true,
	};

	const defaultStateWithFetchingFalse = {
		...state,
		isFetching: false,
	};

	const defaultErrorState = (action: { payload: IErrorPayload }) => ({
		...state,
		isFetching: false,
		errorInfo: {
			hasError: true,
			...action.payload
		},
	});

	switch (action.type) {

		case 'LOGIN_USER_PENDING': {
			return { ...defaultStateWithFetchingTrue };
		}
		case 'LOGIN_USER_SUCCESS': {
			return {
				...state,
				token: action.authData.data.token,
				userRole: parseJwt(action.authData.data.token).roles,
				isFetching: false,
			};
		}
		case 'LOGIN_USER_FAILURE': {
			return { ...defaultErrorState(action) };
		}
		case 'GET_ROLE_SUCCESS': {	
      return {
        ...state,
        userRole: parseJwt(action.payload).roles,
      };
    }

		case 'GET_USER_INFO_PENDING': {
			return { 
				...defaultStateWithFetchingTrue,
				userProfileIsLoad: false,
				isFetchingUserInfo: true,
			};
			}
			case 'GET_USER_INFO_SUCCESS': {
			// console.log(parseJwt(action.authData.data.token))
			return {
				...state,
				userInfo: {...action.userInfo},
				userProfileIsLoad: true,
				isFetchingUserInfo: false,
			};
			}
			case 'GET_USER_INFO_FAILURE': {
			return { 
				...defaultErrorState(action),
				userProfileIsLoad: false,
				isFetchingUserInfo: false,
			};
			}

		/// LOGOUT_USER

		// case 'LOGOUT_USER_PENDING': {
		// 	return { ...defaultStateWithFetchingTrue };
		// }
		// case 'LOGOUT_USER_SUCCESS': {
		// 	return {
		// 		...state,
		// 		token: '',
		// 		userRole: [],
		// 		isFetching: false,
		// 	};
		// }
		// case 'LOGOUT_USER_FAILURE': {
		// 	return { ...defaultStateWithFetchingFalse };
		// }
		default:
			return {
				...state
			}
	};

}