import React, { FC } from 'react';
import { Form, Button, Input } from 'antd';
import { restorePassword } from 'store/user/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SignUpSmsStep from 'components/SignUpSmsStep/SignUpSmsStep';
import { setPhoneNumber, verificationPhoneIsUsed } from 'store/verificationSms/actions';
import SignUpPasswordStep from 'components/SignUpPasswordStep/SignUpPasswordStep';
import { IPhoneNumber, IVerificationPhonePostData, IverificationPhoneUsedAction, IverificationPhoneUsedFailureAction, PhoneActions } from 'store/verificationSms/types';
import { IrestorePasswordFailureAction, IrestorePasswordSuccessAction } from 'store/user/types';
import UserNotFound from 'components/UserNotFound/UserNotFound';
import { useForm } from 'antd/lib/form/Form';
import UserBlocked from 'components/UserBlocked/UserBlocked';
import { getTokenData } from 'store/user/actions';
import {restorePasswordScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  restorePassword: any;
  history?: any;
  setPhoneNumber: (data: any) => PhoneActions;
  regInfo: { [key: string]: string };
  verificationPhoneIsUsed: (data: IVerificationPhonePostData) => Promise<IverificationPhoneUsedAction | IverificationPhoneUsedFailureAction>;
  getTokenData: any;
}

const mapStateToProps = state => ({
  regInfo: state.regInfo,
});

const mapDispatchToProps = {
  restorePassword,
  setPhoneNumber,
  verificationPhoneIsUsed,
  getTokenData
};

const connector = connect(mapStateToProps, mapDispatchToProps)

export const RestorePasswordScreen: FC<IProps> = ({ restorePassword, history, ...props }) => {

  const [form] = useForm();

  const [isSignUpCounter, setisSignUpCounter] = React.useState<number>(0);

  const [phoneNumber, setphoneNumberSuccess] = React.useState<string>("_");

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [isLoginSuccess, setisLoginSuccess] = React.useState<boolean>(true);

  const [codePhoneError, setCodePhoneError] = React.useState<boolean>(false);

  const onFinish = (values) => {
    restorePassword({ phone: props.regInfo.phone, passcode: props.regInfo.code, password: values.password }).then(resp => {
      if (resp.type == "RESTORE_PASSWORD_SUCCESS") {
        props.getTokenData(resp.preview.data.token)
        history.push('/dashboard/contracts');
      }
      else if (resp.type = "RESTORE_PASSWORD_FAILURE") {
        setisLoginSuccess(false)
        setErrorCode("ERR_STAFF_011")
      }
    })
  };
  const onFinishFailed = (errorInfo) => {
  };

  const goBackHandler = () => {
    setErrorCode("")
    setisLoginSuccess(true)
    setisSignUpCounter(0)
  }

  const goNextStep = () => {
    setisSignUpCounter(isSignUpCounter + 1)
    props.setPhoneNumber(`+${phoneNumber}`)
  }

  const goNextStepWithVerify = () => {
    props.setPhoneNumber(`+${phoneNumber}`)
    props.verificationPhoneIsUsed({ phone: `+${phoneNumber}`, verificationSubject : "STAFF" }).then(resp => {
      if (resp.verifiPhoneUsed?.data) {
        setisSignUpCounter(isSignUpCounter + 1)
      } else if (resp.payload?.error === "ERR_PARAM_INVALID") {
        setCodePhoneError(true)
      } else {
        setisLoginSuccess(false)
        setErrorCode("ERR_STAFF_004")
      }
    })
  }

  const switchTemplates = (name) => {
    switch (name) {
      // // case 'ERR_AUTH_000':
      // //   return (<UserBadCredentials goBackHandler={goBackHandler} />)
      // case 'ERR_STAFF_003':
      //   return (<UserAlreadyRegistered goBackHandler={goBackHandler} />)
      case 'ERR_STAFF_011':
        return (<UserBlocked goBackHandler={goBackHandler} />)
      case 'ERR_STAFF_004':
        return (<UserNotFound goBackHandler={goBackHandler} />)
      default: return ("")
    }
  }

  const lengthHandler = (e) => {
    setphoneNumberSuccess(e.target.value)
  }

  return (
    <div className="login-wrapper">
      <div className="login-inner">
            {isLoginSuccess && <Form
              name="restorePassword"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {isSignUpCounter === 0 && <>
                <h3 id="restore-title">{restorePasswordScreen.heading}</h3>
                <p id="restore-subtitle">{restorePasswordScreen.subtitle}</p>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: restorePasswordScreen.requiredFieldErrorMessage }
                  ]}
                >
                  <Input className="tel-input" prefix={"+"} placeholder={restorePasswordScreen.phonePlaceholder} onChange={lengthHandler} />
                </Form.Item>
                {codePhoneError ? <span className="small-size">{restorePasswordScreen.phoneError}</span> : ""}
                <div className="btn-wrapper">
                  <Button type="default">
                    <Link to="/">{restorePasswordScreen.backBtn}</Link>
                  </Button>
                  <Button type="primary" id="goNextStep2" onClick={goNextStepWithVerify} disabled={phoneNumber.length > 8 ? false : true}>
                    {restorePasswordScreen.confirmBtn}
                </Button>
                </div>
              </>}
              {isSignUpCounter === 1 &&
                <SignUpSmsStep
                  goNextStep={goNextStep}
                  goBackHandler={goBackHandler}
                  smsType={"RESTORE_PASSWORD"}
                  isLogo={false}
                  form={form}
                  title={restorePasswordScreen.smsStepTitle}
                  text={restorePasswordScreen.smsStepText}
                />}
              {isSignUpCounter === 2 &&
                <SignUpPasswordStep
                  isLogo={false}
                  title={restorePasswordScreen.passwordStepTitle}
                  text={restorePasswordScreen.passwordStepText}
                  passPlaceholder={restorePasswordScreen.passwordStepPassPlaceholder}
                />}
            </Form>}
            {switchTemplates(errorCode)}
      </div>
    </div>
  )
}

export default connector(RestorePasswordScreen);
