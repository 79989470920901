import { Button, Modal, Upload } from 'antd';
import React, { FC } from 'react';
import "./UploadPricesModal.scss";
import { ReactComponent as FolderSvg } from 'assets/images/ic-folder.svg'
import { connect } from 'react-redux';
import { sendPrices } from 'store/prices/actions'
import {marketPrices} from '../../globalConstants/textinputs.json';
interface IProps {
  isModalVisible: boolean;
  sendPrices: any;
  closeModal: (boolean) => void;
  users: any;
  renderPricesList: () => void;
}

const mapStateToProps = state => ({
  users: state.users
}); 

const mapDispatchToProps = {
  sendPrices
};

const connector = connect(mapStateToProps, mapDispatchToProps);


export const UploadPricesModal: FC<IProps> = (props) => {

  const [fileList, setfileList] = React.useState([]);

  const [isOverSize, setisOverSize] = React.useState(false);

  const [unknownFormat, setunknownFormat] = React.useState(false);

  const fileProps = {
    onRemove: file => {
      setfileList([])
      setisOverSize(false)
      setunknownFormat(false)
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        file.flag = true;
        setisOverSize(true)
        return false;
      }
      setisOverSize(false)
      setfileList([file])
      return false;
    }
  };

  const handleCancel = () => {
    props.closeModal(false);
  };

  const handleOk = () => {
    props.closeModal(false);
    props.renderPricesList()
  }

  const onFinish = () => {
    setunknownFormat(false)
    props.sendPrices(fileList).then(resp => {
      if(resp.type === "SEND_PRICE_SUCCESS") {
        handleOk()
      }
      else if(resp.payload?.error === "ERR_MARKET_PRICE_001") {
        setunknownFormat(true)
      }
    })
  };


  return (
    <Modal title={marketPrices.tableImportModalHeading}
      visible={props.isModalVisible}
      closable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="upload-prices-modal"
      centered={true}
    >
      <Upload
        maxCount={1}
        beforeUpload={fileProps.beforeUpload}
        onRemove={fileProps.onRemove}
        fileList={fileList}
        multiple={false}
        accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      >
        <Button icon={<FolderSvg />}>{marketPrices.tableImportModalOpenFile}</Button>
        {(!isOverSize && !unknownFormat) && <ul className="upload-rules-list">
          <li>{marketPrices.tableImportModalSizeFootnote}</li>
          <li>{marketPrices.tableImportModalFormatFootnote}</li>
        </ul>}
        {unknownFormat && <span className="small-size-normal">{marketPrices.tableImportModalErrorWrongFormat}</span>}
        {isOverSize && <span className="small-size-normal">{marketPrices.tableImportModalErrorFileTooLarge}</span>}
      </Upload>
      <div className="btn-wrapper">
        <Button type="default" className="btn" key={'2'} onClick={handleCancel}>
          {marketPrices.tableImportModalCloseBtn}
        </Button>
        <Button type="primary" className="btn" key={'1'} onClick={onFinish} disabled={fileList.length ? false : true}>
          {marketPrices.tableImportModalImportBtn}
        </Button>
      </div>
    </Modal>
  );
};

export default connector(UploadPricesModal);