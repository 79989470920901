import Api from "api";
import {
  ICreateStaffData, ISaveStaffScreenInfo, ISaveStaffScreenInfoAction,
  ISaveStaffScreenPage, ISaveStaffScreenPageAction,
  ISetSingleStaffFailureAction,
  ISetSingleStaffListAction,
  ISetSingleStaffPendingAction,
  ISetStaffFailureAction,
  ISetStaffListAction,
  ISetStaffPendingAction,
  IStaffArchiveFailureAction,
  IStaffArchivePendingAction,
  IStaffArchiveSuccessAction,
  IStaffRegData,
  IStaffRegisterFailureAction,
  IStaffRegisterPendingAction,
  IStaffRegisterSuccessAction,
  IStaffUpdateFailureAction,
  IStaffUpdatePendingAction,
  IStaffUpdateSuccessAction,
  IStaffUpdData,
  IStaffView,
  IUpdateStaffData
} from "./types";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

export const getStaffList:ActionCreator<
ThunkAction<
  Promise<ISetStaffListAction | ISetStaffPendingAction | ISetStaffFailureAction>,
  any,
  null,
  ISetStaffListAction
>
>  = (sortBy, sortType, page=0, size=15, rsql="") => {
  return async (dispatch: Dispatch) => {
    //let rsqli = `&search=role==ALL,phone=in=Руденко,email=in=Руденко,fio.firstName=in=Руденко,fio.lastName=in=Руденко,fio.patronymic=in=Руденко`
    const getStaffListPendingAction:ISetStaffPendingAction = {
      type: 'GET_STAFF_LIST_PENDING',
    };
    dispatch(getStaffListPendingAction);
    try {
      const response = await Api.get(`/core-service/staff?page=${page}&size=${size}&sort=${sortBy},${sortType}${rsql}`)
      const preview = response.data.data
      const getStaffListSuccessAction:ISetStaffListAction = {
        preview,
        type: 'GET_STAFF_LIST_SUCCESS',
      };
      return dispatch(getStaffListSuccessAction);
    } catch (error) {
      const getStaffListFailureAction: ISetStaffFailureAction = {
        type: 'GET_STAFF_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getStaffListFailureAction);
    }
  };
}

export const getSingleStaff:ActionCreator<
ThunkAction<
  Promise<ISetSingleStaffListAction | ISetSingleStaffPendingAction | ISetSingleStaffFailureAction>,
  any,
  null,
  ISetSingleStaffListAction
>
>  = (id) => {
  return async (dispatch: Dispatch) => {
    const getStaffListPendingAction:ISetSingleStaffPendingAction = {
      type: 'GET_SINGLE_STAFF_PENDING',
    };
    dispatch(getStaffListPendingAction);
    try {
      const response = await Api.get(`/core-service/staff/${id}`)
      const preview = response.data.data
      const getStaffListSuccessAction:ISetSingleStaffListAction = {
        preview,
        type: 'GET_SINGLE_STAFF_SUCCESS',
      };
      return dispatch(getStaffListSuccessAction);
    } catch (error) {
      const getStaffListFailureAction: ISetSingleStaffFailureAction = {
        type: 'GET_SINGLE_STAFF_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getStaffListFailureAction);
    }
  };
}

export const createStaff: ActionCreator<
  ThunkAction<
    Promise<IStaffRegisterSuccessAction | IStaffRegisterFailureAction>,
    IStaffRegData,
    ICreateStaffData,
    IStaffRegisterSuccessAction | IStaffRegisterFailureAction
  >
> = (data: ICreateStaffData) => {
  return async (dispatch: Dispatch) => {
    const createStaffPendingAction: IStaffRegisterPendingAction = {
      type: 'STAFF_REGISTER_PENDING',
    };
    dispatch(createStaffPendingAction);

    try {
      const staffDataResponse = await Api.post('/core-service/staff', { ...data });
      const staffData: IStaffRegData = staffDataResponse.data;
      const createStaffRegSuccessAction: IStaffRegisterSuccessAction = {
        staffData,
        type: 'STAFF_REGISTER_SUCCESS',
      };
      return dispatch(createStaffRegSuccessAction);
    } catch (error) {
      const createStaffFailureAction: IStaffRegisterFailureAction = {
        type: 'STAFF_REGISTER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(createStaffFailureAction);
    }
  };
};

export const updateStaff: ActionCreator<
  ThunkAction<
    Promise<IStaffUpdateSuccessAction | IStaffUpdateFailureAction>,
    IStaffUpdData,
    IUpdateStaffData,
    IStaffUpdateSuccessAction | IStaffUpdateFailureAction
  >
> = (id: number, data: IUpdateStaffData) => {
  return async (dispatch: Dispatch) => {
    const updateStaffPendingAction: IStaffUpdatePendingAction = {
      type: 'STAFF_UPDATE_PENDING',
    };
    dispatch(updateStaffPendingAction);

    try {
      const staffDataResponse = await Api.put(`/core-service/staff/${id}`, { ...data });
      const staffData: IStaffUpdData = staffDataResponse.data;
      const updateStaffRegSuccessAction: IStaffUpdateSuccessAction = {
        staffData,
        type: 'STAFF_UPDATE_SUCCESS',
      };
      return dispatch(updateStaffRegSuccessAction);
    } catch (error) {
      const updateStaffFailureAction: IStaffUpdateFailureAction = {
        type: 'STAFF_UPDATE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateStaffFailureAction);
    }
  };
};

export const getStaffViewType = (view:IStaffView) => {
  return (dispatchFunc) => {
    return dispatchFunc({
      type: "SET_STAFF_VIEW_SUCCESS",
      payload: view
    })
  }
};

export const archiveStaff: ActionCreator<
ThunkAction<
  Promise<IStaffArchiveSuccessAction | IStaffArchiveFailureAction>,
  any,
  any,
  IStaffArchiveSuccessAction | IStaffArchiveFailureAction
>
> = (staffId, data) => {
  return async (dispatch: Dispatch) => {
    const arhiveStaffPendingAction: IStaffArchivePendingAction = {
      type: "ARCHIVE_STAFF_PENDING",
    };
    dispatch(arhiveStaffPendingAction);
    try {
      const response = await Api.put(`/core-service/staff/${staffId}/status`, data)
      const preview: any = response.data.data
      const arhiveStaffSuccessAction: IStaffArchiveSuccessAction = {
        preview,
        type: "ARCHIVE_STAFF_SUCCESS",
      };
      return dispatch(arhiveStaffSuccessAction);
    } catch (error) {
      const arhiveStaffFailureAction: IStaffArchiveFailureAction = {
        type: "ARCHIVE_STAFF_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(arhiveStaffFailureAction);
    }
  }
}

export const saveStaffScreenPage = (staffScreenPage :ISaveStaffScreenPage) => {
  return (dispatch) : ISaveStaffScreenPageAction => {
    return dispatch({
      type: "SAVE_STAFF_SCREEN_PAGE_SUCCESS",
      payload: staffScreenPage
    })
  }
}

export const saveStaffScreenInfo = (staffScreenInfo :ISaveStaffScreenInfo) => {
  return (dispatch) : ISaveStaffScreenInfoAction => {
    return dispatch({
      type: "SAVE_STAFF_SCREEN_INFO_SUCCESS",
      payload: staffScreenInfo
    })
  }
}
