import { Button, Space, Table, Tooltip } from 'antd';
import ScreenHeaderList from 'components/ScreenHeaderList/ScreenHeaderList';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAgentsList, getAgentsViewType } from 'store/agents/actions';
import { removeAgent } from 'store/agents/actions';
import './AgentsScreen.scss';
import { ReactComponent as EditSvg } from 'assets/images/basic-edit.svg';
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { ReactComponent as BlockSvg } from 'assets/images/ic-delete.svg';
import { ReactComponent as UserAddSvg } from 'assets/images/ic-user-add.svg'
import {
  IAgentRemoveFailureAction,
  IAgentRemoveSuccessAction, IAgentView,
  ISetAgentsFailureAction,
  ISetAgentsListAction,
  ISetAgentsPendingAction,
  IsetAgentsViewAction
} from 'store/agents/types';
import RemoveModal from 'components/RemoveModal/RemoveModal';
import { agents } from "../../globalConstants/textinputs.json";
import AgentFilter from 'components/AgentFilter/AgentFilter';


interface IProps {
  getAgentsList: (sortBy: string, sortType: string, page: number, size: number, rsql?: string) => Promise<ISetAgentsListAction | ISetAgentsPendingAction | ISetAgentsFailureAction>;
  agents: any;
  user: any;
  getAgentsViewType: (data: IAgentView) => IsetAgentsViewAction;
  removeAgent: (id: number) => Promise<IAgentRemoveSuccessAction | IAgentRemoveFailureAction>;
  // unArchiveUser: (id: number) => Promise<IUserUnArchiveSuccessAction | IUserUnArchiveFailureAction>;
}

const mapStateToProps = state => ({
  agents: state.agents,
  user: state.user
});

const mapDispatchToProps = {
  getAgentsList,
  getAgentsViewType,
  removeAgent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AgentsScreen: FC<IProps> = (props) => {
  useEffect(() => {
    props.getAgentsList('id', 'desc', 0, 10)
  }, [])

  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);

  const [DeleteModalContent, setDeleteModalContent] = React.useState({ id: "" });

  const [hasSearched, setHasSearched] = React.useState(false);

  const changeSearchedState = (value) => {
    setHasSearched(value);
  }

  // const [rsql, setRsql] = React.useState("");

  const viewTypeHandler = (value) => (e) => {
    props.getAgentsViewType(value)
  }

  const actions = props.user?.userRole === "ROLE_ADMIN" ? {
    title: agents.list.tableColActions,
    render: (record) => {
      return (
        <Space size="middle">
          <>
            <Tooltip title={agents.list.tableColActionsEditTooltip}><Link to={`/dashboard/agents/${record.id}`} onClick={viewTypeHandler(true)}><EditSvg /></Link></Tooltip>
            <Tooltip title={agents.list.tableColActionsDeleteTooltip}>
              <Button type="link" onClick={deleteModalHandler(record.id)}>
                <BlockSvg />
              </Button>
            </Tooltip>
          </>
        </Space>
      )
    },
  } : {}

  const columns: Array<any> = [
    {
      title: agents.list.tableColNameLastName,
      sorter: true,
      showSorterTooltip: false,
      render: (record) => {
        return <Link to={`/dashboard/agents/${record.id}`} onClick={viewTypeHandler(false)}>
          {record?.lastName} {record?.firstName}
        </Link>
      },
      className: "title-col"
    },
    {
      title: agents.list.tableColRegion,
      dataIndex: 'region',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: agents.list.tableColDistrict,
      dataIndex: 'district',
    },
    {
      title: agents.list.tableColLocality,
      dataIndex: 'locality',
    },
    {
      title: agents.list.tableColPhone,
      dataIndex: 'phone',
      className: "phone-col"
    },
    {
      title: agents.list.tableColEmail,
      dataIndex: 'email',
      sorter: true,
      showSorterTooltip: false,
      className: "email-col"
    },
    actions
  ];

  const tableOnchangeHandler = (pagination: any, filter: any, sorter: any) => {
    let orderBy = sorter.order === "ascend" ? "asc" : "desc"
    if (sorter.field === "fio") {
      sorter.field = "fio.lastName"
    }
    props.getAgentsList(sorter.field || "id", orderBy || "desc", pagination.current - 1, pagination.pageSize)
  }

  // const filterSearch = (rsqlString) => {
  //   setRsql(rsqlString)
  //   props.getAgentsList('id', 'desc', 0, 10, rsqlString)
  // }

  const deleteModalHandler = (id) => (e) => {
    setIsDeleteModalVisible(!isDeleteModalVisible)
    setDeleteModalContent({ id: id })
  }

  const removeHandlerCallback = (id) => {
    props.removeAgent(id).then(resp => {
      props.getAgentsList('id', 'desc', 0, 10)
    })
  }

  return (
    <div>
      <RemoveModal
        isSaved={true}
        title={agents.deleteModal.heading}
        isModalVisible={isDeleteModalVisible}
        closeModal={setIsDeleteModalVisible}
        img={<WarningSvg />}
        text={<span>{agents.deleteModal.messagePartOne}<br />{agents.deleteModal.messagePartTwo}</span>}
        cancelBtnText={agents.deleteModal.cancelBtn}
        successBtnText={agents.deleteModal.deleteBtn}
        id={DeleteModalContent.id}
        removeAgentHandler={removeHandlerCallback}
      />

      <ScreenHeaderList
        title={agents.list.mainHeading}
        isCreate={true}
        buttonText={agents.list.tableAddBtn}
        btnImage={<UserAddSvg />}
        path={'agents'}
      />
      <AgentFilter changeSearchedState={changeSearchedState} />
      {/* <ListSearch
        role={"user"}
        filterSearch={filterSearch}
      /> */}
      <Table
        className="agents-table"
        columns={columns}
        dataSource={props.agents.results?.content || []}
        onChange={tableOnchangeHandler}
        pagination={{
          defaultPageSize: 10,
          total: props.agents.results?.totalElements,
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '30'],
          position: ["bottomCenter"],
        }}
        loading={props.agents.isFetching}
      />
    </div>
  );
};

export default connector(AgentsScreen);
