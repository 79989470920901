import { Button } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as ErrorSvg } from '../../assets/images/ic-error.svg'
import {userBlockedScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  goBackHandler: () => void;
}

const UserBlocked: FC<IProps> = (props) => {
  return (
    <div id="userNotFound">
      <h3>{userBlockedScreen.title}</h3>
      <ErrorSvg className="error-img" />
      <p>{userBlockedScreen.messagePartOne}<br/>
      {userBlockedScreen.messagePartTwo}</p>
      <div className="btn-wrapper">
        <Button id="back-btn" type="default" onClick={props.goBackHandler}>{userBlockedScreen.backBtn}</Button>
      </div>
    </div>
  );
};

export default UserBlocked;