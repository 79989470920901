import Api from "api";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  IDeleteSingleContractDocumentFailureAction,
  IDeleteSingleContractDocumentPendingAction,
  IDeleteSingleContractDocumentSuccessAction,
  IDownloadSingleContractDocumentFailureAction,
  IDownloadSingleContractDocumentPendingAction,
  IDownloadSingleContractDocumentSuccessAction, IEditContractIdentityDocumentsFailureAction,
  IEditContractIdentityDocumentsPendingAction,
  IEditContractIdentityDocumentsSuccessAction,
  IGetContractBuyerDocumentsFailureAction,
  IGetContractBuyerDocumentsPendingAction,
  IGetContractBuyerDocumentsSuccessAction,
  IGetContractSellerDocumentsFailureAction,
  IGetContractSellerDocumentsPendingAction,
  IGetContractSellerDocumentsSuccessAction,
  IGetNewContractsFailureAction,
  IGetNewContractsPendingAction,
  IGetNewContractsSuccessAction,
  IgetSingleContractFailureAction,
  IgetSingleContractPendingAction,
  IgetSingleContractSuccessAction,
  IPostContractDocumentFailureAction,
  IPostContractDocumentPendingAction,
  IPostContractDocumentSuccessAction,
  ISaveContractScreenInfoAction,
  ISaveContractScreenPage,
  IsetContractsFailureAction,
  IsetContractsListAction,
  IsetContractsPendingAction
} from "./types";

export const getContractsList: ActionCreator<
  ThunkAction<
    Promise<IsetContractsListAction | IsetContractsPendingAction | IsetContractsFailureAction>,
    any,
    null,
    IsetContractsListAction
  >
> = (sortBy, sortType, page = 0, size = 15, rsql = "") => {
  return async (dispatch: Dispatch) => {
    //let rsqli = `&search=role==ALL,phone=in=Руденко,email=in=Руденко,fio.firstName=in=Руденко,fio.lastName=in=Руденко,fio.patronymic=in=Руденко`
    const getAdvertListPendingAction: IsetContractsPendingAction = {
      type: 'GET_CONTRACTS_LIST_PENDING',
    };
    dispatch(getAdvertListPendingAction);
    try {
      const response = await Api.get(`/core-service/deals/?page=${page}&size=${size}&sort=${sortBy},${sortType}${rsql}`)
      const preview = response.data.data
      console.log(preview)
      const getAdvertListSuccessAction: IsetContractsListAction = {
        preview,
        type: 'GET_CONTRACTS_LIST_SUCCESS',
      };
      return dispatch(getAdvertListSuccessAction);
    } catch (error) {
      const getAdvertListFailureAction: IsetContractsFailureAction = {
        type: 'GET_CONTRACTS_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getAdvertListFailureAction);
    }
  };
}

export const getSingleContract: ActionCreator<
    ThunkAction<
        Promise<IgetSingleContractSuccessAction | IgetSingleContractPendingAction | IgetSingleContractFailureAction>,
        any,
        null,
        IgetSingleContractSuccessAction
        >
    > = (id) => {
  return async (dispatch: Dispatch) => {
    const getSingleContractPendingAction: IgetSingleContractPendingAction = {
      type: 'GET_SINGLE_CONTRACT_PENDING',
    };
    dispatch(getSingleContractPendingAction);
    try {
      const response = await Api.get(`/core-service/deals/${id}`)
      const preview = response.data.data
      // console.log(preview)
      const getSingleContractSuccessAction: IgetSingleContractSuccessAction = {
        preview,
        type: 'GET_SINGLE_CONTRACT_SUCCESS',
      };
      return dispatch(getSingleContractSuccessAction);
    } catch (error) {
      const getSingleContractFailureAction: IgetSingleContractFailureAction = {
        type: 'GET_SINGLE_CONTRACT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getSingleContractFailureAction);
    }
  };
}

export const updateSingleContract = (id: string, documentsVerified: boolean = false) => {
  return async (dispatch) => {
    const UpdateSingleContractPendingAction = {
      type: 'UPDATE_SINGLE_CONTRACT_PENDING',
    };

    dispatch(UpdateSingleContractPendingAction);

    try {
      await Api.put(`/core-service/deals/${id}`, {
        documentsVerified,
      });

      const UpdateSingleContractSuccessAction = {
        type: 'UPDATE_SINGLE_CONTRACT_SUCCESS',
      };

      return dispatch(UpdateSingleContractSuccessAction)
    } catch (error) {
      console.log(error);

      const UpdateSingleContractFailureAction = {
        type: 'UPDATE_SINGLE_CONTRACT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(UpdateSingleContractFailureAction);
    }
  };
}

export const updateSingleContractStatus = ({ dealId, dealStatus, prepaymentStatus, prepaymentAmount }: { dealId: string; dealStatus: string; prepaymentStatus: string; prepaymentAmount?: string }) => {
  return async (dispatch) => {
    const UpdateSingleContractStatusPendingAction = {
      type: 'UPDATE_SINGLE_CONTRACT_STATUS_PENDING',
    };

    dispatch(UpdateSingleContractStatusPendingAction);

    try {
      await Api.patch(`/core-service/deals/${dealId}/status`, {
        dealStatus,
      });
      await Api.patch(`/core-service/deals/${dealId}/prepayment`, {
        prepayment: Number(prepaymentAmount),
        prepaymentStatus
      })

      const UpdateSingleContractStatusSuccessAction = {
        type: 'UPDATE_SINGLE_CONTRACT_STATUS_SUCCESS',
      };

      return dispatch(UpdateSingleContractStatusSuccessAction)
    } catch (error) {
      console.log(error);

      const UpdateSingleContractStatusFailureAction = {
        type: 'UPDATE_SINGLE_CONTRACT_STATUS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(UpdateSingleContractStatusFailureAction);
    }
  };
}

export const cancelSingleContract = (id, data) => {
  return async (dispatch) => {
    const CancelSingleContractPendingAction = {
      type: 'CANCEL_SINGLE_CONTRACT_PENDING',
    };
    dispatch(CancelSingleContractPendingAction);
    try {
      await Api.delete(`/core-service/deals/${id}`, {data: {...data}})
      const CancelSingleContractSuccessAction = {
        type: 'CANCEL_SINGLE_CONTRACT_SUCCESS',
      };
      return dispatch(CancelSingleContractSuccessAction)
    } catch (error) {
      console.log(error)
      const CancelSingleContractFailureAction = {
        type: 'CANCEL_SINGLE_CONTRACT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(CancelSingleContractFailureAction);
    }
  };
}

export const getNewContractsCount: ActionCreator<
    ThunkAction<
        Promise<IGetNewContractsSuccessAction | IGetNewContractsPendingAction | IGetNewContractsFailureAction>,
        any,
        null,
        IGetNewContractsSuccessAction
        >
    > = () => {
  return async (dispatch: Dispatch) => {
    const getNewContractsCountPendingAction: IGetNewContractsPendingAction = {
      type: 'GET_NEW_CONTRACTS_COUNT_PENDING',
    };
    dispatch(getNewContractsCountPendingAction);
    try {
      const response = await Api.get(`/core-service/deals/statistics`)
      const statusNewCount = response.data.data.newDealsCount
      // console.log(statusNewCount)
      const getNewContractsCountSuccessAction: IGetNewContractsSuccessAction = {
        type: 'GET_NEW_CONTRACTS_COUNT_SUCCESS',
        payload: statusNewCount,
      };
      return dispatch(getNewContractsCountSuccessAction);
    } catch (error) {
      const getNewContractsCountFailureAction: IGetNewContractsFailureAction = {
        type: 'GET_NEW_CONTRACTS_COUNT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getNewContractsCountFailureAction);
    }
  };
}

export const getContractDocuments: ActionCreator<
    ThunkAction<
        Promise<IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction | IGetContractSellerDocumentsPendingAction | IGetContractBuyerDocumentsPendingAction | IGetContractSellerDocumentsFailureAction
            | IGetContractBuyerDocumentsFailureAction>,
        any,
        null,
        IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction
        >
    > = (id: number, owner: string) => {
  return async (dispatch: Dispatch) => {

    const getContractSellerDocumentsPendingAction: IGetContractSellerDocumentsPendingAction = {
      type: 'GET_CONTRACT_SELLER_DOCUMENTS_PENDING',
    };

    const getContractBuyerDocumentsPendingAction: IGetContractBuyerDocumentsPendingAction = {
      type: 'GET_CONTRACT_BUYER_DOCUMENTS_PENDING',
    };
    dispatch(owner === 'SELLER' ? getContractSellerDocumentsPendingAction: getContractBuyerDocumentsPendingAction);
    try {
      const response = await Api.get(`/core-service/deals/${id}/documents?owner=${owner}`)
      const documents = response.data.data
      console.log(documents)
      const getContractSellerDocumentsSuccessAction: IGetContractSellerDocumentsSuccessAction = {
        type: 'GET_CONTRACT_SELLER_DOCUMENTS_SUCCESS',
        payload: documents
      };

      const getContractBuyerDocumentsSuccessAction: IGetContractBuyerDocumentsSuccessAction = {
        type: 'GET_CONTRACT_BUYER_DOCUMENTS_SUCCESS',
        payload: documents
      };
      return dispatch(owner === 'SELLER' ? getContractSellerDocumentsSuccessAction: getContractBuyerDocumentsSuccessAction);
    } catch (error) {

      const IGetContractSellerDocumentsFailureAction: IGetContractSellerDocumentsFailureAction = {
        type: 'GET_CONTRACT_SELLER_DOCUMENTS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      const IGetContractBuyerDocumentsFailureAction: IGetContractBuyerDocumentsFailureAction = {
        type: 'GET_CONTRACT_BUYER_DOCUMENTS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(owner === 'SELLER' ? IGetContractSellerDocumentsFailureAction: IGetContractBuyerDocumentsFailureAction);
    }
  };
}

export const postContractDocuments: ActionCreator<
    ThunkAction<
        Promise<IPostContractDocumentSuccessAction | IPostContractDocumentFailureAction>,
        any,
        any,
        IPostContractDocumentSuccessAction | IPostContractDocumentFailureAction
        >
    >  = (activeDealID, documentData, owner, type) => {
  return async (dispatch: Dispatch) => {
    const postContractDocumentsPendingAction: IPostContractDocumentPendingAction = {
      type: 'POST_CONTRACT_DOCUMENT_PENDING',
    };
    dispatch(postContractDocumentsPendingAction);

    try {
      let formData = new FormData();
      console.log(documentData[0])
      formData.append("file", documentData[0]);
      console.log(formData);

      const authDataResponse = await Api.post(`/core-service/deals/${activeDealID}/documents?owner=${owner}&type=${type}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const preview = authDataResponse.data
      const postContractDocumentsSuccessAction:IPostContractDocumentSuccessAction = {
        type: 'POST_CONTRACT_DOCUMENT_SUCCESS',
        preview
      };
      return dispatch(postContractDocumentsSuccessAction);
    } catch (error) {

      const postContractDocumentsFailureAction: IPostContractDocumentFailureAction = {
        type: 'POST_CONTRACT_DOCUMENT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(postContractDocumentsFailureAction);
    }
  };
};

export const downloadSingleContractDocument:ActionCreator<
    ThunkAction<
        Promise<IDownloadSingleContractDocumentSuccessAction | IDownloadSingleContractDocumentPendingAction | IDownloadSingleContractDocumentFailureAction>,
        any,
        null,
        IDownloadSingleContractDocumentSuccessAction
        >
    >  = (dealID, documentID) => {
  return async (dispatch: Dispatch) => {
    const downloadSingleContractDocumentPendingAction:IDownloadSingleContractDocumentPendingAction = {
      type: 'DOWNLOAD_SINGLE_CONTRACT_DOCUMENT_PENDING',
    };
    dispatch(downloadSingleContractDocumentPendingAction);
    try {
      const response = await Api.get(`/core-service/deals/${dealID}/documents/${documentID}`, {responseType: "blob"})
      const preview = response.data
      const downloadSingleContractDocumentSuccessAction:IDownloadSingleContractDocumentSuccessAction = {
        preview,
        type: 'DOWNLOAD_SINGLE_CONTRACT_DOCUMENT_SUCCESS',
      };
      dispatch(downloadSingleContractDocumentSuccessAction);
      return preview;
    } catch (error) {
      const downloadSingleContractDocumentFailureAction: IDownloadSingleContractDocumentFailureAction = {
        type: 'DOWNLOAD_SINGLE_CONTRACT_DOCUMENT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(downloadSingleContractDocumentFailureAction);
    }
  };
}

export const deleteSingleContractDocument: ActionCreator<
    ThunkAction<
        Promise<IDeleteSingleContractDocumentSuccessAction | IDeleteSingleContractDocumentFailureAction>,
        any,
        any,
        IDeleteSingleContractDocumentSuccessAction | IDeleteSingleContractDocumentFailureAction
        >
    > = (dealID, documentID, owner) => {
  return async (dispatch: Dispatch) => {
    const deleteSingleContractDocumentPendingAction: IDeleteSingleContractDocumentPendingAction = {
      type: "DELETE_SINGLE_CONTRACT_DOCUMENT_PENDING",
    };
    dispatch(deleteSingleContractDocumentPendingAction);
    try {
      const response = await Api.delete(`/core-service/deals/${dealID}/documents/${documentID}?owner=${owner}`)
      const preview: any = response.data.data
      const deleteSingleContractDocumentSuccessAction: IDeleteSingleContractDocumentSuccessAction = {
        preview,
        type: "DELETE_SINGLE_CONTRACT_DOCUMENT_SUCCESS",
      };
      return dispatch(deleteSingleContractDocumentSuccessAction);
    } catch (error) {
      const deleteSingleContractDocumentFailureAction: IDeleteSingleContractDocumentFailureAction = {
        type: "DELETE_SINGLE_CONTRACT_DOCUMENT_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(deleteSingleContractDocumentFailureAction);
    }
  }
}

export const editContractIdentityDocuments:ActionCreator<
    ThunkAction<
        Promise<IEditContractIdentityDocumentsSuccessAction | IEditContractIdentityDocumentsPendingAction | IEditContractIdentityDocumentsFailureAction>,
        any,
        null,
        IEditContractIdentityDocumentsSuccessAction
        >
    >  = (dealID, body) => {
  return async (dispatch: Dispatch) => {
    const editContractIdentityDocumentsPendingAction:IEditContractIdentityDocumentsPendingAction = {
      type: 'EDIT_CONTRACT_IDENTITY_DOCUMENTS_PENDING',
    };
    dispatch(editContractIdentityDocumentsPendingAction);
    try {
      const response = await Api.put(`/core-service/deals/${dealID}/identity-documents/`, body)
      const preview = response.data
      const editContractIdentityDocumentsSuccessAction:IEditContractIdentityDocumentsSuccessAction = {
        preview,
        type: 'EDIT_CONTRACT_IDENTITY_DOCUMENTS_SUCCESS',
      };
      dispatch(editContractIdentityDocumentsSuccessAction);
      return preview;
    } catch (error) {
      const editContractIdentityDocumentsFailureAction: IEditContractIdentityDocumentsFailureAction = {
        type: 'EDIT_CONTRACT_IDENTITY_DOCUMENTS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(editContractIdentityDocumentsFailureAction);
    }
  };
}

export const saveContractScreenPage = (contractScreenPage :ISaveContractScreenPage) => {
  return (dispatch) : ISaveContractScreenInfoAction => {
    return dispatch({
      type: "SAVE_CONTRACT_SCREEN_PAGE_SUCCESS",
      payload: contractScreenPage
    })
  }
}
