import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import './PrepaymentChangedModal.scss';
import {ReactComponent as SuccessImg} from "../../assets/images/ic-success.svg";
import {contracts} from '../../globalConstants/textinputs.json';
import {getNewContractsCount, updateSingleContract, updateSingleContractStatus} from "../../store/contracts/actions";
import {connect} from "react-redux";
import {
    IGetNewContractsFailureAction,
    IGetNewContractsSuccessAction,
    IUpdateSingleContractFailureAction,
    IUpdateSingleContractPendingAction,
    IUpdateSingleContractStatusFailureAction,
    IUpdateSingleContractStatusPendingAction,
    IUpdateSingleContractStatusSuccessAction,
    IUpdateSingleContractSuccessAction
} from "../../store/contracts/types";

interface IProps {
    isModalVisible: boolean;
    setModalVisibility: (value: boolean) => void;
    setEditData: (value: any) => void;
    handlePrepaymentChangeCancel: () => void;
    editData: any;
    dealStatus: string;
    setDealStatus: (value: string) => void;
    dealID: string;
    edditable?: boolean;
    getNewContractsCount: () => Promise<IGetNewContractsSuccessAction | IGetNewContractsFailureAction>;
    fetchSingleContract: () => void;
    updateSingleContract: (id: string, documentsVerified: boolean) => Promise<IUpdateSingleContractSuccessAction | IUpdateSingleContractPendingAction | IUpdateSingleContractFailureAction>;
    updateSingleContractStatus: ({}: { dealId: string; dealStatus: string; prepaymentStatus: string; prepaymentAmount?: string }) => Promise<IUpdateSingleContractStatusSuccessAction | IUpdateSingleContractStatusPendingAction | IUpdateSingleContractStatusFailureAction>;
}

const mapDispatchToProps = {
    updateSingleContract,
    updateSingleContractStatus,
    getNewContractsCount
};

const connector = connect(null, mapDispatchToProps);

export const PrepaymentChangedModal: FC<IProps> = (props) => {

    const [isOkayDisabled, setIsOkayDisabled] = React.useState(false);

    const {updateSingleContract, updateSingleContractStatus, getNewContractsCount} = props;

    const onFinish = () => {
        setIsOkayDisabled(true);

        props.edditable && updateSingleContract(props.dealID, props.editData.documentsVerified)
        updateSingleContractStatus({ dealId: props.dealID, dealStatus: props.dealStatus, prepaymentStatus: props.editData.prepaymentStatus, prepaymentAmount: props.editData.prepayment }).then(() => {
            props.setModalVisibility(false);
            setIsOkayDisabled(false);
            props.setEditData({});
            props.setDealStatus("");
            props.fetchSingleContract();
            getNewContractsCount();
        });
    }

    return (
        <div>
            <Modal title={contracts.prepaymentChangedModal.title}
                   className="prepayment-changed-modal"
                   closable={false}
                   visible={props.isModalVisible}
                   footer={null}
                   onCancel={props.handlePrepaymentChangeCancel}
                   onOk={onFinish}
                   centered={true}
                   width={500}
            >
                <div className="modal-body-inner">
                    <SuccessImg/>
                    <p className="modal-warning">{contracts.prepaymentChangedModal.warning}</p>
                </div>

                <div className="btn-wrapper">
                    <div className="btn-inner">
                        <Button type="default" className="btn" key={'1'} onClick={props.handlePrepaymentChangeCancel}>
                            {contracts.prepaymentChangedModal.cancelBtn}
                        </Button>
                    </div>
                    <div className="btn-inner">
                        <Button type="primary" className="btn" key={'2'} onClick={onFinish} disabled={!props.editData || !props.dealStatus || isOkayDisabled}>
                            {contracts.prepaymentChangedModal.okayBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connector(PrepaymentChangedModal);
