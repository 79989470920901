import Api from "../../api";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ISetDistrictFailureAction, ISetDistrictListAction, ISetDistrictPendingAction, ISetLocalityFailureAction, ISetLocalityListAction, ISetLocalityPendingAction, ISetLocationDistrictFailureAction, ISetLocationDistrictListAction, ISetLocationDistrictPendingAction, ISetLocationLocalityFailureAction, ISetLocationLocalityListAction, ISetLocationLocalityPendingAction } from "./types";

let config = {
  headers: {
    lang: 'UA'
  },
};

export const getDistrictAdress: ActionCreator<
  ThunkAction<
    Promise<ISetDistrictListAction | ISetDistrictPendingAction | ISetDistrictFailureAction>,
    any,
    null,
    ISetDistrictListAction
  >
> = (region) => {
  return async (dispatch: Dispatch) => {

    const getDistrictAdressPendingAction: ISetDistrictPendingAction = {
      type: 'GET_DISTRICT_PENDING',
    };
    dispatch(getDistrictAdressPendingAction);

    try {
      const LocalityResponse = await Api.get(`/core-service/address/district?search=region%3D%3D${region}`, config);
      const LocalityData = LocalityResponse.data;
      console.log(LocalityData)
      const getDistrictAdressSuccessAction: ISetDistrictListAction = {
        LocalityData,
        type: 'GET_DISTRICT_SUCCESS',
      };
      return dispatch(getDistrictAdressSuccessAction);
    } catch (error) {
      const getDistrictAdressFailureAction: ISetDistrictFailureAction = {
        type: 'GET_DISTRICT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getDistrictAdressFailureAction);
    }
  };
};

export const getLocalityAdress: ActionCreator<
  ThunkAction<
    Promise<ISetLocalityListAction | ISetLocalityPendingAction | ISetLocalityFailureAction>,
    any,
    null,
    ISetDistrictListAction
  >
> = (region, district,) => {
  return async (dispatch: Dispatch) => {

    const getDistrictAdressPendingAction: ISetLocalityPendingAction = {
      type: 'GET_LOCALITY_PENDING',
    };
    dispatch(getDistrictAdressPendingAction);

    try {
      const LocalityResponse = await Api.get(`/core-service/address/locality?search=region%3D%3D${region};district%3D%3D${district}*`, config);
      const LocalityData = LocalityResponse.data;
      console.log(LocalityData)
      const getDistrictAdressSuccessAction: ISetLocalityListAction = {
        LocalityData,
        type: 'GET_LOCALITY_SUCCESS',
      };
      return dispatch(getDistrictAdressSuccessAction);
    } catch (error) {
      const getDistrictAdressFailureAction: ISetLocalityFailureAction = {
        type: 'GET_LOCALITY_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getDistrictAdressFailureAction);
    }
  };
};

export const getLocationDistrictAdress: ActionCreator<
  ThunkAction<
    Promise<ISetLocationDistrictListAction | ISetLocationDistrictPendingAction | ISetLocationDistrictFailureAction>,
    any,
    null,
    ISetLocationDistrictListAction
  >
> = (region) => {
  return async (dispatch: Dispatch) => {

    const getDistrictAdressPendingAction: ISetLocationDistrictPendingAction = {
      type: 'GET_LOCATION_DISTRICT_PENDING',
    };
    dispatch(getDistrictAdressPendingAction);

    try {
      const searchAllDistrictsUrl = '/core-service/land-location/district'
      const searchSingleRegionDistrictsUrl = `/core-service/land-location/district?search=registryAddress.region%3D%3D"${region}"`
      const LocalityResponse = await Api.get(region ? searchSingleRegionDistrictsUrl : searchAllDistrictsUrl);
      const LocalityData = LocalityResponse.data;
      const getDistrictAdressSuccessAction: ISetLocationDistrictListAction = {
        LocalityData,
        type: 'GET_LOCATION_DISTRICT_SUCCESS',
      };
      return dispatch(getDistrictAdressSuccessAction);
    } catch (error) {
      const getDistrictAdressFailureAction: ISetLocationDistrictFailureAction = {
        type: 'GET_LOCATION_DISTRICT_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getDistrictAdressFailureAction);
    }
  };
};

export const getLocationLocalityAdress: ActionCreator<
  ThunkAction<
    Promise<ISetLocationLocalityListAction | ISetLocationLocalityPendingAction | ISetLocationLocalityFailureAction>,
    any,
    null,
    ISetLocationLocalityListAction
  >
> = (region, district) => {
  return async (dispatch: Dispatch) => {

    const getDistrictAdressPendingAction: ISetLocationLocalityPendingAction = {
      type: 'GET_LOCATION_LOCALIY_PENDING',
    };
    dispatch(getDistrictAdressPendingAction);

    try {
      const LocalityResponse = await Api.get(`/core-service/land-location/location?search=registryAddress.region%3D%3D"${region}";registryAddress.district%3D%3D"${district}"`);
      const LocalityData = LocalityResponse.data;
      console.log(LocalityData)
      const getDistrictAdressSuccessAction: ISetLocationLocalityListAction = {
        LocalityData,
        type: 'GET_LOCATION_LOCALIY_SUCCESS',
      };
      return dispatch(getDistrictAdressSuccessAction);
    } catch (error) {
      const getDistrictAdressFailureAction: ISetLocationLocalityFailureAction = {
        type: 'GET_LOCATION_LOCALIY_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getDistrictAdressFailureAction);
    }
  };
};