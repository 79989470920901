import React, { FC } from 'react';

import { Form, Button, Input } from 'antd';
import { ReactComponent as LightLogo } from "../../assets/images/lightLogo.svg"
import { registerUser } from 'store/user/actions';
import { connect } from 'react-redux';
import { validatePhone } from 'services';
import { Link } from 'react-router-dom';
import SignUpSmsStep from 'components/SignUpSmsStep/SignUpSmsStep';
import { setPhoneNumber, verificationPhone } from 'store/verificationSms/actions';
import SignUpPasswordStep from 'components/SignUpPasswordStep/SignUpPasswordStep';
import { IPhoneNumber, IverificationPhoneAction, IverificationPhoneFailureAction, IVerificationPhonePostData, PhoneActions } from 'store/verificationSms/types';
import { IregisterUserSuccessAction } from 'store/user/types';
import UserNotFound from 'components/UserNotFound/UserNotFound';
import UserAlreadyRegistered from 'components/UserAlreadyRegistered/UserAlreadyRegistered';
import { useForm } from 'antd/lib/form/Form';
import { getTokenData } from 'store/user/actions';
import {signUpScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  registerUser: (data: any) => Promise<any>;
  history?: any;
  setPhoneNumber: (data: any) => PhoneActions;
  regInfo: { [key: string]: string };
  verificationPhone: (data: IVerificationPhonePostData) => Promise<IverificationPhoneAction | IverificationPhoneFailureAction>;
  getTokenData: any;
}

const mapStateToProps = state => ({
  regInfo: state.regInfo,
});

const mapDispatchToProps = {
  registerUser,
  setPhoneNumber,
  verificationPhone,
  getTokenData
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const SignUpScreen: FC<IProps> = ({ registerUser, history, ...props }) => {

  const [form] = useForm();

  // const [isExpressReg, setIsExpressReg] = React.useState<boolean>(true);

  const [isSignUpCounter, setisSignUpCounter] = React.useState<number>(0);

  const [phoneNumber, setphoneNumberSuccess] = React.useState<string>("");

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [isLoginSuccess, setisLoginSuccess] = React.useState<boolean>(true);

  const [codePhoneError, setCodePhoneError] = React.useState<boolean>(false);

  const onFinish = (values) => {

    registerUser({ phone: props.regInfo.phone, verificationCode: props.regInfo.code, password: values.password }).then(regData => {
      if (regData.status == "OK") {
        props.getTokenData(regData.data.token)
        history.push('/dashboard/contracts');
      }
    })
  };
  const onFinishFailed = (errorInfo) => {
  };

  const goBackHandler = () => {
    setErrorCode("")
    setisLoginSuccess(true)
    setisSignUpCounter(0)
  }

  const goNextStep = () => {
    setisSignUpCounter(isSignUpCounter + 1)
    props.setPhoneNumber(`+${phoneNumber}`)
  }

  const goNextStepWithVerify = () => {
    props.setPhoneNumber(`+${phoneNumber}`)
    props.verificationPhone({ phone: `+${phoneNumber}` }).then(resp => {
      if (resp.verifiPhone?.status === "OK") {
        setisSignUpCounter(isSignUpCounter + 1)
      } else if (resp.payload.error === "ERR_PARAM_INVALID") {
        setCodePhoneError(true)
      } else {
        setisLoginSuccess(false)
        setErrorCode(resp.payload.error)
      }
    })
  }

  const switchTemplates = (name) => {
    switch (name) {
      // case 'ERR_AUTH_000':
      //   return (<UserBadCredentials goBackHandler={goBackHandler} />)
      case 'ERR_STAFF_003':
        return (<UserAlreadyRegistered goBackHandler={goBackHandler} />)
      case 'ERR_STAFF_004':
        return (<UserNotFound goBackHandler={goBackHandler} />)
      default: return ("")
    }
  }

  const lengthHandler = (e) => {
    setphoneNumberSuccess(e.target.value)
  }

  return (
    <div className="login-wrapper">
      <div className="login-inner">
            {isLoginSuccess && <Form
              form={form}
              name="signUp"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {isSignUpCounter === 0 && <>
                <div className="img-wrapper">
                  <LightLogo />
                </div>
                <h3 id="registration-title">{signUpScreen.heading}</h3>
                <p id="registration-subtitle">{signUpScreen.subtitle}</p>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: signUpScreen.requiredFieldErrorMessage }
                  ]}
                >
                  <Input className="tel-input" prefix={"+"} placeholder={signUpScreen.phonePlaceholder} onChange={lengthHandler} />
                </Form.Item>
                {codePhoneError ? <span className="small-size">{signUpScreen.phoneError}</span> : ""}
                <div className="btn-wrapper">
                  <Button type="default">
                    <Link to="/">{signUpScreen.enterBtn}</Link>
                  </Button>
                  <Button type="primary" id="goNextStep2" onClick={goNextStepWithVerify} disabled={phoneNumber.length > 8 ? false : true}>
                    {signUpScreen.confirmBtn}
                </Button>
                </div>
              </>}
              {/* {!isExpressReg ? <p className="login-error">Неверный логин или пароль!</p> : null} */}
              {isSignUpCounter === 1 &&
                <SignUpSmsStep
                  goNextStep={goNextStep}
                  goBackHandler={goBackHandler}
                  smsType={"REGISTRATION"}
                  form={form}
                  isLogo={true}
                  title={signUpScreen.smsStepTitle}
                  text={signUpScreen.smsStepText}
                />}
              {isSignUpCounter === 2 &&
                <SignUpPasswordStep
                  isLogo={true}
                  title={signUpScreen.passwordStepTitle}
                  text={signUpScreen.passwordStepText}
                  passPlaceholder={signUpScreen.passwordStepPassPlaceholder}
                />}
            </Form>}
            {switchTemplates(errorCode)}
      </div>
    </div>
  )
}

export default connector(SignUpScreen);
