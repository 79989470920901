import {Button, Form, Input, Select} from 'antd';
import {ReactComponent as SearchSmallSvg} from 'assets/images/ic-search-small.svg';
import {ReactComponent as SearchWhiteSvg} from 'assets/images/ic-search-w.svg';
import React, {FC} from 'react';
import {connect} from "react-redux";
import {components, roles, users} from '../../globalConstants/textinputs.json';
import {saveStaffScreenInfo} from "../../store/staff/actions";
import {ISaveStaffScreenInfo, ISaveStaffScreenInfoAction} from "../../store/staff/types";
import {saveUsersScreenInfo} from "../../store/users/actions";
import {ISaveUsersScreenInfo, ISaveUsersScreenInfoAction} from "../../store/users/types";
import "./ListSearch.scss";

const { Option } = Select;

interface IProps {
  filterSearch: any;
  role: string;
  currentPage?: number;
  setCurrentPage?: (value: number) => any;
  saveUsersScreenInfo?: (usersScreenInfo :ISaveUsersScreenInfo) => ISaveUsersScreenInfoAction;
  saveStaffScreenInfo?: (staffScreenInfo :ISaveStaffScreenInfo) => ISaveStaffScreenInfoAction;
  staff: any;
  users: any;
}

const mapDispatchToProps = {
  saveUsersScreenInfo,
  saveStaffScreenInfo
};

const mapStateToProps = state => ({
  users: state.users,
  staff: state.staff
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ListSearch: FC<IProps> = (props) => {

  const [role, setRole] = React.useState(() => {
    return props.role === "staff" ? props.staff.staffScreenInfo.role : props.users.usersScreenInfo.role;
  });
  const [inputValue, setInputValue] = React.useState(() => {
    return props.role === "staff" ? props.staff.staffScreenInfo.inputValue : props.users.usersScreenInfo.inputValue;
  });

  const onFinish = (values: any) => {
    props.setCurrentPage(0);
    let rsqlString = ""
    if (!values.userName) {
      values.userName=""
    }
    if(values.userName.includes('+')) {
      values.userName = values.userName.replace(/\+/g, "")
    }
    if (props.role === "staff") {
      if (values.userRole !== "ALL") {
        rsqlString = `&search=role%3D%3D${values.userRole};(phone%3D%3D*${values.userName}*,position%3D%3D*${values.userName}*,email%3D%3D*${values.userName}*,fio.firstName%3D%3D*${values.userName}*,fio.lastName%3D%3D*${values.userName}*,fio.patronymic%3D%3D*${values.userName}*)`
      } else {
        rsqlString = `&search=phone%3D%3D*${values.userName}*,position%3D%3D*${values.userName}*,email%3D%3D*${values.userName}*,fio.firstName%3D%3D*${values.userName}*,fio.lastName%3D%3D*${values.userName}*,fio.patronymic%3D%3D*${values.userName}*`
      }

      props.saveStaffScreenInfo({
        role,
        inputValue,
        string: rsqlString
      })

    } else if (props.role === "user") {
      if (values.userRole !== "ALL") {
        rsqlString = `&search=tag%3D%3D${values.userRole};(phone%3D%3D*${values.userName}*,email%3D%3D*${values.userName}*,fio.firstName%3D%3D*${values.userName}*,fio.lastName%3D%3D*${values.userName}*,fio.patronymic%3D%3D*${values.userName}*)`
      } else {
        rsqlString = `&search=phone%3D%3D*${values.userName}*,email%3D%3D*${values.userName}*,fio.firstName%3D%3D*${values.userName}*,fio.lastName%3D%3D*${values.userName}*,fio.patronymic%3D%3D*${values.userName}*`
      }
      props.saveUsersScreenInfo({
        role,
        inputValue,
        string: rsqlString
      })

    }
    props.filterSearch(rsqlString)
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const roleChangeHandler = (value) => {
    setRole(value);
  }

  const inputChangeHandler = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <Form
      name="list-search"
      initialValues={{ userRole: role, userName: inputValue}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {props.role === "staff" &&
        <Form.Item
          name="userRole"
          className="user-role"
        >
          <Select onChange={roleChangeHandler}
          >
            <Option value="ALL">{components.searchComponent.staffOptionAll}</Option>
            <Option value="ROLE_ADMIN">{roles.admin}</Option>
            <Option value="ROLE_MANAGER">{roles.manager}</Option>
          </Select>
        </Form.Item>}
      {props.role === "user" &&
      <Form.Item
          name="userRole"
          className="user-tag"
      >
        <Select onChange={roleChangeHandler}
        >
          <Option value="ALL">{components.searchComponent.userOptionAll}</Option>
          <Option value="CLIENT">{users.tags.clients}</Option>
          <Option value="REPRESENTATIVE">{users.tags.representatives}</Option>
          <Option value="REALTOR">{users.tags.realtors}</Option>
          <Option value="INTERNAL_REALTOR">{users.tags.internalRealtors}</Option>
        </Select>
      </Form.Item>}
      <Form.Item
        name="userName"
        className="search-input"
      >
        <Input placeholder={components.searchComponent.inputPlaceholder} prefix={<SearchSmallSvg width="16px" height="16px" />} onChange={inputChangeHandler} />
      </Form.Item>
      <Button type="primary" id="staff-search-btn" htmlType="submit" onClick={onFinish}><SearchWhiteSvg />{components.searchComponent.searchBtn}</Button>
    </Form>
  );
};

export default connector(ListSearch);