import Api from "api";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  ISaveUsersScreenInfo, ISaveUsersScreenInfoAction,
  ISaveUsersScreenPage, ISaveUsersScreenPageAction,
  ISetSingleUserFailureAction,
  ISetSingleUserListAction,
  ISetSingleUserPendingAction,
  ISetUsersFailureAction,
  ISetUsersListAction,
  ISetUsersPendingAction,
  IUpdateUserData,
  IUserArchiveFailureAction,
  IUserArchivePendingAction,
  IUserArchiveSuccessAction,
  IUserGetProposalsFailureAction,
  IUserGetProposalsPendingAction,
  IUserGetProposalsSuccessAction,
  IUserUnArchiveFailureAction,
  IUserUnArchivePendingAction,
  IUserUnArchiveSuccessAction,
  IUserUpdAgentsPhoneData, IUserUpdAgentsPhoneResponseData,
  IUserUpdateAgentsPhoneFailureAction, IUserUpdateAgentsPhonePendingAction,
  IUserUpdateAgentsPhoneSuccessAction,
  IUserUpdateFailureAction,
  IUserUpdatePendingAction,
  IUserUpdatePhoneFailureAction,
  IUserUpdatePhonePendingAction,
  IUserUpdatePhoneSuccessAction,
  IUserUpdateSuccessAction,
  IUserUpdData,
  IUserUpdPhoneData,
  IUserView
} from "./types";

export const getUsersList: ActionCreator<
  ThunkAction<
    Promise<ISetUsersListAction | ISetUsersPendingAction | ISetUsersFailureAction>,
    any,
    null,
    ISetUsersListAction
  >
> = (sortBy, sortType, page = 0, size = 15, inactivityPeriod="",unansweredProposals=false, rsql = "") => {
  return async (dispatch: Dispatch) => {
    //let rsqli = `&search=role==ALL,phone=in=Руденко,email=in=Руденко,fio.firstName=in=Руденко,fio.lastName=in=Руденко,fio.patronymic=in=Руденко`
    const getUsersListPendingAction: ISetUsersPendingAction = {
      type: 'GET_USERS_LIST_PENDING',
    };
    dispatch(getUsersListPendingAction);
    try {
      const response = await Api.get(`/core-service/customers?page=${page}&size=${size}&sort=${sortBy},${sortType}&inactivityPeriod=${inactivityPeriod}&unansweredProposals=${unansweredProposals}${rsql}`)
      const preview = response.data.data
      console.log(preview)
      const getUsersListSuccessAction: ISetUsersListAction = {
        preview,
        type: 'GET_USERS_LIST_SUCCESS',
      };
      return dispatch(getUsersListSuccessAction);
    } catch (error) {
      const getUsersListFailureAction: ISetUsersFailureAction = {
        type: 'GET_USERS_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getUsersListFailureAction);
    }
  };
}

export const getSingleUser: ActionCreator<
  ThunkAction<
    Promise<ISetSingleUserListAction | ISetSingleUserPendingAction | ISetSingleUserFailureAction>,
    any,
    null,
    ISetSingleUserListAction
  >
> = (id) => {
  return async (dispatch: Dispatch) => {
    const getUserListPendingAction: ISetSingleUserPendingAction = {
      type: 'GET_SINGLE_USER_PENDING',
    };
    dispatch(getUserListPendingAction);
    try {
      const response = await Api.get(`/core-service/customers/${id}`)
      const preview = response.data.data
      const getUserListSuccessAction: ISetSingleUserListAction = {
        preview,
        type: 'GET_SINGLE_USER_SUCCESS',
      };
      dispatch(getUserListSuccessAction);
      return preview;
    } catch (error) {
      const getUserListFailureAction: ISetSingleUserFailureAction = {
        type: 'GET_SINGLE_USER_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getUserListFailureAction);
    }
  };
}

export const getUsersViewType = (view: IUserView) => {
  return (dispatchFunc) => {
    return dispatchFunc({
      type: "SET_USERS_VIEW_SUCCESS",
      payload: view
    })
  }
};

export const updateUser: ActionCreator<
  ThunkAction<
    Promise<IUserUpdateSuccessAction | IUserUpdateFailureAction>,
    IUserUpdData,
    IUpdateUserData,
    IUserUpdateSuccessAction | IUserUpdateFailureAction
  >
> = (id: number, data: IUpdateUserData) => {
  return async (dispatch: Dispatch) => {
    const updateUserPendingAction: IUserUpdatePendingAction = {
      type: 'USER_UPDATE_PENDING',
    };
    dispatch(updateUserPendingAction);

    try {
      const UserDataResponse = await Api.put(`/core-service/customers/${id}`, { ...data });
      const userData: IUserUpdData = UserDataResponse.data;
      const updateUserRegSuccessAction: IUserUpdateSuccessAction = {
        userData,
        type: 'USER_UPDATE_SUCCESS',
      };
      return dispatch(updateUserRegSuccessAction);
    } catch (error) {
      const updateUserFailureAction: IUserUpdateFailureAction = {
        type: 'USER_UPDATE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateUserFailureAction);
    }
  };
};

export const userUpdatePhone: ActionCreator<
  ThunkAction<
    Promise<IUserUpdatePhoneSuccessAction | IUserUpdatePhoneFailureAction>,
    IUserUpdPhoneData,
    IUpdateUserData,
    IUserUpdatePhoneSuccessAction | IUserUpdateFailureAction
  >
> = (id: number, data: IUpdateUserData) => {
  return async (dispatch: Dispatch) => {
    const updateUserPendingAction: IUserUpdatePhonePendingAction = {
      type: 'USER_UPDATE_PHONE_PENDING',
    };
    dispatch(updateUserPendingAction);

    try {
      const UserDataResponse = await Api.patch(`/core-service/customers/${id}/phones`, { ...data });
      const userData: IUserUpdPhoneData = UserDataResponse.data;
      const updateUserRegSuccessAction: IUserUpdatePhoneSuccessAction = {
        userData,
        type: 'USER_UPDATE_PHONE_SUCCESS',
      };
      return dispatch(updateUserRegSuccessAction);
    } catch (error) {
      const updateUserFailureAction: IUserUpdatePhoneFailureAction = {
        type: 'USER_UPDATE_PHONE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateUserFailureAction);
    }
  };
};

export const userUpdateAgentsPhone: ActionCreator<
    ThunkAction<
        Promise<IUserUpdateAgentsPhoneSuccessAction | IUserUpdateAgentsPhoneFailureAction>,
        IUserUpdAgentsPhoneData,
        IUserUpdAgentsPhoneResponseData,
        IUserUpdatePhoneSuccessAction | IUserUpdateFailureAction
        >
    > = (id: number, data: IUserUpdAgentsPhoneData) => {
  return async (dispatch: Dispatch) => {
    const updateUserAgentsPhonePendingAction: IUserUpdateAgentsPhonePendingAction = {
      type: 'USER_UPDATE_AGENTS_PHONE_PENDING',
    };
    dispatch(updateUserAgentsPhonePendingAction);

    try {
      const UserDataResponse = await Api.patch(`/core-service/customers/${id}/agent-phone`, { ...data });
      const userResponseData: IUserUpdAgentsPhoneResponseData = UserDataResponse.data;
      const updateUserAgentsPhoneRegSuccessAction: IUserUpdateAgentsPhoneSuccessAction = {
        userResponseData,
        type: 'USER_UPDATE_AGENTS_PHONE_SUCCESS',
      };
      return dispatch(updateUserAgentsPhoneRegSuccessAction);
    } catch (error) {
      const updateUserAgentsPhoneFailureAction: IUserUpdateAgentsPhoneFailureAction = {
        type: 'USER_UPDATE_AGENTS_PHONE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(updateUserAgentsPhoneFailureAction);
    }
  };
};


export const archiveUser: ActionCreator<
  ThunkAction<
    Promise<IUserArchiveSuccessAction | IUserArchiveFailureAction>,
    any,
    any,
    IUserArchiveSuccessAction | IUserArchiveFailureAction
  >
> = (UserId) => {
  return async (dispatch: Dispatch) => {
    const arhiveUserPendingAction: IUserArchivePendingAction = {
      type: "ARCHIVE_USER_PENDING",
    };
    dispatch(arhiveUserPendingAction);
    try {
      const response = await Api.post(`/core-service/customers/${UserId}/block`)
      const preview: any = response.data.data
      const arhiveUserSuccessAction: IUserArchiveSuccessAction = {
        preview,
        type: "ARCHIVE_USER_SUCCESS",
      };
      return dispatch(arhiveUserSuccessAction);
    } catch (error) {
      const arhiveUserFailureAction: IUserArchiveFailureAction = {
        type: "ARCHIVE_USER_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(arhiveUserFailureAction);
    }
  }
}

export const unArchiveUser: ActionCreator<
  ThunkAction<
    Promise<IUserUnArchiveSuccessAction | IUserUnArchiveFailureAction>,
    any,
    any,
    IUserUnArchiveSuccessAction | IUserUnArchiveFailureAction
  >
> = (UserId) => {
  return async (dispatch: Dispatch) => {
    const UnArhiveUserPendingAction: IUserUnArchivePendingAction = {
      type: "UNARCHIVE_USER_PENDING",
    };
    dispatch(UnArhiveUserPendingAction);
    try {
      const response = await Api.post(`/core-service/customers/${UserId}/unblock`)
      const preview: any = response.data.data
      const UnArhiveUserSuccessAction: IUserUnArchiveSuccessAction = {
        preview,
        type: "UNARCHIVE_USER_SUCCESS",
      };
      return dispatch(UnArhiveUserSuccessAction);
    } catch (error) {
      const UnArhiveUserFailureAction: IUserUnArchiveFailureAction = {
        type: "UNARCHIVE_USER_FAILURE",
        payload: {
          ...error.response.data
        }
      };

      return dispatch(UnArhiveUserFailureAction);
    }
  }
}

export const getUserProposals: ActionCreator<
    ThunkAction<
        Promise<IUserGetProposalsSuccessAction | IUserGetProposalsPendingAction | IUserGetProposalsFailureAction>,
        any,
        null,
        IUserGetProposalsSuccessAction
        >
    > = (id, page = 0, size = 15) => {
  return async (dispatch: Dispatch) => {
    const getUserProposalsPendingAction: IUserGetProposalsPendingAction = {
      type: 'GET_USER_PROPOSALS_PENDING',
    };
    dispatch(getUserProposalsPendingAction);
    try {
      const response = await Api.get(`/core-service/customers/${id}/proposals?page=${page}&size=${size}`)
      const proposalResults = response.data.data
      // console.log(proposalList)
      const getUserProposalsSuccessAction: IUserGetProposalsSuccessAction = {
        type: 'GET_USER_PROPOSALS_SUCCESS',
        proposalResults,
      };
      return dispatch(getUserProposalsSuccessAction);
    } catch (error) {
      const getUserProposalsFailureAction: IUserGetProposalsFailureAction = {
        type: 'GET_USER_PROPOSALS_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getUserProposalsFailureAction);
    }
  };
}

export const saveUsersScreenPage = (usersScreenPage :ISaveUsersScreenPage) => {
  return (dispatch) : ISaveUsersScreenPageAction => {
    return dispatch({
      type: "SAVE_USERS_SCREEN_PAGE_SUCCESS",
      payload: usersScreenPage
    })
  }
}

export const saveUsersScreenInfo = (usersScreenInfo :ISaveUsersScreenInfo) => {
  return (dispatch) : ISaveUsersScreenInfoAction => {
    return dispatch({
      type: "SAVE_USERS_SCREEN_INFO_SUCCESS",
      payload: usersScreenInfo
    })
  }
}