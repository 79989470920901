import { link } from 'fs';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  link: string;
  name: string;
  collapsed:boolean;
}

const MenuItem: FC<IProps> = (props) => {
  return (
    <Link to={props.link}>{!props.collapsed ? <span className="link-name">{props.name}</span> : null}</Link>
  );
};

export default MenuItem;