import { Button } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import "./ScreenHeaderList.scss";

interface IProps {
  title: string;
  buttonText?: string;
  btnImage?: any;
  isCreate: boolean;
  path?:string;
}

const ScreenHeaderList: FC<IProps> = (props) => {
  return (
    <div className="header-wrapper">
      <h2>{props.title}</h2>
      {props.isCreate &&
      <Link to={`/dashboard/${props.path}/create`}>
        <Button type="primary" size="small">
          {props.btnImage}
          {props.buttonText}
        </Button>
      </Link>
      }
    </div>
  );
};

export default ScreenHeaderList;