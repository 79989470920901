import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as SearchSmallSvg } from 'assets/images/ic-search-small.svg';
import { ReactComponent as SearchWhiteSvg } from 'assets/images/ic-search-w.svg';
import { ReactComponent as ReloadSvg } from 'assets/images/ic-reload.svg';
import { ReactComponent as FilterSvg } from 'assets/images/ic-filter.svg';
import { components, users } from '../../globalConstants/textinputs.json';
import "./AdvertFilter.scss";
import { useForm } from 'antd/lib/form/Form';
import regionsList from '../../globalConstants/regions.json'
import purposeCodes from '../../globalConstants/purposeCodes.json'
import { IAdvertRsql, IAdvertScreenInfo, IGetAdvertAction, ISaveAdvertScreenInfoAction } from 'store/adverts/types'
import { setAdvertFilter, setAdvertScreenInfo } from 'store/adverts/actions'
import { getLocationDistrictAdress, getLocationLocalityAdress } from 'store/address/actions'

import { connect } from 'react-redux';
import {
    ISetLocationDistrictFailureAction,
    ISetLocationDistrictListAction,
    ISetLocationLocalityListAction
} from 'store/address/types';
import { adverts } from '../../globalConstants/textinputs.json';

interface IProps {
    setAdvertFilter: (data: any) => IGetAdvertAction;
    getLocationDistrictAdress: (region?: string) => Promise<ISetLocationDistrictListAction>
    getLocationLocalityAdress: (region: string, district: string) => Promise<ISetLocationLocalityListAction>
    changeSearchedState: (value: boolean) => void;
    advert: any;
    setAdvertScreenInfo: (advertScreenInfo: IAdvertScreenInfo) => ISaveAdvertScreenInfoAction;
    setResetPage: (value: boolean) => void;
}

const mapStateToProps = state => ({
    staff: state.staff,
    advert: state.advert
});

const mapDispatchToProps = {
    setAdvertFilter,
    getLocationDistrictAdress,
    getLocationLocalityAdress,
    setAdvertScreenInfo
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AdvertFilter: FC<IProps> = (props) => {

    const [form] = useForm();

    const [toggle, setToggle] = React.useState(true);

    const [inputQuery, setInputQuery] = React.useState(props.advert.filter?.inputQuery);

    const [regionList, setRegionList] = React.useState<string[]>([]);

    const [region, setRegion] = React.useState(props.advert.filter?.region);

    const [districtList, setDistrictList] = React.useState([])

    const [district, setDistrict] = React.useState(props.advert.filter?.district);

    const [locality, setLocality] = React.useState(null);

    const [localityValue, setLocalityValue] = React.useState(props.advert.filter?.locality);

    const [fromQuery, setFromQuery] = React.useState(props.advert.filter?.priceFrom);

    const [toQuery, setToQuery] = React.useState(props.advert.filter?.priceTo);

    const [tag, setTag] = React.useState(props.advert.filter?.tag);

    const [sortBy, setSortBy] = React.useState(props.advert.filter?.sortBy);

    const [sortAsc, setSortAsc] = React.useState(props.advert.filter?.sortAsc);

    const [asap, setAsap] = React.useState(props.advert.filter?.asap);


    //const [SingleSearch, setSingleSearch] = React.useState(false);

    const { getLocationDistrictAdress } = props;

    React.useEffect(() => {
        getLocationDistrictAdress().then(resp => {
            if (resp.LocalityData.data) {
                setArrayOfRegions(resp.LocalityData.data)
            }
        })
    }, [])

    React.useEffect(() => {

        if (props.advert.filter?.region) {
            getLocationDistrictAdress(props.advert.filter?.region).then(resp => {
                setDistrictList(resp.LocalityData.data)
            })
        }

    }, [props.advert.filter?.region])

    React.useEffect(() => {
        props.getLocationLocalityAdress(props.advert.filter?.region, props.advert.filter?.district).then(resp => {
            setLocality(resp.LocalityData.data)
        })
    }, [props.advert.filter?.region, props.advert.filter?.district])

    const setArrayOfRegions = (array) => {
        if (array && array.length) {
            const arrayOfRegions = array.map(item => item.region).filter((value, index, self) => self.indexOf(value) === index).filter(Boolean)
            setRegionList(arrayOfRegions)
        }
    }

    const onFinish = (values: any) => {
        console.log("VALUES:", values);
        // if(SingleSearch) {
        //   values = {"advertName|fullSearch" : values.advertNameANDfullSearch}
        //   console.log(values);

        // }
        values = { ...values, "advertName|fullSearch": values.advertNameANDfullSearch }
        let filtersArray = Object.keys(values).filter(item => Boolean(values[item])).map(filterItemName => {
            let [field, type] = filterItemName.split("|");
            switch (type) {
                case "fullSearch":
                    const value = values[filterItemName].replace('+', '%2B');

                    return `(land.cadastralNumber%3D%3D"*${value}*",id%3D%3D"*${value}*",author.fio.lastName%3Dik%3D"${value}",author.fio.firstName%3Dik%3D"${value}",author.email%3D%3D"*${value}*",author.phone%3D%3D"*${value}*")`;
                case "full":
                    return `${field}%3D%3D("${values[filterItemName]}")`;
                case "from":
                    return `${field}%3Dge%3D${values[filterItemName]}`;
                case "to":
                    return `${field}%3Dlt%3D${values[filterItemName]}`;
                case "bool":
                    return `${field}%3D%3D${values[filterItemName]}` 
                case "tag":
                    if (values[filterItemName] !== "ALL") {
                        return `${field}%3D%3D${values[filterItemName]}`;
                    }
                    return;
                default:
                    return "";
            }
        })

        let filters = filtersArray.filter(item => item !== '' && item !== undefined).join(";")
        props.setAdvertFilter({
            filter: filters,
            region: region,
            district: district,
            inputQuery: inputQuery,
            locality: localityValue,
            priceFrom: fromQuery,
            priceTo: toQuery,
            tag: tag,
            sortBy: sortBy,
            sortAsc: sortAsc,
            asap: asap
        })
        props.setResetPage(true);

    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const toggleHandler = () => {
        setToggle(!toggle)
    }

    const resetFilterHandler = () => {
        setRegion(null)
        setDistrict(null)
        setDistrictList([])
        setLocality(null)
        setLocalityValue(null);
        setInputQuery("")
        setFromQuery("")
        setToQuery("");
        setSortBy("updatedDate")
        setSortAsc("desc")
        setTag("")
        setAsap(false)
        props.setAdvertFilter({})
        props.setAdvertScreenInfo({
            activeTab: "1",
            activePage: 0,
            activeTabName: "onModeration"
        })
        form.setFieldsValue({
            "land.registryAddress.region|full": null,
            "land.registryAddress.district|full": null,
            "advertNameANDfullSearch": "",
            "land.registryAddress.settlement|full": null,
            "price|from": "",
            "price|to": "",
            "author.tag|tag": null,
            "sorting": null,
            "sellAsap|bool": false
        });


        form.submit()
    }

    const submitSearchHandler = () => {
        //setSingleSearch(true)
        form.submit()
        props.changeSearchedState(true);
    }

    // const submitBottomSearchHandler = () => {
    //   setSingleSearch(false)
    //   form.submit()
    // }

    const getDistrictHandler = (value) => {
        setRegion(value)
        form.setFieldsValue({ "land.registryAddress.district|full": null, "land.registryAddress.settlement|full": null })
        setDistrict(null);
        setLocalityValue(null);
        setLocality(null);
        getLocationDistrictAdress(value).then(resp => {
            setDistrictList(resp.LocalityData.data)
        })
    }

    const getLocalityHandler = (value) => {
        setDistrict(value);
        props.getLocationLocalityAdress(region, value).then(resp => {
            setLocality(resp.LocalityData.data)
        })
    }

    const handleSearchInputValue = (e) => {
        setInputQuery(e.target.value);
    }

    const changeLocalityHandler = (value) => {
        setLocalityValue(value)
    }

    const changeFromQueryHandler = (e) => {
        setFromQuery(e.target.value)
    }

    const changeToQueryHandler = (e) => {
        setToQuery(e.target.value)
    }

    const sortHandler = name => {
        setSortBy(name.split("|")[0])
        setSortAsc(name.split("|")[1])
    }

    const tagHandler = name => {
        setTag(name)
    }

    const asapHandler = e => {
        setAsap(e.target.checked)
    }

    return (
        <div>
            <Form
                name="adverts-search"
                initialValues={{
                    userRole: "ALL",
                    "land.registryAddress.region|full": props.advert.filter?.region,
                    "land.registryAddress.district|full": props.advert.filter?.district,
                    "advertNameANDfullSearch": props.advert.filter?.inputQuery,
                    "land.registryAddress.settlement|full": props.advert.filter?.locality,
                    "price|from": props.advert.filter?.priceFrom,
                    "price|to": props.advert.filter?.priceTo,
                    "author.tag|tag": props.advert.filter?.tag,
                    "sorting": props.advert.filter?.sortBy ? `${props.advert.filter?.sortBy}|${props.advert.filter.sortAsc}` : null,
                    "sellAsap|bool": props.advert.filter?.asap
                }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className="top-line">
                    {/* <Button type="primary" className="filter-btn" onClick={toggleHandler}>
                        {adverts.main.filters.toogleFiltersBtn}
                    </Button> */}
                    <Form.Item
                        name="advertNameANDfullSearch"
                        className="search-input"
                    >
                        <Input allowClear placeholder={adverts.main.filters.inputPlaceholder}
                            prefix={<SearchSmallSvg width="16px" height="16px" />} onChange={handleSearchInputValue} />
                    </Form.Item>
                    <Button type="primary" id="staff-search-btn" htmlType="submit"
                        onClick={submitSearchHandler}><SearchWhiteSvg />{adverts.main.filters.searchBtn}</Button>
                </div>
                <div className={toggle ? "bottom-line active" : "bottom-line"}>
                    <Row gutter={24} className="custom-row">
                        <Col span={4}>
                            <Form.Item
                                name="land.registryAddress.region|full"
                            >
                                <Select placeholder={adverts.main.filters.regionField} onChange={getDistrictHandler}>
                                    {regionList.map((item, index) => (
                                        <Select.Option key={index} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="land.registryAddress.district|full"
                            >
                                <Select placeholder={adverts.main.filters.districtField} onChange={getLocalityHandler}>
                                    {districtList &&
                                        districtList.map((item, index) => (
                                            <Select.Option key={index} value={item.district}>
                                                {item.district}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="land.registryAddress.settlement|full"
                            >
                                <Select placeholder={adverts.main.filters.localityField}
                                    onChange={changeLocalityHandler}>
                                    {locality &&
                                        locality.map((item, index) => (
                                            <Select.Option key={index} value={item.location}>
                                                {item.location}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                                {/* <Input placeholder="Населений пункт" /> */}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="price|from"
                            >
                                <Input type="number" placeholder={adverts.main.filters.priceFrom} onChange={changeFromQueryHandler} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="price|to"
                            >
                                <Input type="number" placeholder={adverts.main.filters.priceTo} onChange={changeToQueryHandler} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="custom-row">
                        <Col span={4}>
                            <Form.Item
                                name="author.tag|tag"
                            >
                                <Select placeholder={adverts.main.filters.tag} onChange={tagHandler}>
                                    <Select.Option value="ALL">{components.searchComponent.userOptionAll}</Select.Option>
                                    <Select.Option value="CLIENT">{users.tags.clients}</Select.Option>
                                    <Select.Option value="REPRESENTATIVE">{users.tags.representatives}</Select.Option>
                                    <Select.Option value="REALTOR">{users.tags.realtors}</Select.Option>
                                    <Select.Option value="INTERNAL_REALTOR">{users.tags.internalRealtors}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="sellAsap|bool"
                                valuePropName="checked"
                            >
                                <Checkbox onChange={asapHandler}>{adverts.main.filters.asap}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="sorting"
                            >
                                <Select placeholder={adverts.main.filters.advertSort} onChange={sortHandler}>
                                    <Select.Option value={"updatedDate|desc"}>{adverts.main.filters.advertSortDefault}</Select.Option>
                                    <Select.Option value={"createdDate|asc"}>{adverts.main.filters.advertSortOld}</Select.Option>
                                    <Select.Option value={"createdDate|desc"}>{adverts.main.filters.advertSortNew}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>

                        </Col>
                        {/* <Col span={20}>
                            <Form.Item
                name="6"
              >
                <Checkbox>Режим моніторингу цін</Checkbox>
              </Form.Item>
                        </Col> */}
                        <Col span={4}>
                            <Row gutter={24} className="btn-wrapper">
                                <Col span={24}>
                                    <Button type="default" size="small" id="reset-filters" onClick={resetFilterHandler}><ReloadSvg />{adverts.main.filters.resetFiltersBtn}
                                    </Button>
                                    {/* <Button type="primary" size="small" onClick={submitBottomSearchHandler}><FilterSvg />Фільтрувати</Button> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
};

export default connector(AdvertFilter);