import { Reducer } from 'redux';
import { AdvertActions } from './types';

export const initialState = {
  single: {},
  isFromAdvert: "",
  proposalResults: {},
  onModerationCount: 0,
  activeTabInfo: {
    activeTab: "1",
    activePage: 0,
    activeTabName: ""
  },
  activeUserTabInfo: {
    activeTab: "1",
    activePage: 0,
    activeTabName: ""
  },
  filter: {
    string: "",
    region: null,
    district: null,
    inputQuery: "",
    locality: null,
    priceFrom: "",
    priceTo: "",
    tag: null,
    sortBy: "",
    sortAsc: "",
    asap: false
  }
}

export const AdvertReducer: Reducer<any, AdvertActions> = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'GET_SINGLE_ADVERT_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_SINGLE_ADVERT_SUCCESS': {
      return {
        ...state,
        single: action.preview,
        isFetching: false,
      };
    }
    case 'GET_SINGLE_ADVERT_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'GET_ADVERT_FILTER_SUCCESS': {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case "ADVERT_GET_PROPOSALS_PENDING": {
      return {...state}
    }
    case "ADVERT_GET_PROPOSALS_SUCCESS": {
      return {
        ...state,
        proposalResults: action.proposalList
      }
    }
    case "ADVERT_GET_PROPOSALS_FAILURE": {
      return {
        ...defaultErrorState(action)
      };
    }

    case "ADVERT_GET_ON_MODERATION_COUNT_PENDING": {
      return {
        ...state
      }
    }

    case "ADVERT_GET_ON_MODERATION_COUNT_SUCCESS": {
      return {
        ...state,
        onModerationCount: action.payload
      }
    }

    case "ADVERT_GET_ON_MODERATION_COUNT_FAILURE": {
      return  {
        ...defaultErrorState(action)
      }
    }

    case "EDIT_ADVERT_OWNER_PENDING": {
      return {
        ...state
      }
    }

    case "EDIT_ADVERT_OWNER_SUCCESS": {
      return {
        ...state
      }
    }

    case "EDIT_ADVERT_OWNER_FAILURE": {
      return  {
        ...defaultErrorState(action)
      }
    }

    case "SAVE_ADVERT_SCREEN_INFO_SUCCESS": {
      return  {
        ...state,
        activeTabInfo: {
          ...action.payload
        }
      }
    }

    case "SAVE_USER_ADVERT_SCREEN_INFO_SUCCESS": {
      return {
        ...state,
        activeUserTabInfo: {
          ...action.payload
        }
      }
    }

    case "FROM_ADVERT_INFO_SUCCESS": {
      return {
        ...state,
          ...action.payload
      }
    }
    case "UPDATE_ADVERT_AUTHOR_PENDING": {
      return  {
        ...state
      }
    }

    case "UPDATE_ADVERT_AUTHOR_SUCCESS": {
      return {
        ...state
      }
    }

    case "UPDATE_ADVERT_AUTHOR_FAILURE": {
      return  {
        ...defaultErrorState(action)
      }
    }

    default: return state
  }
}
export default AdvertReducer;