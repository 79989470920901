import type { Reducer } from "redux";

import type { ServiceMessagesState, ServiceMessagesActions } from "./types";
import {
  GET_SERVICE_MESSAGES_LIST_REQUEST,
  GET_SERVICE_MESSAGES_LIST_SUCCESS,
  GET_SERVICE_MESSAGES_LIST_FAILURE,
  GET_SERVICE_MESSAGES_UNREAD_COUNT_SUCCESS,
} from "./constants";

export const initialState: ServiceMessagesState = {
  list: [],
  totalElements: 0,
  unreadCount: 0,
  isFetchingList: false,
};

export const serviceMessagesReducer: Reducer<ServiceMessagesState, ServiceMessagesActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SERVICE_MESSAGES_LIST_REQUEST: {
      return {
        ...state,
        isFetchingList: true,
      };
    }

    case GET_SERVICE_MESSAGES_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload.list,
        totalElements: action.payload.totalElements,
        isFetchingList: false,
      };
    }

    case GET_SERVICE_MESSAGES_LIST_FAILURE: {
      return {
        ...state,
        isFetchingList: false,
      };
    }

    case GET_SERVICE_MESSAGES_UNREAD_COUNT_SUCCESS: {
      return {
        ...state,
        unreadCount: action.payload,
      };
    }

    default:
      return state;
  }
};

export default serviceMessagesReducer;
