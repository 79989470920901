import { Button, Modal } from 'antd';
import React, { FC } from 'react';

interface IProps {
  closeModal: (boolean) => void;
  title: string;
  isModalVisible: boolean;
  isSaved?: boolean;
  onSubmit?: () => void;
  onAddTag?: () => void;
  cancelBtnText: string;
  successBtnText: string;
  modalName?: string;
  closable?: boolean;
  fileList?: any;
  setFileList?: (any) => void;
  isTag?: boolean;
}

const SimpleModal: FC<IProps> = (props) => {

  const handleCancel = () => {
    props.closeModal(false);
    if (props.setFileList) {
      props.setFileList([])
    }
  };

  // const onFinish = () => {
  //   props.closeModal(false);
  // }

  return (
    <div>
      <Modal title={props.title}
        visible={props.isModalVisible}
        closable={props.closable || false}
        // onOk={onFinish}
        onCancel={handleCancel}
        footer={null}
        className={`modal-info ${props.modalName}`}
        centered={true}
      >
        {props.children}
        <div className="btn-wrapper">
          {props.isSaved &&
            <Button type="default" id="Cancel" className="btn" key={'1'} onClick={handleCancel}>
              {props.cancelBtnText}
            </Button>
          }
          <Button id="Success" type="primary" className="btn" key={'2'} onClick={props.onSubmit} disabled={!props.fileList?.length}>
            {props.successBtnText}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SimpleModal;