import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as SearchSmallSvg } from 'assets/images/ic-search-small.svg';
import { ReactComponent as SearchWhiteSvg } from 'assets/images/ic-search-w.svg';
import { ReactComponent as ReloadSvg } from 'assets/images/ic-reload.svg';
import "./UserFilter.scss";
import { components, roles, users } from '../../globalConstants/textinputs.json';
import { useForm } from 'antd/lib/form/Form';
import { setAdvertFilter } from 'store/adverts/actions'
import { connect } from 'react-redux';
import { adverts } from '../../globalConstants/textinputs.json';
import { saveUsersScreenInfo } from "../../store/users/actions";
import moment from 'moment';
const { RangePicker } = DatePicker;

interface IProps {
  filterSearch: any;
  staff: any;
  users: any;
  role?: string;
  changeSearchedState?: (value: boolean) => void;
  saveUsersScreenInfo?: any;
}

const mapStateToProps = state => ({
  users: state.users,
  staff: state.staff
});

const mapDispatchToProps = {
  setAdvertFilter,
  saveUsersScreenInfo
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UserFilter: FC<IProps> = (props) => {

  const [form] = useForm();

  const [toggle, setToggle] = React.useState(true);

  const [role, setRole] = React.useState(props.users.usersScreenInfo.role);
  const [inputValue, setInputValue] = React.useState(props.users.usersScreenInfo.inputValue);
  const [dates, setDates] = React.useState(props.users.usersScreenInfo.dates);
  const [proposals, setProposals] = React.useState(props.users.usersScreenInfo.unansweredProposals);
  const [inactivityPeriod, setinactivityPeriod] = React.useState(props.users.usersScreenInfo.inactivityPeriod);

  const roleChangeHandler = (value) => {
    setRole(value);
  }

  const inputChangeHandler = (e) => {
    setInputValue(e.target.value)
  }

  const onFinish = (values: any) => {
    values = { ...values, "advertName|fullSearch": values.advertNameANDfullSearch }
    let filtersArray = Object.keys(values).filter(item => Boolean(values[item])).map(filterItemName => {
      let [field, type] = filterItemName.split("|");
      switch (type) {
        case "fullSearch":
          return `(phone%3Dik%3D${values.advertNameANDfullSearch},email%3Dik%3D${values.advertNameANDfullSearch},fio.firstName%3Dik%3D${values.advertNameANDfullSearch},fio.lastName%3Dik%3D${values.advertNameANDfullSearch},fio.patronymic%3Dik%3D${values.advertNameANDfullSearch})`
        case "tag":
          if (values[filterItemName] !== "ALL") {
            return `${field}%3D%3D${values[filterItemName]}`;
          }
          return;
        case "full":
          return `${field}%3D%3D("${values[filterItemName]}")`;
        case "date":
          return `(${field}%3Dge%3D'${moment(values[filterItemName][0]).format('YYYY-MM-DD')}';${field}%3Dle%3D'${moment(values[filterItemName][1]).format('YYYY-MM-DD')}')`
        default:
          return "";
      }
    })
    let filters = filtersArray.filter(item => item !== '' && item !== undefined).join(";")
    props.saveUsersScreenInfo({
      role,
      inputValue,
      string: filters,
      dates,
      unansweredProposals: proposals,
      inactivityPeriod: values.inactivityPeriod
    })
    props.filterSearch(filters, values.unansweredProposals || false, values.inactivityPeriod || "")
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const toggleHandler = () => {
    setToggle(!toggle)
  }

  const resetFilterHandler = () => {
    form.resetFields();
    setInputValue(null)
    setRole(null)
    setDates([])
    setProposals(false)
    setinactivityPeriod("")
    props.saveUsersScreenInfo({
      role: 'ALL',
      inputValue: "",
      string: ""
    })
    form.setFieldsValue({
      "tag|tag": "ALL",
      "advertNameANDfullSearch": null,
      "createdDate|date": "",
      "unansweredProposals": false,
      "inactivityPeriod": null
    });
    form.submit()
  }

  const changeDateHandler = (dates, dateStrings) => {
    setDates(dateStrings)
  }

  const submitSearchHandler = () => {
    //setSingleSearch(true)
    form.submit()
    //props.changeSearchedState(true);
  }

  const proposalsHandler = (e) => {
    setProposals(e.target.checked)
  }


  return (
    <div>
      <Form
        name="user-search"
        initialValues={{
          "tag|tag": role,
          advertNameANDfullSearch: inputValue,
          "createdDate|date": dates.length && [moment(dates[0], 'DD.MM.YYYY'), moment(dates[1], 'DD.MM.YYYY')],
          unansweredProposals: proposals,
          inactivityPeriod: inactivityPeriod
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="top-line">
          {/* <Button type="primary" className="filter-btn" onClick={toggleHandler}>
            Параметри пошуку
          </Button> */}
          <Form.Item
            name="advertNameANDfullSearch"
            className="search-input"
          >
            <Input allowClear placeholder={components.searchComponent.inputPlaceholder} prefix={<SearchSmallSvg width="16px" height="16px" />} onChange={inputChangeHandler} />
          </Form.Item>
          <Button type="primary" id="staff-search-btn" htmlType="submit" onClick={submitSearchHandler}><SearchWhiteSvg />Знайти</Button>
        </div>
        <div className={toggle ? "bottom-line active" : "bottom-line"}>
          <Row gutter={24} className="custom-row">
            <Col span={5}>
              <Form.Item
                name="tag|tag"
                className="user-tag"
              >
                <Select onChange={roleChangeHandler}>
                  <Select.Option value="ALL">{components.searchComponent.userOptionAll}</Select.Option>
                  <Select.Option value="CLIENT">{users.tags.clients}</Select.Option>
                  <Select.Option value="REPRESENTATIVE">{users.tags.representatives}</Select.Option>
                  <Select.Option value="REALTOR">{users.tags.realtors}</Select.Option>
                  <Select.Option value="INTERNAL_REALTOR">{users.tags.internalRealtors}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="inactivityPeriod"
                className="user-tag"
              >
                <Select placeholder={"Період без активності"}>
                  <Select.Option value="THREE_DAYS">Три Дні</Select.Option>
                  <Select.Option value="WEEK">Тиждень</Select.Option>
                  <Select.Option value="MONTH">Місяць</Select.Option>
                  <Select.Option value="THREE_MONTHS">Три місяці</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="createdDate|date"
              >
                <RangePicker onChange={changeDateHandler} placeholder={['Дата реєстрації, від', 'до']}
                  format={'DD.MM.YYYY'}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="unansweredProposals"
                valuePropName="checked"
              >
                <Checkbox onChange={proposalsHandler}>Є пропозиції без відповіді</Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button type="default" size="small" id="reset-filters" onClick={resetFilterHandler}><ReloadSvg />{adverts.main.filters.resetFiltersBtn}</Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default connector(UserFilter);
