import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginScreen from 'pages/LoginScreen/LoginScreen';
import { IAppState } from 'store';
// import { IUserState } from 'store/user/types';
import PrivateRoute from './PrivateRoute';
import DashboardScreen from 'pages/DashboardScreen/DashboardScreen';
import { getUserStoredData } from 'services';
import SignUpScreen from 'pages/SignUpScreen/SignUpScreen';
import RestorePasswordScreen from 'pages/RestorePasswordScreen/RestorePasswordScreen';


interface Props {
    user: any;
}


interface State {

}


export class GlobalRouter extends Component<Props, State> {
    state = {}

    render() {
        const { token } = getUserStoredData();
        return (
            <BrowserRouter
                basename={'/'}
                forceRefresh={false}
                getUserConfirmation={() => { }}
            >
                <div className="global-wrapper">
                    <Route exact path="/" render={() => (
                        token ? (
                            <Redirect to="/dashboard/contracts" />
                        ) : (
                                <Redirect to="/" />
                            )
                    )} />
                    <Route exact path="/" component={(LoginScreen)} />
                    <Route exact path="/signUp" component={(SignUpScreen)} />
                    <Route exact path="/restorePassword" component={(RestorePasswordScreen)} />
                    
                    <PrivateRoute path="/dashboard" render={props => <DashboardScreen {...props} />} />
                </div>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: state.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRouter)
