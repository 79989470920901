import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, {FC, useEffect} from 'react';
import { ReactComponent as SearchSmallSvg } from 'assets/images/ic-search-small.svg';
import { ReactComponent as SearchWhiteSvg } from 'assets/images/ic-search-w.svg';
import { ReactComponent as ReloadSvg } from 'assets/images/ic-reload.svg';
import "./ContractFilter.scss";
import { useForm } from 'antd/lib/form/Form';
import { connect, useDispatch } from "react-redux";
import { contracts } from '../../globalConstants/textinputs.json';
import { contractPrepaymentStatuses, dealStatuses, OptionsMapper } from "../../services/mapper";
import { getStaffList } from "../../store/staff/actions";
import { ISetStaffListAction } from "../../store/staff/types";
const { OptGroup } = Select;

interface IProps {
  filterSearch: any;
  filterSettings: any;
  getStaffList: (sortBy: string, sortType: string, page: number, size: number, rsql?: string) => Promise<ISetStaffListAction>;
}


const initialFilterSettings = {
  contractNameANDfullSearch: undefined,
  'createdDate|from': undefined,
  'createdDate|to': undefined,
  'dealPayment.prepaymentStatus|in': undefined,
  'staffId|staff': undefined,
  'status|in': undefined
}

const mapDispatchToProps = {
  getStaffList
};

const mapStateToProps = state => ({
  filterSettings: state.contracts.filterSettings
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export const ContractFilter: FC<IProps> = (props) => {
  const { getStaffList, filterSettings } = props;
  const [form] = useForm();
  const dispatch = useDispatch();

  const [staffList, setStaffList] = React.useState<any>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [values, setValues] = React.useState<any>([]);

  React.useEffect(() => {
    getStaffList('id', 'asc', 0, 20).then(resp => {
      setStaffList(resp.preview.content)
      setTotalPages(resp.preview.totalPages)
    })

    const valuesArr = [];
    dealStatuses.map((item, index) => {
      if (item?.group !== dealStatuses[index - 1]?.group) {
        valuesArr.push(dealStatuses.filter((p) => p.group === item.group));
      }
      return [...valuesArr];
    });
    setValues(valuesArr);
  }, [])

  const handleSelectScroll = (e) => {

    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {

      if (currentPage < totalPages) {

        getStaffList('id', 'asc', currentPage + 1, 20).then(resp => {
          setCurrentPage(currentPage + 1)

          setStaffList(prevState => (
            [...prevState, ...resp.preview.content]
          ))
        })
      }


    }
  }

  const renderDealSelect = () => {
    return values.map((item, index) => {
      return (
        <OptGroup>
          {item.map((item2) => {
            return <option value={item2.value}>{item2.label}</option>;
          })}
        </OptGroup>
      );
    });
  };

  const onFinish = (values: any) => {
    values = { ...values, "contractName|fullSearch": values.contractNameANDfullSearch }
    let staffFilter = "";
    let filtersArray = Object.keys(values).filter(item => Boolean(values[item])).map(filterItemName => {
      let [field, type] = filterItemName.split("|");
      switch (type) {
        case "fullSearch":
          const value = values[filterItemName].replace('+', '%2B');

          return `(
            ad.land.cadastralNumber%3D%3D*${value}*,ad.id%3D%3D*${value}*,id%3D%3D*${value}*,seller.fio.lastName%3Dik%3D${value},seller.fio.firstName%3Dik%3D${value},seller.fio.patronymic%3Dik%3D${value},seller.phone%3D%3D*${value}*,buyer.phone%3D%3D*${value}*,buyer.fio.lastName%3Dik%3D${value},buyer.fio.firstName%3Dik%3D${value},buyer.fio.patronymic%3Dik%3D${value},seller.email%3D%3D*${value}*,buyer.email%3D%3D*${value}*)`;
        case "full":
          return `${field}%3D%3D${values[filterItemName]}`;
        case "in":
          if (values[filterItemName].length) {
            return `${field}%3Din%3D(${values[filterItemName]})`;
          } else {
            break;
          }
        case "from":
          return `${field}%3Dge%3D${values[filterItemName].format('YYYY-MM-DDThh:mm:ss.SSSSSS')}`;
        case "to":
          return `${field}%3Dlt%3D${values[filterItemName].format('YYYY-MM-DDThh:mm:ss.SSSSSS')}`;
        case 'staff':
          staffFilter = `&${field}=${values[filterItemName]}`
          return ""
        default:
          return "";
      }
    })

    let filters = `&search=${filtersArray.filter(item => item !== '' && item !== undefined).join(";")}${staffFilter}`
    dispatch({
      type: 'SAVE_CONTRACT_FILTER_SETTINGS',
      payload: {
        fields: values,
        rsqlString: filters
      }
    })
    props.filterSearch(filters)
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const resetFilterHandler = () => {
    setStaffList([])
    setCurrentPage(0)
    setTotalPages(0);

    getStaffList('id', 'asc', 0, 20).then(resp => {
      setStaffList(resp.preview.content)
      setTotalPages(resp.preview.totalPages)
    })
    form.resetFields();
    form.submit()
  }

  useEffect(() => {
    form.setFieldsValue(filterSettings.fields);
  }, [filterSettings.fields])

  return (
    <div>
      <Form
        name="contract-search"
        initialValues={initialFilterSettings}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="top-line">
          <Form.Item
            name="contractNameANDfullSearch"
            className="search-input"
          >
            <Input placeholder={contracts.list.filters.inputPlaceholder} prefix={<SearchSmallSvg width="16px" height="16px" />} allowClear />
          </Form.Item>
          <Button type="primary" id="staff-search-btn" htmlType="submit"><SearchWhiteSvg />{contracts.list.filters.searchBtn}</Button>
        </div>
        <div className="bottom-line active">
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="staffId|staff"
              >
                <Select placeholder={contracts.list.filters.staffPlaceholder} onPopupScroll={handleSelectScroll}>
                  {staffList.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {`${item.fio?.lastName} ${item.fio?.firstName}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="status|in"
              >
                <Select mode="multiple" placeholder={contracts.list.filters.statusPlaceholder}>
                  {renderDealSelect()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="dealPayment.prepaymentStatus|in"
              >
                <Select mode="multiple" placeholder={contracts.list.filters.prepaymentPlaceholder}>
                  {OptionsMapper(contractPrepaymentStatuses)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="createdDate|from"
              >
                <DatePicker placeholder={contracts.list.filters.creationDateFromPlaceholder} format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="createdDate|to"
              >
                <DatePicker placeholder={contracts.list.filters.creationDateToPlaceholder} format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={20}>
            </Col>
            <Col span={4}>
              <Row gutter={24} className="btn-wrapper">
                <Col span={24}>
                  <Button type="default" size="small" id="reset-filters" onClick={resetFilterHandler}><ReloadSvg />{contracts.list.filters.resetFiltersBtn}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default connector(ContractFilter);
