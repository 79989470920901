import Api from "api";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  ICalculateMarketPriceFailureAction, ICalculateMarketPricePendingAction, ICalculateMarketPriceSuccessAction,
  IDownloadPricesAction,
  IDownloadPricesFailureAction,
  IDownloadPricesPendingAction, IGetMarketPricesFailureAction, IGetMarketPricesPendingAction,
  IGetMarketPricesSuccessAction,
  ISendPricesFailureAction,
  ISendPricesPendingAction,
  ISendPricesSuccessAction,
  ISetPricesFailureAction,
  ISetPricesListAction,
  ISetPricesPendingAction
} from "./types";

export const getPricesList:ActionCreator<
ThunkAction<
  Promise<ISetPricesListAction | ISetPricesPendingAction | ISetPricesFailureAction>,
  any,
  null,
  ISetPricesListAction
>
>  = (sortBy, sortType, page=0, size=15) => {
  return async (dispatch: Dispatch) => {
    const getPRICESListPendingAction:ISetPricesPendingAction = {
      type: 'GET_PRICES_LIST_PENDING',
    };
    dispatch(getPRICESListPendingAction);
    try {
      const response = await Api.get(`/core-service/market-prices/documents?page=${page}&size=${size}&sort=${sortBy},${sortType}`)
      const preview = response.data.data
      console.log(preview)
      const getPRICESListSuccessAction:ISetPricesListAction = {
        preview,
        type: 'GET_PRICES_LIST_SUCCESS',
      };
      return dispatch(getPRICESListSuccessAction);
    } catch (error) {
      const getPRICESListFailureAction: ISetPricesFailureAction = {
        type: 'GET_PRICES_LIST_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getPRICESListFailureAction);
    }
  };
}

export const sendPrices: ActionCreator<
ThunkAction<
  Promise<ISendPricesSuccessAction | ISendPricesFailureAction>,
  any,                 
  any, 
  ISendPricesSuccessAction | ISendPricesFailureAction
>
>  = (documentData) => {
  return async (dispatch: Dispatch) => {
    const sendPricesPendingAction: ISendPricesPendingAction = {
      type: 'SEND_PRICE_PENDING',
    };
    dispatch(sendPricesPendingAction);

    try {
      let formData = new FormData();
      console.log(documentData[0])
      formData.append("marketPrice", documentData[0]);
      console.log(formData); // not consoled, blet! empty!!
      
      const authDataResponse = await Api.post(`/core-service/market-prices`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const preview = authDataResponse.data
      const sendPricesSuccessAction:ISendPricesSuccessAction = {
        type: 'SEND_PRICE_SUCCESS',
        preview
      };
      return dispatch(sendPricesSuccessAction);
    } catch (error) {

      const sendPricesFailureAction: ISendPricesFailureAction = {
        type: 'SEND_PRICE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(sendPricesFailureAction);
    }
  };
};

export const downloadPricesFile:ActionCreator<
ThunkAction<
  Promise<IDownloadPricesAction | IDownloadPricesPendingAction | IDownloadPricesFailureAction>,
  any,
  null,
  IDownloadPricesAction
>
>  = (id) => {
  return async (dispatch: Dispatch) => {
    const getStaffListPendingAction:IDownloadPricesPendingAction = {
      type: 'GET_DOWNLOAD_PRICES_PENDING',
    };
    dispatch(getStaffListPendingAction);
    try {
      const response = await Api.get(`/core-service/market-prices/documents/${id}`, {responseType: "blob"})
      const preview = response.data
      const getStaffListSuccessAction:IDownloadPricesAction = {
        preview,
        type: 'GET_DOWNLOAD_PRICES_SUCCESS',
      };
      dispatch(getStaffListSuccessAction);
      return preview;
    } catch (error) {
      const getStaffListFailureAction: IDownloadPricesFailureAction = {
        type: 'GET_DOWNLOAD_PRICES_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getStaffListFailureAction);
    }
  };
}

export const getMarketPrices:ActionCreator<
    ThunkAction<
        Promise<IGetMarketPricesSuccessAction | IGetMarketPricesPendingAction | IGetMarketPricesFailureAction>,
        any,
        null,
        IGetMarketPricesSuccessAction
        >
    >  = () => {
  return async (dispatch: Dispatch) => {
    const getMarketPricesPendingAction:IGetMarketPricesPendingAction = {
      type: 'GET_MARKET_PRICES_PENDING',
    };
    dispatch(getMarketPricesPendingAction);
    try {
      const response = await Api.get(`/core-service/market-prices`)
      const marketPrices = response.data.data
      console.log(marketPrices)
      const getMarketPricesSuccessAction:IGetMarketPricesSuccessAction = {
        marketPrices,
        type: 'GET_MARKET_PRICES_SUCCESS',
      };
      return dispatch(getMarketPricesSuccessAction);
    } catch (error) {
      const getMarketPricesFailureAction: IGetMarketPricesFailureAction = {
        type: 'GET_MARKET_PRICES_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(getMarketPricesFailureAction);
    }
  };
}

export const calculateMarketPrice:ActionCreator<
    ThunkAction<
        Promise<ICalculateMarketPriceSuccessAction | ICalculateMarketPricePendingAction | ICalculateMarketPriceFailureAction>,
        any,
        null,
        ICalculateMarketPriceSuccessAction
        >
    >  = (id, area) => {
  return async (dispatch: Dispatch) => {
    const calculateMarketPricePendingAction:ICalculateMarketPricePendingAction = {
      type: 'CALCULATE_MARKET_PRICE_PENDING',
    };
    dispatch(calculateMarketPricePendingAction);
    try {
      const response = await Api.post(`/core-service/market-prices/calculate-price`, { "marketPriceId": id,
        "square": area})
      const marketPriceRange = response.data.data;
      // console.log(marketPriceRange)
      const calculateMarketPriceSuccessAction:ICalculateMarketPriceSuccessAction = {
        marketPriceRange,
        type: 'CALCULATE_MARKET_PRICE_SUCCESS',
      };
      return dispatch(calculateMarketPriceSuccessAction);
    } catch (error) {
      const calculateMarketPriceFailureAction: ICalculateMarketPriceFailureAction = {
        type: 'CALCULATE_MARKET_PRICE_FAILURE',
        payload: {
          ...error.response.data
        }
      };

      return dispatch(calculateMarketPriceFailureAction);
    }
  };
}