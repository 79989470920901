import { Button } from 'antd';
import React, { FC } from 'react';
import {ReactComponent as WarningSvg} from '../../assets/images/ic-search-no-results.svg'
import {userNotFoundScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  goBackHandler: () => void;
}

const UserNotFound: FC<IProps> = (props) => {
  return (
    <div id="userNotFound">
      <h3>{userNotFoundScreen.title}</h3>
      <WarningSvg className="error-img"/>
      <p>{userNotFoundScreen.messagePartOne}
      {userNotFoundScreen.messagePartTwo}</p>
      <div className="btn-wrapper">
        <Button id="back-btn" type="default" onClick={props.goBackHandler}>{userNotFoundScreen.backBtn}</Button>
      </div>
    </div>
  );
};

export default UserNotFound;