import ScreenHeaderList from 'components/ScreenHeaderList/ScreenHeaderList';
import React, { FC, useEffect } from 'react';
import { ReactComponent as UserAddSvg } from 'assets/images/ic-user-add.svg'
import { Button, Space, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import {getStaffList, getStaffViewType, archiveStaff, saveStaffScreenPage} from 'store/staff/actions';
import { Link } from 'react-router-dom';
import { ReactComponent as EditSvg } from 'assets/images/basic-edit.svg';
import { ReactComponent as UnblockSvg } from 'assets/images/ic-block-unblock.svg';
import { ReactComponent as BlockSvg } from 'assets/images/ic-block.svg';
import {
  ISaveStaffScreenPage, ISaveStaffScreenPageAction,
  ISetStaffFailureAction,
  ISetStaffListAction,
  ISetStaffPendingAction,
  IsetStaffViewAction,
  IStaffArchiveFailureAction,
  IStaffArchiveSuccessAction,
  IStaffView
} from 'store/staff/types';
import ListSearch from 'components/ListSearch/ListSearch';
import ArchiveModal from 'components/ArchiveModal/ArchiveModal';
import { setActualStatus } from 'services/mapper';
import { openNotification } from 'services';
import {staff, smallNotification} from '../../globalConstants/textinputs.json';
import { ISaveUsersScreenPage, ISaveUsersScreenPageAction } from 'store/users/types';
import { saveUsersScreenPage } from 'store/users/actions';
import { ISaveContractScreenInfoAction, ISaveContractScreenPage } from 'store/contracts/types';
import { saveContractScreenPage } from 'store/contracts/actions';

interface IProps {
  history: any;
  getStaffList: (sortBy: string, sortType: string, page: number, size: number, rsql?: string) => Promise<ISetStaffListAction | ISetStaffPendingAction | ISetStaffFailureAction>;
  staff: any;
  getStaffViewType: (data: IStaffView) => IsetStaffViewAction;
  archiveStaff: (id: number, data: any) => Promise<IStaffArchiveSuccessAction | IStaffArchiveFailureAction>;
  saveStaffScreenPage: (staffScreenPage: ISaveStaffScreenPage) => ISaveStaffScreenPageAction;
  saveUsersScreenPage: (usersScreenPage :ISaveUsersScreenPage) => ISaveUsersScreenPageAction;
  saveContractScreenPage: (staffScreenPage: ISaveContractScreenPage) => ISaveContractScreenInfoAction;
}

const mapStateToProps = state => ({
  staff: state.staff,
});

const mapDispatchToProps = {
  getStaffList,
  getStaffViewType,
  archiveStaff,
  saveStaffScreenPage,
  saveUsersScreenPage,
  saveContractScreenPage
};

const connector = connect(mapStateToProps, mapDispatchToProps)

export const StaffScreen: FC<IProps> = (props) => {

  useEffect(() => {
    props.getStaffList('id', 'desc', currentPage, 10, rsql)
    props.saveUsersScreenPage({
      currentPage: 0
    })
    props.saveContractScreenPage({
      currentPage: 0
    })
  }, [])

  const [currentPage, setCurrentPage] = React.useState<number>(props.staff?.staffScreenPage?.currentPage)

  const [isArchiveModalVisible, setIsArchiveModalVisible] = React.useState(false);

  const [ArchiveModalContent, setrchiveModalContent] = React.useState({ id: "", status: "" });

  const [rsql, setRsql] = React.useState(props.staff?.staffScreenInfo?.string);

  const viewTypeHandler = (value) => (e) => {
    props.getStaffViewType(value)
    props.saveStaffScreenPage({
      currentPage: currentPage
    })
  }

  const columns: Array<any> = [
    {
      title: 'ПІБ',
      dataIndex: 'fio',
      sorter: true,
      showSorterTooltip: false,
      render: (fio, record) => {
        return <Link to={`/dashboard/staff/${record.id}`} onClick={viewTypeHandler(false)}>
          {fio.lastName} {fio.firstName} {fio.patronymic}
        </Link>
      }
    },
    {
      title: staff.list.tableColPosition,
      dataIndex: 'position',
    },
    {
      title: staff.list.tableColPhone,
      dataIndex: 'phone',
    },
    {
      title: staff.list.tableColEmail,
      dataIndex: 'email',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: staff.list.tableColActions,
      render: (record) => {
        return (
          <Space size="middle">
            <Tooltip title={staff.list.tableColActionsEditTooltip}><Link to={`/dashboard/staff/${record.id}`} onClick={viewTypeHandler(true)}><EditSvg /></Link></Tooltip>
            <Tooltip title={record.status === "ACTIVE" || record.status === "NOT_VERIFIED" ? staff.list.tableColActionsBlockTooltip : staff.list.tableColActionsUnBlockTooltip}>
              <Button type="link" onClick={modalArchiveHandler(record.id, record.status)}>
                {record.status === "ACTIVE" || record.status === "NOT_VERIFIED" ? <UnblockSvg /> : <BlockSvg />}
              </Button></Tooltip>
          </Space>
        )
      }
    }
  ];

  const modalArchiveHandler = (id, status) => (e) => {
    setrchiveModalContent({ id: id, status: status })
    setIsArchiveModalVisible(true)
  }

  const handleModalClose = () => {
    setIsArchiveModalVisible(false)
  }

  const archiveHandlerCallback = (id, status) => {
    props.archiveStaff(id, { "status": setActualStatus(status) }).then(resp => {
      if (resp.type === "ARCHIVE_STAFF_SUCCESS") {
        props.getStaffList('id', 'desc', props.staff.results.number, 10, rsql)
      } else {
        if (resp.payload.error === "ERR_STAFF_008") {
          openNotification('error', smallNotification.errorTitle, staff.errors.cannotBlockSelf)
        }
      }
    })
  }

  const tableOnchangeHandler = (pagination: any, filter: any, sorter: any) => {
    let orderBy = sorter.order === "ascend" ? "asc" : "desc"
    if (sorter.field === "fio") {
      sorter.field = "fio.lastName"
    }
    setCurrentPage(pagination.current - 1);
    props.getStaffList(sorter.field || "id", orderBy || "desc", pagination.current - 1, pagination.pageSize, rsql)
  }

  const filterSearch = (rsqlString) => {
    setRsql(rsqlString)
    props.getStaffList('id', 'desc', 0, 10, rsqlString)
  }

  return (
    <div>
      <ArchiveModal
        isModalVisible={isArchiveModalVisible}
        handleModalClose={handleModalClose}
        id={ArchiveModalContent.id}
        status={ArchiveModalContent.status}
        archivePartnerHandler={archiveHandlerCallback}
        archivedText={<span>{staff.blockModal.unBlockMessagePartOne}<br />{staff.blockModal.unBlockMessagePartTwo}</span>}
        unArchivedText={<span>{staff.blockModal.blockMessagePartOne}<br /> {staff.blockModal.blockMessagePartTwo}</span>}
      />
      <ScreenHeaderList
        title={staff.list.mainHeading}
        buttonText={staff.list.tableAddBtn}
        btnImage={<UserAddSvg />}
        path={'staff'}
        isCreate={true}
      />
      <ListSearch role={"staff"} filterSearch={filterSearch}  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}/>
      <Table
        columns={columns}
        dataSource={props.staff.results?.content || []}
        onChange={tableOnchangeHandler}
        pagination={{
          defaultPageSize: 10,
          total: props.staff.results?.totalElements,
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '30'],
          position: ["bottomCenter"],
          current: currentPage + 1
        }}
        loading={props.staff.isFetching}
      />
    </div>
  );
};

export default connector(StaffScreen);