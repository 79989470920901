import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as SearchSmallSvg } from 'assets/images/ic-search-small.svg';
import { ReactComponent as SearchWhiteSvg } from 'assets/images/ic-search-w.svg';
import { ReactComponent as ReloadSvg } from 'assets/images/ic-reload.svg';
import { ReactComponent as FilterSvg } from 'assets/images/ic-filter.svg';
import "./AgentFilter.scss";
import { useForm } from 'antd/lib/form/Form';
import regionsList from '../../globalConstants/regions.json'
import purposeCodes from '../../globalConstants/purposeCodes.json'
import { IAdvertRsql, IGetAdvertAction } from 'store/adverts/types'
import { setAdvertFilter } from 'store/adverts/actions'
import { getLocationDistrictAdress, getLocationLocalityAdress } from 'store/address/actions'

import { connect } from 'react-redux';
import { ISetLocationDistrictFailureAction, ISetLocationDistrictListAction, ISetLocationLocalityListAction } from 'store/address/types';
import { adverts } from '../../globalConstants/textinputs.json';

interface IProps {
  setAdvertFilter: (data: any) => IGetAdvertAction;
  getLocationDistrictAdress: (region: string) => Promise<ISetLocationDistrictListAction>
  getLocationLocalityAdress: (region: string, district: string) => Promise<ISetLocationLocalityListAction>
  changeSearchedState: (value: boolean) => void;
}

const mapStateToProps = state => ({
  staff: state.staff,
});

const mapDispatchToProps = {
  setAdvertFilter,
  getLocationDistrictAdress,
  getLocationLocalityAdress
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const AgentFilter: FC<IProps> = (props) => {

  const [form] = useForm();

  const [toggle, setToggle] = React.useState(false);

  const [region, setRegion] = React.useState(null);

  const [district, setDistrict] = React.useState(null);

  const [locality, setLocality] = React.useState(null);

  //const [SingleSearch, setSingleSearch] = React.useState(false);

  const onFinish = (values: any) => {
    console.log(values);
    // if(SingleSearch) {
    //   values = {"advertName|fullSearch" : values.advertNameANDfullSearch}
    //   console.log(values);

    // }
    values = { ...values, "advertName|fullSearch": values.advertNameANDfullSearch }
    let filtersArray = Object.keys(values).filter(item => Boolean(values[item])).map(filterItemName => {
      let [field, type] = filterItemName.split("|");
      switch (type) {
        case "fullSearch":
          return `(land.cadastralNumber%3D%3D*${values[filterItemName]}*,id%3D%3D*${values[filterItemName]}*,author.fio.lastName%3D%3D*${values[filterItemName]}*,author.fio.firstName%3D%3D*${values[filterItemName]}*,author.email%3D%3D*${values[filterItemName]}*,author.phone%3D%3D*${values[filterItemName]}*)`;
        case "full":
          return `${field}%3D%3D${values[filterItemName]}`;
        case "from":
          return `${field}%3Dge%3D${values[filterItemName]}`;
        case "to":
          return `${field}%3Dlt%3D${values[filterItemName]}`;
        default:
          return "";
      }
    })
    let filters = filtersArray.filter(item => item !== '' && item !== undefined).join(";")
    props.setAdvertFilter(filters)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const toggleHandler = () => {
    setToggle(!toggle)
  }

  const resetFilterHandler = () => {
    form.resetFields();
    setRegion(null)
    setDistrict(null)
    setLocality(null)
    form.submit()
  }

  const submitSearchHandler = () => {
    //setSingleSearch(true)
    form.submit()
    props.changeSearchedState(true);
  }

  // const submitBottomSearchHandler = () => {
  //   setSingleSearch(false)
  //   form.submit()
  // }

  const getDistrictHandler = (value) => {
    setRegion(value)
    form.setFieldsValue({ "land.registryAddress.district|full": null, "land.registryAddress.settlement|full": null })
    setLocality(null)
    props.getLocationDistrictAdress(value).then(resp => {
      setDistrict(resp.LocalityData.data)
    })
  }

  const getLocalityHandler = (value) => {
    props.getLocationLocalityAdress(region, value).then(resp => {
      setLocality(resp.LocalityData.data)
    })
  }

  return (
    <div>
      <Form
        name="agent-search"
        initialValues={{ userRole: "ALL" }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="top-line">
          <Button type="primary" className="filter-btn" onClick={toggleHandler}>
            Параметри пошуку
          </Button>
          <Form.Item
            name="advertNameANDfullSearch"
            className="search-input"
          >
            <Input placeholder="Пошук представників…" prefix={<SearchSmallSvg width="16px" height="16px" />} />
          </Form.Item>
          <Button type="primary" id="staff-search-btn" htmlType="submit" onClick={submitSearchHandler}><SearchWhiteSvg />Знайти</Button>
        </div>
        <div className={toggle ? "bottom-line active" : "bottom-line"}>
          <Row gutter={24} className="custom-row">
            <Col span={7}>
              <Form.Item
                name="land.registryAddress.region|full"
              >
                <Select placeholder={adverts.main.filters.regionField} onChange={getDistrictHandler}>
                  {regionsList.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="land.registryAddress.district|full"
              >
                <Select placeholder={adverts.main.filters.districtField} onChange={getLocalityHandler}>
                  {district &&
                    district.map((item, index) => (
                      <Select.Option key={index} value={item.district}>
                        {item.district}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="land.registryAddress.settlement|full"
              >
                <Select placeholder={adverts.main.filters.localityField}>
                  {locality &&
                    locality.map((item, index) => (
                      <Select.Option key={index} value={item.location}>
                        {item.location}
                      </Select.Option>
                    ))
                  }
                </Select>
                {/* <Input placeholder="Населений пункт" /> */}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button type="default" size="small" id="reset-filters" onClick={resetFilterHandler}><ReloadSvg />{adverts.main.filters.resetFiltersBtn}</Button>
              {/* <Button type="primary" size="small" onClick={submitBottomSearchHandler}><FilterSvg />Фільтрувати</Button> */}
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default connector(AgentFilter);