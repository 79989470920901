import React, { FC } from 'react';
import { ReactComponent as SearchNoResults } from 'assets/images/ic-search-no-results.svg';
import './EmptyContent.scss';

interface IProps {
    messageOne?: string;
    messageTwo?: string;
}

const EmptyContent: FC<IProps> = (props) => {
    return (
        <div className="empty-content">
            <SearchNoResults/>
            { props.messageOne && <p className="message-one">{props.messageOne}</p>}
            { props.messageTwo && <p className="message-two">{props.messageTwo}</p>}
        </div>
    )
}

export default EmptyContent;