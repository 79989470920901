import { 
    ISelectLocale, 
    ISetLocaleSuccessAction, 
    ISetLocalePendingAction, 
    ISetLocaleFailureAction,
    IGetLocalesListPendingAction,
    IGetLocalesListSuccessAction,
    IGetLocalesListFailureAction,
    IInitLocalesPendingAction,
    IInitLocalesSuccessAction,
    IInitLocalesFailureAction, 
    ILocaleState,
    IDictionaryStore,
    IDictionary,
    IConfig,
    ILocalesListItem
} from "./types";
import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import 'moment/locale/ru';
import 'moment/locale/uk';
// export function initLocales(lang) {
//     return async  (dispatch, getState) => {
//       console.log('action', lang)
//       dispatch({
//           type: 'SET_LANGUAGE',
//           payload: { lang }
//       })
//     }
// }

export const initLocales: ActionCreator<
ThunkAction<
  Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
  ILocaleState,                  // The type for the data within the last action
  IConfig,                       // The type of the parameter for the nested function 
  IInitLocalesSuccessAction | IInitLocalesFailureAction  // The type of the last action to be dispatched
>
> = (config: IConfig) => {
  return async (dispatch: Dispatch) => {

        const initLocalesPendingAction: IInitLocalesPendingAction = {
            type: 'INIT_LOCALES_PENDING',
        };
        dispatch(initLocalesPendingAction);

        try {

            let localeDictionaries:IDictionaryStore = {};
            let localesList:ILocalesListItem[] = [];
            let currentLocaleJSON:IDictionary = {};
            let mainLocaleJSON:IDictionary = {};

            const allPromisesDone = await Promise.all(config.packages.map( async (currentPackage, packageNumber) => {
                try{
                    const importedLang: any = await import(`locales/${currentPackage.dictionaryPath}`);
                    // import(`moment/locale/${currentPackage.dictionaryPath.split('.')[0]}`);
                    // console.log('moment file',`moment/locale/${currentPackage.dictionaryPath.split('.')[0]}`)
                    localeDictionaries[currentPackage.code] = importedLang.default;
                    localesList.push({
                        code: currentPackage.code,
                        name: currentPackage.name
                    });
                    // localesList.push(currentPackage.name);
                } catch (e) {
                    console.error(e);
                }
            }))

            currentLocaleJSON = {...localeDictionaries[config.mainLocale]};
            mainLocaleJSON =  {...localeDictionaries[config.mainLocale]};
            // console.log('Done copy',localeDictionaries, localeDictionaries[config.mainLocale])
        
            const initLocalesSuccessAction: IInitLocalesSuccessAction = {
                type: 'INIT_LOCALES_SUCCESS',
                adaptedLocales: {
                    mainLocale: config.mainLocale,
                    currentLocale: config.mainLocale,
                    localeDictionaries,
                    localesList,
                    currentLocaleJSON,
                    mainLocaleJSON
                }
            };

            return dispatch(initLocalesSuccessAction);
            
            
        } catch (e) {
            const initLocalesFailureAction: IInitLocalesFailureAction = {
                type: 'INIT_LOCALES_FAILURE',
            };
            return dispatch(initLocalesFailureAction);
        }


  };
};


export const getLocalesList: ActionCreator<
  ThunkAction<
    Promise<IGetLocalesListSuccessAction | IGetLocalesListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    string,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetLocalesListSuccessAction | IGetLocalesListFailureAction  // The type of the last action to be dispatched
  >
> = (lang: string) => {
    return async (dispatch: Dispatch) => {

        const getLocalesListPendingAction: IGetLocalesListPendingAction = {
            type: 'GET_LOCALES_LIST_PENDING',
        };
        dispatch(getLocalesListPendingAction);

        try {
            const getLocalesListSuccessAction: IGetLocalesListSuccessAction = {
                type: 'GET_LOCALES_LIST_SUCCESS',
            };
            return dispatch(getLocalesListSuccessAction);
        } catch (e) {
            const getLocalesListFailureAction: IGetLocalesListFailureAction = {
                type: 'GET_LOCALES_LIST_FAILURE',
            };
            return dispatch(getLocalesListFailureAction);
        }


    };
};

export const selectLocale: ActionCreator<
  ThunkAction<
    Promise<ISetLocaleSuccessAction | ISetLocaleFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    string,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    ISetLocaleSuccessAction | ISetLocaleFailureAction  // The type of the last action to be dispatched
  >
> = (lang: string) => {
    return async (dispatch: Dispatch) => {

        const setLocalePendingAction: ISetLocalePendingAction = {
            type: 'SET_LOCALE_PENDING',
        };
        dispatch(setLocalePendingAction);

        try {
            const setLocaleSuccessAction: ISetLocaleSuccessAction = {
                type: 'SET_LOCALE_SUCCESS',
                lang
            };
            return dispatch(setLocaleSuccessAction);
        } catch (e) {
            const setLocaleFailureAction: ISetLocaleFailureAction = {
                type: 'SET_LOCALE_FAILURE',
            };
            return dispatch(setLocaleFailureAction);
        }


    };
};