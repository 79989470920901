import { Button, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { Input } from 'antd';
import {
  IAdvertBlockFailureAction,
  IAdvertBlockSuccessAction, IAdvertGetOnModerationCountFailureAction, IAdvertGetOnModerationCountPendingAction,
  IAdvertGetOnModerationCountSuccessAction
} from 'store/adverts/types';
import { connect } from 'react-redux';
import {advertBlock, getAdvertOnModerationCount} from 'store/adverts/actions'
import "./BlockAdvertModal.scss";
import {adverts as advertText, smallNotification} from '../../globalConstants/textinputs.json';

const { TextArea } = Input;

interface IProps {
  isModalVisible: boolean;
  closeModal: (boolean) => void;
  onSubmit?: () => void;
  id: string;
  updateSingleAdvert: () => void;
  advertBlock: (id: string, data: any) => Promise<IAdvertBlockSuccessAction | IAdvertBlockFailureAction>;
  getAdvertOnModerationCount: () => Promise<IAdvertGetOnModerationCountSuccessAction | IAdvertGetOnModerationCountFailureAction>;
}

const BlockAdvertModal: FC<IProps> = (props) => {

  const [form] = useForm();

  const [isBlockBtnDisabled, setIsBlockBtnDisabled] = React.useState<boolean>(false);

  const handleCancel = () => {
    props.closeModal(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    // console.log('Success:', values);

    if (isBlockBtnDisabled) {
      return
    }

    setIsBlockBtnDisabled(true);

    props.advertBlock(props.id, values).then(resp => {
      if (resp.status === "OK") {
        props.closeModal(false);
        form.resetFields();
        setIsBlockBtnDisabled(false);
        props.updateSingleAdvert()
        props.getAdvertOnModerationCount();
      }
    })
    // props.updateStaff(props.match.params.id, prepareAddStaff(values)).then(resp => {
    //   console.log(resp)
    //   if (resp.type === "STAFF_UPDATE_SUCCESS") {
    //     props.getSingleStaff(props.match.params.id)
    //     setIsEdited(false)
    //   } else if (resp.payload.error === "ERR_PARAM_INVALID") {
    //     openNotification('error', smallNotification.errorTitle, "Невiрний формат телефону!")
    //   } else {
    //     setErrorCode(resp.payload.error)
    //   }
    // })
  };

  return (
    <Modal title={advertText.blockModal.title}
      visible={props.isModalVisible}
      closable={false}
      onCancel={handleCancel}
      footer={null}
      className="block-modal"
      centered={true}
    >
      <Form
        name="edit-staff"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="blockDescription"
          label={advertText.blockModal.reasonLabel}
          rules={[
            { required: true, message: advertText.blockModal.requiredFieldErrorMessage }
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <div className="btn-wrapper">
          <Button type="default" id="blockCancel" className="btn" key={'1'} onClick={handleCancel}>
            {advertText.blockModal.cancelBtn}
          </Button>
          <Button type="primary" disabled={isBlockBtnDisabled} className="btn" key={'2'} htmlType="submit">
            {advertText.blockModal.blockBtn}
        </Button>
        </div>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  advertBlock,
  getAdvertOnModerationCount
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockAdvertModal);