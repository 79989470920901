import { Spin } from 'antd';
import React, { FC } from 'react';
import "./SpinComponent.scss";

interface IProps {
  fullHeight?: boolean;
}

const SpinComponent:FC<IProps> = (props) => {
  return (
    <div className={props.fullHeight ? "spin-wrapper full-height" : "spin-wrapper"}>
      <Spin size="default" />
    </div>
  );
};

export default SpinComponent;