import { Button, Form, Input } from 'antd'
import React, { FC } from 'react';
import { passwordIsValid } from 'services';
import { ReactComponent as LightLogo } from "../../assets/images/lightLogo.svg"
import {passwordConfirmationStep} from "../../globalConstants/textinputs.json";

interface IProps {
  text:string;
  title:string;
  passPlaceholder: string;
  isLogo: boolean;
}

const SignUpPasswordStep:FC<IProps> = (props) => {

  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");

  const checkPaswordStrenght = (name) => (e) => {
    if (name === "password") {
      setPassword(e.target.value)
    }
    else {
      setConfirmPassword(e.target.value)
    }
  }
  return (
    <div>
      <div id="registerStep3">
        {props.isLogo ? <div className="img-wrapper">
          <LightLogo />
        </div>: null}
        <h3>{props.title}</h3>
        <p id="registration-subtitle">{props.text}</p>
        <Form.Item
          name="password"
          rules={[{ required: true, message: passwordConfirmationStep.passwordRequiredFieldErrorMessage }]}
        >
          <Input.Password
            placeholder={props.passPlaceholder}
            onChange={checkPaswordStrenght('password')}
          />
        </Form.Item>
        {(password.length && !passwordIsValid(password)) ? <span className="small-size">{passwordConfirmationStep.passwordStructureError}</span> : ""}
        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, message: passwordConfirmationStep.passwordRequiredFieldErrorMessage }]}
        >
          <Input.Password
            placeholder={passwordConfirmationStep.secondInputPlaceholder}
            onChange={checkPaswordStrenght('confirmPassword')}
          />
        </Form.Item>
        {(password.length && confirmPassword.length && (password !== confirmPassword)) ? <span className="small-size">{passwordConfirmationStep.passwordsNotSameError}</span>: ""}
        <div className="btn-wrapper">
          <Button id="submit-signUP-btn" htmlType="submit" type="primary" disabled={(password.length !== 0 && confirmPassword.length !== 0 && passwordIsValid(password) && (password === confirmPassword)) ? false: true}>{passwordConfirmationStep.saveBtn}</Button>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPasswordStep;