import './App.scss';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import GlobalRouter from './router/GlobalRouter';
import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { logoutUser, getTokenData } from 'store/user/actions';
import { IGetUserInfoSuccessAction, IGetUserInfoFailureAction } from 'store/user/types';
import { getUserInfo } from 'store/user/actions';
import { SS_AUTH_TOKEN } from 'store/types';
import { getUserStoredData } from 'services';
import SpinComponent from 'components/SpinComponent/SpinComponent';

interface IProps {
    locale: ILocaleState;
    user: any;
    initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    getTokenData: any
    logoutUser: any;
    getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
}

interface State {

}

export class App extends Component<IProps> {
    state: State = {}

    componentWillMount = () => {
        this.props.initLocales().then(() => {
            const { token } = getUserStoredData();

            // console.log('sessionStorage.getItem(SS_AUTH_TOKEN)', token)
            if(token !== null){
                this.props.getTokenData(token)
                this.props.getUserInfo()
                    .then((data) => {
                        // this.props
                        if(data.type === "GET_USER_INFO_FAILURE"){
                            if(data.payload.error === "Forbidden"){
                                this.props.logoutUser();
                            }
                        }
                        // console.log(this.props, data)
                    });
            }
        });

    }


    render() {
        return (
            <>
            <GlobalRouter/>
            {
                this.props.user.isFetchingUserInfo &&
                <>
                <SpinComponent fullHeight={true}/>
                </>
            }
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    user: state.user
})

const mapDispatchToProps = {
    logoutUser,
    getTokenData,
    initLocales,
    getUserInfo
  };

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
//     initLocales: () => dispatch(initLocales(config)),
//     getTokenData: () => dispatch(getTokenData({role:'token'})),
//     logoutUser: () => dispatch(logoutUser()),
// })

export default connect(mapStateToProps, mapDispatchToProps)(App)
