import React, { Component, PureComponent } from 'react';
import { BrowserRouter, HashRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from 'store';
// import { IUserState } from 'store/user/types';
import { checkAccess, getUserStoredData } from 'services';

// import AuthScreen from ""

interface IProps {
    user: any;
    path: string;
    render: (props: any) => JSX.Element | Element;
    roles?: any;
    exact?: boolean;
}
interface State {

}


export class PrivateRoute extends PureComponent<IProps> {
    state = {}

    getRoles = (storedRoles: string | null): string[] => {
        let roles: string[] | [] = [];
        if(storedRoles){
            const rolesString: string | null = storedRoles;
            roles = rolesString !== null? JSON.parse(rolesString) : [];
        }
        return roles;
    }


    render() {
        const { token, roles } = getUserStoredData();

        return (
            <Route
                exact={this.props.exact}
                path={this.props.path}
                render={props =>
                    !token?
                    (
                        <Redirect to={{ pathname: '/' }} />
                    ) :
                    (
                        this.props.roles && this.props.roles.length?


                            roles && checkAccess(this.props.roles, this.getRoles(roles))?

                                    this.props.render(props)
                                :
                            // this.props.render(props)
                                    <Redirect to={{ pathname: '/dashboard' }} />
                            :
                                this.props.render(props)
                            // authorised so return component
                    )
                }
            />
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: state.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
