import React, {FC} from 'react';
import './IdentitiyAndTaxDocumentsBlock.scss';
import {contracts, users} from '../../globalConstants/textinputs.json';
import {connect} from "react-redux";
import {Button, Col, Form, Input, Row} from "antd";
import {useForm} from "antd/lib/form/Form";
import {editContractIdentityDocuments, getContractDocuments} from "../../store/contracts/actions";
import {
    IEditContractIdentityDocumentsFailureAction,
    IEditContractIdentityDocumentsSuccessAction, IGetContractBuyerDocumentsFailureAction,
    IGetContractBuyerDocumentsPendingAction,
    IGetContractBuyerDocumentsSuccessAction,
    IGetContractSellerDocumentsFailureAction,
    IGetContractSellerDocumentsPendingAction,
    IGetContractSellerDocumentsSuccessAction
} from "../../store/contracts/types";

interface IProps {
    documentsObj: any;
    dealID: number;
    editContractIdentityDocuments: (dealID, body) => Promise<IEditContractIdentityDocumentsSuccessAction | IEditContractIdentityDocumentsFailureAction>;
    owner: "SELLER" | "BUYER";
    getContractDocuments: (id: number, owner: string) => Promise<IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction | IGetContractSellerDocumentsPendingAction | IGetContractBuyerDocumentsPendingAction | IGetContractSellerDocumentsFailureAction
        | IGetContractBuyerDocumentsFailureAction>;
}


const mapDispatchToProps = {
    editContractIdentityDocuments,
    getContractDocuments
};

const connector = connect(null, mapDispatchToProps)

const IdentityAndTaxDocumentsBlock: FC<IProps> = (props) => {

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const [form] = useForm();

    const {documentsObj} = props;

    const [idCardFieldDisabled, setIdCardFieldDisabled] = React.useState<boolean>(false);

    const [passportFieldDisabled, setPassportFieldDisabled] = React.useState<boolean>(false);

    React.useEffect(() => {

        form.setFieldsValue({
            taxpayerIdentificationNumber: documentsObj.taxpayerIdentificationNumber,
            idCardNumber: documentsObj.identityDocument?.idCardNumber,
            number: documentsObj.identityDocument?.number,
            series: documentsObj.identityDocument?.series
        })

    }, [documentsObj])

    React.useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            if (documentsObj.identityDocument?.idCardNumber?.length > 0) {
                setPassportFieldDisabled(true)
            } else {
                setPassportFieldDisabled(false)

            }

            if (documentsObj.identityDocument?.series?.length > 0 || documentsObj.identityDocument?.number?.length > 0) {
                setIdCardFieldDisabled(true)
            } else {
                setIdCardFieldDisabled(false)

            }

        }

        return () => {
            isCancelled = true;
        }

    }, [documentsObj.identityDocument])


    const editInfo = () => {
        setIsEdit(true);
    }

    const stopEdit = () => {
        setIsEdit(false);
    }

    const saveChangesHandler = () => {
        form.submit();
    }

    const onFinish = (values: any) => {
        Object.keys(values).forEach(agentItem => {
            if (values[agentItem] == undefined || values[agentItem] == "") {
                values[agentItem] = null
            }
            return values[agentItem]
        })

        let data = {
            "documentOwner": props.owner,
            "identityDocument": {
                "idCardNumber": values.idCardNumber,
                "number": values.number,
                "series": values.series ? values.series.toUpperCase() : null
            },
            "taxpayerIdentificationNumber": values.taxpayerIdentificationNumber
        }

        props.editContractIdentityDocuments(props.dealID, data).then(resp => {
            if (resp.status === 'OK') {
                props.getContractDocuments(props.dealID, props.owner);
                stopEdit();
            }
        })


    }

    const onFieldsChange = (changedFields, allFields) => {

        switch (changedFields[0].name[0]) {

            case 'series' : {

                let seriesField = allFields.filter(item => item.name[0] === 'series');
                let numberField = allFields.filter(item => item.name[0] === 'number');

                if (seriesField.length) {
                    if (seriesField[0].value?.length > 0 || numberField.value?.length > 0) {
                        setIdCardFieldDisabled(true);
                        form.setFieldsValue({
                            idCardNumber: null,
                        })
                    } else {
                        setIdCardFieldDisabled(false)
                    }
                }


                break;
            }

            case 'number' : {

                let seriesField = allFields.filter(item => item.name[0] === 'series');
                let numberField = allFields.filter(item => item.name[0] === 'number');

                if (numberField.length) {
                    if (seriesField[0].value?.length > 0 || numberField.value?.length > 0) {
                        setIdCardFieldDisabled(true);
                        form.setFieldsValue({
                            idCardNumber: null,
                        })

                    } else {
                        setIdCardFieldDisabled(false)
                    }
                }

                break;
            }

            case 'idCardNumber': {
                let IdCardField = allFields.filter(item => item.name[0] === 'idCardNumber');
                if (IdCardField.length) {

                    if (IdCardField[0].value?.length > 0) {
                        setPassportFieldDisabled(true);
                        form.setFieldsValue({
                            number: null,
                            series: null
                        })

                    } else {
                        setPassportFieldDisabled(false)
                    }
                }
            }

                break;


            default: {
                break;
            }


        }


    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);

    }

    return (
        <>
            {
                isEdit ? <div className="identity-and-tax-documents-block identity-and-tax-documents-edit-block">
                        <div className="block-wrapper">
                            <Form name="edit-identity-and-tax-documents" form={form} onFinish={onFinish}
                                  className="edit-form" onFieldsChange={onFieldsChange}
                                  onFinishFailed={onFinishFailed} initialValues={{
                                taxpayerIdentificationNumber: documentsObj.taxpayerIdentificationNumber,
                                idCardNumber: documentsObj.identityDocument?.idCardNumber,
                                number: documentsObj.identityDocument?.number,
                                series: documentsObj.identityDocument?.series,

                            }}>
                                <div className="block-info info-passport-doc">
                                    <p className="info-title">{contracts.documents.identityDocumentHeading}</p>
                                    <p className="info-subtitle">{contracts.documents.identityDocumentEditText}</p>
                                    <Row gutter={24}>
                                        <Col span={4}>
                                            <Form.Item
                                                name="series"
                                                label={contracts.documents.identityDocumentEditPassportLabel}
                                                rules={[
                                                    {
                                                        pattern: new RegExp(".[a-zA-Z\u0400-\u04FF]"),
                                                        message: "Серія має містити дві літери",
                                                        validateTrigger: 'onSubmit'
                                                    }
                                                ]}
                                            >
                                                <Input type="text" minLength={2} maxLength={2}
                                                       disabled={passportFieldDisabled} className="pass-series-input"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="number-wrapper"
                                                name="number"
                                                label="&nbsp;"
                                                rules={[
                                                    {
                                                        len: 6,
                                                        message: "Номер паспорта має містити 6 цифр",
                                                        validateTrigger: 'onSubmit'
                                                    }
                                                ]}
                                            >
                                                <Input type="number" disabled={passportFieldDisabled} className="pass-number-input"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="idCardNumber"
                                                label={contracts.documents.identityDocumentEditIdCardLabel}
                                                rules={[
                                                    {
                                                        len: 9,
                                                        message: "Номер ID-картки має містити 9 цифр",
                                                        validateTrigger: 'onSubmit'
                                                    }
                                                ]}
                                            >
                                                <Input type="number" disabled={idCardFieldDisabled} className="id-card-number-input"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="block-info info-taxpayer">
                                    <p className="info-title">{contracts.documents.taxDocumentHeading}</p>
                                    <p className="info-subtitle">{contracts.documents.taxDocumentEditText}</p>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item
                                                name="taxpayerIdentificationNumber"
                                                label={contracts.documents.taxDocumentEditLabel}
                                            >
                                                <Input type="number" className="taxpayer-number-input"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </div>
                            </Form>

                        </div>

                        <div className="block-btn-wrapper">
                            <Button type="primary" onClick={saveChangesHandler} className="block-btn save-btn" size="small">
                                {contracts.documents.identityAndTaxDocumentsSaveBtn}
                            </Button>
                        </div>
                    </div>
                    : <div className="identity-and-tax-documents-block identity-and-tax-documents-view-block">
                        <div className="block-wrapper">
                            <div className="block-info info-passport-doc">
                                <p className="info-title">{contracts.documents.identityDocumentHeading}</p>
                                <p className="info-subtitle">{contracts.documents.identityDocumentText}</p>
                                {
                                    documentsObj.identityDocument?.idCardNumber ?
                                        <p className="info-data"><span
                                            className="data-label">{contracts.documents.identityDocumentIdCardLabel}</span>
                                            <span
                                                className="data-number data-id-number">{documentsObj.identityDocument?.idCardNumber}</span>
                                        </p>
                                        :
                                        <p className="info-data"><span
                                            className="data-label">{contracts.documents.identityDocumentPassportLabel}</span>
                                            <span
                                                className="data-number data-passport-full">{`${documentsObj.identityDocument?.series || ""}${documentsObj.identityDocument?.number || ""}`}</span>
                                        </p>
                                }

                            </div>
                            <div className="block-info info-taxpayer">
                                <p className="info-title">{contracts.documents.taxDocumentHeading}</p>
                                <p className="info-subtitle">{contracts.documents.taxDocumentText}</p>
                                <p className="info-data"><span
                                    className="data-label">{contracts.documents.taxDocumentLabel}</span> <span
                                    className="data-number taxpayer-number">{documentsObj.taxpayerIdentificationNumber}</span></p>
                            </div>
                        </div>

                        <div className="block-btn-wrapper">
                            <Button type="primary" className="block-btn edit-btn" size="small" onClick={editInfo}>
                                {contracts.documents.identityAndTaxDocumentsEditBtn}
                            </Button>
                        </div>
                    </div>

            }

        </>

    );
};

export default connector(IdentityAndTaxDocumentsBlock);