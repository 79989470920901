import { Button, Modal } from 'antd';
import React, { FC } from 'react';

interface IProps {
  isModalVisible: boolean;
  closeModal: any;
  title: string;
  isSaved?: boolean;
  onSubmit?: any;
  backPath?: string;
  img: any;
  text: any;
  cancelBtnText?: string;
  successBtnText?: string;
  resetErrorHandler?: any;
  id?: string;
  removeAgentHandler?: (id: string) => void;
  removeChatHandler?: (id: string) => void;
}

const RemoveModal: FC<IProps> = (props) => {

  const handleCancel = () => {
    props.closeModal(false);
  };

  const onFinish = () => {
    props.closeModal(false);
    if (props.removeAgentHandler) {
      props.removeAgentHandler(props.id)
    }
    if (props.removeChatHandler) {
      props.removeChatHandler(props.id)
    }
  }

  return (
    <Modal title={props.title}
      visible={props.isModalVisible}
      closable={false}
      onOk={onFinish}
      onCancel={handleCancel}
      footer={null}
      className="modal-info"
      centered={true}
    >
      {props.img}
      <p>{props.text}</p>
      {props.children}
      <div className="btn-wrapper">
        {props.isSaved &&
          <Button type="default" id="removeCancel" className="btn" key={'1'} onClick={handleCancel}>
            {props.cancelBtnText}
          </Button>
        }
        <Button id="removeSuccess" type="primary" className="btn" key={'2'} onClick={onFinish}>
          {props.successBtnText}
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveModal;