import React, {FC} from 'react';
import {ReactComponent as AttachSvg} from 'assets/images/ic-attach.svg'
import {ReactComponent as DeleteSvg} from 'assets/images/ic-delete-black.svg'
import {ReactComponent as DownloadSvg} from 'assets/images/ic-download-black.svg'
import {ReactComponent as WarningSvg} from 'assets/images/ic-warning.svg'
import './DocumentsBlock.scss';
import {Button, Skeleton} from 'antd';
import {documentSubTitleMapper, documentTitleMapper} from "../../services/mapper";
import {contracts} from '../../globalConstants/textinputs.json';
import {downloadSingleAgentDocument} from "../../store/agents/actions";
import {
    DocumentTypes,
    IDocument, IDownloadSingleAgentDocumentFailureAction,
    IDownloadSingleAgentDocumentPendingAction,
    IDownloadSingleAgentDocumentSuccessAction
} from "../../store/agents/types";
import {
    IDownloadSingleContractDocumentFailureAction,
    IDownloadSingleContractDocumentPendingAction,
    IDownloadSingleContractDocumentSuccessAction,
} from "../../store/contracts/types";
import {downloadSingleContractDocument} from "../../store/contracts/actions";
import {connect} from "react-redux";
import UploadDocumentModal from "../UploadDocumentModal/UploadDocumentModal";
import RemoveDocumentModal from "../RemoveDocumentModal/RemoveDocumentModal";

interface IProps {
    sourceEntity: "DEAL" | "AGENT";
    type: DocumentTypes;
    owner?: "SELLER" | "BUYER";
    activeDealID?: number;
    agentID?: number;
    documentsList?: Array<IDocument>;
    downloadSingleContractDocument: (dealID: number, documentID: number) => Promise<IDownloadSingleContractDocumentSuccessAction | IDownloadSingleContractDocumentPendingAction | IDownloadSingleContractDocumentFailureAction>;
    downloadSingleAgentDocument: (agentID: number, documentID: number) => Promise<IDownloadSingleAgentDocumentSuccessAction | IDownloadSingleAgentDocumentPendingAction | IDownloadSingleAgentDocumentFailureAction>;
    documentsLoading?: boolean;
}


const mapDispatchToProps = {
    downloadSingleContractDocument,
    downloadSingleAgentDocument
};

const connector = connect(null, mapDispatchToProps)

const DocumentsBlock: FC<IProps> = (props) => {

    const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState<boolean>(false);

    const [isUploadModalVisible, setIsUploadModalVisible] = React.useState<boolean>(false);

    const [deleteModalContent, setDeleteModalContent] = React.useState<any>({});

    const openUploadModal = () => {
        setIsUploadModalVisible(true);
    }

    const closeDeleteModalHandler = () => {
        setIsDeleteModalVisible(false);
        setDeleteModalContent({});
    }

    const downloadFileHandler = (entityID, documentID, name) => e => {

        const formDownloadLink = (res) => {
            // @ts-ignore
            var blob = new Blob([res]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        switch (props.sourceEntity) {
            case "DEAL": {
                props.downloadSingleContractDocument(entityID, documentID).then(res => {
                    formDownloadLink(res);
                });
                break;

            }
            case "AGENT": {
                props.downloadSingleAgentDocument(entityID, documentID).then(res => {
                  formDownloadLink(res)
                });
                break;
            }

            default: {
                break;
            }

        }


    }

    const deleteFileHandler = (entityID, documentID, fileName) => (e) => {
        setDeleteModalContent({entityID: entityID, documentID: documentID, fileName: fileName})
        setIsDeleteModalVisible(true);
    }

    const currentDocumentsMapper = (documents) => {
        return documents.map((item, index) => {
            return (
                <div className="list-item" key={item.id}>
                    <span className="item-title">{item.fileName}</span>
                    <div className="item-btn-wrapper">
                        {
                            props.sourceEntity === "DEAL" &&
                            <>
                                <button className="item-btn item-btn-download"
                                        onClick={downloadFileHandler(props.activeDealID, item.id, item.fileName)}><DownloadSvg/>
                                </button>
                                <button className="item-btn item-btn-delete" onClick={deleteFileHandler(props.activeDealID, item.id, item.fileName)}><DeleteSvg/></button>
                            </>
                        }

                        {
                            props.sourceEntity === "AGENT" &&
                            <>
                                <button className="item-btn item-btn-download"
                                        onClick={downloadFileHandler(props.agentID, item.id, item.fileName)}><DownloadSvg/>
                                </button>
                                <button className="item-btn item-btn-delete" onClick={deleteFileHandler(props.agentID, item.id, item.fileName)}><DeleteSvg/></button>
                            </>
                        }

                    </div>

                </div>
            )
        })
    }

    return (
            props.documentsLoading ?
               <Skeleton paragraph={{ rows: 1, width: "100%" }}/> :
                <>
                    {props.sourceEntity === "DEAL" &&
                    <>
                        <UploadDocumentModal sourceEntity="DEAL" type={props.type} owner={props.owner} activeDealID={props.activeDealID}
                                             isModalVisible={isUploadModalVisible} setModalVisibility={setIsUploadModalVisible}/>

                        <RemoveDocumentModal sourceEntity="DEAL" isModalVisible={isDeleteModalVisible} owner={props.owner} closeModal={closeDeleteModalHandler} cancelBtnText={contracts.documents.deleteDocumentModalCancelBtn} successBtnText={contracts.documents.deleteDocumentModalRemoveBtn} entityID={deleteModalContent.entityID} documentID={deleteModalContent.documentID}
                                             title={contracts.documents.deleteDocumentModalTitle} img={<WarningSvg/>} text={<p>{contracts.documents.deleteDocumentModalTextOne} <strong>{deleteModalContent.fileName}</strong>?<br/>{contracts.documents.deleteDocumentModalTextTwo}</p>}/>
                    </>
                    }

                    {props.sourceEntity ==="AGENT" &&
                    <>
                        <UploadDocumentModal sourceEntity="AGENT" type={props.type} agentID={props.agentID} isModalVisible={isUploadModalVisible} setModalVisibility={setIsUploadModalVisible}/>

                        <RemoveDocumentModal sourceEntity="AGENT" isModalVisible={isDeleteModalVisible} closeModal={closeDeleteModalHandler} cancelBtnText={contracts.documents.deleteDocumentModalCancelBtn} successBtnText={contracts.documents.deleteDocumentModalRemoveBtn} entityID={deleteModalContent.entityID} documentID={deleteModalContent.documentID}
                                             title={contracts.documents.deleteDocumentModalTitle} img={<WarningSvg/>} text={<p>{contracts.documents.deleteDocumentModalTextOne} <strong>{deleteModalContent.fileName}</strong>?<br/>{contracts.documents.deleteDocumentModalTextTwo}</p>}/>
                    </>
                    }

                    <div className={`${props.type.toLowerCase()} documents-block`}>
                        <div className={`block-info ${props.documentsList && props.documentsList.length ? 'list-present' : 'list-empty'}`}>
                            <p className="info-title">{documentTitleMapper(props.type)}</p>
                            <p className="info-subtitle">{documentSubTitleMapper(props.type)}</p>
                            <div className="info-list">
                                {
                                    props.documentsList && props.documentsList.length ?
                                        <>
                                            {currentDocumentsMapper(props.documentsList)}
                                        </>

                                        : null

                                }
                            </div>


                        </div>
                        <div className="block-btn-wrapper">
                            <Button type="primary" className="block-btn" size="small" onClick={openUploadModal}>
                                <AttachSvg/>
                                {contracts.documents.uploadDocumentUploadBtn}
                            </Button>
                        </div>
                    </div>

                </>
    );
};

export default connector(DocumentsBlock);