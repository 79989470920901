import { Reducer } from 'redux';
import { ChatActions } from './types';

export const initialState = {
  single: [],
  list: [],
  singleChat: [],
  actualChat: [],
  updateFlag: false,
  searchParams: null
}
export const ChatReducer: Reducer<any, ChatActions> = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'GET_CHAT_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_CHAT_LIST_SUCCESS': {
      return {
        ...state,
        list: action.preview,
        isFetching: false,
      };
    }
    case 'GET_CHAT_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'GET_ADMIN_CHAT_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_ADMIN_CHAT_LIST_SUCCESS': {
      return {
        ...state,
        list: action.preview,
        isFetching: false,
      };
    }
    case 'GET_ADMIN_CHAT_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'GET_SINGLE_ROOM_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_SINGLE_ROOM_SUCCESS': {
      return {
        ...state,
        actualChat: action.preview,
        isFetching: false,
      };
    }
    case 'GET_SINGLE_ROOM_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'SET_SINGLE_USER_CHAT_INFO_SUCCESS': {
      return {
        ...state,
        single: action.payload,
      };
    }
    case 'SET_ACTIVE_SINGLE_USER_CHAT_INFO_SUCCESS': {
      return {
        ...state,
        actualChat: action.payload || [],
      };
    }
    case 'GET_SINGLE_CHAT_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_SINGLE_CHAT_SUCCESS': {
      return {
        ...state,
        singleChat: action.preview || [],
        isFetching: false,
      };
    }
    case 'GET_SINGLE_CHAT_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'UPDATE_SINGLE_CHAT_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'UPDATE_SINGLE_CHAT_SUCCESS': {
      // console.log(...state.singleChat);
      console.log(state.singleChat.content)
      return {
        ...state,
        singleChat: {
          ...state.singleChat,
          number: action.preview.number,
          content: ([...action.preview.content, ...state.singleChat.content ])
        },
        isFetching: false,
      };
    }
    case 'UPDATE_SINGLE_CHAT_FAILURE': {
      return { ...defaultErrorState(action) };
    }
    case 'SET_NEW_MESSAGE_SUCCESS': {
      return {
        ...state,
        singleChat: {
          ...state.singleChat,
          content:([...state.singleChat.content, action.payload])
        }
      };
    }
    case 'UPDATE_FLAG_SUCCESS': {
      return {
        ...state,
        updateFlag: action.payload
      };
    }
    case 'UPDATE_SEARCH_SUCCESS': {
      return {
        ...state,
        searchParams: action.payload
      };
    }
    default: return state
  }
}
export default ChatReducer;