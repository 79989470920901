import { Reducer } from 'redux';
import { PricesActions } from './types';

export const initialState = {
    single: {},
    marketPrices: {},
    calculatedMarketPriceFrom: null,
    calculatedMarketPriceTo: null
}
export const PricesReducer: Reducer<any, PricesActions> = (state = initialState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultErrorState = (action: { payload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
      hasError: true,
      ...action.payload
    },
  });

  switch (action.type) {
    case 'GET_PRICES_LIST_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }
    case 'GET_PRICES_LIST_SUCCESS': {
      return {
        ...state,
        results: action.preview,
        isFetching: false,
      };
    }
    case 'GET_PRICES_LIST_FAILURE': {
      return { ...defaultErrorState(action) };
    }

    case 'GET_MARKET_PRICES_PENDING': {
      return { ...defaultStateWithFetchingTrue };
    }

    case "GET_MARKET_PRICES_SUCCESS": {
      return {
        ...state,
        marketPrices: action.marketPrices
      }
    }

    case 'GET_MARKET_PRICES_FAILURE': {
      return { ...defaultErrorState(action) };
    }

    case "CALCULATE_MARKET_PRICE_PENDING": {
      return { ...state };
    }

    case "CALCULATE_MARKET_PRICE_SUCCESS": {
      return {
        ...state,
        calculatedMarketPriceFrom: action.marketPriceRange.calculatedFromPrice.toFixed(2),
        calculatedMarketPriceTo: action.marketPriceRange.calculatedToPrice.toFixed(2)
      }
    }

    case "CALCULATE_MARKET_PRICE_FAILURE": {
        return { ...defaultErrorState(action) };
    }

    default: return state
  }
}
export default PricesReducer;