import React, { FC } from 'react';
import { Divider, Layout, Menu } from 'antd';
import { Switch } from 'react-router-dom';
import "./DashboardScreen.scss";
import { connect, useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'store/user/actions'
import { ILogoutUserFailureAction, ILogoutUserSuccessAction } from 'store/user/types';
import Sider from 'antd/lib/layout/Sider';
import MenuItem from 'components/MenuItem/MenuItem';
import { ReactComponent as FileSvg } from 'assets/images/ic-file.svg'
import { ReactComponent as ContractSvg } from 'assets/images/ic-handshake.svg'
import { ReactComponent as UsersSvg } from 'assets/images/ic-users.svg'
import { ReactComponent as ChatSvg } from 'assets/images/ic-chat-message.svg'
import { ReactComponent as BellSvg } from 'assets/images/ic-bell.svg'
import { ReactComponent as StaffSvg } from 'assets/images/ic-office.svg'
import { ReactComponent as ContentSvg } from 'assets/images/ic-post.svg'
import { ReactComponent as ChartSvg } from 'assets/images/ic-chart.svg'
import { ReactComponent as PriceSvg } from 'assets/images/ic-money.svg'
import { ReactComponent as ProfileSvg } from 'assets/images/ic-profile.svg'
import { ReactComponent as LogoutSvg } from 'assets/images/ic-logout.svg'
import { ReactComponent as ShortLogoSvg } from 'assets/images/shortLogo.svg'
import { ReactComponent as LogoDarkSvg } from 'assets/images/darkLogo.svg'
import { ReactComponent as ExitSvg } from 'assets/images/ic-profile-exit.svg'
import { ReactComponent as AgentsSvg } from 'assets/images/ic-agents.svg'
import { ReactComponent as InfoSvg } from 'assets/images/ic-info.svg'
import { ReactComponent as DeclineSvg } from 'assets/images/ic-choise-decline.svg'
import MenuSearch from 'components/MenuSearch/MenuSearch';
import StaffScreen from 'pages/StaffScreen/StaffScreen';
import AddStaffScreen from 'pages/AddStaffScreen/AddStaffScreen';
import EditStaffScreen from 'pages/EditStaffScreen/EditStaffScreen';
import UsersScreen from 'pages/UsersScreen/UsersScreen';
import EditUserScreen from 'pages/EditUserScreen/EditUserScreen';
import AdvertsScreen from 'pages/AdvertsScreen/AdvertsScreen';
import InfoModal from 'components/InfoModal/InfoModal';
import EditAdvertScreen from 'pages/EditAdvertScreen/EditAdvertScreen';
import ContractsScreen from 'pages/ContractsScreen/ContractsScreen';
import PricesScreen from 'pages/PricesScreen/PricesScreen';
import AgentsScreen from 'pages/AgentsScreen/AgentsScreen';
import AddAgentScreen from 'pages/AddAgentScreen/AddAgentScreen';
import EditAgentScreen from 'pages/EditAgentScreen/EditAgentScreen';
import PrivateRoute from 'router/PrivateRoute';
import ChatScreen from 'pages/ChatScreen/ChatScreen';
import EditContractScreen from "../EditContractScreen/EditContractScreen";
import { sidebar } from '../../globalConstants/textinputs.json';
import {
  IAdvertGetOnModerationCountFailureAction,
  IAdvertGetOnModerationCountSuccessAction
} from "../../store/adverts/types";
import { getAdvertOnModerationCount } from "../../store/adverts/actions";
import { getNewContractsCount } from "../../store/contracts/actions";
import {
  IGetNewContractsFailureAction,
  IGetNewContractsSuccessAction
} from "../../store/contracts/types";
import UserAdvertsScreen from "pages/UserAdvertsScreen/UserAdvertsScreen";
import ServiceMessagesScreen from 'pages/ServiceMessagesScreen';
import { selectServiceMessagesUnreadCount } from 'store/serviceMessages/selectors';
import { getServiceMessagesUnreadCount } from 'store/serviceMessages/actions';
import {getUserStoredData} from "../../services";
import { getTokenData, getUserInfo } from 'store/user/actions';

interface IProps {
  logoutUser: () => Promise<ILogoutUserSuccessAction | ILogoutUserFailureAction>;
  user: any;
  location: any;
  getTokenData: any;
  getUserInfo: any;
  advert: any;
  contracts: any;
  getAdvertOnModerationCount: () => Promise<IAdvertGetOnModerationCountSuccessAction | IAdvertGetOnModerationCountFailureAction>;
  getNewContractsCount: () => Promise<IGetNewContractsSuccessAction | IGetNewContractsFailureAction>
}

const mapStateToProps = state => ({
  user: state.user,
  advert: state.advert,
  contracts: state.contracts
});

const mapDispatchToProps = {
  logoutUser,
  getTokenData,
  getUserInfo,
  getAdvertOnModerationCount,
  getNewContractsCount
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const DashboardScreen: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const serviceMessagesUnreadCount = useSelector(selectServiceMessagesUnreadCount);

  const [advertsOnModeration, setAdvertsOnModeration] = React.useState(props.advert.onModerationCount);

  const [newContractsCount, setNewContractsCount] = React.useState(props.contracts.statusNewCount)

  const [collapsed, onCollapsed] = React.useState<boolean>(false);

  const [isExitModalVisible, setIsExitModalVisible] = React.useState(false);

  const topList = [
    {
      id: 1,
      link: "/dashboard/advert",
      image: <FileSvg />,
      name: sidebar.adverts,
      role: "ROLE_MANAGER",
      count: advertsOnModeration
    },
    {
      id: 2,
      link: "/dashboard/contracts",
      image: <ContractSvg />,
      name: sidebar.deals,
      role: "ROLE_MANAGER",
      count: newContractsCount
    },
    {
      id: 3,
      link: "/dashboard/users",
      image: <UsersSvg />,
      name: sidebar.users,
      role: "ROLE_MANAGER"
    },
    {
      id: 4,
      link: "/dashboard/chat",
      image: <ChatSvg />,
      name: sidebar.chats,
      role: "ROLE_MANAGER",
    },
    {
      id: 5,
      link: "/dashboard/service-messages",
      image: <BellSvg />,
      name: sidebar.serviceMessages,
      role: "ROLE_ADMIN",
      count: serviceMessagesUnreadCount
    }
  ]

  const middleList = [
    {
      id: 6,
      link: "/dashboard/staff",
      image: <StaffSvg />,
      name: sidebar.staff
    },
    {
      id: 12,
      link: "/dashboard/agents",
      image: <AgentsSvg />,
      name: sidebar.agents
    },
    {
      id: 7,
      link: "/dashboard/contentManagment",
      image: <ContentSvg />,
      name: sidebar.contentManagement,
    },
    {
      id: 8,
      link: "/dashboard/statistic",
      image: <ChartSvg />,
      name: sidebar.statistics,
    },
    {
      id: 9,
      link: "/dashboard/prices",
      image: <PriceSvg />,
      name: sidebar.marketPrices,
    }
  ]

  const bottomList = [
    {
      id: 10,
      link: "/dashboard/profile",
      image: <ProfileSvg />,
      name: sidebar.myProfile,
      role: "ROLE_MANAGER"
    }
  ]

  const { getAdvertOnModerationCount, getNewContractsCount } = props;

  React.useEffect(() => {
    let isCancelled = false;

    getAdvertOnModerationCount().then(resp => {
      if (!isCancelled) {
        setAdvertsOnModeration(resp.payload)
      }
    });

    getNewContractsCount().then(resp => {
      if (!isCancelled) {
        setNewContractsCount(resp.payload);
      }
    })

    return () => {
      isCancelled = true;
    }

  }, [])

  React.useEffect(() => {
    setAdvertsOnModeration(props.advert.onModerationCount)
  }, [props.advert.onModerationCount])

  React.useEffect(() => {
    setNewContractsCount(props.contracts.statusNewCount)
  }, [props.contracts.statusNewCount])

  React.useEffect(() => {
    dispatch(getServiceMessagesUnreadCount());
  }, [dispatch]);

  React.useEffect(() => {
    const { token } = getUserStoredData();
    if(token) {
      props.getTokenData(token);
      props.getUserInfo()
    }
  }, [])

  const logOutHandler = () => {
    setIsExitModalVisible(true)
  }

  const logOut = () => {
    props.logoutUser()
  }

  const menuMapper = (list) => {
    if (props.user.userRole === "ROLE_MANAGER") {
      return list.map(item => {
        if (item.role === "ROLE_MANAGER") {
          return (<Menu.Item key={item.id} icon={item.image} title={item.name}>
            <MenuItem link={item.link} name={item.name} collapsed={collapsed} />
            {item.count > 0 ?
              <span className="count"><span className="number">{item.count}</span></span> : null}
          </Menu.Item>)
        }
      })
    } else {
      return list.map(item => {
        return (<Menu.Item key={item.id} icon={item.image} title={item.name}>
          <MenuItem link={item.link} name={item.name} collapsed={collapsed} />
          {item.count > 0 ?
            <span className="count"> <span className="number">{item.count}</span></span> : null}
        </Menu.Item>)
      })
    }
  }

  // const SiderTrigger = <div className="hide-sider"><HideSvg/>{!collapsed ? <span className="link-name">Згорнути меню</span> : null}</div>

  return (
    <div className="dashboard-wrapper">
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapsed}
          breakpoint="lg"
          width="260px"
          // trigger={SiderTrigger}
          onBreakpoint={broken => {
          }}
        >
          <div className="logo">
            {!collapsed ? <LogoDarkSvg /> : <ShortLogoSvg />}
          </div>
          {/* <div className="search-wrapper">
            <MenuSearch collapsed={collapsed} onCollapsed={onCollapsed} />
          </div> */}
          <Menu theme="light" mode="inline">
            {menuMapper(topList)}
            <Divider />
            {menuMapper(middleList)}
            {props.user.userRole !== "ROLE_MANAGER" && <Divider />}
            {menuMapper(bottomList)}
            <Menu.Item key="22" onClick={logOutHandler} className="exit-item">
              <span className="exit-button"><LogoutSvg />{!collapsed ?
                <span className="link-name">{sidebar.exit}</span> : null}</span>
            </Menu.Item>
          </Menu>

        </Sider>
        <div
          className={props.location.pathname !== "/dashboard/chat" ? "content-wrapper" : "content-wrapper full"}>
          <Switch>
            <PrivateRoute exact path="/dashboard/staff" roles={'ROLE_ADMIN'}
              render={props => <StaffScreen {...props} />} />
            <PrivateRoute path="/dashboard/staff/create" roles={'ROLE_ADMIN'}
              render={props => <AddStaffScreen {...props} />} />
            <PrivateRoute path="/dashboard/staff/:id" roles={'ROLE_ADMIN'}
              render={props => <EditStaffScreen {...props} />} />

            <PrivateRoute exact path="/dashboard/users" render={props => <UsersScreen {...props} />} />
            <PrivateRoute exact path="/dashboard/users/:id/adverts" render={props => <UserAdvertsScreen {...props} />} />
            <PrivateRoute path="/dashboard/users/:id" render={props => <EditUserScreen {...props} />} />

            <PrivateRoute exact path="/dashboard/advert" render={props => <AdvertsScreen {...props} />} />
            <PrivateRoute path="/dashboard/advert/:id" render={props => <EditAdvertScreen {...props} />} />

            <PrivateRoute exact path="/dashboard/contracts"
              render={props => <ContractsScreen {...props} />} />
            <PrivateRoute exact path="/dashboard/contracts/:id"
              render={props => <EditContractScreen {...props} />} />

            <PrivateRoute exact path="/dashboard/prices" render={props => <PricesScreen {...props} />} />

            <PrivateRoute exact path="/dashboard/agents" render={props => <AgentsScreen {...props} />} />
            <PrivateRoute path="/dashboard/agents/create" render={props => <AddAgentScreen {...props} />} />
            <PrivateRoute path="/dashboard/agents/:id" render={props => <EditAgentScreen {...props} />} />

            <PrivateRoute exact path="/dashboard/chat" render={props => <ChatScreen {...props} />} />
            <PrivateRoute exact path="/dashboard/service-messages" render={props => <ServiceMessagesScreen {...props} />} />
          </Switch>
        </div>
        <InfoModal
          isSaved={true}
          title={sidebar.exitModalHeading}
          isModalVisible={isExitModalVisible}
          closeModal={setIsExitModalVisible}
          cancelBtnText={sidebar.exitModalExitBtn}
          onSubmit={logOut}
          img={<ExitSvg />}
          text={sidebar.exitModalMessage}
          successBtnText={sidebar.exitModalBackBtn}
        />
      </Layout>
    </div>
  );
}

export default connector(DashboardScreen);


