import { Button } from 'antd';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as WarningSvg} from '../../assets/images/ic-warning.svg'
import {userBadCredentialsScreen} from '../../globalConstants/textinputs.json';

interface IProps {
  goBackHandler: () => void;
}

const UserBadCredentials: FC<IProps> = (props) => {
  return (
    <div id="userNotFound">
      <h3>{userBadCredentialsScreen.title}</h3>
      <WarningSvg className="error-img"/>
      <p>{userBadCredentialsScreen.messagePartOne}<br />
  {userBadCredentialsScreen.messagePartTwo}</p>
      <div className="btn-wrapper">
        <Button id="back-btn" type="default" onClick={props.goBackHandler}>{userBadCredentialsScreen.backBtn}</Button>
        <Button type="primary" id="restore-pass-btn"><Link to="/restorePassword">{userBadCredentialsScreen.resetPasswordBtn}</Link></Button>
      </div>
    </div>
  );
};

export default UserBadCredentials;