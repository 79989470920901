import {Button, Col, Form, Input, Row, Select, Spin, Upload} from 'antd';
import React, { FC } from 'react';
import "./EditAgentScreen.scss";
import { connect } from 'react-redux';

import {getAgentDocuments, getSingleAgent, removeAgent, updateAgent, updateAvatar} from 'store/agents/actions'
import { ReactComponent as EditSvg } from 'assets/images/ic-edit.svg';
import { ReactComponent as BlockSvg } from 'assets/images/ic-block-w.svg'
import { ReactComponent as DeclineSvg } from 'assets/images/ic-choise-decline.svg'
import { ReactComponent as ApproveSvg } from 'assets/images/ic-choise-approve.svg'
import { ReactComponent as WarningSvg } from 'assets/images/ic-warning.svg'
import { ReactComponent as BackSvg } from 'assets/images/ic-back.svg'
import InfoModal from 'components/InfoModal/InfoModal';
import { openNotification } from 'services';
import { useForm } from 'antd/lib/form/Form';
import SpinComponent from 'components/SpinComponent/SpinComponent';
import { Link } from 'react-router-dom';
import {
  IAgentRemoveFailureAction,
  IAgentRemoveSuccessAction,
  IAgentUpdateFailureAction,
  IAgentUpdatePendingAction,
  IAgentUpdateSuccessAction, IDocument,
  IGetAgentsDocumentsFailureAction,
  IGetAgentsDocumentsPendingAction,
  IGetAgentsDocumentsSuccessAction,
  ISetSingleAgentAction,
  ISetSingleAgentFailureAction,
  ISetSingleAgentPendingAction,
  IupdateAvatarFailureAction,
  IupdateAvatarSuccessAction
} from 'store/agents/types';
import DocumentsBlock from "../../components/DocumentsBlock/DocumentsBlock";
import regionsList from "../../globalConstants/regions.json";
import { getDistrictAdress, getLocalityAdress } from 'store/address/actions';
import {ReactComponent as FolderSvg} from "../../assets/images/ic-folder.svg";
import RemoveModal from "../../components/RemoveModal/RemoveModal";
import {agents, smallNotification} from '../../globalConstants/textinputs.json';

const { Option } = Select;

let OnlyRegionsList = regionsList.filter(item => item.title !== "Всі")

interface IProps {
  history: any;
  match: any;
  getSingleAgent: (id: number) => Promise<ISetSingleAgentAction | ISetSingleAgentPendingAction | ISetSingleAgentFailureAction>;
  agents: any;
  user: any;
  getDistrictAdress: any;
  getLocalityAdress: any;
  updateAgent: (id: number, data: any) => Promise<IAgentUpdateSuccessAction | IAgentUpdateFailureAction>
  updateAvatar: (id: number, documentData: any) => Promise<IupdateAvatarSuccessAction | IupdateAvatarFailureAction>
  removeAgent: (id: number) => Promise<IAgentRemoveSuccessAction | IAgentRemoveFailureAction>;
  getAgentDocuments: (id: number) => Promise<IGetAgentsDocumentsSuccessAction | IGetAgentsDocumentsPendingAction | IGetAgentsDocumentsFailureAction>
}

const mapStateToProps = state => ({
  agents: state.agents,
  user: state.user
});

const mapDispatchToProps = {
  getSingleAgent,
  getDistrictAdress,
  getLocalityAdress,
  updateAgent,
  updateAvatar,
  removeAgent,
  getAgentDocuments
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const EditAgentScreen: FC<IProps> = (props) => {

  const {getSingleAgent, getDistrictAdress, getLocalityAdress} = props;

  const { single, documentsList } = props.agents

  const [form] = useForm();

  const [isEdited, setIsEdited] = React.useState<boolean>(Boolean(props.agents.view));

  const [isResetModalVisible, setIsResetModalVisible] = React.useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);

  const [errorCode, setErrorCode] = React.useState<string>("");

  const [isUserRegModalVisible, setUserRegModalVisible] = React.useState(true);

  const [region, setRegion] = React.useState<any>(single.region);

  const [district, setDistrict] = React.useState<any>([]);

  const [districtValue, setDistrictValue] = React.useState<any>(single.district);

  const [locality, setLocality] = React.useState<any>([]);

  const [localityValue, setLocalityValue] = React.useState<any>(single.locality)

  const [fileList, setfileList] = React.useState([]);

  const [isOverSize, setisOverSize] = React.useState(false);

  const [unknownFormat, setunknownFormat] = React.useState(false);

  const [isFileMissed, setIsFileMissed] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false)

  const [avatarPath, setAvatarPath] = React.useState({"avatarId" : single.avatarId})

  const [isOldAvatar, setIsOldAvatar] = React.useState(true);

  const [identityDocumentsList, setIdentityDocumentsList] = React.useState<Array<IDocument>>([]);
  const [taxpayerIdentificationNumberDocumentsList, setTaxpayerIdentificationNumberDocumentsList] = React.useState<Array<IDocument>>([]);
  const [contractWithRepresentativeDocumentsList, setContractWithRepresentativeDocumentsList] = React.useState<Array<IDocument>>([]);

  const [documentsLoading, setDocumentsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      form.resetFields();
      getSingleAgent(props.match.params.id).then(() => {
        if (isEdited) {
          enableEdit();
        }
        setDocumentsLoading(true);
        props.getAgentDocuments(props.match.params.id).then(resp => {
          setDocumentsLoading(false);
        })
      })


    }

    return () => {
      isCancelled = true;
    }

  }, [])

  React.useEffect(() => {
    setRegion(single.region);
    setDistrictValue(single.district);
    setLocalityValue(single.locality);
    form.setFieldsValue({ ...single, lastName: single.lastName, firstName: single.firstName, phone: single?.phone?.replace('+', ''), region: single.region, district: single.district, locality: single.locality })
  }, [single])

  React.useEffect(() => {
    let identityDocumentsListTemp = documentsList?.filter(item => item.documentType === "IDENTITY_DOCUMENT")[0]?.documents;
    let taxpayerIdentificationNumberDocumentsListTemp = documentsList?.filter(item => item.documentType === "TAXPAYER_IDENTIFICATION_NUMBER")[0]?.documents;
    let contractWithRepresentativeDocumentsListTemp = documentsList?.filter(item => item.documentType === "CONTRACT_WITH_REPRESENTATIVE")[0]?.documents;

    setIdentityDocumentsList(identityDocumentsListTemp);
    setTaxpayerIdentificationNumberDocumentsList(taxpayerIdentificationNumberDocumentsListTemp);
    setContractWithRepresentativeDocumentsList(contractWithRepresentativeDocumentsListTemp);

  },[documentsList])

  const enableEdit = () => {
    setIsEdited(true)
    setIsLoading(true)
    getDistrictAdress(region).then(resp => {

      if (resp.type === 'GET_DISTRICT_SUCCESS') {
        setDistrict(resp.LocalityData.data);
      }

    }).then(() => {
      getLocalityAdress(region, districtValue).then(resp => {
        if (resp.type === "GET_LOCALITY_SUCCESS") {
          setLocality(resp.LocalityData.data)
        }
      }).then(() => {
        setIsLoading(false)
        form.resetFields();

        setfileList([{
          name: single.avatarId,
          uid: "1",
          type: '.png',
          size: 1,
        }])

      })
    })

  }

  const resetModalHandler = () => {
    setIsResetModalVisible(true)
  }

  const fileProps = {
    onRemove: file => {
      setfileList([])
      setisOverSize(false)
      setunknownFormat(false)
    },
    beforeUpload: file => {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        file.flag = true;
        setisOverSize(true)
        return false;
      }
      setIsFileMissed(false)
      setisOverSize(false)
      setfileList([file])
      setIsOldAvatar(false)
      return false;
    }
  };


  const onFinish = (values: any) => {
    if (fileList.length) {
      if (isOldAvatar) {
        updateAgentFunc(values)
      } else {
        props.updateAvatar(props.match.params.id, fileList).then(resp => {
          if (resp.type === "UPDATE_AVATAR_SUCCESS") {
            setAvatarPath({ "avatarId": resp.preview.data })
            let ava = { "avatarId": resp.preview.data }
            props.updateAgent(props.match.params.id, { ...prepareUpdateAgent(values), ...ava }).then(resp => {
              if (resp.type === "AGENT_UPDATE_SUCCESS") {
                setIsEdited(false);
                getSingleAgent(props.match.params.id)

              } else if (resp.payload.error === "ERR_PARAM_INVALID") {
                setIsLoading(false)
                openNotification('error', smallNotification.errorTitle, agents.errors.incorrectPhoneFormat)
              } else {
                setIsLoading(false)
                setErrorCode(resp.payload.error)
              }
            })
          }
        })
      }
    }

  };

  const submitHandler = () => {
    setIsFileMissed(false)
    form.submit();
    if (!fileList.length) {
      setIsFileMissed(true)
    }
  }

  const updateAgentFunc = (values) => {
    props.updateAgent(props.match.params.id, { ...prepareUpdateAgent(values), ...avatarPath }).then(resp => {
      if (resp.type === "AGENT_UPDATE_SUCCESS") {
        setIsEdited(false);
        getSingleAgent(props.match.params.id)
      } else if (resp.payload.error === "ERR_PARAM_INVALID") {
        setIsLoading(false)
        openNotification('error', smallNotification.errorTitle, agents.errors.incorrectPhoneFormat)
      } else {
        setIsLoading(false)
        setErrorCode(resp.payload.error)
      }
    })
  }

  const prepareUpdateAgent = (values) => {
    Object.keys(values).forEach(agentItem => {
      if (values[agentItem] == undefined || values[agentItem] == "") {
        values[agentItem] = null
      }
      return values[agentItem]
    })
    return (
        {
          "district": values.district,
          "email": values.email,
          "phone": `+${values.phone}`,
          "locality": values.locality.split('|')[1],
          "region": values.region,
          "localityType": values.locality.split('|')[0],
          "firstName": values.firstName,
          "lastName": values.lastName,
        }
    )
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const infoBackHandler = () => {
    setIsEdited(false)
    setIsResetModalVisible(false)
  }

  const regionSelectHandler = (name) => {
    setRegion(name)
    getDistrictAdress(name).then(resp => {

      if (resp.type === 'GET_DISTRICT_SUCCESS') {
        setDistrict(resp.LocalityData.data)
        setLocality([]);
        form.setFieldsValue({
          "district": "",
          "locality": ""
        })
      }
    })
  }

  const districtSelectHandler = (name) => {
    setDistrictValue(name);
    getLocalityAdress(region,name).then(resp => {
      if (resp.type === "GET_LOCALITY_SUCCESS") {
        setLocality(resp.LocalityData.data)
        form.setFieldsValue({
          locality: ""
        })
      }
    })
  }

  const switchModals = (name) => {
    switch (name) {
      case 'ERR_AGENT_INFO_003':
        return (<InfoModal
          isSaved={false}
          title={agents.errorModal.heading}
          isModalVisible={isUserRegModalVisible}
          closeModal={setUserRegModalVisible}
          resetErrorHandler={resetErrorHandler}
          backPath={"/dashboard/staff"}
          img={<WarningSvg />}
          text={agents.edit.alreadyExistsError}
          successBtnText={agents.errorModal.backBtn}
        />)
      default: return ("")
    }
  }

  const resetErrorHandler = () => {
    setErrorCode("")
    setUserRegModalVisible(true)
  }

  const deleteModalHandler = () => {
    setIsDeleteModalVisible(true)
  }

  const removeHandlerCallback = (id) => {
    props.removeAgent(id).then(resp => {
      if (resp.type === "REMOVE_AGENT_SUCCESS") {
        props.history.push("/dashboard/agents");
      }
    })
  }

  const goBackHandler = (e) => {
    e.preventDefault();
    props.history.goBack();
  }


  return (
    <>
      <div className="edit-agent-wrapper">
        {(props.agents.isFetching || isLoading) && <SpinComponent fullHeight={true} />}
        {switchModals(errorCode)}
        <RemoveModal
            isSaved={true}
            title={agents.deleteModal.heading}
            isModalVisible={isDeleteModalVisible}
            closeModal={setIsDeleteModalVisible}
            img={<WarningSvg />}
            text={<span>{agents.deleteModal.messagePartOne}<br />{agents.deleteModal.messagePartTwo}</span>}
            cancelBtnText={agents.deleteModal.cancelBtn}
            successBtnText={agents.deleteModal.deleteBtn}
            id={props.match.params.id}
            removeAgentHandler={removeHandlerCallback}
        />
        <InfoModal
          isSaved={true}
          title={"Увага!"}
          isModalVisible={isResetModalVisible}
          closeModal={setIsResetModalVisible}
          onSubmit={infoBackHandler}
          img={<WarningSvg />}
          text={<span>{agents.edit.cancelEditModalMessagePartOne}<br />{agents.edit.cancelEditModalMessagePartTwo}</span>}
          cancelBtnText={agents.edit.cancelEditModalCancelBtn}
          successBtnText={agents.edit.cancelEditModalStayBtn}
        />
        {single && Object.keys(single).length ?
          <>
          <div className="agent-profile-wrapper">
            <>
              <div className="profile-header">
                <h2>{!isEdited ? <><Link to={"/dashboard/agents"} className="goBack" onClick={goBackHandler}><BackSvg /></Link><img src={`${process.env.REACT_APP_AMAZON_URL}/${single.avatarId}`} alt="img"/>{`${single.lastName} ${single.firstName}`}</> : agents.edit.heading}</h2>
                {props.user?.userRole === "ROLE_ADMIN" && <div className="btn-wrapper">
                  {!isEdited ?
                    <>
                      <Button size="small" type="default" onClick={enableEdit} className="edit-agent-btn"><EditSvg />{agents.view.editBtnText}</Button>
                      <Button size="small" type="primary" onClick={deleteModalHandler}>
                        <BlockSvg />{agents.view.deleteBtnText}
                      </Button>
                    </>
                    :
                    <>
                      <Button size="small" type="default" onClick={resetModalHandler} className="edit-staff-cancel-btn"><DeclineSvg />{agents.edit.cancelBtnText}</Button>
                      <Button size="small" type="primary" onClick={submitHandler}><ApproveSvg />{agents.edit.saveChangesBtnText}</Button>
                    </>}
                </div>}
              </div>
              {!isEdited ?
                <>
                  <Row gutter={24} style={{marginBottom:16}}>
                    <Col span={6}>
                      <span className="title">{agents.fields.email}</span>
                      <p className="name">{single.email}</p>
                    </Col>
                    <Col span={6}>
                      <span className="title">{agents.fields.phone}</span>
                      <p className="name">{single.phone}</p>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={6}>
                      <span className="title">{agents.fields.region}</span>
                      <p className="name">{single.region}</p>
                    </Col>
                    <Col span={6}>
                      <span className="title">{agents.fields.district}</span>
                      <p className="name">{single.district}</p>
                    </Col>
                    <Col span={6}>
                      <span className="title">{agents.fields.locality}</span>
                      <p className="name">{single.locality}</p>
                    </Col>
                  </Row>
                </>
                : <Form name='edit-agent'
                        className='edit-agent-form'
                        form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    lastName: single.lastName,
                    firstName: single.firstName,
                    phone: single.phone.replace("+", ""),
                    email: single.email,
                    region: single.region,
                    district: single.district,
                    locality: single.locality,
                  }}>
                    <Row gutter={24}>
                      <Form.Item
                          name="lastName"
                          label={agents.fields.lastName}
                          rules={[
                            { required: true, message: agents.fields.requiredFieldErrorMessage},
                            { min: 2, message: agents.fields.lastNameErrorMessage },
                          ]}
                      >
                        <Input maxLength={50} />
                      </Form.Item>
                      <Form.Item
                          name="firstName"
                          label={agents.fields.firstName}
                          rules={[
                            { required: true, message: agents.fields.requiredFieldErrorMessage},
                            { min: 2, message: agents.fields.firstNameErrorMessage },
                          ]}
                      >
                        <Input maxLength={50} />
                      </Form.Item>
                      <Form.Item
                          label={agents.fields.email}
                          name={['email']}
                          rules={[{ required: true, message: agents.fields.requiredFieldErrorMessage}, { type: 'email', message: agents.fields.emailErrorMessage }]
                          }>
                        <Input />
                      </Form.Item>
                      <Form.Item
                          name="phone"
                          label={agents.fields.phone}
                          rules={[
                            { required: true, message: agents.fields.requiredFieldErrorMessage},
                          ]}
                      >
                        <Input className="tel-input" prefix={"+"} />
                      </Form.Item>
                    </Row>

                    <Row gutter={24}>
                      <Form.Item
                          name="region"
                          label={agents.fields.region}
                          rules={[
                            { required: true, message: agents.fields.requiredFieldErrorMessage}
                          ]}
                      >
                        <Select onChange={regionSelectHandler}>
                          {OnlyRegionsList.map((item, index) => (
                              <Select.Option key={index} value={item.value}>
                                {item.title}
                              </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                          name="district"
                          label={agents.fields.district}
                          rules={[
                            { required: true, message: agents.fields.requiredFieldErrorMessage }
                          ]}
                      >
                        <Select onChange={districtSelectHandler}>
                          {district.map((item, index) => (
                              <Select.Option key={index} value={item.district}>
                                {item.district}
                              </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                          name="locality"
                          label={agents.fields.locality}
                          rules={[
                            { required: true, message: agents.fields.requiredFieldErrorMessage }
                          ]}
                      >
                        <Select>
                          {locality.map((item, index) => (
                              <Select.Option key={index} value={`${item.localityType}|${item.locality}`}>
                                {`${item.localityType} ${item.locality}`}
                              </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Row>
                    <Row gutter={24}>
                      <Upload
                          className="ant-row ant-form-item"
                          maxCount={1}
                          beforeUpload={fileProps.beforeUpload}
                          onRemove={fileProps.onRemove}
                          fileList={fileList}
                          multiple={false}
                          accept=".jpg, .jpeg, .bmp, .gif, .png"
                      >
                        <Button className={isFileMissed && "red-border"} icon={<FolderSvg />}>{agents.fields.uploadImageLabel}</Button>
                        {unknownFormat && <span className="small-size-normal">{agents.fields.uploadImageErrorFormat}</span>}
                        {isOverSize && <span className="small-size-normal">{agents.fields.uploadImageErrorFileTooLarge}</span>}
                        {isFileMissed && <span className="small-size-normal" style={{ textAlign: "center" }}>{agents.fields.uploadImageErrorNoFile}</span>}
                      </Upload>
                    </Row>
                  </Form>
              }

            </>
          </div>
          <div className="agent-documents-wrapper">
            <h3 className="agent-documents-heading">{agents.list.documentsHeading}</h3>

            <DocumentsBlock sourceEntity="AGENT" type="CONTRACT_WITH_REPRESENTATIVE" agentID={props.match.params.id} documentsList={contractWithRepresentativeDocumentsList} documentsLoading={documentsLoading}/>
            <DocumentsBlock sourceEntity="AGENT" type="IDENTITY_DOCUMENT" agentID={props.match.params.id} documentsList={identityDocumentsList} documentsLoading={documentsLoading}/>
            <DocumentsBlock sourceEntity="AGENT" type="TAXPAYER_IDENTIFICATION_NUMBER" agentID={props.match.params.id} documentsList={taxpayerIdentificationNumberDocumentsList} documentsLoading={documentsLoading}/>
          </div>
          </>
          : null}
      </div>
    </>
  );
};

export default connector(EditAgentScreen);
