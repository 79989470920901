import { Button, Form, Input } from 'antd';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as LightLogo } from "../../assets/images/lightLogo.svg"
import { verificationSms, setVerifiCode, verificationCode } from 'store/verificationSms/actions';
import { IverificationCodeAction, IverificationCodeFailureAction, IVerificationCodePostData, IVerificationPostData, IverificationSmsAction, IverificationSmsFailureAction, IVerifiCode, PhoneActions } from 'store/verificationSms/types';
import {smsConfirmationStep} from '../../globalConstants/textinputs.json';

interface IProps {
  verificationSms: (data: IVerificationPostData) => Promise<IverificationSmsAction | IverificationSmsFailureAction>;
  regInfo: { [key: string]: string };
  goBackHandler: () => void;
  goNextStep: () => void;
  setVerifiCode: (data: IVerifiCode) => PhoneActions;
  verificationCode: (data: IVerificationCodePostData) => Promise<IverificationCodeAction | IverificationCodeFailureAction>;
  smsType: string;
  isLogo: boolean;
  title: string;
  text: string;
  form?: any;
}

const mapStateToProps = state => ({
  regInfo: state.regInfo,
});

const mapDispatchToProps = {
  verificationSms,
  setVerifiCode,
  verificationCode
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const SignUpSmsStep: FC<IProps> = (props) => {

  React.useEffect(() => {
    props.form.setFieldsValue({ verificationCode: "" })
    getCodeFunc()
  }, []);

  const getCodeFunc = () => {
    props.verificationSms({ "phone": props.regInfo.phone, "verificationType": props.smsType, "verificationSubject": "STAFF" }).then(resp => {
      console.log('aror', resp)
      if (resp.type === "GET_VERIFICATION_SMS_SUCCESS") {
        setStartCounter(true)
        setisVerify(false)
        setMinutes(0)
        setSeconds(59)
      }
      // else if (resp.type === "GET_VERIFICATION_SMS_FAILURE" && resp.payload.error === "ERR_OTP_003") {
      //   setStartCounter(true)
      //   setMinutes(4)
      //   setSeconds(59)
      //} 
      // else if (resp.type === "GET_VERIFICATION_SMS_FAILURE" && resp.payload.error === "ERR_OTP_001") {
      //   setStartCounter(true)
      //   setMinutes(0)
      //   setSeconds(59)
      // } 
      else {
        setStartCounter(true)
        setisVerify(false)
        let timer = Number((resp.payload.errorMsg.match(/\d+/g)).toString())
        let minutes = Math.floor(timer / 60);
        let seconds = timer - minutes * 60;
        setMinutes(minutes)
        setSeconds(seconds)
      }
    })
  }

  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const [startСounter, setStartCounter] = React.useState(false);

  const [isVerify, setisVerify] = React.useState(false);

  const [codeNumber, setCodeNumber] = React.useState<string>("");

  const [codeError, setCodeError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (startСounter) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
            setisVerify(true);
            setStartCounter(false);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval);
      };
    }
  });


  const renewCode = () => {
    getCodeFunc()
    //old code for renew
    // props.verificationSms({ "phone": props.regInfo.phone, "verificationType": props.smsType, "verificationSubject": "STAFF"}).then(resp => {
    //   if (resp?.verifiCode?.status === "OK") {
    //     setisVerify(false)
    //     setMinutes(0)
    //     setSeconds(59)
    //     setStartCounter(true)
    //   } else {
    //     setisVerify(false)
    //     setMinutes(4)
    //     setSeconds(59)
    //   }
    // })
  }

  const codeHandler = (e) => {
    setCodeNumber(e.target.value)
    props.setVerifiCode(e.target.value)
  }

  const verifiCodeHandler = () => {
    props.verificationCode({ code: codeNumber, phone: props.regInfo.phone, verificationType: props.smsType, "verificationSubject": "STAFF" }).then(resp => {
      if (resp.verifiCodeStatus?.data) {
        setCodeError(false)
        props.goNextStep()
      } else {
        setCodeError(true)
      }
    })
  }

  return (
    <div id="registerStep2">
      {props.isLogo ? <div className="img-wrapper">
        <LightLogo />
      </div> : null}
      <h3>{props.title}</h3>
      <p id="registration-subtitle">{props.text}</p>
      <Form.Item
        name="verificationCode"
        rules={[
          { required: true, message: smsConfirmationStep.requiredFieldErrorMessage }
        ]}
      >
        <Input
          onChange={codeHandler}
          placeholder={smsConfirmationStep.codeInputPlaceholder}
        />
      </Form.Item>
      {codeError ? <span className="small-size">{smsConfirmationStep.codeError}</span> : ""}
      <Button id="verifiCode" type="default" onClick={renewCode} disabled={isVerify ? false : true}>{smsConfirmationStep.sendCodeAgainBtn}
      {minutes === 0 && seconds === 0
          ? null
          : <span className="time-counter"> ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})</span>
        }
      </Button>
      <div className="btn-wrapper">
        <Button id="back-btn" type="default" onClick={props.goBackHandler}>{smsConfirmationStep.backBtn}</Button>
        <Button type="primary" id="reg-step-3" onClick={verifiCodeHandler} disabled={codeNumber.length > 3 ? false : true}>{smsConfirmationStep.proceedBtn}</Button>
      </div>
      <div>
      </div>
    </div>
  );
};

export default connector(SignUpSmsStep);