import React, {FC, useState} from 'react';
import { connect } from 'react-redux';
import "./EditContractScreen.scss";
import {
    getSingleContract,
    updateSingleContract,
    updateSingleContractStatus,
    cancelSingleContract, getNewContractsCount, getContractDocuments
} from "../../store/contracts/actions";
import {
    ICancelSingleContractFailureAction,
    ICancelSingleContractPendingAction,
    ICancelSingleContractSuccessAction,
    IGetContractBuyerDocumentsFailureAction,
    IGetContractBuyerDocumentsPendingAction,
    IGetContractBuyerDocumentsSuccessAction,
    IGetContractSellerDocumentsFailureAction,
    IGetContractSellerDocumentsPendingAction,
    IGetContractSellerDocumentsSuccessAction,
    IGetNewContractsFailureAction,
    IGetNewContractsSuccessAction,
    IgetSingleContractFailureAction,
    IgetSingleContractSuccessAction,
    IUpdateSingleContractFailureAction,
    IUpdateSingleContractPendingAction,
    IUpdateSingleContractStatusFailureAction,
    IUpdateSingleContractStatusPendingAction,
    IUpdateSingleContractStatusSuccessAction,
    IUpdateSingleContractSuccessAction
} from "../../store/contracts/types";
import { Link } from "react-router-dom";
import { ReactComponent as BackSvg } from "../../assets/images/ic-back.svg";
import { ReactComponent as SuccessFilledSvg } from '../../assets/images/ic-success-filled.svg'
import { Button, Col, Row, Form, Input, Select, Checkbox } from 'antd';
import { ReactComponent as EditSvg } from "../../assets/images/ic-edit.svg";
import { ReactComponent as DeclineSvg } from "../../assets/images/ic-choise-decline.svg";
import { ReactComponent as ApproveSvg } from "../../assets/images/ic-choise-approve.svg";
import { ReactComponent as FileBlueSvg } from "../../assets/images/ic-file-blue.svg";
import { ReactComponent as CancelSvg } from "../../assets/images/ic-cancel.svg";
import { ReactComponent as DownloadSvg } from "../../assets/images/ic-download-yellow.svg";
import { ReactComponent as ProfileSvg } from "../../assets/images/ic-profile-small.svg";
import { ReactComponent as ChatSvg } from "../../assets/images/ic-chat-message.svg";
import moment from 'moment';
import {
    contractPrepaymentMapper,
    contractPrepaymentStatuses,
    contractStatusMapper,
    dealStatuses, OptionsMapper,
    conludedDealStatuses,
    cancledDealStatuses,
    contractColorStatusMapper
} from "../../services/mapper";
import SpinComponent from "../../components/SpinComponent/SpinComponent";
import { useForm } from "antd/lib/form/Form";
import CancelModal from "../../components/CancelModal/CancelModal";
import { contracts } from '../../globalConstants/textinputs.json';
import OwnerInfoBlock from "../../components/OwnerInfoBlock/OwnerInfoBlock";
import DocumentsBlock from "../../components/DocumentsBlock/DocumentsBlock";
import IdentityAndTaxDocumentsBlock from "../../components/IdentityAndTaxDocumentsBlock/IdentityAndTaxDocumentsBlock";
import { getSingleAdvert } from "../../store/adverts/actions";
import { ISetSingleAdvertFailureAction, ISetSingleAdvertListAction } from "../../store/adverts/types";
import PrepaymentChangedModal from "../../components/PrepaymentChangedModal/PrepaymentChangedModal";
import {  DEAL_CANCELED_DEAL, DEAL_CONCLUDED_DEAL, EXPECTING_PREPAYMENT_DEAL, NEW_DEAL } from 'globalConstants';

interface IProps {
    match: any,
    history: any,
    contracts: any,
    advert: any,
    getSingleContract: (id: string) => Promise<IgetSingleContractSuccessAction | IgetSingleContractFailureAction>,
    updateSingleContract: (id: string, documentsVerified: boolean) => Promise<IUpdateSingleContractSuccessAction | IUpdateSingleContractPendingAction | IUpdateSingleContractFailureAction>,
    updateSingleContractStatus: ({}: { dealId: string; dealStatus: string; prepaymentStatus: string; prepaymentAmount?: string }) => Promise<IUpdateSingleContractStatusSuccessAction | IUpdateSingleContractStatusPendingAction | IUpdateSingleContractStatusFailureAction>,
    cancelSingleContract: (id: string, { reason: string }) => Promise<ICancelSingleContractSuccessAction | ICancelSingleContractPendingAction | ICancelSingleContractFailureAction>
    getNewContractsCount: () => Promise<IGetNewContractsSuccessAction | IGetNewContractsFailureAction>;
    getContractDocuments: (id: number, owner: string) => Promise<IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction | IGetContractSellerDocumentsPendingAction | IGetContractBuyerDocumentsPendingAction | IGetContractSellerDocumentsFailureAction
        | IGetContractBuyerDocumentsFailureAction>;
    getSingleAdvert: (id: string) => Promise<ISetSingleAdvertListAction | ISetSingleAdvertFailureAction>;
    userRole: string;
}

const mapStateToProps = state => ({
    contracts: state.contracts,
    advert: state.advert,
    userRole: state.user.userRole
});

const mapDispatchToProps = {
    getSingleContract,
    updateSingleContract,
    updateSingleContractStatus,
    cancelSingleContract,
    getNewContractsCount,
    getContractDocuments,
    getSingleAdvert
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const EditContractScreen: FC<IProps> = (props) => {

    const [isLoading, setIsLoading] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = React.useState(false);

    const { single } = props.contracts;

    const { getSingleContract, updateSingleContract, updateSingleContractStatus, cancelSingleContract, getNewContractsCount, getContractDocuments, getSingleAdvert } = props;

    const [otherDocumentsSeller, setOtherDocumentsSeller] = React.useState([]);

    const [landTitleDocumentsSeller, setLandTitleDocumentsSeller] = React.useState([]);

    const [identityAndTaxDocumentsSeller, setIdentityAndTaxDocumentsSeller] = React.useState<any>([]);

    const [otherDocumentsBuyer, setOtherDocumentsBuyer] = React.useState([]);

    const [landTitleDocumentsBuyer, setLandTitleDocumentsBuyer] = React.useState([]);

    const [identityAndTaxDocumentsBuyer, setIdentityAndTaxDocumentsBuyer] = React.useState<any>([]);

    const [ownerInfo, setOwnerInfo] = React.useState<any>()

    const [adStatusInfo, setAdStatusInfo] = React.useState<string>("")

    const [dealStatusInfo, setDealStatusInfo] = React.useState<any>("")

    const [isPrepaymentChangedModalVisible, setIsPrepaymentChangedModalVisible] = React.useState<boolean>(false);

    const [editDataForModal, setEditDataForModal] = React.useState<any>({});

    const [dealStatusForModal, setDealStatusForModal] = React.useState<string>("");

    const [singleContractForm] = useForm();

    const [prepaymentStatusValue, setPrepaymentStatusValue] = React.useState<string>(single.dealStatus);

    const [dealStatusValue, setDealStatusValue] = React.useState<string>(single.dealStatus);

    const edditingPrepaymentAllowed = React.useMemo(() => props.userRole === 'ROLE_ADMIN' || (single.dealStatus !== "DEAL_CONCLUDED" && single.dealStatus !== "DEAL_CANCELED" ),[single.dealStatus, props.userRole]);
    const edditinAllowed = React.useMemo(() => single.dealStatus !== "DEAL_CONCLUDED" && single.dealStatus !== "DEAL_CANCELED" , [single.dealStatus])

    React.useEffect(() => {
        let isCancelled = false;
        setIsLoading(true);
        getSingleContract(props.match.params.id).then(resp => {
            if (!isCancelled) {
                setIsLoading(false);
                getSingleAdvert(resp.preview.adId.id).then(resp => {

                    setAdStatusInfo(resp.preview.adStatus);
                    setDealStatusInfo(resp.preview.dealStatus);

                    if (resp.preview.adStatus === 'DEAL_IN_PROGRESS') {
                        setOwnerInfo({
                            firstName: resp.preview.ownerFirstName,
                            lastName: resp.preview.ownerLastName,
                            patronymic: resp.preview.ownerPatronymic,
                            phone: resp.preview.ownerPhone,
                            adID: resp.preview.id
                        })
                    }

                })
            }
        })

        return () => {
            isCancelled = true;
        }

    }, []);

    React.useEffect(() => {
        setDealStatusValue(single.dealStatus)
    }, [single.dealStatus])

    React.useEffect(() => {
        if (single.id && single.dealStatus !== NEW_DEAL) {
            getContractDocuments(single.id, "SELLER")
            getContractDocuments(single.id, "BUYER")
        }
    }, [single.id])

    React.useEffect(() => {
        let identityAndTaxDocumentsSeller = {
            taxpayerIdentificationNumber: props.contracts?.sellerDocuments?.taxpayerIdentificationNumber,
            identityDocument: {
                idCardNumber: props.contracts?.sellerDocuments?.identityDocument?.idCardNumber || null,
                number: props.contracts?.sellerDocuments?.identityDocument?.number || null,
                series: props.contracts?.sellerDocuments?.identityDocument?.series || null,
            }
        }
        setIdentityAndTaxDocumentsSeller(identityAndTaxDocumentsSeller)
        if (props.contracts?.sellerDocuments?.documents && props.contracts?.sellerDocuments?.documents?.length) {
            let otherDocumentsSellerTemp = props.contracts.sellerDocuments.documents.filter(item => item.documentType === "OTHER");
            let landTitleDocumentsSellerTemp = props.contracts.sellerDocuments.documents.filter(item => item.documentType === "LAND_TITLE_DOCUMENT");

            setOtherDocumentsSeller(otherDocumentsSellerTemp);
            setLandTitleDocumentsSeller(landTitleDocumentsSellerTemp);

        } else {
            setOtherDocumentsSeller([]);
            setLandTitleDocumentsSeller([]);
        }

        let identityAndTaxDocumentsBuyer = {
            taxpayerIdentificationNumber: props.contracts?.buyerDocuments?.taxpayerIdentificationNumber,
            identityDocument: {
                idCardNumber: props.contracts?.buyerDocuments?.identityDocument?.idCardNumber || null,
                number: props.contracts?.buyerDocuments?.identityDocument?.number || null,
                series: props.contracts?.buyerDocuments?.identityDocument?.series || null,
            }
        }

        setIdentityAndTaxDocumentsBuyer(identityAndTaxDocumentsBuyer)

        if (props.contracts?.buyerDocuments?.documents && props.contracts?.buyerDocuments?.documents?.length) {

            let otherDocumentsBuyerTemp = props.contracts.buyerDocuments.documents.filter(item => item.documentType === "OTHER");
            let landTitleDocumentsBuyerTemp = props.contracts.buyerDocuments.documents.filter(item => item.documentType === "LAND_TITLE_DOCUMENT");
            setOtherDocumentsBuyer(otherDocumentsBuyerTemp);
            setLandTitleDocumentsBuyer(landTitleDocumentsBuyerTemp);

        } else {
            setOtherDocumentsBuyer([]);
            setLandTitleDocumentsBuyer([]);
        }


    }, [props.contracts])


    const resetEdit = () => {
        singleContractForm.resetFields();
        setPrepaymentStatusValue(single.prepaymentStatus);
        setDealStatusValue(undefined);
        setIsEdit(false);
    }

    const FetchSingleContract = React.useCallback(() => {
        setIsLoading(true);
        getSingleContract(props.match.params.id).then(() => {
            setIsLoading(false);
            resetEdit();
        })

    }, [getSingleContract, props.match.params.id, resetEdit])

    const getSingleContractHandler = () => {
        setIsLoading(true);
        getSingleContract(props.match.params.id).then(resp => {
            setIsLoading(false);
            getSingleAdvert(resp.preview.adId.id).then(resp => {
                setOwnerInfo({
                    firstName: resp.preview.ownerFirstName,
                    lastName: resp.preview.ownerLastName,
                    patronymic: resp.preview.ownerPatronymic,
                    phone: resp.preview.ownerPhone,
                    adID: resp.preview.id
                })
            })
        })
    }

    const goBackHandler = () => {
        //e.preventDefault();
        //props.history.goBack();
    }

    const allowEditHandler = () => {
        setIsEdit(true);
    }

    const disallowEditHandler = () => {
        setIsEdit(false);
    }

    const onFinish = (values) => {
        let editData = {
            "documentsVerified": values.documentsVerified,
            "prepayment": values.prepayment,
            "prepaymentStatus": values.prepaymentStatus
        }

        if (values.dealStatus === "DEAL_CANCELED" && edditinAllowed) {
            handleCancelModalOpen();
        } else if (values.prepayment !== single.prepayment) {
            setEditDataForModal({
                ...editData,
            })

            setDealStatusForModal(values.dealStatus);

            setIsPrepaymentChangedModalVisible(true);
        } else {
            edditinAllowed && updateSingleContract(props.match.params.id, editData.documentsVerified)
            updateSingleContractStatus({ dealId: props.match.params.id, dealStatus: values.dealStatus, prepaymentStatus: values.prepaymentStatus, prepaymentAmount: values.prepayment }).then(() => {
                FetchSingleContract();
                getNewContractsCount();
            });
        }

    }

    const submitHandler = () => {
        singleContractForm.submit();
    }

    const handleCancelModalOpen = () => {
        setIsCancelModalVisible(true);
    }

    const handleCancelModalClose = () => {
        setIsCancelModalVisible(false);
    }

    const handleDealCancel = (id, data) => {
        cancelSingleContract(id, { ...data }).then(() => {
            handleCancelModalClose();
            FetchSingleContract();
            getNewContractsCount();
        })
    }

    const handlePrepaymentChangeCancel = () => {
        setIsPrepaymentChangedModalVisible(false);
        singleContractForm.setFieldsValue({
            prepayment: single.prepayment
        })
        setEditDataForModal({})
        setDealStatusForModal("");
    }

    const dealStatusOnChangeHandler = (value) => {
        setDealStatusValue(value);
    }

    const prepaymentStatusOnChangeHandler = (value) => {
        setPrepaymentStatusValue(value);
    }

    const dealStatusOptionsMapper = (dealStatus) => {
        switch (dealStatus) {
            case DEAL_CONCLUDED_DEAL:
                return OptionsMapper(conludedDealStatuses)
            case DEAL_CANCELED_DEAL:
                return OptionsMapper(cancledDealStatuses)
            default:
                return OptionsMapper(dealStatuses)
        }
    }

    const actualDealStatus = dealStatusValue || single.dealStatus;

    return (
        <div className='single-contract-screen'>
            {isLoading ? <SpinComponent fullHeight={true} /> :

                <>
                    <CancelModal isModalVisible={isCancelModalVisible} handleModalClose={handleCancelModalClose}
                        id={single.id} handleDealCancel={handleDealCancel} cancelEdit={disallowEditHandler} />

                    <PrepaymentChangedModal isModalVisible={isPrepaymentChangedModalVisible}
                        fetchSingleContract={FetchSingleContract} dealStatus={dealStatusForModal}
                        setDealStatus={setDealStatusForModal} dealID={props.match.params.id}
                        handlePrepaymentChangeCancel={handlePrepaymentChangeCancel}
                        editData={editDataForModal} setEditData={setEditDataForModal}
                        setModalVisibility={setIsPrepaymentChangedModalVisible} edditable={edditinAllowed} />

                    <div className="single-contract-info">
                        <div className="info-header">
                            {!isEdit ? <Link to={"/dashboard/contracts"} className="goBack"
                                onClick={goBackHandler}><BackSvg /></Link> : null}
                            <h2 className='header-title'>{contracts.single.cadastrialNo} {single.cadastralNumber} </h2>

                            {single.documentsVerified ?
                                <p className='header-documents-ok'>
                                    <SuccessFilledSvg />{contracts.single.documentsInOrder}</p> : null}
                            <div className="btn-wrapper">
                                {isEdit ? <>
                                    <Button size="small" type="default"
                                        className="edit-contract-cancel-btn"
                                        onClick={resetEdit}><DeclineSvg />{contracts.edit.cancelBtn}</Button>
                                    <Button size="small" type="primary" className='edit-contract-save-btn'
                                        onClick={submitHandler}><ApproveSvg />{contracts.edit.saveBtn}
                                    </Button>
                                </> :
                                    <> {
                                        !edditingPrepaymentAllowed ? null :
                                            <Button size="small" type="default" className="edit-contract-btn"
                                                onClick={allowEditHandler}><EditSvg />{contracts.view.editBtn}
                                            </Button>
                                    }
                                    </>
                                }
                            </div>
                        </div>
                        <div className={isEdit ? 'info-numbers' : 'view-mode info-numbers'}>
                            <p className='numbers-id'>{contracts.single.ID} {single.id}</p>
                            <p className="numbers-date">{moment.utc(single.createdDate).local().format('DD.MM.YYYY, HH:mm')}</p>
                        </div>
                        <div className="info-details">
                            {

                                isEdit ?
                                    <div className='forms-wrapper'>
                                        <Form name='update-deal' className='update-deal-form'
                                            initialValues={{
                                                prepaymentStatus: single.prepaymentStatus,
                                                prepayment: single.prepayment,
                                                documentsVerified: single.documentsVerified,
                                                dealStatus: single.dealStatus
                                            }}
                                            form={singleContractForm}
                                            onFinish={onFinish}>
                                            <Row gutter={24}>
                                                <Col span={12}>
                                                    <Form.Item name="dealStatus"
                                                        label={contracts.fields.dealStatus}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: contracts.fields.requiredFieldErrorMessage
                                                            }
                                                        ]}>
                                                        <Select onChange={dealStatusOnChangeHandler} disabled={!edditinAllowed}>
                                                            {dealStatusOptionsMapper(single.dealStatus)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name="prepaymentStatus"
                                                        label={contracts.fields.prepaymentStatus}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: contracts.fields.requiredFieldErrorMessage
                                                            }
                                                        ]}>
                                                        <Select
                                                            className="prepayment-select"
                                                            onChange={prepaymentStatusOnChangeHandler}
                                                        >
                                                            {OptionsMapper(contractPrepaymentStatuses)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name='prepayment'
                                                        label={contracts.fields.prepaymentAmount}
                                                        rules={[
                                                            {
                                                                required: actualDealStatus === EXPECTING_PREPAYMENT_DEAL,
                                                                message: contracts.fields.requiredFieldErrorMessage
                                                            }
                                                        ]}>
                                                        <Input
                                                            className="prepayment-input"
                                                            type="number"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {single.dealStatus !== NEW_DEAL && <Col span={6}>
                                                    <Form.Item name='documentsVerified'
                                                        valuePropName='checked'>
                                                        <Checkbox>{contracts.single.documentsInOrder}</Checkbox>
                                                    </Form.Item>
                                                </Col>}
                                            </Row>
                                        </Form>
                                    </div>
                                    :
                                    <div className='details-readonly'>
                                        <Row gutter={24}>
                                            <Col span={6}>
                                                <span className='details-title'>{contracts.fields.dealStatus}</span>
                                                <p className="details-bg-text"><span style={{ backgroundColor: contractColorStatusMapper(single.dealStatus) }} className='details-text'>{single.dealStatus ? contractStatusMapper(single.dealStatus) : contracts.fields.notStated}</span></p>
                                            </Col>
                                            <Col span={6}>
                                                <span className='details-title'>{contracts.fields.dealPrice}</span>
                                                <p className='details-text'>{single.price ? `${single.price} ${contracts.fields.currency}` : contracts.fields.notStated}</p>
                                            </Col>
                                            <Col span={6}>
                                                <span
                                                    className='details-title'>{contracts.fields.prepaymentStatus}</span>
                                                <p className='details-text'>{single.prepaymentStatus ? contractPrepaymentMapper(single.prepaymentStatus) : contracts.fields.notStated}</p>
                                            </Col>
                                            <Col span={6}>
                                                <span
                                                    className='details-title'>{contracts.fields.prepaymentAmount}</span>
                                                <p className='details-text'>{single.prepayment ? `${single.prepayment} ${contracts.fields.currency}` : contracts.fields.notStated}</p>
                                            </Col>
                                        </Row>
                                        {single.dealStatus === "DEAL_CANCELED" &&
                                            <Row gutter={24}>
                                                <Col span={6}>
                                                    <span
                                                        className="details-title">{contracts.fields.cancellationDate}</span>
                                                    <p className="details-text">{single.cancelDate ? moment.utc(single.cancelDate).local().format('DD.MM.YYYY, HH:mm') : contracts.fields.notStated}</p>
                                                </Col>
                                                <Col span={6}>
                                                    <span
                                                        className="details-title">{contracts.fields.cancellationAuthor}</span>
                                                    <p className="details-text">{single.actor?.fio?.lastName || contracts.fields.notStated} {single.actor?.fio?.firstName || contracts.fields.notStated}</p>
                                                </Col>
                                                <Col span={9}>
                                                    <span
                                                        className="details-title">{contracts.fields.cancellationReason}</span>
                                                    <p className="details-text">{single.cancelReason || contracts.fields.notStated}</p>
                                                </Col>
                                            </Row>
                                        }

                                    </div>
                            }
                        </div>
                        {
                            !isEdit && <div className="info-buttons">
                                <Row gutter={16}>
                                    {
                                        single.adId?.id &&
                                        <Col span={6}>

                                            <Link to={{
                                                pathname: `/dashboard/advert/${single.adId?.id}`, state: {
                                                    from: "dealPage", fromId: props.match.params.id
                                                }
                                            }}>
                                                <Button type="default"> <FileBlueSvg />{contracts.buttons.goToAdvert}</Button>
                                            </Link>
                                        </Col>
                                    }

                                    {
                                        single.dealStatus === "DEAL_CONCLUDED" || single.dealStatus === "DEAL_CANCELED" ? null :
                                            <>
                                                <Col span={6}>
                                                    <Button type="default" onClick={handleCancelModalOpen}>
                                                        <CancelSvg />{contracts.buttons.cancelDeal}</Button>
                                                </Col>
                                                <Col span={6}>
                                                    <Button
                                                        type="default"><DownloadSvg />{contracts.buttons.downloadInvoice}
                                                    </Button>
                                                </Col>
                                            </>

                                    }

                                </Row>
                            </div>
                        }

                    </div>

                    <div className="single-seller-info">
                        <div className="info-header">
                            <h3 className='header-title'>{contracts.sellerAndOwnerInfo.heading}</h3>
                            <div className="btn-wrapper">
                                <Link to={`/dashboard/users/${single.seller?.id}`} className="info-link"><Button
                                    size="small" type="primary"><ProfileSvg />{contracts.sellerAndOwnerInfo.profileBtn}
                                </Button></Link>
                                <Button size="small" type="primary"
                                    className="info-btn"><ChatSvg />{contracts.sellerAndOwnerInfo.writeBtn}</Button>
                            </div>
                        </div>
                        <div className="info-details">
                            <Row gutter={24} className="details-row">
                                <Col span={8}>
                                    <span className='details-title'>{contracts.sellerAndOwnerInfo.sellerLabel}</span>
                                    <p className='details-text'>{single.seller?.fio?.lastName || contracts.fields.notStated} {single.seller?.fio?.firstName || contracts.fields.notStated}</p>
                                </Col>
                                <Col span={6}>
                                    <span className='details-title'>{contracts.sellerAndOwnerInfo.phoneLabel}</span>
                                    <p className='details-text'>{single.sellerPhone || contracts.fields.notStated}</p>
                                </Col>
                                <Col span={6}>
                                    <span className='details-title'>{contracts.sellerAndOwnerInfo.emailLabel}</span>
                                    <p className='details-text'>{single.sellerEmail || contracts.fields.notStated}</p>
                                </Col>
                            </Row>
                            <>
                                {
                                    //adStatusInfo === 'DEAL_IN_PROGRESS' && dealStatusInfo !== 'NEW' && dealStatusInfo !== DEAL_CANCELED_DEAL ?
                                        <>
                                            {ownerInfo &&
                                                <>
                                                    <div className="owner-row-wrap">
                                                        <OwnerInfoBlock ownerLastName={ownerInfo.lastName}
                                                            ownerFirstName={ownerInfo.firstName}
                                                            ownerPatronymic={ownerInfo.patronymic}
                                                            ownerPhone={ownerInfo.phone}
                                                            adID={ownerInfo.adID}
                                                            updateAdvert={getSingleContractHandler} />
                                                    </div>
                                                    {single.dealStatus !== NEW_DEAL &&
                                                        <>
                                                            {landTitleDocumentsSeller && single.dealStatus !== DEAL_CANCELED_DEAL ?
                                                                <DocumentsBlock sourceEntity="DEAL" activeDealID={single.id} type="LAND_TITLE_DOCUMENT"
                                                                    owner="SELLER"
                                                                    documentsList={landTitleDocumentsSeller} />
                                                                : null}
                                                            {identityAndTaxDocumentsSeller && single.dealStatus !== DEAL_CANCELED_DEAL ?
                                                                <IdentityAndTaxDocumentsBlock
                                                                    documentsObj={identityAndTaxDocumentsSeller}
                                                                    dealID={single.id} owner="SELLER" />
                                                                : null}
                                                            {otherDocumentsSeller && single.dealStatus !== DEAL_CANCELED_DEAL ?
                                                                <DocumentsBlock sourceEntity="DEAL" activeDealID={single.id} type="OTHER" owner="SELLER"
                                                                    documentsList={otherDocumentsSeller} />
                                                                : null}

                                                        </>}
                                                </>
                                            } </>
                                            //: null
                                }


                            </>
                        </div>
                    </div>

                    <div className="single-buyer-info">
                        <div className="info-header">
                            <h3 className='header-title'>{contracts.buyerInfo.heading}</h3>
                            <div className="btn-wrapper">
                                <Link to={`/dashboard/users/${single.buyer?.id}`} className="info-link"><Button
                                    size="small" type="primary"><ProfileSvg />{contracts.buyerInfo.profileBtn}
                                </Button></Link>
                                <Button size="small" type="primary"
                                    className="info-btn"><ChatSvg />{contracts.buyerInfo.writeBtn}</Button>
                            </div>
                        </div>
                        <div className="info-details">
                            <Row gutter={24} className="details-row">
                                <Col span={8}>
                                    <span className='details-title'>{contracts.buyerInfo.sellerLabel}</span>
                                    <p className='details-text'>{single.buyer?.fio?.lastName || contracts.fields.notStated} {single.buyer?.fio?.firstName || contracts.fields.notStated}</p>
                                </Col>
                                <Col span={6}>
                                    <span className='details-title'>{contracts.buyerInfo.phoneLabel}</span>
                                    <p className='details-text'>{single.buyerPhone || contracts.fields.notStated}</p>
                                </Col>
                                <Col span={6}>
                                    <span className='details-title'>{contracts.buyerInfo.emailLabel}</span>
                                    <p className='details-text'>{single.buyerEmail || contracts.fields.notStated}</p>
                                </Col>
                            </Row>
                        </div>
                        {single.dealStatus !== NEW_DEAL && <>
                            {//adStatusInfo === 'DEAL_IN_PROGRESS' && dealStatusInfo !== 'NEW' && dealStatusInfo !== DEAL_CANCELED_DEAL ?
                                <>
                                    {/* <DocumentsBlock sourceEntity="DEAL" activeDealID={single.id} type="LAND_TITLE_DOCUMENT" owner="BUYER"
                                                documentsList={landTitleDocumentsBuyer}/> */}
                                    {identityAndTaxDocumentsBuyer && single.dealStatus !== DEAL_CANCELED_DEAL ? <IdentityAndTaxDocumentsBlock documentsObj={identityAndTaxDocumentsBuyer}
                                        dealID={single.id}
                                        owner="BUYER" />
                                        : null}
                                    {otherDocumentsBuyer && single.dealStatus !== DEAL_CANCELED_DEAL ? <DocumentsBlock sourceEntity="DEAL" activeDealID={single.id} type="OTHER" owner="BUYER"
                                        documentsList={otherDocumentsBuyer} />
                                        : null}
                                </>
                                //: null
                                }
                        </>}

                    </div>
                </>
            }
        </div>
    );
};

export default connector(EditContractScreen);
