import React, { FC, useEffect } from 'react';
import "./ChatList.scss";
import { ReactComponent as NewChatSvg } from 'assets/images/ic-chat-new.svg'
import { ReactComponent as InfoSvg } from 'assets/images/ic-info.svg'
import { connect, useDispatch } from 'react-redux';
import { Client, Stomp } from '@stomp/stompjs'
import SimpleModal from 'components/SimpleModal/SimpleModal';
import { Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ISetUsersFailureAction, ISetUsersListAction, ISetUsersPendingAction } from 'store/users/types';
import { getUsersList } from 'store/users/actions';
import { getStaffList } from 'store/staff/actions';
import { IsetAddListAction } from 'store/adverts/types';
import { getAdvertList } from 'store/adverts/actions';
import { createChat, getChatList, setSingleUserChatInfo, getAdminChatList, getTagsList, updateSearch } from 'store/chats/actions';
import { IcreateChatFailureAction, IcreateChatSuccessAction } from 'store/chats/types';
import moment from 'moment';
import { sha512 } from 'js-sha512';
import ChatSearch from 'components/ChatSearch/ChatSearch';

interface IProps {
  user: any;
  chats: any;
  getUsersList: (sortBy: string, sortType: string, page?: number, size?: number, rsql?: string) => Promise<ISetUsersListAction>;
  getAdvertList: (sortBy: string, sortType: string, page?: number, size?: number, rsql?: string) => Promise<IsetAddListAction>;
  createChat: (data: any) => Promise<IcreateChatSuccessAction | IcreateChatFailureAction>;
  getChatList: any;
  getAdminChatList: any;
  setSingleUserChatInfo: any;
  socketClient?: any;
  getTagsList: any;
  getStaffList: any;
  updateSearch: any;
}

const mapStateToProps = state => ({
  chats: state.chats,
  user: state.user
});

const mapDispatchToProps = {
  getUsersList,
  getAdvertList,
  createChat,
  getChatList,
  setSingleUserChatInfo,
  getAdminChatList,
  getTagsList,
  getStaffList,
  updateSearch
};

const connector = connect(mapStateToProps, mapDispatchToProps)

const ChatList: FC<IProps> = (props) => {

  const dispatch = useDispatch();

  const [form] = useForm();

  const [person, setPerson] = React.useState(null);

  const [personList, setPersonList] = React.useState([]);

  const [advertList, setAdvertList] = React.useState([]);

  const [isChatActive, setisChatActive] = React.useState(null);

  const [chatTypeState, setChatTypeState] = React.useState({ chatType: 'all', active: false })

  const [tagsList, isSetTags] = React.useState([])

  const [staffList, isSetStaffList] = React.useState([])

  const { socketClient } = props

  const { userInfo } = props.user

  let pageContentParams = { "pageNumber": 0, "pageSize": 2000 }

  useEffect(() => {
    props.getTagsList('id', 'desc', 0, 2000).then(resp => {
      isSetTags(resp.preview.content)
    })
    props.getStaffList('id', 'desc', 0, 2000).then(resp => {
      isSetStaffList(resp.preview.content)
    })
    if (props.user.userInfo.id && props.user.userRole === "ROLE_MANAGER") {
      props.getChatList(userInfo.id)
    }
    if (props.user.userInfo.id && props.user.userRole === "ROLE_ADMIN") {
      // props.getChatList(userInfo.id)
      props.getAdminChatList(pageContentParams)
    }
  }, [props.user.userInfo.id])

  //const socketClient = new Client()
  // const socketClient = new Client({
  //   //brokerURL: `ws://163.172.103.125:8060/chat-service/farmland-chats`,
  //   brokerURL: `wss://api.farmland.t.coreteka.com:443/chat-service/farmland-chats`
  // });
  useEffect(() => {
    if (props.user.userInfo.versionUuid) {
      //console.log('zalup')
      //let shaUuid = sha512(props.user.userInfo.versionUuid)
      socketClient.onConnect = () => {
        socketClient.subscribe(`/topic/user.${props.user.userInfo.versionUuid}`, (notif) => { ///   /topic/user.${props.user.userInfo.id}
          // dispatch(setNotificationAction(JSON.parse(notif.body)))
        })
      }
      socketClient.activate()
    }
    return () => {
      socketClient.deactivate();
      props.setSingleUserChatInfo([])
    };
  }, [props.user.userInfo.versionUuid])


  const [isSimpleModalVisible, setIsSimpleModalVisible] = React.useState(false);

  const SimpleModalHandler = () => {
    setIsSimpleModalVisible(true)
  }

  const startChatFunc = (list, id) => {
    dispatch({
      type: 'GET_SINGLE_CHAT_SUCCESS',
    })
    setisChatActive(id)
    let single = list.filter(item => item.chatId === id)
    props.setSingleUserChatInfo(single)
  }

  const onFinish = (values: any) => {

    props.createChat(prepareCreateChat(values)).then(resp => {

      if (props.user.userInfo.id && props.user.userRole === "ROLE_MANAGER") {
        props.getChatList(userInfo.id).then(ress => {
          startChatFunc(ress.preview.content, resp.preview.data.chatId)
        })
      }
      if (props.user.userInfo.id && props.user.userRole === "ROLE_ADMIN") {
        props.getAdminChatList(pageContentParams).then(ress => {
          startChatFunc(ress.preview.content, resp.preview.data.chatId)
        })
      }
    })
    setIsSimpleModalVisible(false)
  }

  const prepareCreateChat = (values) => {
    let addIdVal = null
    let roomTypeVal = "GENERAL"
    if (values.adverts !== "GENERAL") {
      addIdVal = values.adverts
      roomTypeVal = "AD_RELATED"
    }
    return (
      {
        adId: addIdVal,
        customerId: values.customerID,
        roomType: roomTypeVal
      }
    )
  }

  const formSubmitHandler = () => {
    form.submit()
  }

  const handleSelectPersonChange = (value) => {
    setPerson(value)
    props.getAdvertList('id', 'desc', 0, 2000, `&search=author.id==${value}`).then(resp => {
      setAdvertList(resp.preview.content)
    })
  }

  const handlePersonSearch = (value) => {
    if (value.length > 2) {
      props.getUsersList('id', 'desc', 0, 2000, `&search=phone==*${value}*,fio.firstName==*${value}*,fio.lastName==*${value}*`).then(resp => {
        setPersonList(resp.preview.content)
      })
    }
  }

  const activeChatHandler = (index) => e => {
    dispatch({
      type: 'GET_SINGLE_CHAT_SUCCESS',
    })
    setisChatActive(index)
    let single = props.chats.list.content.filter(item => item.chatId === index)
    props.setSingleUserChatInfo(single)
  }

  const chatListMapper = (value) => {
    return value.map(item => {
      const className = isChatActive === item.chatId ? 'chat-item active' : 'chat-item';
      return (
        <div className={className} key={item.chatId} onClick={activeChatHandler(item.chatId)}>
          <div className="top-side">
            <span>{`${item.customer?.fio.lastName} ${item.customer?.fio.firstName}`}</span>
            <span className="date">{moment.utc(item.customer?.createdDate).local().format('YYYY.MM.DD, HH:mm')}</span>
            <span className="unreaded">{item.unreadCount}</span>
          </div>
          <p>
            {item.lastMessage?.content?.length > 99 ? `${item.lastMessage?.content.slice(0, 100)} ...` : item.lastMessage?.content}
          </p>
        </div>
      )
    })
  }

  const toggleChatType = (value) => e => {
    setChatTypeState({ chatType: value, active: !chatTypeState.active })
    if (value === "all") {
      props.getAdminChatList({ ...pageContentParams, ...props.chats.searchParams })
    } else if (value === "myChat") {
      props.getAdminChatList({ managerId: userInfo.id, ...pageContentParams, ...props.chats.searchParams })
    }
  }

  const searchHandler = (values) => {
    // Object.keys(values).forEach(chatItem => {
    //   if (values[chatItem] == undefined || values[chatItem] == "") {
    //     values[chatItem] = null
    //   }
    //   return values[chatItem]
    // })
    if (values) {
      let startDate = values.startDate ? moment(values.startDate).format('YYYY-MM-DDThh:mm:ss.SSSSSS') : null
      let finishDate = values.finishDate ? moment(values.finishDate).format('YYYY-MM-DDThh:mm:ss.SSSSSS') : null
      let data = {
        // "averageWaitTimeRange": {
        //   "max": 0,
        //   "min": 0
        // },
        "managerId": values.manager || userInfo.id,
        // "maximumWaitTime": 0,
        "pageNumber": 0,
        "pageSize": 2000,
        // "qualityRates": [
        //   "EXCELLENT"
        // ],
        "search": values.searchLine,
        "tagId": values.tag,
        "timeline": {
          "end": finishDate,
          "start": startDate
        }
      }
      props.getAdminChatList(data)
      props.updateSearch(data)
      //setSearchParams(data)
    } else {
      props.getAdminChatList(pageContentParams)
      //setSearchParams({})
    }

  }

  return (
    <div className="left-side">
      <SimpleModal
        isSaved={true}
        title={"Новий чат"}
        isModalVisible={isSimpleModalVisible}
        closeModal={setIsSimpleModalVisible}
        cancelBtnText={"Скасувати"}
        successBtnText={"Розпочати"}
        onSubmit={formSubmitHandler}
        modalName={"lefted"}
        fileList={[1]}
      >
        <Form
          name="new-chat"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="customerID"
            rules={[
              { required: true, message: 'Заповнiть це поле' }
            ]}
          >
            <Select
              showSearch
              // value={this.state.value}
              placeholder="Виберіть користувача…"
              // style={this.props.style}
              // defaultActiveFirstOption={false}
              // showArrow={false}
              filterOption={false}
              onSearch={handlePersonSearch}
              onChange={handleSelectPersonChange}
              notFoundContent={null}
            >
              {personList.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {`${item.fio?.lastName} ${item.fio?.firstName}, ${item.phone}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="adverts"
            rules={[
              { required: true, message: 'Заповнiть це поле' }
            ]}
          >
            <Select
              placeholder="Виберіть тему спілкування…"
            >
              <Select.Option key={'unik'} value={'GENERAL'}>
                Загальні питання
              </Select.Option>
              {advertList.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {`Оголошення ID ${item.id} / №ДЗК: ${item.cadastralNumber}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </SimpleModal>
      <div className="head">
        <h2>Чати</h2>
        <NewChatSvg onClick={SimpleModalHandler} />
      </div>
      <div className="chat-search-wrapper">
        <ChatSearch tagList={tagsList} staffList={staffList} searchHandler={searchHandler} searchParams={props.updateSearch} userRole={props.user.userRole} />
      </div>
      {props.user.userRole === "ROLE_ADMIN" ?
        <div className="chat-buttons-wrapper">
          <button className={chatTypeState.chatType === 'myChat' ? 'active' : null} onClick={toggleChatType("myChat")}>Мої чати</button>
          <button className={chatTypeState.chatType === 'all' ? 'active' : null} onClick={toggleChatType("all")}>Всі чати</button>
        </div>
        : null}
      {props.chats.list && props.chats.list.content?.length ?
        <div className="chat-list-wrapper">
          {chatListMapper(props.chats.list.content)}
        </div> :
        <div className="empty-wrapper">
          <InfoSvg />
          <p>У вас ще немає чатів.<br />
            Натисніть на кнопку “Новий чат”<br />  для старту чату
          </p>
        </div>
      }
      {console.log(props.chats.list.content)}
    </div>
  );
};

export default connector(ChatList);
