import { Button, Modal, Upload } from 'antd';
import React, { FC } from 'react';
import { ReactComponent as FolderSvg } from 'assets/images/ic-folder.svg'
import { connect } from 'react-redux';
import {contracts} from '../../globalConstants/textinputs.json';
import './UploadDocumentsModal.scss';
import {getAgentDocuments, postAgentDocuments} from "../../store/agents/actions";
import {
    DocumentTypes, IGetAgentsDocumentsFailureAction,
    IGetAgentsDocumentsPendingAction,
    IGetAgentsDocumentsSuccessAction, IPostAgentDocumentsFailureAction, IPostAgentDocumentsSuccessAction
} from "../../store/agents/types";
import {
    IGetContractBuyerDocumentsFailureAction,
    IGetContractBuyerDocumentsPendingAction,
    IGetContractBuyerDocumentsSuccessAction,
    IGetContractSellerDocumentsFailureAction,
    IGetContractSellerDocumentsPendingAction,
    IGetContractSellerDocumentsSuccessAction,
    IPostContractDocumentFailureAction,
    IPostContractDocumentSuccessAction
} from "../../store/contracts/types";
import {getContractDocuments, postContractDocuments} from "../../store/contracts/actions";

interface IProps {
    sourceEntity: "DEAL" | "AGENT";
    type: DocumentTypes;
    owner?: "SELLER" | "BUYER";
    activeDealID?: number;
    agentID?: number;
    isModalVisible: boolean;
    setModalVisibility: (boolean) => void;
    postContractDocuments: (activeDealID: number, documentData: any, owner: string, type: DocumentTypes) => Promise<IPostContractDocumentSuccessAction | IPostContractDocumentFailureAction>;
    getContractDocuments: (id: number, owner: string) => Promise<IGetContractSellerDocumentsSuccessAction | IGetContractBuyerDocumentsSuccessAction | IGetContractSellerDocumentsPendingAction | IGetContractBuyerDocumentsPendingAction | IGetContractSellerDocumentsFailureAction
        | IGetContractBuyerDocumentsFailureAction>;
    getAgentDocuments: (id: number) => Promise<IGetAgentsDocumentsSuccessAction | IGetAgentsDocumentsPendingAction | IGetAgentsDocumentsFailureAction>;
    postAgentDocuments: (agentID: number, documentData: any, type: DocumentTypes) => Promise<IPostAgentDocumentsSuccessAction | IPostAgentDocumentsFailureAction>;
}


const mapDispatchToProps = {
    postContractDocuments,
    getContractDocuments,
    postAgentDocuments,
    getAgentDocuments
};

const connector = connect(null, mapDispatchToProps);

export const UploadDocumentModal: FC<IProps> = (props) => {

    const [fileList, setfileList] = React.useState([]);

    const [isOverSize, setisOverSize] = React.useState(false);

    const [isUploading, setIsUploading] = React.useState<boolean>(false);

    const fileProps = {
        onRemove: file => {
            setfileList([])
            setisOverSize(false)
            setisOverSize(false)
        },
        beforeUpload: file => {
            const isLt2M = file.size / 1024 / 1024 < 25;
            if (!isLt2M) {
                file.flag = true;
                setisOverSize(true)
                return false;
            }
            setisOverSize(false)
            setfileList([file])
            return false;
        }
    };

    const handleCancel = () => {
        props.setModalVisibility(false);
        setfileList([]);
    };

    const handleOk = () => {
        props.setModalVisibility(false);
        setIsUploading(false);
        setfileList([]);

        switch (props.sourceEntity) {
            case "DEAL": {
                props.getContractDocuments(props.activeDealID, props.owner);
                break;
            }
            case "AGENT": {
                props.getAgentDocuments(props.agentID);
                break;
            }

            default: {
                break;
            }
        }

    }

    const onFinish = () => {
        setIsUploading(true);

        switch (props.sourceEntity) {
            case "DEAL": {
                props.postContractDocuments(props.activeDealID, fileList, props.owner, props.type).then(resp => {
                    if(resp.type === "POST_CONTRACT_DOCUMENT_SUCCESS") {
                        handleOk();
                    }
                })
                break;
            }

            case "AGENT": {
                props.postAgentDocuments(props.agentID, fileList, props.type).then(resp => {
                    if (resp.type === "POST_AGENT_DOCUMENT_SUCCESS") {
                        handleOk();
                    }
                })

                break;
            }

            default : {
                break;
            }

        }


    };


    return (
        <Modal title={contracts.documents.uploadDocumentModalTitle}
               visible={props.isModalVisible}
               closable={false}
               onOk={handleOk}
               onCancel={handleCancel}
               footer={null}
               className="upload-document-modal"
               centered={true}
        >
            <Upload
                maxCount={1}
                beforeUpload={fileProps.beforeUpload}
                onRemove={fileProps.onRemove}
                fileList={fileList}
                multiple={false}
            >
                <Button icon={<FolderSvg />}>{contracts.documents.uploadDocumentModalOpenFileBtn}</Button>
                {(!isOverSize) && <ul className="upload-rules-list">
                    <li>{contracts.documents.uploadDocumentModalSizeFootnote}</li>
                </ul>}
                {isOverSize && <span className="small-size-normal">{contracts.documents.uploadDocumentModalErrorFileTooLarge}</span>}
            </Upload>
            <div className="btn-wrapper">
                <Button type="default" className="btn" key={'2'} onClick={handleCancel}>
                    {contracts.documents.uploadDocumentModalCloseBtn}
                </Button>
                <Button type="primary" className="btn" key={'1'} onClick={onFinish} disabled={!fileList.length || isUploading}>
                    {contracts.documents.uploadDocumentModalSaveFileBtn}
                </Button>
            </div>
        </Modal>
    );
};

export default connector(UploadDocumentModal);